// Generated by purs version 0.14.5
"use strict";
var Data_Eq = require("../Data.Eq/index.js");
var Data_Generic_Rep = require("../Data.Generic.Rep/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Nullable = require("../Data.Nullable/index.js");
var Data_Variant_Internal = require("../Data.Variant.Internal/index.js");
var Kishimen = require("../Kishimen/index.js");
var TofuJSON = require("../TofuJSON/index.js");
var Types_ClassificationGroup = require("../Types.ClassificationGroup/index.js");
var Types_Component = require("../Types.Component/index.js");
var Types_Element = require("../Types.Element/index.js");
var Types_JSDateString = require("../Types.JSDateString/index.js");
var Types_Newtypes = require("../Types.Newtypes/index.js");
var Types_Project = require("../Types.Project/index.js");
var Types_Unit = require("../Types.Unit/index.js");
var InPlaceResourceUpdate = (function () {
    function InPlaceResourceUpdate() {

    };
    InPlaceResourceUpdate.value = new InPlaceResourceUpdate();
    return InPlaceResourceUpdate;
})();
var SplitElementComponent = (function () {
    function SplitElementComponent(value0) {
        this.value0 = value0;
    };
    SplitElementComponent.create = function (value0) {
        return new SplitElementComponent(value0);
    };
    return SplitElementComponent;
})();
var InternalOrgResourcePriceIdMatch = (function () {
    function InternalOrgResourcePriceIdMatch() {

    };
    InternalOrgResourcePriceIdMatch.value = new InternalOrgResourcePriceIdMatch();
    return InternalOrgResourcePriceIdMatch;
})();
var CostClassGroupSortingNumberMatch = (function () {
    function CostClassGroupSortingNumberMatch() {

    };
    CostClassGroupSortingNumberMatch.value = new CostClassGroupSortingNumberMatch();
    return CostClassGroupSortingNumberMatch;
})();
var FindPriceMatchesForResources = (function () {
    function FindPriceMatchesForResources(value0) {
        this.value0 = value0;
    };
    FindPriceMatchesForResources.create = function (value0) {
        return new FindPriceMatchesForResources(value0);
    };
    return FindPriceMatchesForResources;
})();
var CopyComponentOnly = (function () {
    function CopyComponentOnly() {

    };
    CopyComponentOnly.value = new CopyComponentOnly();
    return CopyComponentOnly;
})();
var CopyComponentWithResources = (function () {
    function CopyComponentWithResources() {

    };
    CopyComponentWithResources.value = new CopyComponentWithResources();
    return CopyComponentWithResources;
})();
var CopyComponentWithResourcesAndLocations = (function () {
    function CopyComponentWithResourcesAndLocations() {

    };
    CopyComponentWithResourcesAndLocations.value = new CopyComponentWithResourcesAndLocations();
    return CopyComponentWithResourcesAndLocations;
})();
var EditEstimationElementDescription = (function () {
    function EditEstimationElementDescription(value0) {
        this.value0 = value0;
    };
    EditEstimationElementDescription.create = function (value0) {
        return new EditEstimationElementDescription(value0);
    };
    return EditEstimationElementDescription;
})();
var EditEstimationElementRtCode = (function () {
    function EditEstimationElementRtCode(value0) {
        this.value0 = value0;
    };
    EditEstimationElementRtCode.create = function (value0) {
        return new EditEstimationElementRtCode(value0);
    };
    return EditEstimationElementRtCode;
})();
var EditEstimationElementRtDescription = (function () {
    function EditEstimationElementRtDescription(value0) {
        this.value0 = value0;
    };
    EditEstimationElementRtDescription.create = function (value0) {
        return new EditEstimationElementRtDescription(value0);
    };
    return EditEstimationElementRtDescription;
})();
var EditEstimationElementUnit = (function () {
    function EditEstimationElementUnit(value0) {
        this.value0 = value0;
    };
    EditEstimationElementUnit.create = function (value0) {
        return new EditEstimationElementUnit(value0);
    };
    return EditEstimationElementUnit;
})();
var EditEstimationElementMemo = (function () {
    function EditEstimationElementMemo(value0) {
        this.value0 = value0;
    };
    EditEstimationElementMemo.create = function (value0) {
        return new EditEstimationElementMemo(value0);
    };
    return EditEstimationElementMemo;
})();
var EditEstimationElementSubprojectId = (function () {
    function EditEstimationElementSubprojectId(value0) {
        this.value0 = value0;
    };
    EditEstimationElementSubprojectId.create = function (value0) {
        return new EditEstimationElementSubprojectId(value0);
    };
    return EditEstimationElementSubprojectId;
})();
var EditEstimationElementCode = (function () {
    function EditEstimationElementCode(value0) {
        this.value0 = value0;
    };
    EditEstimationElementCode.create = function (value0) {
        return new EditEstimationElementCode(value0);
    };
    return EditEstimationElementCode;
})();
var AddEstimationElementAmountForWildcardLocation = (function () {
    function AddEstimationElementAmountForWildcardLocation(value0) {
        this.value0 = value0;
    };
    AddEstimationElementAmountForWildcardLocation.create = function (value0) {
        return new AddEstimationElementAmountForWildcardLocation(value0);
    };
    return AddEstimationElementAmountForWildcardLocation;
})();
var EditEstimationElementAmountForSingleLocation = (function () {
    function EditEstimationElementAmountForSingleLocation(value0) {
        this.value0 = value0;
    };
    EditEstimationElementAmountForSingleLocation.create = function (value0) {
        return new EditEstimationElementAmountForSingleLocation(value0);
    };
    return EditEstimationElementAmountForSingleLocation;
})();
var EditEstimationElementGroupFlag = (function () {
    function EditEstimationElementGroupFlag(value0) {
        this.value0 = value0;
    };
    EditEstimationElementGroupFlag.create = function (value0) {
        return new EditEstimationElementGroupFlag(value0);
    };
    return EditEstimationElementGroupFlag;
})();
var EditEstimationComponentDescription = (function () {
    function EditEstimationComponentDescription(value0) {
        this.value0 = value0;
    };
    EditEstimationComponentDescription.create = function (value0) {
        return new EditEstimationComponentDescription(value0);
    };
    return EditEstimationComponentDescription;
})();
var EditEstimationComponentCode = (function () {
    function EditEstimationComponentCode(value0) {
        this.value0 = value0;
    };
    EditEstimationComponentCode.create = function (value0) {
        return new EditEstimationComponentCode(value0);
    };
    return EditEstimationComponentCode;
})();
var EditEstimationComponentGroup = (function () {
    function EditEstimationComponentGroup(value0) {
        this.value0 = value0;
    };
    EditEstimationComponentGroup.create = function (value0) {
        return new EditEstimationComponentGroup(value0);
    };
    return EditEstimationComponentGroup;
})();
var EditEstimationComponentCostGroup = (function () {
    function EditEstimationComponentCostGroup(value0) {
        this.value0 = value0;
    };
    EditEstimationComponentCostGroup.create = function (value0) {
        return new EditEstimationComponentCostGroup(value0);
    };
    return EditEstimationComponentCostGroup;
})();
var EditEstimationComponentSubprojectId = (function () {
    function EditEstimationComponentSubprojectId(value0) {
        this.value0 = value0;
    };
    EditEstimationComponentSubprojectId.create = function (value0) {
        return new EditEstimationComponentSubprojectId(value0);
    };
    return EditEstimationComponentSubprojectId;
})();
var EditEstimationComponentUnit = (function () {
    function EditEstimationComponentUnit(value0) {
        this.value0 = value0;
    };
    EditEstimationComponentUnit.create = function (value0) {
        return new EditEstimationComponentUnit(value0);
    };
    return EditEstimationComponentUnit;
})();
var EditEstimationComponentChecked = (function () {
    function EditEstimationComponentChecked(value0) {
        this.value0 = value0;
    };
    EditEstimationComponentChecked.create = function (value0) {
        return new EditEstimationComponentChecked(value0);
    };
    return EditEstimationComponentChecked;
})();
var EditEstimationComponentFlagged = (function () {
    function EditEstimationComponentFlagged(value0) {
        this.value0 = value0;
    };
    EditEstimationComponentFlagged.create = function (value0) {
        return new EditEstimationComponentFlagged(value0);
    };
    return EditEstimationComponentFlagged;
})();
var EditEstimationComponentQuantity = (function () {
    function EditEstimationComponentQuantity(value0) {
        this.value0 = value0;
    };
    EditEstimationComponentQuantity.create = function (value0) {
        return new EditEstimationComponentQuantity(value0);
    };
    return EditEstimationComponentQuantity;
})();
var EditEstimationComponentMemo = (function () {
    function EditEstimationComponentMemo(value0) {
        this.value0 = value0;
    };
    EditEstimationComponentMemo.create = function (value0) {
        return new EditEstimationComponentMemo(value0);
    };
    return EditEstimationComponentMemo;
})();
var EditEstimationComponentClass = (function () {
    function EditEstimationComponentClass(value0) {
        this.value0 = value0;
    };
    EditEstimationComponentClass.create = function (value0) {
        return new EditEstimationComponentClass(value0);
    };
    return EditEstimationComponentClass;
})();
var EditOrgResourcePrice = (function () {
    function EditOrgResourcePrice(value0) {
        this.value0 = value0;
    };
    EditOrgResourcePrice.create = function (value0) {
        return new EditOrgResourcePrice(value0);
    };
    return EditOrgResourcePrice;
})();
var DeleteOrgResourcePrices = (function () {
    function DeleteOrgResourcePrices(value0) {
        this.value0 = value0;
    };
    DeleteOrgResourcePrices.create = function (value0) {
        return new DeleteOrgResourcePrices(value0);
    };
    return DeleteOrgResourcePrices;
})();
var AddOrgResourceGrouping = (function () {
    function AddOrgResourceGrouping(value0) {
        this.value0 = value0;
    };
    AddOrgResourceGrouping.create = function (value0) {
        return new AddOrgResourceGrouping(value0);
    };
    return AddOrgResourceGrouping;
})();
var AddOrgResourceGroupingClassification = (function () {
    function AddOrgResourceGroupingClassification(value0) {
        this.value0 = value0;
    };
    AddOrgResourceGroupingClassification.create = function (value0) {
        return new AddOrgResourceGroupingClassification(value0);
    };
    return AddOrgResourceGroupingClassification;
})();
var EditOrgResourceGroupingClassification = (function () {
    function EditOrgResourceGroupingClassification(value0) {
        this.value0 = value0;
    };
    EditOrgResourceGroupingClassification.create = function (value0) {
        return new EditOrgResourceGroupingClassification(value0);
    };
    return EditOrgResourceGroupingClassification;
})();
var AddOrgPriceList = (function () {
    function AddOrgPriceList(value0) {
        this.value0 = value0;
    };
    AddOrgPriceList.create = function (value0) {
        return new AddOrgPriceList(value0);
    };
    return AddOrgPriceList;
})();
var EditOrgPriceList = (function () {
    function EditOrgPriceList(value0) {
        this.value0 = value0;
    };
    EditOrgPriceList.create = function (value0) {
        return new EditOrgPriceList(value0);
    };
    return EditOrgPriceList;
})();
var AddOrgPriceListResourceToComponent = (function () {
    function AddOrgPriceListResourceToComponent(value0) {
        this.value0 = value0;
    };
    AddOrgPriceListResourceToComponent.create = function (value0) {
        return new AddOrgPriceListResourceToComponent(value0);
    };
    return AddOrgPriceListResourceToComponent;
})();
var ReplaceComponentResource = (function () {
    function ReplaceComponentResource(value0) {
        this.value0 = value0;
    };
    ReplaceComponentResource.create = function (value0) {
        return new ReplaceComponentResource(value0);
    };
    return ReplaceComponentResource;
})();
var UpdateAllOrgResourceCostClasses = (function () {
    function UpdateAllOrgResourceCostClasses() {

    };
    UpdateAllOrgResourceCostClasses.value = new UpdateAllOrgResourceCostClasses();
    return UpdateAllOrgResourceCostClasses;
})();
var UpdateEstimationLocationAmount = (function () {
    function UpdateEstimationLocationAmount(value0) {
        this.value0 = value0;
    };
    UpdateEstimationLocationAmount.create = function (value0) {
        return new UpdateEstimationLocationAmount(value0);
    };
    return UpdateEstimationLocationAmount;
})();
var UpdateEstimationLocationCode = (function () {
    function UpdateEstimationLocationCode(value0) {
        this.value0 = value0;
    };
    UpdateEstimationLocationCode.create = function (value0) {
        return new UpdateEstimationLocationCode(value0);
    };
    return UpdateEstimationLocationCode;
})();
var UpdateEstimationLocationDescription = (function () {
    function UpdateEstimationLocationDescription(value0) {
        this.value0 = value0;
    };
    UpdateEstimationLocationDescription.create = function (value0) {
        return new UpdateEstimationLocationDescription(value0);
    };
    return UpdateEstimationLocationDescription;
})();
var AddEstimationLocation = (function () {
    function AddEstimationLocation(value0) {
        this.value0 = value0;
    };
    AddEstimationLocation.create = function (value0) {
        return new AddEstimationLocation(value0);
    };
    return AddEstimationLocation;
})();
var DeleteEstimationLocations = (function () {
    function DeleteEstimationLocations(value0) {
        this.value0 = value0;
    };
    DeleteEstimationLocations.create = function (value0) {
        return new DeleteEstimationLocations(value0);
    };
    return DeleteEstimationLocations;
})();
var EstimationLocationsMatrixAction = (function () {
    function EstimationLocationsMatrixAction(value0) {
        this.value0 = value0;
    };
    EstimationLocationsMatrixAction.create = function (value0) {
        return new EstimationLocationsMatrixAction(value0);
    };
    return EstimationLocationsMatrixAction;
})();
var AddEstimationComponent = (function () {
    function AddEstimationComponent(value0) {
        this.value0 = value0;
    };
    AddEstimationComponent.create = function (value0) {
        return new AddEstimationComponent(value0);
    };
    return AddEstimationComponent;
})();
var EditEstimationComponent = (function () {
    function EditEstimationComponent(value0) {
        this.value0 = value0;
    };
    EditEstimationComponent.create = function (value0) {
        return new EditEstimationComponent(value0);
    };
    return EditEstimationComponent;
})();
var EditEstimationComponents = (function () {
    function EditEstimationComponents(value0) {
        this.value0 = value0;
    };
    EditEstimationComponents.create = function (value0) {
        return new EditEstimationComponents(value0);
    };
    return EditEstimationComponents;
})();
var ResetEstimationLocations = (function () {
    function ResetEstimationLocations(value0) {
        this.value0 = value0;
    };
    ResetEstimationLocations.create = function (value0) {
        return new ResetEstimationLocations(value0);
    };
    return ResetEstimationLocations;
})();
var CopyEstimationComponentsToProject = (function () {
    function CopyEstimationComponentsToProject(value0) {
        this.value0 = value0;
    };
    CopyEstimationComponentsToProject.create = function (value0) {
        return new CopyEstimationComponentsToProject(value0);
    };
    return CopyEstimationComponentsToProject;
})();
var DeleteEstimationComponents = (function () {
    function DeleteEstimationComponents(value0) {
        this.value0 = value0;
    };
    DeleteEstimationComponents.create = function (value0) {
        return new DeleteEstimationComponents(value0);
    };
    return DeleteEstimationComponents;
})();
var ReplaceEstimationComponent = (function () {
    function ReplaceEstimationComponent(value0) {
        this.value0 = value0;
    };
    ReplaceEstimationComponent.create = function (value0) {
        return new ReplaceEstimationComponent(value0);
    };
    return ReplaceEstimationComponent;
})();
var CopyComponentAndApplyEditAction = (function () {
    function CopyComponentAndApplyEditAction(value0) {
        this.value0 = value0;
    };
    CopyComponentAndApplyEditAction.create = function (value0) {
        return new CopyComponentAndApplyEditAction(value0);
    };
    return CopyComponentAndApplyEditAction;
})();
var CopyMultiLinkedComponentAndApplyEditAction = (function () {
    function CopyMultiLinkedComponentAndApplyEditAction(value0) {
        this.value0 = value0;
    };
    CopyMultiLinkedComponentAndApplyEditAction.create = function (value0) {
        return new CopyMultiLinkedComponentAndApplyEditAction(value0);
    };
    return CopyMultiLinkedComponentAndApplyEditAction;
})();
var ElementUpdateAddElementSpecs = (function () {
    function ElementUpdateAddElementSpecs(value0) {
        this.value0 = value0;
    };
    ElementUpdateAddElementSpecs.create = function (value0) {
        return new ElementUpdateAddElementSpecs(value0);
    };
    return ElementUpdateAddElementSpecs;
})();
var ElementUpdateReplaceElementSpecs = (function () {
    function ElementUpdateReplaceElementSpecs(value0) {
        this.value0 = value0;
    };
    ElementUpdateReplaceElementSpecs.create = function (value0) {
        return new ElementUpdateReplaceElementSpecs(value0);
    };
    return ElementUpdateReplaceElementSpecs;
})();
var ElementUpdateReplaceElement = (function () {
    function ElementUpdateReplaceElement(value0) {
        this.value0 = value0;
    };
    ElementUpdateReplaceElement.create = function (value0) {
        return new ElementUpdateReplaceElement(value0);
    };
    return ElementUpdateReplaceElement;
})();
var DuplicateElementsWithinProject = (function () {
    function DuplicateElementsWithinProject() {

    };
    DuplicateElementsWithinProject.value = new DuplicateElementsWithinProject();
    return DuplicateElementsWithinProject;
})();
var CopyElementsToNewProject = (function () {
    function CopyElementsToNewProject(value0) {
        this.value0 = value0;
    };
    CopyElementsToNewProject.create = function (value0) {
        return new CopyElementsToNewProject(value0);
    };
    return CopyElementsToNewProject;
})();
var UpdateExistingElement = (function () {
    function UpdateExistingElement(value0) {
        this.value0 = value0;
    };
    UpdateExistingElement.create = function (value0) {
        return new UpdateExistingElement(value0);
    };
    return UpdateExistingElement;
})();
var CopyElementOnly = (function () {
    function CopyElementOnly() {

    };
    CopyElementOnly.value = new CopyElementOnly();
    return CopyElementOnly;
})();
var CopyElementWithComponents = (function () {
    function CopyElementWithComponents(value0) {
        this.value0 = value0;
    };
    CopyElementWithComponents.create = function (value0) {
        return new CopyElementWithComponents(value0);
    };
    return CopyElementWithComponents;
})();
var CopyElementWithinProject = (function () {
    function CopyElementWithinProject() {

    };
    CopyElementWithinProject.value = new CopyElementWithinProject();
    return CopyElementWithinProject;
})();
var AddElementSpecs = (function () {
    function AddElementSpecs(value0) {
        this.value0 = value0;
    };
    AddElementSpecs.create = function (value0) {
        return new AddElementSpecs(value0);
    };
    return AddElementSpecs;
})();
var AddEstimationElement = (function () {
    function AddEstimationElement(value0) {
        this.value0 = value0;
    };
    AddEstimationElement.create = function (value0) {
        return new AddEstimationElement(value0);
    };
    return AddEstimationElement;
})();
var EditEstimationElement = (function () {
    function EditEstimationElement(value0) {
        this.value0 = value0;
    };
    EditEstimationElement.create = function (value0) {
        return new EditEstimationElement(value0);
    };
    return EditEstimationElement;
})();
var AddExistingProjectComponentsToElement = (function () {
    function AddExistingProjectComponentsToElement(value0) {
        this.value0 = value0;
    };
    AddExistingProjectComponentsToElement.create = function (value0) {
        return new AddExistingProjectComponentsToElement(value0);
    };
    return AddExistingProjectComponentsToElement;
})();
var AddExistingProjectElementSpecsToElement = (function () {
    function AddExistingProjectElementSpecsToElement(value0) {
        this.value0 = value0;
    };
    AddExistingProjectElementSpecsToElement.create = function (value0) {
        return new AddExistingProjectElementSpecsToElement(value0);
    };
    return AddExistingProjectElementSpecsToElement;
})();
var AddReferenceProjectComponentToElement = (function () {
    function AddReferenceProjectComponentToElement(value0) {
        this.value0 = value0;
    };
    AddReferenceProjectComponentToElement.create = function (value0) {
        return new AddReferenceProjectComponentToElement(value0);
    };
    return AddReferenceProjectComponentToElement;
})();
var AddReferenceProjectComponentsToElementWithoutDuplicates = (function () {
    function AddReferenceProjectComponentsToElementWithoutDuplicates(value0) {
        this.value0 = value0;
    };
    AddReferenceProjectComponentsToElementWithoutDuplicates.create = function (value0) {
        return new AddReferenceProjectComponentsToElementWithoutDuplicates(value0);
    };
    return AddReferenceProjectComponentsToElementWithoutDuplicates;
})();
var AddNewProjectComponentToElement = (function () {
    function AddNewProjectComponentToElement(value0) {
        this.value0 = value0;
    };
    AddNewProjectComponentToElement.create = function (value0) {
        return new AddNewProjectComponentToElement(value0);
    };
    return AddNewProjectComponentToElement;
})();
var CopyEstimationElementsToProject = (function () {
    function CopyEstimationElementsToProject(value0) {
        this.value0 = value0;
    };
    CopyEstimationElementsToProject.create = function (value0) {
        return new CopyEstimationElementsToProject(value0);
    };
    return CopyEstimationElementsToProject;
})();
var CopySelectedElements = (function () {
    function CopySelectedElements(value0) {
        this.value0 = value0;
    };
    CopySelectedElements.create = function (value0) {
        return new CopySelectedElements(value0);
    };
    return CopySelectedElements;
})();
var EditElementLocationCode = (function () {
    function EditElementLocationCode(value0) {
        this.value0 = value0;
    };
    EditElementLocationCode.create = function (value0) {
        return new EditElementLocationCode(value0);
    };
    return EditElementLocationCode;
})();
var EditElementLocationDescription = (function () {
    function EditElementLocationDescription(value0) {
        this.value0 = value0;
    };
    EditElementLocationDescription.create = function (value0) {
        return new EditElementLocationDescription(value0);
    };
    return EditElementLocationDescription;
})();
var EditElementLocationAmount = (function () {
    function EditElementLocationAmount(value0) {
        this.value0 = value0;
    };
    EditElementLocationAmount.create = function (value0) {
        return new EditElementLocationAmount(value0);
    };
    return EditElementLocationAmount;
})();
var ElementLocationsMatrixAction = (function () {
    function ElementLocationsMatrixAction(value0) {
        this.value0 = value0;
    };
    ElementLocationsMatrixAction.create = function (value0) {
        return new ElementLocationsMatrixAction(value0);
    };
    return ElementLocationsMatrixAction;
})();
var AddElementLocation = (function () {
    function AddElementLocation(value0) {
        this.value0 = value0;
    };
    AddElementLocation.create = function (value0) {
        return new AddElementLocation(value0);
    };
    return AddElementLocation;
})();
var DeleteElementLocations = (function () {
    function DeleteElementLocations(value0) {
        this.value0 = value0;
    };
    DeleteElementLocations.create = function (value0) {
        return new DeleteElementLocations(value0);
    };
    return DeleteElementLocations;
})();
var AddEstimationResource = (function () {
    function AddEstimationResource(value0) {
        this.value0 = value0;
    };
    AddEstimationResource.create = function (value0) {
        return new AddEstimationResource(value0);
    };
    return AddEstimationResource;
})();
var AddAndDeleteMultipleResources = (function () {
    function AddAndDeleteMultipleResources(value0) {
        this.value0 = value0;
    };
    AddAndDeleteMultipleResources.create = function (value0) {
        return new AddAndDeleteMultipleResources(value0);
    };
    return AddAndDeleteMultipleResources;
})();
var CopyResourcesToComponent = (function () {
    function CopyResourcesToComponent(value0) {
        this.value0 = value0;
    };
    CopyResourcesToComponent.create = function (value0) {
        return new CopyResourcesToComponent(value0);
    };
    return CopyResourcesToComponent;
})();
var ReplaceComponentResources = (function () {
    function ReplaceComponentResources(value0) {
        this.value0 = value0;
    };
    ReplaceComponentResources.create = function (value0) {
        return new ReplaceComponentResources(value0);
    };
    return ReplaceComponentResources;
})();
var AddResourcesToComponentFromOrgPriceList = (function () {
    function AddResourcesToComponentFromOrgPriceList(value0) {
        this.value0 = value0;
    };
    AddResourcesToComponentFromOrgPriceList.create = function (value0) {
        return new AddResourcesToComponentFromOrgPriceList(value0);
    };
    return AddResourcesToComponentFromOrgPriceList;
})();
var ReplaceComponentResourceFromOrgPriceList = (function () {
    function ReplaceComponentResourceFromOrgPriceList(value0) {
        this.value0 = value0;
    };
    ReplaceComponentResourceFromOrgPriceList.create = function (value0) {
        return new ReplaceComponentResourceFromOrgPriceList(value0);
    };
    return ReplaceComponentResourceFromOrgPriceList;
})();
var ReplaceComponentResourceFromProject = (function () {
    function ReplaceComponentResourceFromProject(value0) {
        this.value0 = value0;
    };
    ReplaceComponentResourceFromProject.create = function (value0) {
        return new ReplaceComponentResourceFromProject(value0);
    };
    return ReplaceComponentResourceFromProject;
})();
var EditEstimationResource = (function () {
    function EditEstimationResource(value0) {
        this.value0 = value0;
    };
    EditEstimationResource.create = function (value0) {
        return new EditEstimationResource(value0);
    };
    return EditEstimationResource;
})();
var EditEstimationCostClassPrice = (function () {
    function EditEstimationCostClassPrice(value0) {
        this.value0 = value0;
    };
    EditEstimationCostClassPrice.create = function (value0) {
        return new EditEstimationCostClassPrice(value0);
    };
    return EditEstimationCostClassPrice;
})();
var EditEstimationCostClassPriceConsumptionRate = (function () {
    function EditEstimationCostClassPriceConsumptionRate(value0) {
        this.value0 = value0;
    };
    EditEstimationCostClassPriceConsumptionRate.create = function (value0) {
        return new EditEstimationCostClassPriceConsumptionRate(value0);
    };
    return EditEstimationCostClassPriceConsumptionRate;
})();
var EditEstimationCostClassPriceAndConsumptionRate = (function () {
    function EditEstimationCostClassPriceAndConsumptionRate(value0) {
        this.value0 = value0;
    };
    EditEstimationCostClassPriceAndConsumptionRate.create = function (value0) {
        return new EditEstimationCostClassPriceAndConsumptionRate(value0);
    };
    return EditEstimationCostClassPriceAndConsumptionRate;
})();
var DeleteEstimationResources = (function () {
    function DeleteEstimationResources(value0) {
        this.value0 = value0;
    };
    DeleteEstimationResources.create = function (value0) {
        return new DeleteEstimationResources(value0);
    };
    return DeleteEstimationResources;
})();
var SetEstimationResourcesCostClass = (function () {
    function SetEstimationResourcesCostClass(value0) {
        this.value0 = value0;
    };
    SetEstimationResourcesCostClass.create = function (value0) {
        return new SetEstimationResourcesCostClass(value0);
    };
    return SetEstimationResourcesCostClass;
})();
var SetEstimationResourcesGroup = (function () {
    function SetEstimationResourcesGroup(value0) {
        this.value0 = value0;
    };
    SetEstimationResourcesGroup.create = function (value0) {
        return new SetEstimationResourcesGroup(value0);
    };
    return SetEstimationResourcesGroup;
})();
var genericTargetComponentAction = {
    to: function (x) {
        if (x instanceof Data_Generic_Rep.Inl) {
            return InPlaceResourceUpdate.value;
        };
        if (x instanceof Data_Generic_Rep.Inr) {
            return new SplitElementComponent(x.value0);
        };
        throw new Error("Failed pattern match at Types.Estimation (line 688, column 1 - line 688, column 88): " + [ x.constructor.name ]);
    },
    from: function (x) {
        if (x instanceof InPlaceResourceUpdate) {
            return new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value);
        };
        if (x instanceof SplitElementComponent) {
            return new Data_Generic_Rep.Inr(x.value0);
        };
        throw new Error("Failed pattern match at Types.Estimation (line 688, column 1 - line 688, column 88): " + [ x.constructor.name ]);
    }
};
var tofuJSONTargetComponentAction = {
    writeImpl: TofuJSON.writeGenericSum()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "InPlaceResourceUpdate";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "SplitElementComponent";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "componentId";
        }
    })(Types_Component.tofuJSONComponentId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "elementId";
        }
    })(Types_Element.tofuJSONElementId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "elementSpecId";
        }
    })(Types_Newtypes.tofuJSONElementSpecId)(TofuJSON.readFieldsNil)()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "componentId";
        }
    })(Types_Component.tofuJSONComponentId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "elementId";
        }
    })(Types_Element.tofuJSONElementId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "elementSpecId";
        }
    })(Types_Newtypes.tofuJSONElementSpecId)(TofuJSON.nilWriteForeignFields)()()())()()())()()()))()(TofuJSON.readVariantNil)))(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "InPlaceResourceUpdate";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "SplitElementComponent";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "componentId";
        }
    })(Types_Component.tofuJSONComponentId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "elementId";
        }
    })(Types_Element.tofuJSONElementId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "elementSpecId";
        }
    })(Types_Newtypes.tofuJSONElementSpecId)(TofuJSON.readFieldsNil)()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "componentId";
        }
    })(Types_Component.tofuJSONComponentId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "elementId";
        }
    })(Types_Element.tofuJSONElementId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "elementSpecId";
        }
    })(Types_Newtypes.tofuJSONElementSpecId)(TofuJSON.nilWriteForeignFields)()()())()()())()()()))()(TofuJSON.nilWriteForeignVariant)))(genericTargetComponentAction)(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "InPlaceResourceUpdate";
        }
    })()(Kishimen.genericSumToVariantArgNoArguments))(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "SplitElementComponent";
        }
    })()(Kishimen.genericSumToVariantArgArguments))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "InPlaceResourceUpdate";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "SplitElementComponent";
        }
    }))),
    readImpl: TofuJSON.readGenericSum(genericTargetComponentAction)(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "InPlaceResourceUpdate";
        }
    })()(Kishimen.genericSumToVariantArgNoArguments))(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "SplitElementComponent";
        }
    })()(Kishimen.genericSumToVariantArgArguments))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "InPlaceResourceUpdate";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "SplitElementComponent";
        }
    })))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "InPlaceResourceUpdate";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "SplitElementComponent";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "componentId";
        }
    })(Types_Component.tofuJSONComponentId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "elementId";
        }
    })(Types_Element.tofuJSONElementId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "elementSpecId";
        }
    })(Types_Newtypes.tofuJSONElementSpecId)(TofuJSON.readFieldsNil)()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "componentId";
        }
    })(Types_Component.tofuJSONComponentId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "elementId";
        }
    })(Types_Element.tofuJSONElementId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "elementSpecId";
        }
    })(Types_Newtypes.tofuJSONElementSpecId)(TofuJSON.nilWriteForeignFields)()()())()()())()()()))()(TofuJSON.readVariantNil)))(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "InPlaceResourceUpdate";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "SplitElementComponent";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "componentId";
        }
    })(Types_Component.tofuJSONComponentId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "elementId";
        }
    })(Types_Element.tofuJSONElementId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "elementSpecId";
        }
    })(Types_Newtypes.tofuJSONElementSpecId)(TofuJSON.readFieldsNil)()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "componentId";
        }
    })(Types_Component.tofuJSONComponentId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "elementId";
        }
    })(Types_Element.tofuJSONElementId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "elementSpecId";
        }
    })(Types_Newtypes.tofuJSONElementSpecId)(TofuJSON.nilWriteForeignFields)()()())()()())()()()))()(TofuJSON.nilWriteForeignVariant)))
};
var genericMatchingRules = {
    to: function (x) {
        if (x instanceof Data_Generic_Rep.Inl) {
            return InternalOrgResourcePriceIdMatch.value;
        };
        if (x instanceof Data_Generic_Rep.Inr) {
            return CostClassGroupSortingNumberMatch.value;
        };
        throw new Error("Failed pattern match at Types.Estimation (line 623, column 1 - line 623, column 64): " + [ x.constructor.name ]);
    },
    from: function (x) {
        if (x instanceof InternalOrgResourcePriceIdMatch) {
            return new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value);
        };
        if (x instanceof CostClassGroupSortingNumberMatch) {
            return new Data_Generic_Rep.Inr(Data_Generic_Rep.NoArguments.value);
        };
        throw new Error("Failed pattern match at Types.Estimation (line 623, column 1 - line 623, column 64): " + [ x.constructor.name ]);
    }
};
var tofuJSONMatchingRules = {
    writeImpl: TofuJSON.writeGenericSum()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "CostClassGroupSortingNumberMatch";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "InternalOrgResourcePriceIdMatch";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.readVariantNil)))(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "CostClassGroupSortingNumberMatch";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "InternalOrgResourcePriceIdMatch";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.nilWriteForeignVariant)))(genericMatchingRules)(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "InternalOrgResourcePriceIdMatch";
        }
    })()(Kishimen.genericSumToVariantArgNoArguments))(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "CostClassGroupSortingNumberMatch";
        }
    })()(Kishimen.genericSumToVariantArgNoArguments))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "InternalOrgResourcePriceIdMatch";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "CostClassGroupSortingNumberMatch";
        }
    }))),
    readImpl: TofuJSON.readGenericSum(genericMatchingRules)(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "InternalOrgResourcePriceIdMatch";
        }
    })()(Kishimen.genericSumToVariantArgNoArguments))(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "CostClassGroupSortingNumberMatch";
        }
    })()(Kishimen.genericSumToVariantArgNoArguments))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "InternalOrgResourcePriceIdMatch";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "CostClassGroupSortingNumberMatch";
        }
    })))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "CostClassGroupSortingNumberMatch";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "InternalOrgResourcePriceIdMatch";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.readVariantNil)))(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "CostClassGroupSortingNumberMatch";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "InternalOrgResourcePriceIdMatch";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.nilWriteForeignVariant)))
};
var genericEstimationResourcePriceMatchAction = {
    to: function (x) {
        return new FindPriceMatchesForResources(x);
    },
    from: function (x) {
        return x.value0;
    }
};
var tofuJSONEstimationResourcePriceMatchAction = {
    writeImpl: TofuJSON.writeGenericSum()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "FindPriceMatchesForResources";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "matchingRule";
        }
    })(tofuJSONMatchingRules)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "pricelistName";
        }
    })(Types_Component.tofuJSONOrgEstimationResourceId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "projectId";
        }
    })(Types_Project.tofuJSONProjectId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "resourceIds";
        }
    })(TofuJSON.readArray(Types_Component.tofuJSONEstimationResourceId))(TofuJSON.readFieldsNil)()())()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "matchingRule";
        }
    })(tofuJSONMatchingRules)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "pricelistName";
        }
    })(Types_Component.tofuJSONOrgEstimationResourceId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "projectId";
        }
    })(Types_Project.tofuJSONProjectId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "resourceIds";
        }
    })(TofuJSON.readArray(Types_Component.tofuJSONEstimationResourceId))(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()()))()(TofuJSON.readVariantNil))(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "FindPriceMatchesForResources";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "matchingRule";
        }
    })(tofuJSONMatchingRules)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "pricelistName";
        }
    })(Types_Component.tofuJSONOrgEstimationResourceId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "projectId";
        }
    })(Types_Project.tofuJSONProjectId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "resourceIds";
        }
    })(TofuJSON.readArray(Types_Component.tofuJSONEstimationResourceId))(TofuJSON.readFieldsNil)()())()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "matchingRule";
        }
    })(tofuJSONMatchingRules)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "pricelistName";
        }
    })(Types_Component.tofuJSONOrgEstimationResourceId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "projectId";
        }
    })(Types_Project.tofuJSONProjectId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "resourceIds";
        }
    })(TofuJSON.readArray(Types_Component.tofuJSONEstimationResourceId))(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()()))()(TofuJSON.nilWriteForeignVariant))(genericEstimationResourcePriceMatchAction)(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "FindPriceMatchesForResources";
        }
    })()(Kishimen.genericSumToVariantArgArguments)),
    readImpl: TofuJSON.readGenericSum(genericEstimationResourcePriceMatchAction)(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "FindPriceMatchesForResources";
        }
    })()(Kishimen.genericSumToVariantArgArguments))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "FindPriceMatchesForResources";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "matchingRule";
        }
    })(tofuJSONMatchingRules)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "pricelistName";
        }
    })(Types_Component.tofuJSONOrgEstimationResourceId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "projectId";
        }
    })(Types_Project.tofuJSONProjectId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "resourceIds";
        }
    })(TofuJSON.readArray(Types_Component.tofuJSONEstimationResourceId))(TofuJSON.readFieldsNil)()())()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "matchingRule";
        }
    })(tofuJSONMatchingRules)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "pricelistName";
        }
    })(Types_Component.tofuJSONOrgEstimationResourceId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "projectId";
        }
    })(Types_Project.tofuJSONProjectId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "resourceIds";
        }
    })(TofuJSON.readArray(Types_Component.tofuJSONEstimationResourceId))(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()()))()(TofuJSON.readVariantNil))(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "FindPriceMatchesForResources";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "matchingRule";
        }
    })(tofuJSONMatchingRules)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "pricelistName";
        }
    })(Types_Component.tofuJSONOrgEstimationResourceId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "projectId";
        }
    })(Types_Project.tofuJSONProjectId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "resourceIds";
        }
    })(TofuJSON.readArray(Types_Component.tofuJSONEstimationResourceId))(TofuJSON.readFieldsNil)()())()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "matchingRule";
        }
    })(tofuJSONMatchingRules)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "pricelistName";
        }
    })(Types_Component.tofuJSONOrgEstimationResourceId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "projectId";
        }
    })(Types_Project.tofuJSONProjectId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "resourceIds";
        }
    })(TofuJSON.readArray(Types_Component.tofuJSONEstimationResourceId))(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()()))()(TofuJSON.nilWriteForeignVariant))
};
var genericEstimationResourcePriceListAction = {
    to: function (x) {
        if (x instanceof Data_Generic_Rep.Inl) {
            return new EditOrgResourcePrice(x.value0);
        };
        if (x instanceof Data_Generic_Rep.Inr && x.value0 instanceof Data_Generic_Rep.Inl) {
            return new DeleteOrgResourcePrices(x.value0.value0);
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0 instanceof Data_Generic_Rep.Inl)) {
            return new AddOrgResourceGrouping(x.value0.value0.value0);
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0 instanceof Data_Generic_Rep.Inl))) {
            return new AddOrgResourceGroupingClassification(x.value0.value0.value0.value0);
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inl)))) {
            return new EditOrgResourceGroupingClassification(x.value0.value0.value0.value0.value0);
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inl))))) {
            return new AddOrgPriceList(x.value0.value0.value0.value0.value0.value0);
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inl)))))) {
            return new EditOrgPriceList(x.value0.value0.value0.value0.value0.value0.value0);
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inl))))))) {
            return new AddOrgPriceListResourceToComponent(x.value0.value0.value0.value0.value0.value0.value0.value0);
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inl)))))))) {
            return new ReplaceComponentResource(x.value0.value0.value0.value0.value0.value0.value0.value0.value0);
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr)))))))) {
            return UpdateAllOrgResourceCostClasses.value;
        };
        throw new Error("Failed pattern match at Types.Estimation (line 700, column 1 - line 700, column 104): " + [ x.constructor.name ]);
    },
    from: function (x) {
        if (x instanceof EditOrgResourcePrice) {
            return new Data_Generic_Rep.Inl(x.value0);
        };
        if (x instanceof DeleteOrgResourcePrices) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(x.value0));
        };
        if (x instanceof AddOrgResourceGrouping) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(x.value0)));
        };
        if (x instanceof AddOrgResourceGroupingClassification) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(x.value0))));
        };
        if (x instanceof EditOrgResourceGroupingClassification) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(x.value0)))));
        };
        if (x instanceof AddOrgPriceList) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(x.value0))))));
        };
        if (x instanceof EditOrgPriceList) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(x.value0)))))));
        };
        if (x instanceof AddOrgPriceListResourceToComponent) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(x.value0))))))));
        };
        if (x instanceof ReplaceComponentResource) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(x.value0)))))))));
        };
        if (x instanceof UpdateAllOrgResourceCostClasses) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(Data_Generic_Rep.NoArguments.value)))))))));
        };
        throw new Error("Failed pattern match at Types.Estimation (line 700, column 1 - line 700, column 104): " + [ x.constructor.name ]);
    }
};
var tofuJSONEstimationResourcePriceListAction = {
    writeImpl: TofuJSON.writeGenericSum()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "AddOrgPriceList";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "name";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "orgPriceListId";
        }
    })(Types_Component.tofuJSONOrgEstimationResourceId)(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "name";
        }
    })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "orgPriceListId";
        }
    })(Types_Component.tofuJSONOrgEstimationResourceId)(TofuJSON.nilWriteForeignFields)()()())()()()))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "AddOrgPriceListResourceToComponent";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "componentId";
        }
    })(Types_Component.tofuJSONComponentId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "orgResourcePriceIds";
        }
    })(TofuJSON.readArray(TofuJSON.readInt))(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "componentId";
        }
    })(Types_Component.tofuJSONComponentId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "orgResourcePriceIds";
        }
    })(TofuJSON.readArray(TofuJSON.readInt))(TofuJSON.nilWriteForeignFields)()()())()()()))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "AddOrgResourceGrouping";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "associatedOrgCostClassIds";
        }
    })(TofuJSON.readArray(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "id";
        }
    })(TofuJSON.readInt)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "orgCostClassId";
        }
    })(TofuJSON.readInt)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "orgPriceListId";
        }
    })(Types_Component.tofuJSONOrgEstimationResourceId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "orgResourceGroupingId";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsNil)()())()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "id";
        }
    })(TofuJSON.readInt)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "orgCostClassId";
        }
    })(TofuJSON.readInt)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "orgPriceListId";
        }
    })(Types_Component.tofuJSONOrgEstimationResourceId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "orgResourceGroupingId";
        }
    })(TofuJSON.readString)(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "description";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "orgPriceListId";
        }
    })(Types_Component.tofuJSONOrgEstimationResourceId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "orgResourceGroupingId";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsNil)()())()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "associatedOrgCostClassIds";
        }
    })(TofuJSON.readArray(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "id";
        }
    })(TofuJSON.readInt)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "orgCostClassId";
        }
    })(TofuJSON.readInt)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "orgPriceListId";
        }
    })(Types_Component.tofuJSONOrgEstimationResourceId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "orgResourceGroupingId";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsNil)()())()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "id";
        }
    })(TofuJSON.readInt)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "orgCostClassId";
        }
    })(TofuJSON.readInt)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "orgPriceListId";
        }
    })(Types_Component.tofuJSONOrgEstimationResourceId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "orgResourceGroupingId";
        }
    })(TofuJSON.readString)(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "description";
        }
    })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "orgPriceListId";
        }
    })(Types_Component.tofuJSONOrgEstimationResourceId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "orgResourceGroupingId";
        }
    })(TofuJSON.readString)(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()()))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "AddOrgResourceGroupingClassification";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "orgClassificationId";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "orgCostClassId";
        }
    })(TofuJSON.readInt)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "orgPriceListId";
        }
    })(Types_Component.tofuJSONOrgEstimationResourceId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "orgResourceGroupingId";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsNil)()())()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "orgClassificationId";
        }
    })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "orgCostClassId";
        }
    })(TofuJSON.readInt)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "orgPriceListId";
        }
    })(Types_Component.tofuJSONOrgEstimationResourceId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "orgResourceGroupingId";
        }
    })(TofuJSON.readString)(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()()))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "DeleteOrgResourcePrices";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "orgResourcePriceIds";
        }
    })(TofuJSON.readArray(TofuJSON.readInt))(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "orgResourcePriceIds";
        }
    })(TofuJSON.readArray(TofuJSON.readInt))(TofuJSON.nilWriteForeignFields)()()()))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "EditOrgPriceList";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "newOrgPriceList";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "name";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "orgPriceListId";
        }
    })(Types_Component.tofuJSONOrgEstimationResourceId)(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "name";
        }
    })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "orgPriceListId";
        }
    })(Types_Component.tofuJSONOrgEstimationResourceId)(TofuJSON.nilWriteForeignFields)()()())()()()))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "orgPriceListId";
        }
    })(Types_Component.tofuJSONOrgEstimationResourceId)(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "newOrgPriceList";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "name";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "orgPriceListId";
        }
    })(Types_Component.tofuJSONOrgEstimationResourceId)(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "name";
        }
    })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "orgPriceListId";
        }
    })(Types_Component.tofuJSONOrgEstimationResourceId)(TofuJSON.nilWriteForeignFields)()()())()()()))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "orgPriceListId";
        }
    })(Types_Component.tofuJSONOrgEstimationResourceId)(TofuJSON.nilWriteForeignFields)()()())()()()))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "EditOrgResourceGroupingClassification";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "newOrgCostClassId";
        }
    })(TofuJSON.readInt)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "orgClassificationId";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "orgPriceListId";
        }
    })(Types_Component.tofuJSONOrgEstimationResourceId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "orgResourceGroupingAssociationId";
        }
    })(TofuJSON.readInt)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "orgResourceGroupingId";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsNil)()())()())()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "newOrgCostClassId";
        }
    })(TofuJSON.readInt)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "orgClassificationId";
        }
    })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "orgPriceListId";
        }
    })(Types_Component.tofuJSONOrgEstimationResourceId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "orgResourceGroupingAssociationId";
        }
    })(TofuJSON.readInt)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "orgResourceGroupingId";
        }
    })(TofuJSON.readString)(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())()()()))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "EditOrgResourcePrice";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "discountPercentage";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "eanCode";
        }
    })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "index";
        }
    })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "name";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "orgResourcePriceId";
        }
    })(TofuJSON.readInt)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "ownCode";
        }
    })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "pricePerUnit";
        }
    })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "resourceClassificationGroup";
        }
    })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "sortingNumber";
        }
    })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "taxRate";
        }
    })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "unit";
        }
    })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "validDate";
        }
    })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "vendor";
        }
    })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "vendorBatchSize";
        }
    })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "vendorCode";
        }
    })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "vendorUnit";
        }
    })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsNil)()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "discountPercentage";
        }
    })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "eanCode";
        }
    })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "index";
        }
    })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "name";
        }
    })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "orgResourcePriceId";
        }
    })(TofuJSON.readInt)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "ownCode";
        }
    })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "pricePerUnit";
        }
    })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "resourceClassificationGroup";
        }
    })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "sortingNumber";
        }
    })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "taxRate";
        }
    })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "unit";
        }
    })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "validDate";
        }
    })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "vendor";
        }
    })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "vendorBatchSize";
        }
    })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "vendorCode";
        }
    })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "vendorUnit";
        }
    })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()()))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "ReplaceComponentResource";
        }
    })(TofuJSON.readArray(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "componentId";
        }
    })(Types_Component.tofuJSONComponentId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "orgResourcePriceIds";
        }
    })(TofuJSON.readArray(TofuJSON.readInt))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "replaceTargetResourceId";
        }
    })(Types_Component.tofuJSONEstimationResourceId)(TofuJSON.readFieldsNil)()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "componentId";
        }
    })(Types_Component.tofuJSONComponentId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "orgResourcePriceIds";
        }
    })(TofuJSON.readArray(TofuJSON.readInt))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "replaceTargetResourceId";
        }
    })(Types_Component.tofuJSONEstimationResourceId)(TofuJSON.nilWriteForeignFields)()()())()()())()()())))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "UpdateAllOrgResourceCostClasses";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.readVariantNil)))))))))))(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "AddOrgPriceList";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "name";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "orgPriceListId";
        }
    })(Types_Component.tofuJSONOrgEstimationResourceId)(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "name";
        }
    })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "orgPriceListId";
        }
    })(Types_Component.tofuJSONOrgEstimationResourceId)(TofuJSON.nilWriteForeignFields)()()())()()()))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "AddOrgPriceListResourceToComponent";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "componentId";
        }
    })(Types_Component.tofuJSONComponentId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "orgResourcePriceIds";
        }
    })(TofuJSON.readArray(TofuJSON.readInt))(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "componentId";
        }
    })(Types_Component.tofuJSONComponentId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "orgResourcePriceIds";
        }
    })(TofuJSON.readArray(TofuJSON.readInt))(TofuJSON.nilWriteForeignFields)()()())()()()))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "AddOrgResourceGrouping";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "associatedOrgCostClassIds";
        }
    })(TofuJSON.readArray(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "id";
        }
    })(TofuJSON.readInt)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "orgCostClassId";
        }
    })(TofuJSON.readInt)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "orgPriceListId";
        }
    })(Types_Component.tofuJSONOrgEstimationResourceId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "orgResourceGroupingId";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsNil)()())()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "id";
        }
    })(TofuJSON.readInt)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "orgCostClassId";
        }
    })(TofuJSON.readInt)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "orgPriceListId";
        }
    })(Types_Component.tofuJSONOrgEstimationResourceId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "orgResourceGroupingId";
        }
    })(TofuJSON.readString)(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "description";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "orgPriceListId";
        }
    })(Types_Component.tofuJSONOrgEstimationResourceId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "orgResourceGroupingId";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsNil)()())()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "associatedOrgCostClassIds";
        }
    })(TofuJSON.readArray(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "id";
        }
    })(TofuJSON.readInt)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "orgCostClassId";
        }
    })(TofuJSON.readInt)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "orgPriceListId";
        }
    })(Types_Component.tofuJSONOrgEstimationResourceId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "orgResourceGroupingId";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsNil)()())()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "id";
        }
    })(TofuJSON.readInt)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "orgCostClassId";
        }
    })(TofuJSON.readInt)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "orgPriceListId";
        }
    })(Types_Component.tofuJSONOrgEstimationResourceId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "orgResourceGroupingId";
        }
    })(TofuJSON.readString)(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "description";
        }
    })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "orgPriceListId";
        }
    })(Types_Component.tofuJSONOrgEstimationResourceId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "orgResourceGroupingId";
        }
    })(TofuJSON.readString)(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()()))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "AddOrgResourceGroupingClassification";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "orgClassificationId";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "orgCostClassId";
        }
    })(TofuJSON.readInt)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "orgPriceListId";
        }
    })(Types_Component.tofuJSONOrgEstimationResourceId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "orgResourceGroupingId";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsNil)()())()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "orgClassificationId";
        }
    })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "orgCostClassId";
        }
    })(TofuJSON.readInt)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "orgPriceListId";
        }
    })(Types_Component.tofuJSONOrgEstimationResourceId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "orgResourceGroupingId";
        }
    })(TofuJSON.readString)(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()()))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "DeleteOrgResourcePrices";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "orgResourcePriceIds";
        }
    })(TofuJSON.readArray(TofuJSON.readInt))(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "orgResourcePriceIds";
        }
    })(TofuJSON.readArray(TofuJSON.readInt))(TofuJSON.nilWriteForeignFields)()()()))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "EditOrgPriceList";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "newOrgPriceList";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "name";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "orgPriceListId";
        }
    })(Types_Component.tofuJSONOrgEstimationResourceId)(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "name";
        }
    })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "orgPriceListId";
        }
    })(Types_Component.tofuJSONOrgEstimationResourceId)(TofuJSON.nilWriteForeignFields)()()())()()()))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "orgPriceListId";
        }
    })(Types_Component.tofuJSONOrgEstimationResourceId)(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "newOrgPriceList";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "name";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "orgPriceListId";
        }
    })(Types_Component.tofuJSONOrgEstimationResourceId)(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "name";
        }
    })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "orgPriceListId";
        }
    })(Types_Component.tofuJSONOrgEstimationResourceId)(TofuJSON.nilWriteForeignFields)()()())()()()))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "orgPriceListId";
        }
    })(Types_Component.tofuJSONOrgEstimationResourceId)(TofuJSON.nilWriteForeignFields)()()())()()()))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "EditOrgResourceGroupingClassification";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "newOrgCostClassId";
        }
    })(TofuJSON.readInt)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "orgClassificationId";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "orgPriceListId";
        }
    })(Types_Component.tofuJSONOrgEstimationResourceId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "orgResourceGroupingAssociationId";
        }
    })(TofuJSON.readInt)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "orgResourceGroupingId";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsNil)()())()())()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "newOrgCostClassId";
        }
    })(TofuJSON.readInt)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "orgClassificationId";
        }
    })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "orgPriceListId";
        }
    })(Types_Component.tofuJSONOrgEstimationResourceId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "orgResourceGroupingAssociationId";
        }
    })(TofuJSON.readInt)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "orgResourceGroupingId";
        }
    })(TofuJSON.readString)(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())()()()))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "EditOrgResourcePrice";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "discountPercentage";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "eanCode";
        }
    })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "index";
        }
    })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "name";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "orgResourcePriceId";
        }
    })(TofuJSON.readInt)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "ownCode";
        }
    })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "pricePerUnit";
        }
    })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "resourceClassificationGroup";
        }
    })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "sortingNumber";
        }
    })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "taxRate";
        }
    })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "unit";
        }
    })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "validDate";
        }
    })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "vendor";
        }
    })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "vendorBatchSize";
        }
    })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "vendorCode";
        }
    })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "vendorUnit";
        }
    })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsNil)()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "discountPercentage";
        }
    })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "eanCode";
        }
    })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "index";
        }
    })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "name";
        }
    })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "orgResourcePriceId";
        }
    })(TofuJSON.readInt)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "ownCode";
        }
    })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "pricePerUnit";
        }
    })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "resourceClassificationGroup";
        }
    })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "sortingNumber";
        }
    })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "taxRate";
        }
    })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "unit";
        }
    })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "validDate";
        }
    })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "vendor";
        }
    })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "vendorBatchSize";
        }
    })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "vendorCode";
        }
    })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "vendorUnit";
        }
    })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()()))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "ReplaceComponentResource";
        }
    })(TofuJSON.readArray(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "componentId";
        }
    })(Types_Component.tofuJSONComponentId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "orgResourcePriceIds";
        }
    })(TofuJSON.readArray(TofuJSON.readInt))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "replaceTargetResourceId";
        }
    })(Types_Component.tofuJSONEstimationResourceId)(TofuJSON.readFieldsNil)()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "componentId";
        }
    })(Types_Component.tofuJSONComponentId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "orgResourcePriceIds";
        }
    })(TofuJSON.readArray(TofuJSON.readInt))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "replaceTargetResourceId";
        }
    })(Types_Component.tofuJSONEstimationResourceId)(TofuJSON.nilWriteForeignFields)()()())()()())()()())))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "UpdateAllOrgResourceCostClasses";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.nilWriteForeignVariant)))))))))))(genericEstimationResourcePriceListAction)(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "EditOrgResourcePrice";
        }
    })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "DeleteOrgResourcePrices";
        }
    })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "AddOrgResourceGrouping";
        }
    })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "AddOrgResourceGroupingClassification";
        }
    })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "EditOrgResourceGroupingClassification";
        }
    })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "AddOrgPriceList";
        }
    })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "EditOrgPriceList";
        }
    })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "AddOrgPriceListResourceToComponent";
        }
    })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "ReplaceComponentResource";
        }
    })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "UpdateAllOrgResourceCostClasses";
        }
    })()(Kishimen.genericSumToVariantArgNoArguments))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "ReplaceComponentResource";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "UpdateAllOrgResourceCostClasses";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "AddOrgPriceListResourceToComponent";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "UpdateAllOrgResourceCostClasses";
        }
    }))({
        reflectSymbol: function () {
            return "ReplaceComponentResource";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "EditOrgPriceList";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "UpdateAllOrgResourceCostClasses";
        }
    }))({
        reflectSymbol: function () {
            return "ReplaceComponentResource";
        }
    }))({
        reflectSymbol: function () {
            return "AddOrgPriceListResourceToComponent";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "AddOrgPriceList";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "UpdateAllOrgResourceCostClasses";
        }
    }))({
        reflectSymbol: function () {
            return "ReplaceComponentResource";
        }
    }))({
        reflectSymbol: function () {
            return "EditOrgPriceList";
        }
    }))({
        reflectSymbol: function () {
            return "AddOrgPriceListResourceToComponent";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "EditOrgResourceGroupingClassification";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "UpdateAllOrgResourceCostClasses";
        }
    }))({
        reflectSymbol: function () {
            return "ReplaceComponentResource";
        }
    }))({
        reflectSymbol: function () {
            return "EditOrgPriceList";
        }
    }))({
        reflectSymbol: function () {
            return "AddOrgPriceListResourceToComponent";
        }
    }))({
        reflectSymbol: function () {
            return "AddOrgPriceList";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "AddOrgResourceGroupingClassification";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "UpdateAllOrgResourceCostClasses";
        }
    }))({
        reflectSymbol: function () {
            return "ReplaceComponentResource";
        }
    }))({
        reflectSymbol: function () {
            return "EditOrgResourceGroupingClassification";
        }
    }))({
        reflectSymbol: function () {
            return "EditOrgPriceList";
        }
    }))({
        reflectSymbol: function () {
            return "AddOrgPriceListResourceToComponent";
        }
    }))({
        reflectSymbol: function () {
            return "AddOrgPriceList";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "AddOrgResourceGrouping";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "UpdateAllOrgResourceCostClasses";
        }
    }))({
        reflectSymbol: function () {
            return "ReplaceComponentResource";
        }
    }))({
        reflectSymbol: function () {
            return "EditOrgResourceGroupingClassification";
        }
    }))({
        reflectSymbol: function () {
            return "EditOrgPriceList";
        }
    }))({
        reflectSymbol: function () {
            return "AddOrgResourceGroupingClassification";
        }
    }))({
        reflectSymbol: function () {
            return "AddOrgPriceListResourceToComponent";
        }
    }))({
        reflectSymbol: function () {
            return "AddOrgPriceList";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "DeleteOrgResourcePrices";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "UpdateAllOrgResourceCostClasses";
        }
    }))({
        reflectSymbol: function () {
            return "ReplaceComponentResource";
        }
    }))({
        reflectSymbol: function () {
            return "EditOrgResourceGroupingClassification";
        }
    }))({
        reflectSymbol: function () {
            return "EditOrgPriceList";
        }
    }))({
        reflectSymbol: function () {
            return "AddOrgResourceGroupingClassification";
        }
    }))({
        reflectSymbol: function () {
            return "AddOrgResourceGrouping";
        }
    }))({
        reflectSymbol: function () {
            return "AddOrgPriceListResourceToComponent";
        }
    }))({
        reflectSymbol: function () {
            return "AddOrgPriceList";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "EditOrgResourcePrice";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "UpdateAllOrgResourceCostClasses";
        }
    }))({
        reflectSymbol: function () {
            return "ReplaceComponentResource";
        }
    }))({
        reflectSymbol: function () {
            return "EditOrgResourceGroupingClassification";
        }
    }))({
        reflectSymbol: function () {
            return "EditOrgPriceList";
        }
    }))({
        reflectSymbol: function () {
            return "DeleteOrgResourcePrices";
        }
    }))({
        reflectSymbol: function () {
            return "AddOrgResourceGroupingClassification";
        }
    }))({
        reflectSymbol: function () {
            return "AddOrgResourceGrouping";
        }
    }))({
        reflectSymbol: function () {
            return "AddOrgPriceListResourceToComponent";
        }
    }))({
        reflectSymbol: function () {
            return "AddOrgPriceList";
        }
    }))),
    readImpl: TofuJSON.readGenericSum(genericEstimationResourcePriceListAction)(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "EditOrgResourcePrice";
        }
    })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "DeleteOrgResourcePrices";
        }
    })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "AddOrgResourceGrouping";
        }
    })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "AddOrgResourceGroupingClassification";
        }
    })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "EditOrgResourceGroupingClassification";
        }
    })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "AddOrgPriceList";
        }
    })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "EditOrgPriceList";
        }
    })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "AddOrgPriceListResourceToComponent";
        }
    })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "ReplaceComponentResource";
        }
    })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "UpdateAllOrgResourceCostClasses";
        }
    })()(Kishimen.genericSumToVariantArgNoArguments))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "ReplaceComponentResource";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "UpdateAllOrgResourceCostClasses";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "AddOrgPriceListResourceToComponent";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "UpdateAllOrgResourceCostClasses";
        }
    }))({
        reflectSymbol: function () {
            return "ReplaceComponentResource";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "EditOrgPriceList";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "UpdateAllOrgResourceCostClasses";
        }
    }))({
        reflectSymbol: function () {
            return "ReplaceComponentResource";
        }
    }))({
        reflectSymbol: function () {
            return "AddOrgPriceListResourceToComponent";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "AddOrgPriceList";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "UpdateAllOrgResourceCostClasses";
        }
    }))({
        reflectSymbol: function () {
            return "ReplaceComponentResource";
        }
    }))({
        reflectSymbol: function () {
            return "EditOrgPriceList";
        }
    }))({
        reflectSymbol: function () {
            return "AddOrgPriceListResourceToComponent";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "EditOrgResourceGroupingClassification";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "UpdateAllOrgResourceCostClasses";
        }
    }))({
        reflectSymbol: function () {
            return "ReplaceComponentResource";
        }
    }))({
        reflectSymbol: function () {
            return "EditOrgPriceList";
        }
    }))({
        reflectSymbol: function () {
            return "AddOrgPriceListResourceToComponent";
        }
    }))({
        reflectSymbol: function () {
            return "AddOrgPriceList";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "AddOrgResourceGroupingClassification";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "UpdateAllOrgResourceCostClasses";
        }
    }))({
        reflectSymbol: function () {
            return "ReplaceComponentResource";
        }
    }))({
        reflectSymbol: function () {
            return "EditOrgResourceGroupingClassification";
        }
    }))({
        reflectSymbol: function () {
            return "EditOrgPriceList";
        }
    }))({
        reflectSymbol: function () {
            return "AddOrgPriceListResourceToComponent";
        }
    }))({
        reflectSymbol: function () {
            return "AddOrgPriceList";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "AddOrgResourceGrouping";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "UpdateAllOrgResourceCostClasses";
        }
    }))({
        reflectSymbol: function () {
            return "ReplaceComponentResource";
        }
    }))({
        reflectSymbol: function () {
            return "EditOrgResourceGroupingClassification";
        }
    }))({
        reflectSymbol: function () {
            return "EditOrgPriceList";
        }
    }))({
        reflectSymbol: function () {
            return "AddOrgResourceGroupingClassification";
        }
    }))({
        reflectSymbol: function () {
            return "AddOrgPriceListResourceToComponent";
        }
    }))({
        reflectSymbol: function () {
            return "AddOrgPriceList";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "DeleteOrgResourcePrices";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "UpdateAllOrgResourceCostClasses";
        }
    }))({
        reflectSymbol: function () {
            return "ReplaceComponentResource";
        }
    }))({
        reflectSymbol: function () {
            return "EditOrgResourceGroupingClassification";
        }
    }))({
        reflectSymbol: function () {
            return "EditOrgPriceList";
        }
    }))({
        reflectSymbol: function () {
            return "AddOrgResourceGroupingClassification";
        }
    }))({
        reflectSymbol: function () {
            return "AddOrgResourceGrouping";
        }
    }))({
        reflectSymbol: function () {
            return "AddOrgPriceListResourceToComponent";
        }
    }))({
        reflectSymbol: function () {
            return "AddOrgPriceList";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "EditOrgResourcePrice";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "UpdateAllOrgResourceCostClasses";
        }
    }))({
        reflectSymbol: function () {
            return "ReplaceComponentResource";
        }
    }))({
        reflectSymbol: function () {
            return "EditOrgResourceGroupingClassification";
        }
    }))({
        reflectSymbol: function () {
            return "EditOrgPriceList";
        }
    }))({
        reflectSymbol: function () {
            return "DeleteOrgResourcePrices";
        }
    }))({
        reflectSymbol: function () {
            return "AddOrgResourceGroupingClassification";
        }
    }))({
        reflectSymbol: function () {
            return "AddOrgResourceGrouping";
        }
    }))({
        reflectSymbol: function () {
            return "AddOrgPriceListResourceToComponent";
        }
    }))({
        reflectSymbol: function () {
            return "AddOrgPriceList";
        }
    })))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "AddOrgPriceList";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "name";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "orgPriceListId";
        }
    })(Types_Component.tofuJSONOrgEstimationResourceId)(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "name";
        }
    })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "orgPriceListId";
        }
    })(Types_Component.tofuJSONOrgEstimationResourceId)(TofuJSON.nilWriteForeignFields)()()())()()()))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "AddOrgPriceListResourceToComponent";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "componentId";
        }
    })(Types_Component.tofuJSONComponentId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "orgResourcePriceIds";
        }
    })(TofuJSON.readArray(TofuJSON.readInt))(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "componentId";
        }
    })(Types_Component.tofuJSONComponentId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "orgResourcePriceIds";
        }
    })(TofuJSON.readArray(TofuJSON.readInt))(TofuJSON.nilWriteForeignFields)()()())()()()))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "AddOrgResourceGrouping";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "associatedOrgCostClassIds";
        }
    })(TofuJSON.readArray(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "id";
        }
    })(TofuJSON.readInt)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "orgCostClassId";
        }
    })(TofuJSON.readInt)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "orgPriceListId";
        }
    })(Types_Component.tofuJSONOrgEstimationResourceId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "orgResourceGroupingId";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsNil)()())()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "id";
        }
    })(TofuJSON.readInt)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "orgCostClassId";
        }
    })(TofuJSON.readInt)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "orgPriceListId";
        }
    })(Types_Component.tofuJSONOrgEstimationResourceId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "orgResourceGroupingId";
        }
    })(TofuJSON.readString)(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "description";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "orgPriceListId";
        }
    })(Types_Component.tofuJSONOrgEstimationResourceId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "orgResourceGroupingId";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsNil)()())()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "associatedOrgCostClassIds";
        }
    })(TofuJSON.readArray(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "id";
        }
    })(TofuJSON.readInt)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "orgCostClassId";
        }
    })(TofuJSON.readInt)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "orgPriceListId";
        }
    })(Types_Component.tofuJSONOrgEstimationResourceId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "orgResourceGroupingId";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsNil)()())()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "id";
        }
    })(TofuJSON.readInt)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "orgCostClassId";
        }
    })(TofuJSON.readInt)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "orgPriceListId";
        }
    })(Types_Component.tofuJSONOrgEstimationResourceId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "orgResourceGroupingId";
        }
    })(TofuJSON.readString)(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "description";
        }
    })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "orgPriceListId";
        }
    })(Types_Component.tofuJSONOrgEstimationResourceId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "orgResourceGroupingId";
        }
    })(TofuJSON.readString)(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()()))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "AddOrgResourceGroupingClassification";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "orgClassificationId";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "orgCostClassId";
        }
    })(TofuJSON.readInt)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "orgPriceListId";
        }
    })(Types_Component.tofuJSONOrgEstimationResourceId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "orgResourceGroupingId";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsNil)()())()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "orgClassificationId";
        }
    })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "orgCostClassId";
        }
    })(TofuJSON.readInt)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "orgPriceListId";
        }
    })(Types_Component.tofuJSONOrgEstimationResourceId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "orgResourceGroupingId";
        }
    })(TofuJSON.readString)(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()()))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "DeleteOrgResourcePrices";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "orgResourcePriceIds";
        }
    })(TofuJSON.readArray(TofuJSON.readInt))(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "orgResourcePriceIds";
        }
    })(TofuJSON.readArray(TofuJSON.readInt))(TofuJSON.nilWriteForeignFields)()()()))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "EditOrgPriceList";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "newOrgPriceList";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "name";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "orgPriceListId";
        }
    })(Types_Component.tofuJSONOrgEstimationResourceId)(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "name";
        }
    })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "orgPriceListId";
        }
    })(Types_Component.tofuJSONOrgEstimationResourceId)(TofuJSON.nilWriteForeignFields)()()())()()()))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "orgPriceListId";
        }
    })(Types_Component.tofuJSONOrgEstimationResourceId)(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "newOrgPriceList";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "name";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "orgPriceListId";
        }
    })(Types_Component.tofuJSONOrgEstimationResourceId)(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "name";
        }
    })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "orgPriceListId";
        }
    })(Types_Component.tofuJSONOrgEstimationResourceId)(TofuJSON.nilWriteForeignFields)()()())()()()))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "orgPriceListId";
        }
    })(Types_Component.tofuJSONOrgEstimationResourceId)(TofuJSON.nilWriteForeignFields)()()())()()()))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "EditOrgResourceGroupingClassification";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "newOrgCostClassId";
        }
    })(TofuJSON.readInt)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "orgClassificationId";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "orgPriceListId";
        }
    })(Types_Component.tofuJSONOrgEstimationResourceId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "orgResourceGroupingAssociationId";
        }
    })(TofuJSON.readInt)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "orgResourceGroupingId";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsNil)()())()())()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "newOrgCostClassId";
        }
    })(TofuJSON.readInt)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "orgClassificationId";
        }
    })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "orgPriceListId";
        }
    })(Types_Component.tofuJSONOrgEstimationResourceId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "orgResourceGroupingAssociationId";
        }
    })(TofuJSON.readInt)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "orgResourceGroupingId";
        }
    })(TofuJSON.readString)(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())()()()))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "EditOrgResourcePrice";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "discountPercentage";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "eanCode";
        }
    })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "index";
        }
    })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "name";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "orgResourcePriceId";
        }
    })(TofuJSON.readInt)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "ownCode";
        }
    })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "pricePerUnit";
        }
    })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "resourceClassificationGroup";
        }
    })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "sortingNumber";
        }
    })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "taxRate";
        }
    })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "unit";
        }
    })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "validDate";
        }
    })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "vendor";
        }
    })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "vendorBatchSize";
        }
    })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "vendorCode";
        }
    })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "vendorUnit";
        }
    })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsNil)()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "discountPercentage";
        }
    })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "eanCode";
        }
    })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "index";
        }
    })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "name";
        }
    })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "orgResourcePriceId";
        }
    })(TofuJSON.readInt)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "ownCode";
        }
    })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "pricePerUnit";
        }
    })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "resourceClassificationGroup";
        }
    })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "sortingNumber";
        }
    })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "taxRate";
        }
    })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "unit";
        }
    })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "validDate";
        }
    })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "vendor";
        }
    })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "vendorBatchSize";
        }
    })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "vendorCode";
        }
    })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "vendorUnit";
        }
    })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()()))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "ReplaceComponentResource";
        }
    })(TofuJSON.readArray(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "componentId";
        }
    })(Types_Component.tofuJSONComponentId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "orgResourcePriceIds";
        }
    })(TofuJSON.readArray(TofuJSON.readInt))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "replaceTargetResourceId";
        }
    })(Types_Component.tofuJSONEstimationResourceId)(TofuJSON.readFieldsNil)()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "componentId";
        }
    })(Types_Component.tofuJSONComponentId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "orgResourcePriceIds";
        }
    })(TofuJSON.readArray(TofuJSON.readInt))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "replaceTargetResourceId";
        }
    })(Types_Component.tofuJSONEstimationResourceId)(TofuJSON.nilWriteForeignFields)()()())()()())()()())))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "UpdateAllOrgResourceCostClasses";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.readVariantNil)))))))))))(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "AddOrgPriceList";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "name";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "orgPriceListId";
        }
    })(Types_Component.tofuJSONOrgEstimationResourceId)(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "name";
        }
    })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "orgPriceListId";
        }
    })(Types_Component.tofuJSONOrgEstimationResourceId)(TofuJSON.nilWriteForeignFields)()()())()()()))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "AddOrgPriceListResourceToComponent";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "componentId";
        }
    })(Types_Component.tofuJSONComponentId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "orgResourcePriceIds";
        }
    })(TofuJSON.readArray(TofuJSON.readInt))(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "componentId";
        }
    })(Types_Component.tofuJSONComponentId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "orgResourcePriceIds";
        }
    })(TofuJSON.readArray(TofuJSON.readInt))(TofuJSON.nilWriteForeignFields)()()())()()()))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "AddOrgResourceGrouping";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "associatedOrgCostClassIds";
        }
    })(TofuJSON.readArray(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "id";
        }
    })(TofuJSON.readInt)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "orgCostClassId";
        }
    })(TofuJSON.readInt)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "orgPriceListId";
        }
    })(Types_Component.tofuJSONOrgEstimationResourceId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "orgResourceGroupingId";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsNil)()())()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "id";
        }
    })(TofuJSON.readInt)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "orgCostClassId";
        }
    })(TofuJSON.readInt)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "orgPriceListId";
        }
    })(Types_Component.tofuJSONOrgEstimationResourceId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "orgResourceGroupingId";
        }
    })(TofuJSON.readString)(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "description";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "orgPriceListId";
        }
    })(Types_Component.tofuJSONOrgEstimationResourceId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "orgResourceGroupingId";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsNil)()())()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "associatedOrgCostClassIds";
        }
    })(TofuJSON.readArray(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "id";
        }
    })(TofuJSON.readInt)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "orgCostClassId";
        }
    })(TofuJSON.readInt)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "orgPriceListId";
        }
    })(Types_Component.tofuJSONOrgEstimationResourceId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "orgResourceGroupingId";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsNil)()())()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "id";
        }
    })(TofuJSON.readInt)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "orgCostClassId";
        }
    })(TofuJSON.readInt)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "orgPriceListId";
        }
    })(Types_Component.tofuJSONOrgEstimationResourceId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "orgResourceGroupingId";
        }
    })(TofuJSON.readString)(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "description";
        }
    })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "orgPriceListId";
        }
    })(Types_Component.tofuJSONOrgEstimationResourceId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "orgResourceGroupingId";
        }
    })(TofuJSON.readString)(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()()))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "AddOrgResourceGroupingClassification";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "orgClassificationId";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "orgCostClassId";
        }
    })(TofuJSON.readInt)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "orgPriceListId";
        }
    })(Types_Component.tofuJSONOrgEstimationResourceId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "orgResourceGroupingId";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsNil)()())()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "orgClassificationId";
        }
    })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "orgCostClassId";
        }
    })(TofuJSON.readInt)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "orgPriceListId";
        }
    })(Types_Component.tofuJSONOrgEstimationResourceId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "orgResourceGroupingId";
        }
    })(TofuJSON.readString)(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()()))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "DeleteOrgResourcePrices";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "orgResourcePriceIds";
        }
    })(TofuJSON.readArray(TofuJSON.readInt))(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "orgResourcePriceIds";
        }
    })(TofuJSON.readArray(TofuJSON.readInt))(TofuJSON.nilWriteForeignFields)()()()))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "EditOrgPriceList";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "newOrgPriceList";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "name";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "orgPriceListId";
        }
    })(Types_Component.tofuJSONOrgEstimationResourceId)(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "name";
        }
    })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "orgPriceListId";
        }
    })(Types_Component.tofuJSONOrgEstimationResourceId)(TofuJSON.nilWriteForeignFields)()()())()()()))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "orgPriceListId";
        }
    })(Types_Component.tofuJSONOrgEstimationResourceId)(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "newOrgPriceList";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "name";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "orgPriceListId";
        }
    })(Types_Component.tofuJSONOrgEstimationResourceId)(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "name";
        }
    })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "orgPriceListId";
        }
    })(Types_Component.tofuJSONOrgEstimationResourceId)(TofuJSON.nilWriteForeignFields)()()())()()()))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "orgPriceListId";
        }
    })(Types_Component.tofuJSONOrgEstimationResourceId)(TofuJSON.nilWriteForeignFields)()()())()()()))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "EditOrgResourceGroupingClassification";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "newOrgCostClassId";
        }
    })(TofuJSON.readInt)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "orgClassificationId";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "orgPriceListId";
        }
    })(Types_Component.tofuJSONOrgEstimationResourceId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "orgResourceGroupingAssociationId";
        }
    })(TofuJSON.readInt)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "orgResourceGroupingId";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsNil)()())()())()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "newOrgCostClassId";
        }
    })(TofuJSON.readInt)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "orgClassificationId";
        }
    })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "orgPriceListId";
        }
    })(Types_Component.tofuJSONOrgEstimationResourceId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "orgResourceGroupingAssociationId";
        }
    })(TofuJSON.readInt)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "orgResourceGroupingId";
        }
    })(TofuJSON.readString)(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())()()()))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "EditOrgResourcePrice";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "discountPercentage";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "eanCode";
        }
    })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "index";
        }
    })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "name";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "orgResourcePriceId";
        }
    })(TofuJSON.readInt)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "ownCode";
        }
    })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "pricePerUnit";
        }
    })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "resourceClassificationGroup";
        }
    })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "sortingNumber";
        }
    })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "taxRate";
        }
    })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "unit";
        }
    })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "validDate";
        }
    })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "vendor";
        }
    })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "vendorBatchSize";
        }
    })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "vendorCode";
        }
    })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "vendorUnit";
        }
    })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsNil)()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "discountPercentage";
        }
    })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "eanCode";
        }
    })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "index";
        }
    })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "name";
        }
    })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "orgResourcePriceId";
        }
    })(TofuJSON.readInt)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "ownCode";
        }
    })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "pricePerUnit";
        }
    })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "resourceClassificationGroup";
        }
    })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "sortingNumber";
        }
    })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "taxRate";
        }
    })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "unit";
        }
    })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "validDate";
        }
    })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "vendor";
        }
    })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "vendorBatchSize";
        }
    })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "vendorCode";
        }
    })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "vendorUnit";
        }
    })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()()))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "ReplaceComponentResource";
        }
    })(TofuJSON.readArray(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "componentId";
        }
    })(Types_Component.tofuJSONComponentId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "orgResourcePriceIds";
        }
    })(TofuJSON.readArray(TofuJSON.readInt))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "replaceTargetResourceId";
        }
    })(Types_Component.tofuJSONEstimationResourceId)(TofuJSON.readFieldsNil)()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "componentId";
        }
    })(Types_Component.tofuJSONComponentId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "orgResourcePriceIds";
        }
    })(TofuJSON.readArray(TofuJSON.readInt))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "replaceTargetResourceId";
        }
    })(Types_Component.tofuJSONEstimationResourceId)(TofuJSON.nilWriteForeignFields)()()())()()())()()())))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "UpdateAllOrgResourceCostClasses";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.nilWriteForeignVariant)))))))))))
};
var genericEstimationResourceActionParams = {
    to: function (x) {
        if (x instanceof Data_Generic_Rep.Inl) {
            return new AddEstimationResource(x.value0);
        };
        if (x instanceof Data_Generic_Rep.Inr && x.value0 instanceof Data_Generic_Rep.Inl) {
            return new AddAndDeleteMultipleResources(x.value0.value0);
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0 instanceof Data_Generic_Rep.Inl)) {
            return new CopyResourcesToComponent(x.value0.value0.value0);
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0 instanceof Data_Generic_Rep.Inl))) {
            return new ReplaceComponentResources(x.value0.value0.value0.value0);
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inl)))) {
            return new AddResourcesToComponentFromOrgPriceList(x.value0.value0.value0.value0.value0);
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inl))))) {
            return new ReplaceComponentResourceFromOrgPriceList(x.value0.value0.value0.value0.value0.value0);
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inl)))))) {
            return new ReplaceComponentResourceFromProject(x.value0.value0.value0.value0.value0.value0.value0);
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inl))))))) {
            return new EditEstimationResource(x.value0.value0.value0.value0.value0.value0.value0.value0);
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inl)))))))) {
            return new EditEstimationCostClassPrice(x.value0.value0.value0.value0.value0.value0.value0.value0.value0);
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inl))))))))) {
            return new EditEstimationCostClassPriceConsumptionRate(x.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0);
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inl)))))))))) {
            return new EditEstimationCostClassPriceAndConsumptionRate(x.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0);
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inl))))))))))) {
            return new DeleteEstimationResources(x.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0);
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inl)))))))))))) {
            return new SetEstimationResourcesCostClass(x.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0);
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr)))))))))))) {
            return new SetEstimationResourcesGroup(x.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0);
        };
        throw new Error("Failed pattern match at Types.Estimation (line 692, column 1 - line 692, column 98): " + [ x.constructor.name ]);
    },
    from: function (x) {
        if (x instanceof AddEstimationResource) {
            return new Data_Generic_Rep.Inl(x.value0);
        };
        if (x instanceof AddAndDeleteMultipleResources) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(x.value0));
        };
        if (x instanceof CopyResourcesToComponent) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(x.value0)));
        };
        if (x instanceof ReplaceComponentResources) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(x.value0))));
        };
        if (x instanceof AddResourcesToComponentFromOrgPriceList) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(x.value0)))));
        };
        if (x instanceof ReplaceComponentResourceFromOrgPriceList) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(x.value0))))));
        };
        if (x instanceof ReplaceComponentResourceFromProject) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(x.value0)))))));
        };
        if (x instanceof EditEstimationResource) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(x.value0))))))));
        };
        if (x instanceof EditEstimationCostClassPrice) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(x.value0)))))))));
        };
        if (x instanceof EditEstimationCostClassPriceConsumptionRate) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(x.value0))))))))));
        };
        if (x instanceof EditEstimationCostClassPriceAndConsumptionRate) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(x.value0)))))))))));
        };
        if (x instanceof DeleteEstimationResources) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(x.value0))))))))))));
        };
        if (x instanceof SetEstimationResourcesCostClass) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(x.value0)))))))))))));
        };
        if (x instanceof SetEstimationResourcesGroup) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(x.value0)))))))))))));
        };
        throw new Error("Failed pattern match at Types.Estimation (line 692, column 1 - line 692, column 98): " + [ x.constructor.name ]);
    }
};
var tofuJSONEstimationResourceActionParams = {
    writeImpl: TofuJSON.writeGenericSum()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "AddAndDeleteMultipleResources";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "addResources";
        }
    })(TofuJSON.readArray(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "costClassCode";
        }
    })(Types_Newtypes.tofuJSONCostClassCode)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "details";
        }
    })(TofuJSON.readMaybe(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "changeDate";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "contract";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "currency";
        }
    })(TofuJSON.readMaybe(Types_Project.tofuJSONCurrency))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "discountGroupCode";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "discountPercentage";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "eanCode";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "multiplier";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "resourceClassificationGroup";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "sortingNumber";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "taxPercentage";
        }
    })(Types_Unit.tofuJSONTaxrate)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "unitConsumptionRate";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "validDate";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "vendor";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "vendorBatchSize";
        }
    })(TofuJSON.readMaybe(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "vendorCode";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "wastePercentage";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsNil)()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "changeDate";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "contract";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "currency";
        }
    })(TofuJSON.readMaybe(Types_Project.tofuJSONCurrency))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "discountGroupCode";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "discountPercentage";
        }
    })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "eanCode";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "multiplier";
        }
    })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "resourceClassificationGroup";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "sortingNumber";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "taxPercentage";
        }
    })(Types_Unit.tofuJSONTaxrate)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "unitConsumptionRate";
        }
    })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "validDate";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "vendor";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "vendorBatchSize";
        }
    })(TofuJSON.readMaybe(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "vendorCode";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "wastePercentage";
        }
    })(TofuJSON.readNumber)(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "estimationComponentId";
        }
    })(Types_Component.tofuJSONComponentId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "group";
        }
    })(TofuJSON.readMaybe(Types_ClassificationGroup.tofuJSONClassificationGroupCode))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "index";
        }
    })(TofuJSON.readMaybe(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "isCostClassResource";
        }
    })(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "name";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "orgResourcePriceId";
        }
    })(TofuJSON.readMaybe(TofuJSON.readInt))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "orgResourcePriceListId";
        }
    })(TofuJSON.readMaybe(Types_Component.tofuJSONOrgEstimationResourceId))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "price";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "unit";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.readFieldsNil)()())()())()())()())()())()())()())()())()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "costClassCode";
        }
    })(Types_Newtypes.tofuJSONCostClassCode)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "details";
        }
    })(TofuJSON.readMaybe(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "changeDate";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "contract";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "currency";
        }
    })(TofuJSON.readMaybe(Types_Project.tofuJSONCurrency))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "discountGroupCode";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "discountPercentage";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "eanCode";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "multiplier";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "resourceClassificationGroup";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "sortingNumber";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "taxPercentage";
        }
    })(Types_Unit.tofuJSONTaxrate)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "unitConsumptionRate";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "validDate";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "vendor";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "vendorBatchSize";
        }
    })(TofuJSON.readMaybe(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "vendorCode";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "wastePercentage";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsNil)()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "changeDate";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "contract";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "currency";
        }
    })(TofuJSON.readMaybe(Types_Project.tofuJSONCurrency))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "discountGroupCode";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "discountPercentage";
        }
    })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "eanCode";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "multiplier";
        }
    })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "resourceClassificationGroup";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "sortingNumber";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "taxPercentage";
        }
    })(Types_Unit.tofuJSONTaxrate)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "unitConsumptionRate";
        }
    })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "validDate";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "vendor";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "vendorBatchSize";
        }
    })(TofuJSON.readMaybe(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "vendorCode";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "wastePercentage";
        }
    })(TofuJSON.readNumber)(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "estimationComponentId";
        }
    })(Types_Component.tofuJSONComponentId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "group";
        }
    })(TofuJSON.readMaybe(Types_ClassificationGroup.tofuJSONClassificationGroupCode))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "index";
        }
    })(TofuJSON.readMaybe(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "isCostClassResource";
        }
    })(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "name";
        }
    })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "orgResourcePriceId";
        }
    })(TofuJSON.readMaybe(TofuJSON.readInt))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "orgResourcePriceListId";
        }
    })(TofuJSON.readMaybe(Types_Component.tofuJSONOrgEstimationResourceId))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "price";
        }
    })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "unit";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "deleteResources";
        }
    })(TofuJSON.readArray(Types_Component.tofuJSONEstimationResourceId))(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "addResources";
        }
    })(TofuJSON.readArray(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "costClassCode";
        }
    })(Types_Newtypes.tofuJSONCostClassCode)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "details";
        }
    })(TofuJSON.readMaybe(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "changeDate";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "contract";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "currency";
        }
    })(TofuJSON.readMaybe(Types_Project.tofuJSONCurrency))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "discountGroupCode";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "discountPercentage";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "eanCode";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "multiplier";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "resourceClassificationGroup";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "sortingNumber";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "taxPercentage";
        }
    })(Types_Unit.tofuJSONTaxrate)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "unitConsumptionRate";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "validDate";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "vendor";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "vendorBatchSize";
        }
    })(TofuJSON.readMaybe(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "vendorCode";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "wastePercentage";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsNil)()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "changeDate";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "contract";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "currency";
        }
    })(TofuJSON.readMaybe(Types_Project.tofuJSONCurrency))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "discountGroupCode";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "discountPercentage";
        }
    })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "eanCode";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "multiplier";
        }
    })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "resourceClassificationGroup";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "sortingNumber";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "taxPercentage";
        }
    })(Types_Unit.tofuJSONTaxrate)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "unitConsumptionRate";
        }
    })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "validDate";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "vendor";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "vendorBatchSize";
        }
    })(TofuJSON.readMaybe(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "vendorCode";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "wastePercentage";
        }
    })(TofuJSON.readNumber)(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "estimationComponentId";
        }
    })(Types_Component.tofuJSONComponentId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "group";
        }
    })(TofuJSON.readMaybe(Types_ClassificationGroup.tofuJSONClassificationGroupCode))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "index";
        }
    })(TofuJSON.readMaybe(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "isCostClassResource";
        }
    })(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "name";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "orgResourcePriceId";
        }
    })(TofuJSON.readMaybe(TofuJSON.readInt))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "orgResourcePriceListId";
        }
    })(TofuJSON.readMaybe(Types_Component.tofuJSONOrgEstimationResourceId))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "price";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "unit";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.readFieldsNil)()())()())()())()())()())()())()())()())()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "costClassCode";
        }
    })(Types_Newtypes.tofuJSONCostClassCode)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "details";
        }
    })(TofuJSON.readMaybe(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "changeDate";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "contract";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "currency";
        }
    })(TofuJSON.readMaybe(Types_Project.tofuJSONCurrency))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "discountGroupCode";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "discountPercentage";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "eanCode";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "multiplier";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "resourceClassificationGroup";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "sortingNumber";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "taxPercentage";
        }
    })(Types_Unit.tofuJSONTaxrate)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "unitConsumptionRate";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "validDate";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "vendor";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "vendorBatchSize";
        }
    })(TofuJSON.readMaybe(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "vendorCode";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "wastePercentage";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsNil)()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "changeDate";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "contract";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "currency";
        }
    })(TofuJSON.readMaybe(Types_Project.tofuJSONCurrency))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "discountGroupCode";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "discountPercentage";
        }
    })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "eanCode";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "multiplier";
        }
    })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "resourceClassificationGroup";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "sortingNumber";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "taxPercentage";
        }
    })(Types_Unit.tofuJSONTaxrate)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "unitConsumptionRate";
        }
    })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "validDate";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "vendor";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "vendorBatchSize";
        }
    })(TofuJSON.readMaybe(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "vendorCode";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "wastePercentage";
        }
    })(TofuJSON.readNumber)(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "estimationComponentId";
        }
    })(Types_Component.tofuJSONComponentId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "group";
        }
    })(TofuJSON.readMaybe(Types_ClassificationGroup.tofuJSONClassificationGroupCode))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "index";
        }
    })(TofuJSON.readMaybe(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "isCostClassResource";
        }
    })(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "name";
        }
    })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "orgResourcePriceId";
        }
    })(TofuJSON.readMaybe(TofuJSON.readInt))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "orgResourcePriceListId";
        }
    })(TofuJSON.readMaybe(Types_Component.tofuJSONOrgEstimationResourceId))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "price";
        }
    })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "unit";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "deleteResources";
        }
    })(TofuJSON.readArray(Types_Component.tofuJSONEstimationResourceId))(TofuJSON.nilWriteForeignFields)()()())()()()))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "AddEstimationResource";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "costClassCode";
        }
    })(Types_Newtypes.tofuJSONCostClassCode)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "details";
        }
    })(TofuJSON.readMaybe(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "changeDate";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "contract";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "currency";
        }
    })(TofuJSON.readMaybe(Types_Project.tofuJSONCurrency))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "discountGroupCode";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "discountPercentage";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "eanCode";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "multiplier";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "resourceClassificationGroup";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "sortingNumber";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "taxPercentage";
        }
    })(Types_Unit.tofuJSONTaxrate)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "unitConsumptionRate";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "validDate";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "vendor";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "vendorBatchSize";
        }
    })(TofuJSON.readMaybe(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "vendorCode";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "wastePercentage";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsNil)()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "changeDate";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "contract";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "currency";
        }
    })(TofuJSON.readMaybe(Types_Project.tofuJSONCurrency))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "discountGroupCode";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "discountPercentage";
        }
    })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "eanCode";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "multiplier";
        }
    })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "resourceClassificationGroup";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "sortingNumber";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "taxPercentage";
        }
    })(Types_Unit.tofuJSONTaxrate)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "unitConsumptionRate";
        }
    })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "validDate";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "vendor";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "vendorBatchSize";
        }
    })(TofuJSON.readMaybe(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "vendorCode";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "wastePercentage";
        }
    })(TofuJSON.readNumber)(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "estimationComponentId";
        }
    })(Types_Component.tofuJSONComponentId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "group";
        }
    })(TofuJSON.readMaybe(Types_ClassificationGroup.tofuJSONClassificationGroupCode))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "index";
        }
    })(TofuJSON.readMaybe(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "isCostClassResource";
        }
    })(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "name";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "orgResourcePriceId";
        }
    })(TofuJSON.readMaybe(TofuJSON.readInt))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "orgResourcePriceListId";
        }
    })(TofuJSON.readMaybe(Types_Component.tofuJSONOrgEstimationResourceId))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "price";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "unit";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.readFieldsNil)()())()())()())()())()())()())()())()())()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "costClassCode";
        }
    })(Types_Newtypes.tofuJSONCostClassCode)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "details";
        }
    })(TofuJSON.readMaybe(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "changeDate";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "contract";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "currency";
        }
    })(TofuJSON.readMaybe(Types_Project.tofuJSONCurrency))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "discountGroupCode";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "discountPercentage";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "eanCode";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "multiplier";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "resourceClassificationGroup";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "sortingNumber";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "taxPercentage";
        }
    })(Types_Unit.tofuJSONTaxrate)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "unitConsumptionRate";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "validDate";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "vendor";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "vendorBatchSize";
        }
    })(TofuJSON.readMaybe(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "vendorCode";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "wastePercentage";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsNil)()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "changeDate";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "contract";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "currency";
        }
    })(TofuJSON.readMaybe(Types_Project.tofuJSONCurrency))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "discountGroupCode";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "discountPercentage";
        }
    })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "eanCode";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "multiplier";
        }
    })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "resourceClassificationGroup";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "sortingNumber";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "taxPercentage";
        }
    })(Types_Unit.tofuJSONTaxrate)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "unitConsumptionRate";
        }
    })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "validDate";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "vendor";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "vendorBatchSize";
        }
    })(TofuJSON.readMaybe(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "vendorCode";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "wastePercentage";
        }
    })(TofuJSON.readNumber)(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "estimationComponentId";
        }
    })(Types_Component.tofuJSONComponentId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "group";
        }
    })(TofuJSON.readMaybe(Types_ClassificationGroup.tofuJSONClassificationGroupCode))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "index";
        }
    })(TofuJSON.readMaybe(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "isCostClassResource";
        }
    })(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "name";
        }
    })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "orgResourcePriceId";
        }
    })(TofuJSON.readMaybe(TofuJSON.readInt))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "orgResourcePriceListId";
        }
    })(TofuJSON.readMaybe(Types_Component.tofuJSONOrgEstimationResourceId))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "price";
        }
    })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "unit";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()()))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "AddResourcesToComponentFromOrgPriceList";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "componentId";
        }
    })(Types_Component.tofuJSONComponentId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "orgResourcePriceIds";
        }
    })(TofuJSON.readArray(TofuJSON.readInt))(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "componentId";
        }
    })(Types_Component.tofuJSONComponentId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "orgResourcePriceIds";
        }
    })(TofuJSON.readArray(TofuJSON.readInt))(TofuJSON.nilWriteForeignFields)()()())()()()))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "CopyResourcesToComponent";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "componentId";
        }
    })(Types_Component.tofuJSONComponentId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "resourceIds";
        }
    })(TofuJSON.readArray(Types_Component.tofuJSONEstimationResourceId))(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "componentId";
        }
    })(Types_Component.tofuJSONComponentId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "resourceIds";
        }
    })(TofuJSON.readArray(Types_Component.tofuJSONEstimationResourceId))(TofuJSON.nilWriteForeignFields)()()())()()()))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "DeleteEstimationResources";
        }
    })(TofuJSON.readArray(Types_Component.tofuJSONEstimationResourceId))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "EditEstimationCostClassPrice";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "componentId";
        }
    })(Types_Component.tofuJSONComponentId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "costClassCode";
        }
    })(Types_Newtypes.tofuJSONCostClassCode)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "pricePerUnit";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "resourceId";
        }
    })(TofuJSON.readMaybe(Types_Component.tofuJSONEstimationResourceId))(TofuJSON.readFieldsNil)()())()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "componentId";
        }
    })(Types_Component.tofuJSONComponentId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "costClassCode";
        }
    })(Types_Newtypes.tofuJSONCostClassCode)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "pricePerUnit";
        }
    })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "resourceId";
        }
    })(TofuJSON.readMaybe(Types_Component.tofuJSONEstimationResourceId))(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()()))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "EditEstimationCostClassPriceAndConsumptionRate";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "componentId";
        }
    })(Types_Component.tofuJSONComponentId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "costClassCode";
        }
    })(Types_Newtypes.tofuJSONCostClassCode)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "pricePerUnit";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "resourceId";
        }
    })(TofuJSON.readMaybe(Types_Component.tofuJSONEstimationResourceId))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "unitConsumptionRate";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsNil)()())()())()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "componentId";
        }
    })(Types_Component.tofuJSONComponentId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "costClassCode";
        }
    })(Types_Newtypes.tofuJSONCostClassCode)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "pricePerUnit";
        }
    })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "resourceId";
        }
    })(TofuJSON.readMaybe(Types_Component.tofuJSONEstimationResourceId))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "unitConsumptionRate";
        }
    })(TofuJSON.readNumber)(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())()()()))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "EditEstimationCostClassPriceConsumptionRate";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "componentId";
        }
    })(Types_Component.tofuJSONComponentId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "costClassCode";
        }
    })(Types_Newtypes.tofuJSONCostClassCode)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "resourceId";
        }
    })(TofuJSON.readMaybe(Types_Component.tofuJSONEstimationResourceId))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "unitConsumptionRate";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsNil)()())()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "componentId";
        }
    })(Types_Component.tofuJSONComponentId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "costClassCode";
        }
    })(Types_Newtypes.tofuJSONCostClassCode)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "resourceId";
        }
    })(TofuJSON.readMaybe(Types_Component.tofuJSONEstimationResourceId))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "unitConsumptionRate";
        }
    })(TofuJSON.readNumber)(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()()))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "EditEstimationResource";
        }
    })(TofuJSON.readArray(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "resourceId";
        }
    })(Types_Component.tofuJSONEstimationResourceId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "resourceParams";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "changedDate";
        }
    })(TofuJSON.readNullable(Types_JSDateString.tofuJSONJSDateString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "costClassCode";
        }
    })(TofuJSON.readNullable(Types_Newtypes.tofuJSONCostClassCode))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "currencyId";
        }
    })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "discountPercentage";
        }
    })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "eanCode";
        }
    })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "group";
        }
    })(TofuJSON.readNullable(Types_ClassificationGroup.tofuJSONClassificationGroupCode))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "index";
        }
    })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "name";
        }
    })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "orgResourcePriceId";
        }
    })(TofuJSON.readNullable(TofuJSON.readInt))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "orgResourcePriceListId";
        }
    })(TofuJSON.readNullable(Types_Component.tofuJSONOrgEstimationResourceId))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "pricePerUnit";
        }
    })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "resourceClassificationGroup";
        }
    })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "sortingNumber";
        }
    })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "taxRate";
        }
    })(TofuJSON.readNullable(Types_Unit.tofuJSONTaxrate))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "unit";
        }
    })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "unitConsumptionRate";
        }
    })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "validDate";
        }
    })(TofuJSON.readNullable(Types_JSDateString.tofuJSONJSDateString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "vendor";
        }
    })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "vendorBatchSize";
        }
    })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "vendorCode";
        }
    })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "vendorUnit";
        }
    })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "wastePercentage";
        }
    })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsNil)()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "changedDate";
        }
    })(TofuJSON.readNullable(Types_JSDateString.tofuJSONJSDateString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "costClassCode";
        }
    })(TofuJSON.readNullable(Types_Newtypes.tofuJSONCostClassCode))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "currencyId";
        }
    })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "discountPercentage";
        }
    })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "eanCode";
        }
    })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "group";
        }
    })(TofuJSON.readNullable(Types_ClassificationGroup.tofuJSONClassificationGroupCode))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "index";
        }
    })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "name";
        }
    })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "orgResourcePriceId";
        }
    })(TofuJSON.readNullable(TofuJSON.readInt))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "orgResourcePriceListId";
        }
    })(TofuJSON.readNullable(Types_Component.tofuJSONOrgEstimationResourceId))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "pricePerUnit";
        }
    })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "resourceClassificationGroup";
        }
    })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "sortingNumber";
        }
    })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "taxRate";
        }
    })(TofuJSON.readNullable(Types_Unit.tofuJSONTaxrate))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "unit";
        }
    })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "unitConsumptionRate";
        }
    })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "validDate";
        }
    })(TofuJSON.readNullable(Types_JSDateString.tofuJSONJSDateString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "vendor";
        }
    })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "vendorBatchSize";
        }
    })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "vendorCode";
        }
    })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "vendorUnit";
        }
    })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "wastePercentage";
        }
    })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()()))(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "resourceId";
        }
    })(Types_Component.tofuJSONEstimationResourceId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "resourceParams";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "changedDate";
        }
    })(TofuJSON.readNullable(Types_JSDateString.tofuJSONJSDateString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "costClassCode";
        }
    })(TofuJSON.readNullable(Types_Newtypes.tofuJSONCostClassCode))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "currencyId";
        }
    })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "discountPercentage";
        }
    })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "eanCode";
        }
    })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "group";
        }
    })(TofuJSON.readNullable(Types_ClassificationGroup.tofuJSONClassificationGroupCode))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "index";
        }
    })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "name";
        }
    })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "orgResourcePriceId";
        }
    })(TofuJSON.readNullable(TofuJSON.readInt))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "orgResourcePriceListId";
        }
    })(TofuJSON.readNullable(Types_Component.tofuJSONOrgEstimationResourceId))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "pricePerUnit";
        }
    })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "resourceClassificationGroup";
        }
    })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "sortingNumber";
        }
    })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "taxRate";
        }
    })(TofuJSON.readNullable(Types_Unit.tofuJSONTaxrate))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "unit";
        }
    })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "unitConsumptionRate";
        }
    })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "validDate";
        }
    })(TofuJSON.readNullable(Types_JSDateString.tofuJSONJSDateString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "vendor";
        }
    })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "vendorBatchSize";
        }
    })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "vendorCode";
        }
    })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "vendorUnit";
        }
    })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "wastePercentage";
        }
    })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsNil)()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "changedDate";
        }
    })(TofuJSON.readNullable(Types_JSDateString.tofuJSONJSDateString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "costClassCode";
        }
    })(TofuJSON.readNullable(Types_Newtypes.tofuJSONCostClassCode))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "currencyId";
        }
    })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "discountPercentage";
        }
    })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "eanCode";
        }
    })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "group";
        }
    })(TofuJSON.readNullable(Types_ClassificationGroup.tofuJSONClassificationGroupCode))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "index";
        }
    })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "name";
        }
    })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "orgResourcePriceId";
        }
    })(TofuJSON.readNullable(TofuJSON.readInt))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "orgResourcePriceListId";
        }
    })(TofuJSON.readNullable(Types_Component.tofuJSONOrgEstimationResourceId))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "pricePerUnit";
        }
    })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "resourceClassificationGroup";
        }
    })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "sortingNumber";
        }
    })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "taxRate";
        }
    })(TofuJSON.readNullable(Types_Unit.tofuJSONTaxrate))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "unit";
        }
    })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "unitConsumptionRate";
        }
    })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "validDate";
        }
    })(TofuJSON.readNullable(Types_JSDateString.tofuJSONJSDateString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "vendor";
        }
    })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "vendorBatchSize";
        }
    })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "vendorCode";
        }
    })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "vendorUnit";
        }
    })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "wastePercentage";
        }
    })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()()))(TofuJSON.nilWriteForeignFields)()()())()()())))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "ReplaceComponentResourceFromOrgPriceList";
        }
    })(TofuJSON.readArray(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "componentId";
        }
    })(Types_Component.tofuJSONComponentId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "orgResourcePriceIds";
        }
    })(TofuJSON.readArray(TofuJSON.readInt))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "replaceTargetResourceId";
        }
    })(Types_Component.tofuJSONEstimationResourceId)(TofuJSON.readFieldsNil)()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "componentId";
        }
    })(Types_Component.tofuJSONComponentId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "orgResourcePriceIds";
        }
    })(TofuJSON.readArray(TofuJSON.readInt))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "replaceTargetResourceId";
        }
    })(Types_Component.tofuJSONEstimationResourceId)(TofuJSON.nilWriteForeignFields)()()())()()())()()())))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "ReplaceComponentResourceFromProject";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "componentId";
        }
    })(Types_Component.tofuJSONComponentId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "newResourceId";
        }
    })(Types_Component.tofuJSONEstimationResourceId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "replaceTargetResourceId";
        }
    })(Types_Component.tofuJSONEstimationResourceId)(TofuJSON.readFieldsNil)()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "componentId";
        }
    })(Types_Component.tofuJSONComponentId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "newResourceId";
        }
    })(Types_Component.tofuJSONEstimationResourceId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "replaceTargetResourceId";
        }
    })(Types_Component.tofuJSONEstimationResourceId)(TofuJSON.nilWriteForeignFields)()()())()()())()()()))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "ReplaceComponentResources";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "componentId";
        }
    })(Types_Component.tofuJSONComponentId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "referenceComponentId";
        }
    })(Types_Component.tofuJSONComponentId)(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "componentId";
        }
    })(Types_Component.tofuJSONComponentId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "referenceComponentId";
        }
    })(Types_Component.tofuJSONComponentId)(TofuJSON.nilWriteForeignFields)()()())()()()))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "SetEstimationResourcesCostClass";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "costClassCode";
        }
    })(Types_Newtypes.tofuJSONCostClassCode)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "estimationResourceIds";
        }
    })(TofuJSON.readArray(Types_Component.tofuJSONEstimationResourceId))(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "costClassCode";
        }
    })(Types_Newtypes.tofuJSONCostClassCode)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "estimationResourceIds";
        }
    })(TofuJSON.readArray(Types_Component.tofuJSONEstimationResourceId))(TofuJSON.nilWriteForeignFields)()()())()()()))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "SetEstimationResourcesGroup";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "estimationResourceIds";
        }
    })(TofuJSON.readArray(Types_Component.tofuJSONEstimationResourceId))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "group";
        }
    })(TofuJSON.readNullable(Types_ClassificationGroup.tofuJSONClassificationGroupCode))(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "estimationResourceIds";
        }
    })(TofuJSON.readArray(Types_Component.tofuJSONEstimationResourceId))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "group";
        }
    })(TofuJSON.readNullable(Types_ClassificationGroup.tofuJSONClassificationGroupCode))(TofuJSON.nilWriteForeignFields)()()())()()()))()(TofuJSON.readVariantNil)))))))))))))))(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "AddAndDeleteMultipleResources";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "addResources";
        }
    })(TofuJSON.readArray(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "costClassCode";
        }
    })(Types_Newtypes.tofuJSONCostClassCode)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "details";
        }
    })(TofuJSON.readMaybe(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "changeDate";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "contract";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "currency";
        }
    })(TofuJSON.readMaybe(Types_Project.tofuJSONCurrency))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "discountGroupCode";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "discountPercentage";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "eanCode";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "multiplier";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "resourceClassificationGroup";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "sortingNumber";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "taxPercentage";
        }
    })(Types_Unit.tofuJSONTaxrate)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "unitConsumptionRate";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "validDate";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "vendor";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "vendorBatchSize";
        }
    })(TofuJSON.readMaybe(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "vendorCode";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "wastePercentage";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsNil)()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "changeDate";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "contract";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "currency";
        }
    })(TofuJSON.readMaybe(Types_Project.tofuJSONCurrency))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "discountGroupCode";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "discountPercentage";
        }
    })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "eanCode";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "multiplier";
        }
    })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "resourceClassificationGroup";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "sortingNumber";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "taxPercentage";
        }
    })(Types_Unit.tofuJSONTaxrate)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "unitConsumptionRate";
        }
    })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "validDate";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "vendor";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "vendorBatchSize";
        }
    })(TofuJSON.readMaybe(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "vendorCode";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "wastePercentage";
        }
    })(TofuJSON.readNumber)(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "estimationComponentId";
        }
    })(Types_Component.tofuJSONComponentId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "group";
        }
    })(TofuJSON.readMaybe(Types_ClassificationGroup.tofuJSONClassificationGroupCode))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "index";
        }
    })(TofuJSON.readMaybe(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "isCostClassResource";
        }
    })(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "name";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "orgResourcePriceId";
        }
    })(TofuJSON.readMaybe(TofuJSON.readInt))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "orgResourcePriceListId";
        }
    })(TofuJSON.readMaybe(Types_Component.tofuJSONOrgEstimationResourceId))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "price";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "unit";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.readFieldsNil)()())()())()())()())()())()())()())()())()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "costClassCode";
        }
    })(Types_Newtypes.tofuJSONCostClassCode)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "details";
        }
    })(TofuJSON.readMaybe(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "changeDate";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "contract";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "currency";
        }
    })(TofuJSON.readMaybe(Types_Project.tofuJSONCurrency))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "discountGroupCode";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "discountPercentage";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "eanCode";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "multiplier";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "resourceClassificationGroup";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "sortingNumber";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "taxPercentage";
        }
    })(Types_Unit.tofuJSONTaxrate)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "unitConsumptionRate";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "validDate";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "vendor";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "vendorBatchSize";
        }
    })(TofuJSON.readMaybe(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "vendorCode";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "wastePercentage";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsNil)()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "changeDate";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "contract";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "currency";
        }
    })(TofuJSON.readMaybe(Types_Project.tofuJSONCurrency))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "discountGroupCode";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "discountPercentage";
        }
    })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "eanCode";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "multiplier";
        }
    })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "resourceClassificationGroup";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "sortingNumber";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "taxPercentage";
        }
    })(Types_Unit.tofuJSONTaxrate)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "unitConsumptionRate";
        }
    })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "validDate";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "vendor";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "vendorBatchSize";
        }
    })(TofuJSON.readMaybe(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "vendorCode";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "wastePercentage";
        }
    })(TofuJSON.readNumber)(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "estimationComponentId";
        }
    })(Types_Component.tofuJSONComponentId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "group";
        }
    })(TofuJSON.readMaybe(Types_ClassificationGroup.tofuJSONClassificationGroupCode))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "index";
        }
    })(TofuJSON.readMaybe(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "isCostClassResource";
        }
    })(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "name";
        }
    })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "orgResourcePriceId";
        }
    })(TofuJSON.readMaybe(TofuJSON.readInt))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "orgResourcePriceListId";
        }
    })(TofuJSON.readMaybe(Types_Component.tofuJSONOrgEstimationResourceId))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "price";
        }
    })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "unit";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "deleteResources";
        }
    })(TofuJSON.readArray(Types_Component.tofuJSONEstimationResourceId))(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "addResources";
        }
    })(TofuJSON.readArray(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "costClassCode";
        }
    })(Types_Newtypes.tofuJSONCostClassCode)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "details";
        }
    })(TofuJSON.readMaybe(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "changeDate";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "contract";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "currency";
        }
    })(TofuJSON.readMaybe(Types_Project.tofuJSONCurrency))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "discountGroupCode";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "discountPercentage";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "eanCode";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "multiplier";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "resourceClassificationGroup";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "sortingNumber";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "taxPercentage";
        }
    })(Types_Unit.tofuJSONTaxrate)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "unitConsumptionRate";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "validDate";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "vendor";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "vendorBatchSize";
        }
    })(TofuJSON.readMaybe(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "vendorCode";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "wastePercentage";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsNil)()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "changeDate";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "contract";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "currency";
        }
    })(TofuJSON.readMaybe(Types_Project.tofuJSONCurrency))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "discountGroupCode";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "discountPercentage";
        }
    })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "eanCode";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "multiplier";
        }
    })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "resourceClassificationGroup";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "sortingNumber";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "taxPercentage";
        }
    })(Types_Unit.tofuJSONTaxrate)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "unitConsumptionRate";
        }
    })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "validDate";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "vendor";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "vendorBatchSize";
        }
    })(TofuJSON.readMaybe(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "vendorCode";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "wastePercentage";
        }
    })(TofuJSON.readNumber)(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "estimationComponentId";
        }
    })(Types_Component.tofuJSONComponentId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "group";
        }
    })(TofuJSON.readMaybe(Types_ClassificationGroup.tofuJSONClassificationGroupCode))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "index";
        }
    })(TofuJSON.readMaybe(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "isCostClassResource";
        }
    })(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "name";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "orgResourcePriceId";
        }
    })(TofuJSON.readMaybe(TofuJSON.readInt))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "orgResourcePriceListId";
        }
    })(TofuJSON.readMaybe(Types_Component.tofuJSONOrgEstimationResourceId))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "price";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "unit";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.readFieldsNil)()())()())()())()())()())()())()())()())()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "costClassCode";
        }
    })(Types_Newtypes.tofuJSONCostClassCode)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "details";
        }
    })(TofuJSON.readMaybe(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "changeDate";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "contract";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "currency";
        }
    })(TofuJSON.readMaybe(Types_Project.tofuJSONCurrency))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "discountGroupCode";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "discountPercentage";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "eanCode";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "multiplier";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "resourceClassificationGroup";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "sortingNumber";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "taxPercentage";
        }
    })(Types_Unit.tofuJSONTaxrate)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "unitConsumptionRate";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "validDate";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "vendor";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "vendorBatchSize";
        }
    })(TofuJSON.readMaybe(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "vendorCode";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "wastePercentage";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsNil)()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "changeDate";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "contract";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "currency";
        }
    })(TofuJSON.readMaybe(Types_Project.tofuJSONCurrency))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "discountGroupCode";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "discountPercentage";
        }
    })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "eanCode";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "multiplier";
        }
    })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "resourceClassificationGroup";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "sortingNumber";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "taxPercentage";
        }
    })(Types_Unit.tofuJSONTaxrate)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "unitConsumptionRate";
        }
    })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "validDate";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "vendor";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "vendorBatchSize";
        }
    })(TofuJSON.readMaybe(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "vendorCode";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "wastePercentage";
        }
    })(TofuJSON.readNumber)(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "estimationComponentId";
        }
    })(Types_Component.tofuJSONComponentId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "group";
        }
    })(TofuJSON.readMaybe(Types_ClassificationGroup.tofuJSONClassificationGroupCode))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "index";
        }
    })(TofuJSON.readMaybe(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "isCostClassResource";
        }
    })(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "name";
        }
    })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "orgResourcePriceId";
        }
    })(TofuJSON.readMaybe(TofuJSON.readInt))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "orgResourcePriceListId";
        }
    })(TofuJSON.readMaybe(Types_Component.tofuJSONOrgEstimationResourceId))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "price";
        }
    })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "unit";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "deleteResources";
        }
    })(TofuJSON.readArray(Types_Component.tofuJSONEstimationResourceId))(TofuJSON.nilWriteForeignFields)()()())()()()))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "AddEstimationResource";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "costClassCode";
        }
    })(Types_Newtypes.tofuJSONCostClassCode)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "details";
        }
    })(TofuJSON.readMaybe(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "changeDate";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "contract";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "currency";
        }
    })(TofuJSON.readMaybe(Types_Project.tofuJSONCurrency))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "discountGroupCode";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "discountPercentage";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "eanCode";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "multiplier";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "resourceClassificationGroup";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "sortingNumber";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "taxPercentage";
        }
    })(Types_Unit.tofuJSONTaxrate)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "unitConsumptionRate";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "validDate";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "vendor";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "vendorBatchSize";
        }
    })(TofuJSON.readMaybe(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "vendorCode";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "wastePercentage";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsNil)()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "changeDate";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "contract";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "currency";
        }
    })(TofuJSON.readMaybe(Types_Project.tofuJSONCurrency))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "discountGroupCode";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "discountPercentage";
        }
    })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "eanCode";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "multiplier";
        }
    })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "resourceClassificationGroup";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "sortingNumber";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "taxPercentage";
        }
    })(Types_Unit.tofuJSONTaxrate)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "unitConsumptionRate";
        }
    })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "validDate";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "vendor";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "vendorBatchSize";
        }
    })(TofuJSON.readMaybe(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "vendorCode";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "wastePercentage";
        }
    })(TofuJSON.readNumber)(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "estimationComponentId";
        }
    })(Types_Component.tofuJSONComponentId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "group";
        }
    })(TofuJSON.readMaybe(Types_ClassificationGroup.tofuJSONClassificationGroupCode))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "index";
        }
    })(TofuJSON.readMaybe(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "isCostClassResource";
        }
    })(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "name";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "orgResourcePriceId";
        }
    })(TofuJSON.readMaybe(TofuJSON.readInt))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "orgResourcePriceListId";
        }
    })(TofuJSON.readMaybe(Types_Component.tofuJSONOrgEstimationResourceId))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "price";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "unit";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.readFieldsNil)()())()())()())()())()())()())()())()())()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "costClassCode";
        }
    })(Types_Newtypes.tofuJSONCostClassCode)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "details";
        }
    })(TofuJSON.readMaybe(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "changeDate";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "contract";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "currency";
        }
    })(TofuJSON.readMaybe(Types_Project.tofuJSONCurrency))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "discountGroupCode";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "discountPercentage";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "eanCode";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "multiplier";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "resourceClassificationGroup";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "sortingNumber";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "taxPercentage";
        }
    })(Types_Unit.tofuJSONTaxrate)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "unitConsumptionRate";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "validDate";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "vendor";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "vendorBatchSize";
        }
    })(TofuJSON.readMaybe(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "vendorCode";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "wastePercentage";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsNil)()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "changeDate";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "contract";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "currency";
        }
    })(TofuJSON.readMaybe(Types_Project.tofuJSONCurrency))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "discountGroupCode";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "discountPercentage";
        }
    })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "eanCode";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "multiplier";
        }
    })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "resourceClassificationGroup";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "sortingNumber";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "taxPercentage";
        }
    })(Types_Unit.tofuJSONTaxrate)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "unitConsumptionRate";
        }
    })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "validDate";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "vendor";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "vendorBatchSize";
        }
    })(TofuJSON.readMaybe(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "vendorCode";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "wastePercentage";
        }
    })(TofuJSON.readNumber)(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "estimationComponentId";
        }
    })(Types_Component.tofuJSONComponentId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "group";
        }
    })(TofuJSON.readMaybe(Types_ClassificationGroup.tofuJSONClassificationGroupCode))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "index";
        }
    })(TofuJSON.readMaybe(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "isCostClassResource";
        }
    })(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "name";
        }
    })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "orgResourcePriceId";
        }
    })(TofuJSON.readMaybe(TofuJSON.readInt))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "orgResourcePriceListId";
        }
    })(TofuJSON.readMaybe(Types_Component.tofuJSONOrgEstimationResourceId))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "price";
        }
    })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "unit";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()()))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "AddResourcesToComponentFromOrgPriceList";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "componentId";
        }
    })(Types_Component.tofuJSONComponentId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "orgResourcePriceIds";
        }
    })(TofuJSON.readArray(TofuJSON.readInt))(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "componentId";
        }
    })(Types_Component.tofuJSONComponentId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "orgResourcePriceIds";
        }
    })(TofuJSON.readArray(TofuJSON.readInt))(TofuJSON.nilWriteForeignFields)()()())()()()))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "CopyResourcesToComponent";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "componentId";
        }
    })(Types_Component.tofuJSONComponentId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "resourceIds";
        }
    })(TofuJSON.readArray(Types_Component.tofuJSONEstimationResourceId))(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "componentId";
        }
    })(Types_Component.tofuJSONComponentId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "resourceIds";
        }
    })(TofuJSON.readArray(Types_Component.tofuJSONEstimationResourceId))(TofuJSON.nilWriteForeignFields)()()())()()()))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "DeleteEstimationResources";
        }
    })(TofuJSON.readArray(Types_Component.tofuJSONEstimationResourceId))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "EditEstimationCostClassPrice";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "componentId";
        }
    })(Types_Component.tofuJSONComponentId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "costClassCode";
        }
    })(Types_Newtypes.tofuJSONCostClassCode)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "pricePerUnit";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "resourceId";
        }
    })(TofuJSON.readMaybe(Types_Component.tofuJSONEstimationResourceId))(TofuJSON.readFieldsNil)()())()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "componentId";
        }
    })(Types_Component.tofuJSONComponentId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "costClassCode";
        }
    })(Types_Newtypes.tofuJSONCostClassCode)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "pricePerUnit";
        }
    })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "resourceId";
        }
    })(TofuJSON.readMaybe(Types_Component.tofuJSONEstimationResourceId))(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()()))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "EditEstimationCostClassPriceAndConsumptionRate";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "componentId";
        }
    })(Types_Component.tofuJSONComponentId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "costClassCode";
        }
    })(Types_Newtypes.tofuJSONCostClassCode)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "pricePerUnit";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "resourceId";
        }
    })(TofuJSON.readMaybe(Types_Component.tofuJSONEstimationResourceId))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "unitConsumptionRate";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsNil)()())()())()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "componentId";
        }
    })(Types_Component.tofuJSONComponentId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "costClassCode";
        }
    })(Types_Newtypes.tofuJSONCostClassCode)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "pricePerUnit";
        }
    })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "resourceId";
        }
    })(TofuJSON.readMaybe(Types_Component.tofuJSONEstimationResourceId))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "unitConsumptionRate";
        }
    })(TofuJSON.readNumber)(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())()()()))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "EditEstimationCostClassPriceConsumptionRate";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "componentId";
        }
    })(Types_Component.tofuJSONComponentId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "costClassCode";
        }
    })(Types_Newtypes.tofuJSONCostClassCode)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "resourceId";
        }
    })(TofuJSON.readMaybe(Types_Component.tofuJSONEstimationResourceId))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "unitConsumptionRate";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsNil)()())()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "componentId";
        }
    })(Types_Component.tofuJSONComponentId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "costClassCode";
        }
    })(Types_Newtypes.tofuJSONCostClassCode)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "resourceId";
        }
    })(TofuJSON.readMaybe(Types_Component.tofuJSONEstimationResourceId))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "unitConsumptionRate";
        }
    })(TofuJSON.readNumber)(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()()))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "EditEstimationResource";
        }
    })(TofuJSON.readArray(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "resourceId";
        }
    })(Types_Component.tofuJSONEstimationResourceId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "resourceParams";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "changedDate";
        }
    })(TofuJSON.readNullable(Types_JSDateString.tofuJSONJSDateString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "costClassCode";
        }
    })(TofuJSON.readNullable(Types_Newtypes.tofuJSONCostClassCode))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "currencyId";
        }
    })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "discountPercentage";
        }
    })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "eanCode";
        }
    })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "group";
        }
    })(TofuJSON.readNullable(Types_ClassificationGroup.tofuJSONClassificationGroupCode))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "index";
        }
    })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "name";
        }
    })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "orgResourcePriceId";
        }
    })(TofuJSON.readNullable(TofuJSON.readInt))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "orgResourcePriceListId";
        }
    })(TofuJSON.readNullable(Types_Component.tofuJSONOrgEstimationResourceId))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "pricePerUnit";
        }
    })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "resourceClassificationGroup";
        }
    })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "sortingNumber";
        }
    })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "taxRate";
        }
    })(TofuJSON.readNullable(Types_Unit.tofuJSONTaxrate))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "unit";
        }
    })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "unitConsumptionRate";
        }
    })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "validDate";
        }
    })(TofuJSON.readNullable(Types_JSDateString.tofuJSONJSDateString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "vendor";
        }
    })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "vendorBatchSize";
        }
    })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "vendorCode";
        }
    })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "vendorUnit";
        }
    })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "wastePercentage";
        }
    })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsNil)()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "changedDate";
        }
    })(TofuJSON.readNullable(Types_JSDateString.tofuJSONJSDateString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "costClassCode";
        }
    })(TofuJSON.readNullable(Types_Newtypes.tofuJSONCostClassCode))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "currencyId";
        }
    })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "discountPercentage";
        }
    })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "eanCode";
        }
    })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "group";
        }
    })(TofuJSON.readNullable(Types_ClassificationGroup.tofuJSONClassificationGroupCode))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "index";
        }
    })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "name";
        }
    })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "orgResourcePriceId";
        }
    })(TofuJSON.readNullable(TofuJSON.readInt))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "orgResourcePriceListId";
        }
    })(TofuJSON.readNullable(Types_Component.tofuJSONOrgEstimationResourceId))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "pricePerUnit";
        }
    })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "resourceClassificationGroup";
        }
    })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "sortingNumber";
        }
    })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "taxRate";
        }
    })(TofuJSON.readNullable(Types_Unit.tofuJSONTaxrate))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "unit";
        }
    })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "unitConsumptionRate";
        }
    })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "validDate";
        }
    })(TofuJSON.readNullable(Types_JSDateString.tofuJSONJSDateString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "vendor";
        }
    })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "vendorBatchSize";
        }
    })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "vendorCode";
        }
    })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "vendorUnit";
        }
    })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "wastePercentage";
        }
    })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()()))(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "resourceId";
        }
    })(Types_Component.tofuJSONEstimationResourceId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "resourceParams";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "changedDate";
        }
    })(TofuJSON.readNullable(Types_JSDateString.tofuJSONJSDateString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "costClassCode";
        }
    })(TofuJSON.readNullable(Types_Newtypes.tofuJSONCostClassCode))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "currencyId";
        }
    })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "discountPercentage";
        }
    })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "eanCode";
        }
    })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "group";
        }
    })(TofuJSON.readNullable(Types_ClassificationGroup.tofuJSONClassificationGroupCode))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "index";
        }
    })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "name";
        }
    })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "orgResourcePriceId";
        }
    })(TofuJSON.readNullable(TofuJSON.readInt))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "orgResourcePriceListId";
        }
    })(TofuJSON.readNullable(Types_Component.tofuJSONOrgEstimationResourceId))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "pricePerUnit";
        }
    })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "resourceClassificationGroup";
        }
    })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "sortingNumber";
        }
    })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "taxRate";
        }
    })(TofuJSON.readNullable(Types_Unit.tofuJSONTaxrate))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "unit";
        }
    })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "unitConsumptionRate";
        }
    })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "validDate";
        }
    })(TofuJSON.readNullable(Types_JSDateString.tofuJSONJSDateString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "vendor";
        }
    })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "vendorBatchSize";
        }
    })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "vendorCode";
        }
    })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "vendorUnit";
        }
    })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "wastePercentage";
        }
    })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsNil)()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "changedDate";
        }
    })(TofuJSON.readNullable(Types_JSDateString.tofuJSONJSDateString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "costClassCode";
        }
    })(TofuJSON.readNullable(Types_Newtypes.tofuJSONCostClassCode))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "currencyId";
        }
    })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "discountPercentage";
        }
    })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "eanCode";
        }
    })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "group";
        }
    })(TofuJSON.readNullable(Types_ClassificationGroup.tofuJSONClassificationGroupCode))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "index";
        }
    })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "name";
        }
    })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "orgResourcePriceId";
        }
    })(TofuJSON.readNullable(TofuJSON.readInt))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "orgResourcePriceListId";
        }
    })(TofuJSON.readNullable(Types_Component.tofuJSONOrgEstimationResourceId))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "pricePerUnit";
        }
    })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "resourceClassificationGroup";
        }
    })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "sortingNumber";
        }
    })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "taxRate";
        }
    })(TofuJSON.readNullable(Types_Unit.tofuJSONTaxrate))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "unit";
        }
    })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "unitConsumptionRate";
        }
    })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "validDate";
        }
    })(TofuJSON.readNullable(Types_JSDateString.tofuJSONJSDateString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "vendor";
        }
    })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "vendorBatchSize";
        }
    })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "vendorCode";
        }
    })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "vendorUnit";
        }
    })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "wastePercentage";
        }
    })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()()))(TofuJSON.nilWriteForeignFields)()()())()()())))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "ReplaceComponentResourceFromOrgPriceList";
        }
    })(TofuJSON.readArray(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "componentId";
        }
    })(Types_Component.tofuJSONComponentId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "orgResourcePriceIds";
        }
    })(TofuJSON.readArray(TofuJSON.readInt))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "replaceTargetResourceId";
        }
    })(Types_Component.tofuJSONEstimationResourceId)(TofuJSON.readFieldsNil)()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "componentId";
        }
    })(Types_Component.tofuJSONComponentId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "orgResourcePriceIds";
        }
    })(TofuJSON.readArray(TofuJSON.readInt))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "replaceTargetResourceId";
        }
    })(Types_Component.tofuJSONEstimationResourceId)(TofuJSON.nilWriteForeignFields)()()())()()())()()())))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "ReplaceComponentResourceFromProject";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "componentId";
        }
    })(Types_Component.tofuJSONComponentId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "newResourceId";
        }
    })(Types_Component.tofuJSONEstimationResourceId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "replaceTargetResourceId";
        }
    })(Types_Component.tofuJSONEstimationResourceId)(TofuJSON.readFieldsNil)()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "componentId";
        }
    })(Types_Component.tofuJSONComponentId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "newResourceId";
        }
    })(Types_Component.tofuJSONEstimationResourceId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "replaceTargetResourceId";
        }
    })(Types_Component.tofuJSONEstimationResourceId)(TofuJSON.nilWriteForeignFields)()()())()()())()()()))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "ReplaceComponentResources";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "componentId";
        }
    })(Types_Component.tofuJSONComponentId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "referenceComponentId";
        }
    })(Types_Component.tofuJSONComponentId)(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "componentId";
        }
    })(Types_Component.tofuJSONComponentId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "referenceComponentId";
        }
    })(Types_Component.tofuJSONComponentId)(TofuJSON.nilWriteForeignFields)()()())()()()))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "SetEstimationResourcesCostClass";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "costClassCode";
        }
    })(Types_Newtypes.tofuJSONCostClassCode)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "estimationResourceIds";
        }
    })(TofuJSON.readArray(Types_Component.tofuJSONEstimationResourceId))(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "costClassCode";
        }
    })(Types_Newtypes.tofuJSONCostClassCode)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "estimationResourceIds";
        }
    })(TofuJSON.readArray(Types_Component.tofuJSONEstimationResourceId))(TofuJSON.nilWriteForeignFields)()()())()()()))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "SetEstimationResourcesGroup";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "estimationResourceIds";
        }
    })(TofuJSON.readArray(Types_Component.tofuJSONEstimationResourceId))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "group";
        }
    })(TofuJSON.readNullable(Types_ClassificationGroup.tofuJSONClassificationGroupCode))(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "estimationResourceIds";
        }
    })(TofuJSON.readArray(Types_Component.tofuJSONEstimationResourceId))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "group";
        }
    })(TofuJSON.readNullable(Types_ClassificationGroup.tofuJSONClassificationGroupCode))(TofuJSON.nilWriteForeignFields)()()())()()()))()(TofuJSON.nilWriteForeignVariant)))))))))))))))(genericEstimationResourceActionParams)(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "AddEstimationResource";
        }
    })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "AddAndDeleteMultipleResources";
        }
    })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "CopyResourcesToComponent";
        }
    })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "ReplaceComponentResources";
        }
    })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "AddResourcesToComponentFromOrgPriceList";
        }
    })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "ReplaceComponentResourceFromOrgPriceList";
        }
    })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "ReplaceComponentResourceFromProject";
        }
    })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "EditEstimationResource";
        }
    })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "EditEstimationCostClassPrice";
        }
    })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "EditEstimationCostClassPriceConsumptionRate";
        }
    })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "EditEstimationCostClassPriceAndConsumptionRate";
        }
    })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "DeleteEstimationResources";
        }
    })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "SetEstimationResourcesCostClass";
        }
    })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "SetEstimationResourcesGroup";
        }
    })()(Kishimen.genericSumToVariantArgArguments))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "SetEstimationResourcesCostClass";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "SetEstimationResourcesGroup";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "DeleteEstimationResources";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "SetEstimationResourcesGroup";
        }
    }))({
        reflectSymbol: function () {
            return "SetEstimationResourcesCostClass";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "EditEstimationCostClassPriceAndConsumptionRate";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "SetEstimationResourcesGroup";
        }
    }))({
        reflectSymbol: function () {
            return "SetEstimationResourcesCostClass";
        }
    }))({
        reflectSymbol: function () {
            return "DeleteEstimationResources";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "EditEstimationCostClassPriceConsumptionRate";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "SetEstimationResourcesGroup";
        }
    }))({
        reflectSymbol: function () {
            return "SetEstimationResourcesCostClass";
        }
    }))({
        reflectSymbol: function () {
            return "EditEstimationCostClassPriceAndConsumptionRate";
        }
    }))({
        reflectSymbol: function () {
            return "DeleteEstimationResources";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "EditEstimationCostClassPrice";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "SetEstimationResourcesGroup";
        }
    }))({
        reflectSymbol: function () {
            return "SetEstimationResourcesCostClass";
        }
    }))({
        reflectSymbol: function () {
            return "EditEstimationCostClassPriceConsumptionRate";
        }
    }))({
        reflectSymbol: function () {
            return "EditEstimationCostClassPriceAndConsumptionRate";
        }
    }))({
        reflectSymbol: function () {
            return "DeleteEstimationResources";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "EditEstimationResource";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "SetEstimationResourcesGroup";
        }
    }))({
        reflectSymbol: function () {
            return "SetEstimationResourcesCostClass";
        }
    }))({
        reflectSymbol: function () {
            return "EditEstimationCostClassPriceConsumptionRate";
        }
    }))({
        reflectSymbol: function () {
            return "EditEstimationCostClassPriceAndConsumptionRate";
        }
    }))({
        reflectSymbol: function () {
            return "EditEstimationCostClassPrice";
        }
    }))({
        reflectSymbol: function () {
            return "DeleteEstimationResources";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "ReplaceComponentResourceFromProject";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "SetEstimationResourcesGroup";
        }
    }))({
        reflectSymbol: function () {
            return "SetEstimationResourcesCostClass";
        }
    }))({
        reflectSymbol: function () {
            return "EditEstimationResource";
        }
    }))({
        reflectSymbol: function () {
            return "EditEstimationCostClassPriceConsumptionRate";
        }
    }))({
        reflectSymbol: function () {
            return "EditEstimationCostClassPriceAndConsumptionRate";
        }
    }))({
        reflectSymbol: function () {
            return "EditEstimationCostClassPrice";
        }
    }))({
        reflectSymbol: function () {
            return "DeleteEstimationResources";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "ReplaceComponentResourceFromOrgPriceList";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "SetEstimationResourcesGroup";
        }
    }))({
        reflectSymbol: function () {
            return "SetEstimationResourcesCostClass";
        }
    }))({
        reflectSymbol: function () {
            return "ReplaceComponentResourceFromProject";
        }
    }))({
        reflectSymbol: function () {
            return "EditEstimationResource";
        }
    }))({
        reflectSymbol: function () {
            return "EditEstimationCostClassPriceConsumptionRate";
        }
    }))({
        reflectSymbol: function () {
            return "EditEstimationCostClassPriceAndConsumptionRate";
        }
    }))({
        reflectSymbol: function () {
            return "EditEstimationCostClassPrice";
        }
    }))({
        reflectSymbol: function () {
            return "DeleteEstimationResources";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "AddResourcesToComponentFromOrgPriceList";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "SetEstimationResourcesGroup";
        }
    }))({
        reflectSymbol: function () {
            return "SetEstimationResourcesCostClass";
        }
    }))({
        reflectSymbol: function () {
            return "ReplaceComponentResourceFromProject";
        }
    }))({
        reflectSymbol: function () {
            return "ReplaceComponentResourceFromOrgPriceList";
        }
    }))({
        reflectSymbol: function () {
            return "EditEstimationResource";
        }
    }))({
        reflectSymbol: function () {
            return "EditEstimationCostClassPriceConsumptionRate";
        }
    }))({
        reflectSymbol: function () {
            return "EditEstimationCostClassPriceAndConsumptionRate";
        }
    }))({
        reflectSymbol: function () {
            return "EditEstimationCostClassPrice";
        }
    }))({
        reflectSymbol: function () {
            return "DeleteEstimationResources";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "ReplaceComponentResources";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "SetEstimationResourcesGroup";
        }
    }))({
        reflectSymbol: function () {
            return "SetEstimationResourcesCostClass";
        }
    }))({
        reflectSymbol: function () {
            return "ReplaceComponentResourceFromProject";
        }
    }))({
        reflectSymbol: function () {
            return "ReplaceComponentResourceFromOrgPriceList";
        }
    }))({
        reflectSymbol: function () {
            return "EditEstimationResource";
        }
    }))({
        reflectSymbol: function () {
            return "EditEstimationCostClassPriceConsumptionRate";
        }
    }))({
        reflectSymbol: function () {
            return "EditEstimationCostClassPriceAndConsumptionRate";
        }
    }))({
        reflectSymbol: function () {
            return "EditEstimationCostClassPrice";
        }
    }))({
        reflectSymbol: function () {
            return "DeleteEstimationResources";
        }
    }))({
        reflectSymbol: function () {
            return "AddResourcesToComponentFromOrgPriceList";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "CopyResourcesToComponent";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "SetEstimationResourcesGroup";
        }
    }))({
        reflectSymbol: function () {
            return "SetEstimationResourcesCostClass";
        }
    }))({
        reflectSymbol: function () {
            return "ReplaceComponentResources";
        }
    }))({
        reflectSymbol: function () {
            return "ReplaceComponentResourceFromProject";
        }
    }))({
        reflectSymbol: function () {
            return "ReplaceComponentResourceFromOrgPriceList";
        }
    }))({
        reflectSymbol: function () {
            return "EditEstimationResource";
        }
    }))({
        reflectSymbol: function () {
            return "EditEstimationCostClassPriceConsumptionRate";
        }
    }))({
        reflectSymbol: function () {
            return "EditEstimationCostClassPriceAndConsumptionRate";
        }
    }))({
        reflectSymbol: function () {
            return "EditEstimationCostClassPrice";
        }
    }))({
        reflectSymbol: function () {
            return "DeleteEstimationResources";
        }
    }))({
        reflectSymbol: function () {
            return "AddResourcesToComponentFromOrgPriceList";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "AddAndDeleteMultipleResources";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "SetEstimationResourcesGroup";
        }
    }))({
        reflectSymbol: function () {
            return "SetEstimationResourcesCostClass";
        }
    }))({
        reflectSymbol: function () {
            return "ReplaceComponentResources";
        }
    }))({
        reflectSymbol: function () {
            return "ReplaceComponentResourceFromProject";
        }
    }))({
        reflectSymbol: function () {
            return "ReplaceComponentResourceFromOrgPriceList";
        }
    }))({
        reflectSymbol: function () {
            return "EditEstimationResource";
        }
    }))({
        reflectSymbol: function () {
            return "EditEstimationCostClassPriceConsumptionRate";
        }
    }))({
        reflectSymbol: function () {
            return "EditEstimationCostClassPriceAndConsumptionRate";
        }
    }))({
        reflectSymbol: function () {
            return "EditEstimationCostClassPrice";
        }
    }))({
        reflectSymbol: function () {
            return "DeleteEstimationResources";
        }
    }))({
        reflectSymbol: function () {
            return "CopyResourcesToComponent";
        }
    }))({
        reflectSymbol: function () {
            return "AddResourcesToComponentFromOrgPriceList";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "AddEstimationResource";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "SetEstimationResourcesGroup";
        }
    }))({
        reflectSymbol: function () {
            return "SetEstimationResourcesCostClass";
        }
    }))({
        reflectSymbol: function () {
            return "ReplaceComponentResources";
        }
    }))({
        reflectSymbol: function () {
            return "ReplaceComponentResourceFromProject";
        }
    }))({
        reflectSymbol: function () {
            return "ReplaceComponentResourceFromOrgPriceList";
        }
    }))({
        reflectSymbol: function () {
            return "EditEstimationResource";
        }
    }))({
        reflectSymbol: function () {
            return "EditEstimationCostClassPriceConsumptionRate";
        }
    }))({
        reflectSymbol: function () {
            return "EditEstimationCostClassPriceAndConsumptionRate";
        }
    }))({
        reflectSymbol: function () {
            return "EditEstimationCostClassPrice";
        }
    }))({
        reflectSymbol: function () {
            return "DeleteEstimationResources";
        }
    }))({
        reflectSymbol: function () {
            return "CopyResourcesToComponent";
        }
    }))({
        reflectSymbol: function () {
            return "AddResourcesToComponentFromOrgPriceList";
        }
    }))({
        reflectSymbol: function () {
            return "AddAndDeleteMultipleResources";
        }
    }))),
    readImpl: TofuJSON.readGenericSum(genericEstimationResourceActionParams)(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "AddEstimationResource";
        }
    })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "AddAndDeleteMultipleResources";
        }
    })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "CopyResourcesToComponent";
        }
    })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "ReplaceComponentResources";
        }
    })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "AddResourcesToComponentFromOrgPriceList";
        }
    })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "ReplaceComponentResourceFromOrgPriceList";
        }
    })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "ReplaceComponentResourceFromProject";
        }
    })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "EditEstimationResource";
        }
    })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "EditEstimationCostClassPrice";
        }
    })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "EditEstimationCostClassPriceConsumptionRate";
        }
    })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "EditEstimationCostClassPriceAndConsumptionRate";
        }
    })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "DeleteEstimationResources";
        }
    })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "SetEstimationResourcesCostClass";
        }
    })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "SetEstimationResourcesGroup";
        }
    })()(Kishimen.genericSumToVariantArgArguments))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "SetEstimationResourcesCostClass";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "SetEstimationResourcesGroup";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "DeleteEstimationResources";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "SetEstimationResourcesGroup";
        }
    }))({
        reflectSymbol: function () {
            return "SetEstimationResourcesCostClass";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "EditEstimationCostClassPriceAndConsumptionRate";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "SetEstimationResourcesGroup";
        }
    }))({
        reflectSymbol: function () {
            return "SetEstimationResourcesCostClass";
        }
    }))({
        reflectSymbol: function () {
            return "DeleteEstimationResources";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "EditEstimationCostClassPriceConsumptionRate";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "SetEstimationResourcesGroup";
        }
    }))({
        reflectSymbol: function () {
            return "SetEstimationResourcesCostClass";
        }
    }))({
        reflectSymbol: function () {
            return "EditEstimationCostClassPriceAndConsumptionRate";
        }
    }))({
        reflectSymbol: function () {
            return "DeleteEstimationResources";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "EditEstimationCostClassPrice";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "SetEstimationResourcesGroup";
        }
    }))({
        reflectSymbol: function () {
            return "SetEstimationResourcesCostClass";
        }
    }))({
        reflectSymbol: function () {
            return "EditEstimationCostClassPriceConsumptionRate";
        }
    }))({
        reflectSymbol: function () {
            return "EditEstimationCostClassPriceAndConsumptionRate";
        }
    }))({
        reflectSymbol: function () {
            return "DeleteEstimationResources";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "EditEstimationResource";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "SetEstimationResourcesGroup";
        }
    }))({
        reflectSymbol: function () {
            return "SetEstimationResourcesCostClass";
        }
    }))({
        reflectSymbol: function () {
            return "EditEstimationCostClassPriceConsumptionRate";
        }
    }))({
        reflectSymbol: function () {
            return "EditEstimationCostClassPriceAndConsumptionRate";
        }
    }))({
        reflectSymbol: function () {
            return "EditEstimationCostClassPrice";
        }
    }))({
        reflectSymbol: function () {
            return "DeleteEstimationResources";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "ReplaceComponentResourceFromProject";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "SetEstimationResourcesGroup";
        }
    }))({
        reflectSymbol: function () {
            return "SetEstimationResourcesCostClass";
        }
    }))({
        reflectSymbol: function () {
            return "EditEstimationResource";
        }
    }))({
        reflectSymbol: function () {
            return "EditEstimationCostClassPriceConsumptionRate";
        }
    }))({
        reflectSymbol: function () {
            return "EditEstimationCostClassPriceAndConsumptionRate";
        }
    }))({
        reflectSymbol: function () {
            return "EditEstimationCostClassPrice";
        }
    }))({
        reflectSymbol: function () {
            return "DeleteEstimationResources";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "ReplaceComponentResourceFromOrgPriceList";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "SetEstimationResourcesGroup";
        }
    }))({
        reflectSymbol: function () {
            return "SetEstimationResourcesCostClass";
        }
    }))({
        reflectSymbol: function () {
            return "ReplaceComponentResourceFromProject";
        }
    }))({
        reflectSymbol: function () {
            return "EditEstimationResource";
        }
    }))({
        reflectSymbol: function () {
            return "EditEstimationCostClassPriceConsumptionRate";
        }
    }))({
        reflectSymbol: function () {
            return "EditEstimationCostClassPriceAndConsumptionRate";
        }
    }))({
        reflectSymbol: function () {
            return "EditEstimationCostClassPrice";
        }
    }))({
        reflectSymbol: function () {
            return "DeleteEstimationResources";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "AddResourcesToComponentFromOrgPriceList";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "SetEstimationResourcesGroup";
        }
    }))({
        reflectSymbol: function () {
            return "SetEstimationResourcesCostClass";
        }
    }))({
        reflectSymbol: function () {
            return "ReplaceComponentResourceFromProject";
        }
    }))({
        reflectSymbol: function () {
            return "ReplaceComponentResourceFromOrgPriceList";
        }
    }))({
        reflectSymbol: function () {
            return "EditEstimationResource";
        }
    }))({
        reflectSymbol: function () {
            return "EditEstimationCostClassPriceConsumptionRate";
        }
    }))({
        reflectSymbol: function () {
            return "EditEstimationCostClassPriceAndConsumptionRate";
        }
    }))({
        reflectSymbol: function () {
            return "EditEstimationCostClassPrice";
        }
    }))({
        reflectSymbol: function () {
            return "DeleteEstimationResources";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "ReplaceComponentResources";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "SetEstimationResourcesGroup";
        }
    }))({
        reflectSymbol: function () {
            return "SetEstimationResourcesCostClass";
        }
    }))({
        reflectSymbol: function () {
            return "ReplaceComponentResourceFromProject";
        }
    }))({
        reflectSymbol: function () {
            return "ReplaceComponentResourceFromOrgPriceList";
        }
    }))({
        reflectSymbol: function () {
            return "EditEstimationResource";
        }
    }))({
        reflectSymbol: function () {
            return "EditEstimationCostClassPriceConsumptionRate";
        }
    }))({
        reflectSymbol: function () {
            return "EditEstimationCostClassPriceAndConsumptionRate";
        }
    }))({
        reflectSymbol: function () {
            return "EditEstimationCostClassPrice";
        }
    }))({
        reflectSymbol: function () {
            return "DeleteEstimationResources";
        }
    }))({
        reflectSymbol: function () {
            return "AddResourcesToComponentFromOrgPriceList";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "CopyResourcesToComponent";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "SetEstimationResourcesGroup";
        }
    }))({
        reflectSymbol: function () {
            return "SetEstimationResourcesCostClass";
        }
    }))({
        reflectSymbol: function () {
            return "ReplaceComponentResources";
        }
    }))({
        reflectSymbol: function () {
            return "ReplaceComponentResourceFromProject";
        }
    }))({
        reflectSymbol: function () {
            return "ReplaceComponentResourceFromOrgPriceList";
        }
    }))({
        reflectSymbol: function () {
            return "EditEstimationResource";
        }
    }))({
        reflectSymbol: function () {
            return "EditEstimationCostClassPriceConsumptionRate";
        }
    }))({
        reflectSymbol: function () {
            return "EditEstimationCostClassPriceAndConsumptionRate";
        }
    }))({
        reflectSymbol: function () {
            return "EditEstimationCostClassPrice";
        }
    }))({
        reflectSymbol: function () {
            return "DeleteEstimationResources";
        }
    }))({
        reflectSymbol: function () {
            return "AddResourcesToComponentFromOrgPriceList";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "AddAndDeleteMultipleResources";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "SetEstimationResourcesGroup";
        }
    }))({
        reflectSymbol: function () {
            return "SetEstimationResourcesCostClass";
        }
    }))({
        reflectSymbol: function () {
            return "ReplaceComponentResources";
        }
    }))({
        reflectSymbol: function () {
            return "ReplaceComponentResourceFromProject";
        }
    }))({
        reflectSymbol: function () {
            return "ReplaceComponentResourceFromOrgPriceList";
        }
    }))({
        reflectSymbol: function () {
            return "EditEstimationResource";
        }
    }))({
        reflectSymbol: function () {
            return "EditEstimationCostClassPriceConsumptionRate";
        }
    }))({
        reflectSymbol: function () {
            return "EditEstimationCostClassPriceAndConsumptionRate";
        }
    }))({
        reflectSymbol: function () {
            return "EditEstimationCostClassPrice";
        }
    }))({
        reflectSymbol: function () {
            return "DeleteEstimationResources";
        }
    }))({
        reflectSymbol: function () {
            return "CopyResourcesToComponent";
        }
    }))({
        reflectSymbol: function () {
            return "AddResourcesToComponentFromOrgPriceList";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "AddEstimationResource";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "SetEstimationResourcesGroup";
        }
    }))({
        reflectSymbol: function () {
            return "SetEstimationResourcesCostClass";
        }
    }))({
        reflectSymbol: function () {
            return "ReplaceComponentResources";
        }
    }))({
        reflectSymbol: function () {
            return "ReplaceComponentResourceFromProject";
        }
    }))({
        reflectSymbol: function () {
            return "ReplaceComponentResourceFromOrgPriceList";
        }
    }))({
        reflectSymbol: function () {
            return "EditEstimationResource";
        }
    }))({
        reflectSymbol: function () {
            return "EditEstimationCostClassPriceConsumptionRate";
        }
    }))({
        reflectSymbol: function () {
            return "EditEstimationCostClassPriceAndConsumptionRate";
        }
    }))({
        reflectSymbol: function () {
            return "EditEstimationCostClassPrice";
        }
    }))({
        reflectSymbol: function () {
            return "DeleteEstimationResources";
        }
    }))({
        reflectSymbol: function () {
            return "CopyResourcesToComponent";
        }
    }))({
        reflectSymbol: function () {
            return "AddResourcesToComponentFromOrgPriceList";
        }
    }))({
        reflectSymbol: function () {
            return "AddAndDeleteMultipleResources";
        }
    })))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "AddAndDeleteMultipleResources";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "addResources";
        }
    })(TofuJSON.readArray(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "costClassCode";
        }
    })(Types_Newtypes.tofuJSONCostClassCode)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "details";
        }
    })(TofuJSON.readMaybe(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "changeDate";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "contract";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "currency";
        }
    })(TofuJSON.readMaybe(Types_Project.tofuJSONCurrency))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "discountGroupCode";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "discountPercentage";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "eanCode";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "multiplier";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "resourceClassificationGroup";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "sortingNumber";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "taxPercentage";
        }
    })(Types_Unit.tofuJSONTaxrate)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "unitConsumptionRate";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "validDate";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "vendor";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "vendorBatchSize";
        }
    })(TofuJSON.readMaybe(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "vendorCode";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "wastePercentage";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsNil)()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "changeDate";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "contract";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "currency";
        }
    })(TofuJSON.readMaybe(Types_Project.tofuJSONCurrency))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "discountGroupCode";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "discountPercentage";
        }
    })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "eanCode";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "multiplier";
        }
    })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "resourceClassificationGroup";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "sortingNumber";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "taxPercentage";
        }
    })(Types_Unit.tofuJSONTaxrate)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "unitConsumptionRate";
        }
    })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "validDate";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "vendor";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "vendorBatchSize";
        }
    })(TofuJSON.readMaybe(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "vendorCode";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "wastePercentage";
        }
    })(TofuJSON.readNumber)(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "estimationComponentId";
        }
    })(Types_Component.tofuJSONComponentId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "group";
        }
    })(TofuJSON.readMaybe(Types_ClassificationGroup.tofuJSONClassificationGroupCode))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "index";
        }
    })(TofuJSON.readMaybe(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "isCostClassResource";
        }
    })(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "name";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "orgResourcePriceId";
        }
    })(TofuJSON.readMaybe(TofuJSON.readInt))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "orgResourcePriceListId";
        }
    })(TofuJSON.readMaybe(Types_Component.tofuJSONOrgEstimationResourceId))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "price";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "unit";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.readFieldsNil)()())()())()())()())()())()())()())()())()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "costClassCode";
        }
    })(Types_Newtypes.tofuJSONCostClassCode)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "details";
        }
    })(TofuJSON.readMaybe(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "changeDate";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "contract";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "currency";
        }
    })(TofuJSON.readMaybe(Types_Project.tofuJSONCurrency))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "discountGroupCode";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "discountPercentage";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "eanCode";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "multiplier";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "resourceClassificationGroup";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "sortingNumber";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "taxPercentage";
        }
    })(Types_Unit.tofuJSONTaxrate)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "unitConsumptionRate";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "validDate";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "vendor";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "vendorBatchSize";
        }
    })(TofuJSON.readMaybe(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "vendorCode";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "wastePercentage";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsNil)()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "changeDate";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "contract";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "currency";
        }
    })(TofuJSON.readMaybe(Types_Project.tofuJSONCurrency))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "discountGroupCode";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "discountPercentage";
        }
    })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "eanCode";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "multiplier";
        }
    })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "resourceClassificationGroup";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "sortingNumber";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "taxPercentage";
        }
    })(Types_Unit.tofuJSONTaxrate)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "unitConsumptionRate";
        }
    })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "validDate";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "vendor";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "vendorBatchSize";
        }
    })(TofuJSON.readMaybe(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "vendorCode";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "wastePercentage";
        }
    })(TofuJSON.readNumber)(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "estimationComponentId";
        }
    })(Types_Component.tofuJSONComponentId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "group";
        }
    })(TofuJSON.readMaybe(Types_ClassificationGroup.tofuJSONClassificationGroupCode))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "index";
        }
    })(TofuJSON.readMaybe(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "isCostClassResource";
        }
    })(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "name";
        }
    })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "orgResourcePriceId";
        }
    })(TofuJSON.readMaybe(TofuJSON.readInt))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "orgResourcePriceListId";
        }
    })(TofuJSON.readMaybe(Types_Component.tofuJSONOrgEstimationResourceId))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "price";
        }
    })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "unit";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "deleteResources";
        }
    })(TofuJSON.readArray(Types_Component.tofuJSONEstimationResourceId))(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "addResources";
        }
    })(TofuJSON.readArray(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "costClassCode";
        }
    })(Types_Newtypes.tofuJSONCostClassCode)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "details";
        }
    })(TofuJSON.readMaybe(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "changeDate";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "contract";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "currency";
        }
    })(TofuJSON.readMaybe(Types_Project.tofuJSONCurrency))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "discountGroupCode";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "discountPercentage";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "eanCode";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "multiplier";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "resourceClassificationGroup";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "sortingNumber";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "taxPercentage";
        }
    })(Types_Unit.tofuJSONTaxrate)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "unitConsumptionRate";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "validDate";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "vendor";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "vendorBatchSize";
        }
    })(TofuJSON.readMaybe(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "vendorCode";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "wastePercentage";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsNil)()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "changeDate";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "contract";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "currency";
        }
    })(TofuJSON.readMaybe(Types_Project.tofuJSONCurrency))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "discountGroupCode";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "discountPercentage";
        }
    })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "eanCode";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "multiplier";
        }
    })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "resourceClassificationGroup";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "sortingNumber";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "taxPercentage";
        }
    })(Types_Unit.tofuJSONTaxrate)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "unitConsumptionRate";
        }
    })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "validDate";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "vendor";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "vendorBatchSize";
        }
    })(TofuJSON.readMaybe(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "vendorCode";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "wastePercentage";
        }
    })(TofuJSON.readNumber)(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "estimationComponentId";
        }
    })(Types_Component.tofuJSONComponentId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "group";
        }
    })(TofuJSON.readMaybe(Types_ClassificationGroup.tofuJSONClassificationGroupCode))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "index";
        }
    })(TofuJSON.readMaybe(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "isCostClassResource";
        }
    })(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "name";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "orgResourcePriceId";
        }
    })(TofuJSON.readMaybe(TofuJSON.readInt))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "orgResourcePriceListId";
        }
    })(TofuJSON.readMaybe(Types_Component.tofuJSONOrgEstimationResourceId))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "price";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "unit";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.readFieldsNil)()())()())()())()())()())()())()())()())()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "costClassCode";
        }
    })(Types_Newtypes.tofuJSONCostClassCode)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "details";
        }
    })(TofuJSON.readMaybe(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "changeDate";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "contract";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "currency";
        }
    })(TofuJSON.readMaybe(Types_Project.tofuJSONCurrency))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "discountGroupCode";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "discountPercentage";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "eanCode";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "multiplier";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "resourceClassificationGroup";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "sortingNumber";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "taxPercentage";
        }
    })(Types_Unit.tofuJSONTaxrate)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "unitConsumptionRate";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "validDate";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "vendor";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "vendorBatchSize";
        }
    })(TofuJSON.readMaybe(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "vendorCode";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "wastePercentage";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsNil)()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "changeDate";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "contract";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "currency";
        }
    })(TofuJSON.readMaybe(Types_Project.tofuJSONCurrency))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "discountGroupCode";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "discountPercentage";
        }
    })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "eanCode";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "multiplier";
        }
    })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "resourceClassificationGroup";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "sortingNumber";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "taxPercentage";
        }
    })(Types_Unit.tofuJSONTaxrate)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "unitConsumptionRate";
        }
    })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "validDate";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "vendor";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "vendorBatchSize";
        }
    })(TofuJSON.readMaybe(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "vendorCode";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "wastePercentage";
        }
    })(TofuJSON.readNumber)(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "estimationComponentId";
        }
    })(Types_Component.tofuJSONComponentId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "group";
        }
    })(TofuJSON.readMaybe(Types_ClassificationGroup.tofuJSONClassificationGroupCode))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "index";
        }
    })(TofuJSON.readMaybe(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "isCostClassResource";
        }
    })(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "name";
        }
    })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "orgResourcePriceId";
        }
    })(TofuJSON.readMaybe(TofuJSON.readInt))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "orgResourcePriceListId";
        }
    })(TofuJSON.readMaybe(Types_Component.tofuJSONOrgEstimationResourceId))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "price";
        }
    })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "unit";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "deleteResources";
        }
    })(TofuJSON.readArray(Types_Component.tofuJSONEstimationResourceId))(TofuJSON.nilWriteForeignFields)()()())()()()))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "AddEstimationResource";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "costClassCode";
        }
    })(Types_Newtypes.tofuJSONCostClassCode)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "details";
        }
    })(TofuJSON.readMaybe(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "changeDate";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "contract";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "currency";
        }
    })(TofuJSON.readMaybe(Types_Project.tofuJSONCurrency))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "discountGroupCode";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "discountPercentage";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "eanCode";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "multiplier";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "resourceClassificationGroup";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "sortingNumber";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "taxPercentage";
        }
    })(Types_Unit.tofuJSONTaxrate)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "unitConsumptionRate";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "validDate";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "vendor";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "vendorBatchSize";
        }
    })(TofuJSON.readMaybe(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "vendorCode";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "wastePercentage";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsNil)()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "changeDate";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "contract";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "currency";
        }
    })(TofuJSON.readMaybe(Types_Project.tofuJSONCurrency))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "discountGroupCode";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "discountPercentage";
        }
    })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "eanCode";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "multiplier";
        }
    })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "resourceClassificationGroup";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "sortingNumber";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "taxPercentage";
        }
    })(Types_Unit.tofuJSONTaxrate)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "unitConsumptionRate";
        }
    })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "validDate";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "vendor";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "vendorBatchSize";
        }
    })(TofuJSON.readMaybe(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "vendorCode";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "wastePercentage";
        }
    })(TofuJSON.readNumber)(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "estimationComponentId";
        }
    })(Types_Component.tofuJSONComponentId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "group";
        }
    })(TofuJSON.readMaybe(Types_ClassificationGroup.tofuJSONClassificationGroupCode))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "index";
        }
    })(TofuJSON.readMaybe(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "isCostClassResource";
        }
    })(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "name";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "orgResourcePriceId";
        }
    })(TofuJSON.readMaybe(TofuJSON.readInt))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "orgResourcePriceListId";
        }
    })(TofuJSON.readMaybe(Types_Component.tofuJSONOrgEstimationResourceId))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "price";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "unit";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.readFieldsNil)()())()())()())()())()())()())()())()())()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "costClassCode";
        }
    })(Types_Newtypes.tofuJSONCostClassCode)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "details";
        }
    })(TofuJSON.readMaybe(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "changeDate";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "contract";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "currency";
        }
    })(TofuJSON.readMaybe(Types_Project.tofuJSONCurrency))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "discountGroupCode";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "discountPercentage";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "eanCode";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "multiplier";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "resourceClassificationGroup";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "sortingNumber";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "taxPercentage";
        }
    })(Types_Unit.tofuJSONTaxrate)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "unitConsumptionRate";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "validDate";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "vendor";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "vendorBatchSize";
        }
    })(TofuJSON.readMaybe(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "vendorCode";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "wastePercentage";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsNil)()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "changeDate";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "contract";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "currency";
        }
    })(TofuJSON.readMaybe(Types_Project.tofuJSONCurrency))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "discountGroupCode";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "discountPercentage";
        }
    })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "eanCode";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "multiplier";
        }
    })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "resourceClassificationGroup";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "sortingNumber";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "taxPercentage";
        }
    })(Types_Unit.tofuJSONTaxrate)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "unitConsumptionRate";
        }
    })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "validDate";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "vendor";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "vendorBatchSize";
        }
    })(TofuJSON.readMaybe(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "vendorCode";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "wastePercentage";
        }
    })(TofuJSON.readNumber)(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "estimationComponentId";
        }
    })(Types_Component.tofuJSONComponentId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "group";
        }
    })(TofuJSON.readMaybe(Types_ClassificationGroup.tofuJSONClassificationGroupCode))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "index";
        }
    })(TofuJSON.readMaybe(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "isCostClassResource";
        }
    })(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "name";
        }
    })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "orgResourcePriceId";
        }
    })(TofuJSON.readMaybe(TofuJSON.readInt))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "orgResourcePriceListId";
        }
    })(TofuJSON.readMaybe(Types_Component.tofuJSONOrgEstimationResourceId))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "price";
        }
    })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "unit";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()()))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "AddResourcesToComponentFromOrgPriceList";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "componentId";
        }
    })(Types_Component.tofuJSONComponentId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "orgResourcePriceIds";
        }
    })(TofuJSON.readArray(TofuJSON.readInt))(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "componentId";
        }
    })(Types_Component.tofuJSONComponentId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "orgResourcePriceIds";
        }
    })(TofuJSON.readArray(TofuJSON.readInt))(TofuJSON.nilWriteForeignFields)()()())()()()))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "CopyResourcesToComponent";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "componentId";
        }
    })(Types_Component.tofuJSONComponentId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "resourceIds";
        }
    })(TofuJSON.readArray(Types_Component.tofuJSONEstimationResourceId))(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "componentId";
        }
    })(Types_Component.tofuJSONComponentId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "resourceIds";
        }
    })(TofuJSON.readArray(Types_Component.tofuJSONEstimationResourceId))(TofuJSON.nilWriteForeignFields)()()())()()()))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "DeleteEstimationResources";
        }
    })(TofuJSON.readArray(Types_Component.tofuJSONEstimationResourceId))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "EditEstimationCostClassPrice";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "componentId";
        }
    })(Types_Component.tofuJSONComponentId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "costClassCode";
        }
    })(Types_Newtypes.tofuJSONCostClassCode)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "pricePerUnit";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "resourceId";
        }
    })(TofuJSON.readMaybe(Types_Component.tofuJSONEstimationResourceId))(TofuJSON.readFieldsNil)()())()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "componentId";
        }
    })(Types_Component.tofuJSONComponentId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "costClassCode";
        }
    })(Types_Newtypes.tofuJSONCostClassCode)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "pricePerUnit";
        }
    })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "resourceId";
        }
    })(TofuJSON.readMaybe(Types_Component.tofuJSONEstimationResourceId))(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()()))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "EditEstimationCostClassPriceAndConsumptionRate";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "componentId";
        }
    })(Types_Component.tofuJSONComponentId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "costClassCode";
        }
    })(Types_Newtypes.tofuJSONCostClassCode)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "pricePerUnit";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "resourceId";
        }
    })(TofuJSON.readMaybe(Types_Component.tofuJSONEstimationResourceId))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "unitConsumptionRate";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsNil)()())()())()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "componentId";
        }
    })(Types_Component.tofuJSONComponentId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "costClassCode";
        }
    })(Types_Newtypes.tofuJSONCostClassCode)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "pricePerUnit";
        }
    })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "resourceId";
        }
    })(TofuJSON.readMaybe(Types_Component.tofuJSONEstimationResourceId))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "unitConsumptionRate";
        }
    })(TofuJSON.readNumber)(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())()()()))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "EditEstimationCostClassPriceConsumptionRate";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "componentId";
        }
    })(Types_Component.tofuJSONComponentId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "costClassCode";
        }
    })(Types_Newtypes.tofuJSONCostClassCode)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "resourceId";
        }
    })(TofuJSON.readMaybe(Types_Component.tofuJSONEstimationResourceId))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "unitConsumptionRate";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsNil)()())()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "componentId";
        }
    })(Types_Component.tofuJSONComponentId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "costClassCode";
        }
    })(Types_Newtypes.tofuJSONCostClassCode)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "resourceId";
        }
    })(TofuJSON.readMaybe(Types_Component.tofuJSONEstimationResourceId))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "unitConsumptionRate";
        }
    })(TofuJSON.readNumber)(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()()))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "EditEstimationResource";
        }
    })(TofuJSON.readArray(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "resourceId";
        }
    })(Types_Component.tofuJSONEstimationResourceId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "resourceParams";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "changedDate";
        }
    })(TofuJSON.readNullable(Types_JSDateString.tofuJSONJSDateString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "costClassCode";
        }
    })(TofuJSON.readNullable(Types_Newtypes.tofuJSONCostClassCode))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "currencyId";
        }
    })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "discountPercentage";
        }
    })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "eanCode";
        }
    })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "group";
        }
    })(TofuJSON.readNullable(Types_ClassificationGroup.tofuJSONClassificationGroupCode))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "index";
        }
    })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "name";
        }
    })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "orgResourcePriceId";
        }
    })(TofuJSON.readNullable(TofuJSON.readInt))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "orgResourcePriceListId";
        }
    })(TofuJSON.readNullable(Types_Component.tofuJSONOrgEstimationResourceId))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "pricePerUnit";
        }
    })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "resourceClassificationGroup";
        }
    })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "sortingNumber";
        }
    })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "taxRate";
        }
    })(TofuJSON.readNullable(Types_Unit.tofuJSONTaxrate))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "unit";
        }
    })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "unitConsumptionRate";
        }
    })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "validDate";
        }
    })(TofuJSON.readNullable(Types_JSDateString.tofuJSONJSDateString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "vendor";
        }
    })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "vendorBatchSize";
        }
    })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "vendorCode";
        }
    })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "vendorUnit";
        }
    })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "wastePercentage";
        }
    })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsNil)()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "changedDate";
        }
    })(TofuJSON.readNullable(Types_JSDateString.tofuJSONJSDateString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "costClassCode";
        }
    })(TofuJSON.readNullable(Types_Newtypes.tofuJSONCostClassCode))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "currencyId";
        }
    })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "discountPercentage";
        }
    })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "eanCode";
        }
    })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "group";
        }
    })(TofuJSON.readNullable(Types_ClassificationGroup.tofuJSONClassificationGroupCode))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "index";
        }
    })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "name";
        }
    })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "orgResourcePriceId";
        }
    })(TofuJSON.readNullable(TofuJSON.readInt))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "orgResourcePriceListId";
        }
    })(TofuJSON.readNullable(Types_Component.tofuJSONOrgEstimationResourceId))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "pricePerUnit";
        }
    })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "resourceClassificationGroup";
        }
    })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "sortingNumber";
        }
    })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "taxRate";
        }
    })(TofuJSON.readNullable(Types_Unit.tofuJSONTaxrate))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "unit";
        }
    })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "unitConsumptionRate";
        }
    })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "validDate";
        }
    })(TofuJSON.readNullable(Types_JSDateString.tofuJSONJSDateString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "vendor";
        }
    })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "vendorBatchSize";
        }
    })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "vendorCode";
        }
    })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "vendorUnit";
        }
    })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "wastePercentage";
        }
    })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()()))(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "resourceId";
        }
    })(Types_Component.tofuJSONEstimationResourceId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "resourceParams";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "changedDate";
        }
    })(TofuJSON.readNullable(Types_JSDateString.tofuJSONJSDateString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "costClassCode";
        }
    })(TofuJSON.readNullable(Types_Newtypes.tofuJSONCostClassCode))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "currencyId";
        }
    })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "discountPercentage";
        }
    })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "eanCode";
        }
    })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "group";
        }
    })(TofuJSON.readNullable(Types_ClassificationGroup.tofuJSONClassificationGroupCode))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "index";
        }
    })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "name";
        }
    })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "orgResourcePriceId";
        }
    })(TofuJSON.readNullable(TofuJSON.readInt))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "orgResourcePriceListId";
        }
    })(TofuJSON.readNullable(Types_Component.tofuJSONOrgEstimationResourceId))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "pricePerUnit";
        }
    })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "resourceClassificationGroup";
        }
    })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "sortingNumber";
        }
    })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "taxRate";
        }
    })(TofuJSON.readNullable(Types_Unit.tofuJSONTaxrate))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "unit";
        }
    })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "unitConsumptionRate";
        }
    })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "validDate";
        }
    })(TofuJSON.readNullable(Types_JSDateString.tofuJSONJSDateString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "vendor";
        }
    })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "vendorBatchSize";
        }
    })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "vendorCode";
        }
    })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "vendorUnit";
        }
    })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "wastePercentage";
        }
    })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsNil)()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "changedDate";
        }
    })(TofuJSON.readNullable(Types_JSDateString.tofuJSONJSDateString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "costClassCode";
        }
    })(TofuJSON.readNullable(Types_Newtypes.tofuJSONCostClassCode))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "currencyId";
        }
    })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "discountPercentage";
        }
    })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "eanCode";
        }
    })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "group";
        }
    })(TofuJSON.readNullable(Types_ClassificationGroup.tofuJSONClassificationGroupCode))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "index";
        }
    })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "name";
        }
    })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "orgResourcePriceId";
        }
    })(TofuJSON.readNullable(TofuJSON.readInt))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "orgResourcePriceListId";
        }
    })(TofuJSON.readNullable(Types_Component.tofuJSONOrgEstimationResourceId))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "pricePerUnit";
        }
    })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "resourceClassificationGroup";
        }
    })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "sortingNumber";
        }
    })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "taxRate";
        }
    })(TofuJSON.readNullable(Types_Unit.tofuJSONTaxrate))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "unit";
        }
    })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "unitConsumptionRate";
        }
    })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "validDate";
        }
    })(TofuJSON.readNullable(Types_JSDateString.tofuJSONJSDateString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "vendor";
        }
    })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "vendorBatchSize";
        }
    })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "vendorCode";
        }
    })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "vendorUnit";
        }
    })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "wastePercentage";
        }
    })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()()))(TofuJSON.nilWriteForeignFields)()()())()()())))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "ReplaceComponentResourceFromOrgPriceList";
        }
    })(TofuJSON.readArray(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "componentId";
        }
    })(Types_Component.tofuJSONComponentId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "orgResourcePriceIds";
        }
    })(TofuJSON.readArray(TofuJSON.readInt))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "replaceTargetResourceId";
        }
    })(Types_Component.tofuJSONEstimationResourceId)(TofuJSON.readFieldsNil)()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "componentId";
        }
    })(Types_Component.tofuJSONComponentId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "orgResourcePriceIds";
        }
    })(TofuJSON.readArray(TofuJSON.readInt))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "replaceTargetResourceId";
        }
    })(Types_Component.tofuJSONEstimationResourceId)(TofuJSON.nilWriteForeignFields)()()())()()())()()())))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "ReplaceComponentResourceFromProject";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "componentId";
        }
    })(Types_Component.tofuJSONComponentId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "newResourceId";
        }
    })(Types_Component.tofuJSONEstimationResourceId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "replaceTargetResourceId";
        }
    })(Types_Component.tofuJSONEstimationResourceId)(TofuJSON.readFieldsNil)()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "componentId";
        }
    })(Types_Component.tofuJSONComponentId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "newResourceId";
        }
    })(Types_Component.tofuJSONEstimationResourceId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "replaceTargetResourceId";
        }
    })(Types_Component.tofuJSONEstimationResourceId)(TofuJSON.nilWriteForeignFields)()()())()()())()()()))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "ReplaceComponentResources";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "componentId";
        }
    })(Types_Component.tofuJSONComponentId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "referenceComponentId";
        }
    })(Types_Component.tofuJSONComponentId)(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "componentId";
        }
    })(Types_Component.tofuJSONComponentId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "referenceComponentId";
        }
    })(Types_Component.tofuJSONComponentId)(TofuJSON.nilWriteForeignFields)()()())()()()))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "SetEstimationResourcesCostClass";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "costClassCode";
        }
    })(Types_Newtypes.tofuJSONCostClassCode)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "estimationResourceIds";
        }
    })(TofuJSON.readArray(Types_Component.tofuJSONEstimationResourceId))(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "costClassCode";
        }
    })(Types_Newtypes.tofuJSONCostClassCode)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "estimationResourceIds";
        }
    })(TofuJSON.readArray(Types_Component.tofuJSONEstimationResourceId))(TofuJSON.nilWriteForeignFields)()()())()()()))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "SetEstimationResourcesGroup";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "estimationResourceIds";
        }
    })(TofuJSON.readArray(Types_Component.tofuJSONEstimationResourceId))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "group";
        }
    })(TofuJSON.readNullable(Types_ClassificationGroup.tofuJSONClassificationGroupCode))(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "estimationResourceIds";
        }
    })(TofuJSON.readArray(Types_Component.tofuJSONEstimationResourceId))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "group";
        }
    })(TofuJSON.readNullable(Types_ClassificationGroup.tofuJSONClassificationGroupCode))(TofuJSON.nilWriteForeignFields)()()())()()()))()(TofuJSON.readVariantNil)))))))))))))))(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "AddAndDeleteMultipleResources";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "addResources";
        }
    })(TofuJSON.readArray(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "costClassCode";
        }
    })(Types_Newtypes.tofuJSONCostClassCode)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "details";
        }
    })(TofuJSON.readMaybe(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "changeDate";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "contract";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "currency";
        }
    })(TofuJSON.readMaybe(Types_Project.tofuJSONCurrency))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "discountGroupCode";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "discountPercentage";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "eanCode";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "multiplier";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "resourceClassificationGroup";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "sortingNumber";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "taxPercentage";
        }
    })(Types_Unit.tofuJSONTaxrate)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "unitConsumptionRate";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "validDate";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "vendor";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "vendorBatchSize";
        }
    })(TofuJSON.readMaybe(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "vendorCode";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "wastePercentage";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsNil)()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "changeDate";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "contract";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "currency";
        }
    })(TofuJSON.readMaybe(Types_Project.tofuJSONCurrency))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "discountGroupCode";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "discountPercentage";
        }
    })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "eanCode";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "multiplier";
        }
    })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "resourceClassificationGroup";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "sortingNumber";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "taxPercentage";
        }
    })(Types_Unit.tofuJSONTaxrate)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "unitConsumptionRate";
        }
    })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "validDate";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "vendor";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "vendorBatchSize";
        }
    })(TofuJSON.readMaybe(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "vendorCode";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "wastePercentage";
        }
    })(TofuJSON.readNumber)(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "estimationComponentId";
        }
    })(Types_Component.tofuJSONComponentId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "group";
        }
    })(TofuJSON.readMaybe(Types_ClassificationGroup.tofuJSONClassificationGroupCode))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "index";
        }
    })(TofuJSON.readMaybe(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "isCostClassResource";
        }
    })(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "name";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "orgResourcePriceId";
        }
    })(TofuJSON.readMaybe(TofuJSON.readInt))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "orgResourcePriceListId";
        }
    })(TofuJSON.readMaybe(Types_Component.tofuJSONOrgEstimationResourceId))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "price";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "unit";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.readFieldsNil)()())()())()())()())()())()())()())()())()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "costClassCode";
        }
    })(Types_Newtypes.tofuJSONCostClassCode)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "details";
        }
    })(TofuJSON.readMaybe(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "changeDate";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "contract";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "currency";
        }
    })(TofuJSON.readMaybe(Types_Project.tofuJSONCurrency))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "discountGroupCode";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "discountPercentage";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "eanCode";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "multiplier";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "resourceClassificationGroup";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "sortingNumber";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "taxPercentage";
        }
    })(Types_Unit.tofuJSONTaxrate)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "unitConsumptionRate";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "validDate";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "vendor";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "vendorBatchSize";
        }
    })(TofuJSON.readMaybe(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "vendorCode";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "wastePercentage";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsNil)()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "changeDate";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "contract";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "currency";
        }
    })(TofuJSON.readMaybe(Types_Project.tofuJSONCurrency))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "discountGroupCode";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "discountPercentage";
        }
    })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "eanCode";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "multiplier";
        }
    })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "resourceClassificationGroup";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "sortingNumber";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "taxPercentage";
        }
    })(Types_Unit.tofuJSONTaxrate)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "unitConsumptionRate";
        }
    })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "validDate";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "vendor";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "vendorBatchSize";
        }
    })(TofuJSON.readMaybe(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "vendorCode";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "wastePercentage";
        }
    })(TofuJSON.readNumber)(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "estimationComponentId";
        }
    })(Types_Component.tofuJSONComponentId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "group";
        }
    })(TofuJSON.readMaybe(Types_ClassificationGroup.tofuJSONClassificationGroupCode))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "index";
        }
    })(TofuJSON.readMaybe(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "isCostClassResource";
        }
    })(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "name";
        }
    })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "orgResourcePriceId";
        }
    })(TofuJSON.readMaybe(TofuJSON.readInt))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "orgResourcePriceListId";
        }
    })(TofuJSON.readMaybe(Types_Component.tofuJSONOrgEstimationResourceId))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "price";
        }
    })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "unit";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "deleteResources";
        }
    })(TofuJSON.readArray(Types_Component.tofuJSONEstimationResourceId))(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "addResources";
        }
    })(TofuJSON.readArray(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "costClassCode";
        }
    })(Types_Newtypes.tofuJSONCostClassCode)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "details";
        }
    })(TofuJSON.readMaybe(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "changeDate";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "contract";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "currency";
        }
    })(TofuJSON.readMaybe(Types_Project.tofuJSONCurrency))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "discountGroupCode";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "discountPercentage";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "eanCode";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "multiplier";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "resourceClassificationGroup";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "sortingNumber";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "taxPercentage";
        }
    })(Types_Unit.tofuJSONTaxrate)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "unitConsumptionRate";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "validDate";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "vendor";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "vendorBatchSize";
        }
    })(TofuJSON.readMaybe(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "vendorCode";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "wastePercentage";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsNil)()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "changeDate";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "contract";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "currency";
        }
    })(TofuJSON.readMaybe(Types_Project.tofuJSONCurrency))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "discountGroupCode";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "discountPercentage";
        }
    })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "eanCode";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "multiplier";
        }
    })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "resourceClassificationGroup";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "sortingNumber";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "taxPercentage";
        }
    })(Types_Unit.tofuJSONTaxrate)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "unitConsumptionRate";
        }
    })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "validDate";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "vendor";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "vendorBatchSize";
        }
    })(TofuJSON.readMaybe(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "vendorCode";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "wastePercentage";
        }
    })(TofuJSON.readNumber)(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "estimationComponentId";
        }
    })(Types_Component.tofuJSONComponentId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "group";
        }
    })(TofuJSON.readMaybe(Types_ClassificationGroup.tofuJSONClassificationGroupCode))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "index";
        }
    })(TofuJSON.readMaybe(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "isCostClassResource";
        }
    })(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "name";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "orgResourcePriceId";
        }
    })(TofuJSON.readMaybe(TofuJSON.readInt))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "orgResourcePriceListId";
        }
    })(TofuJSON.readMaybe(Types_Component.tofuJSONOrgEstimationResourceId))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "price";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "unit";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.readFieldsNil)()())()())()())()())()())()())()())()())()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "costClassCode";
        }
    })(Types_Newtypes.tofuJSONCostClassCode)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "details";
        }
    })(TofuJSON.readMaybe(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "changeDate";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "contract";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "currency";
        }
    })(TofuJSON.readMaybe(Types_Project.tofuJSONCurrency))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "discountGroupCode";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "discountPercentage";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "eanCode";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "multiplier";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "resourceClassificationGroup";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "sortingNumber";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "taxPercentage";
        }
    })(Types_Unit.tofuJSONTaxrate)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "unitConsumptionRate";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "validDate";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "vendor";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "vendorBatchSize";
        }
    })(TofuJSON.readMaybe(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "vendorCode";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "wastePercentage";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsNil)()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "changeDate";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "contract";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "currency";
        }
    })(TofuJSON.readMaybe(Types_Project.tofuJSONCurrency))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "discountGroupCode";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "discountPercentage";
        }
    })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "eanCode";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "multiplier";
        }
    })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "resourceClassificationGroup";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "sortingNumber";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "taxPercentage";
        }
    })(Types_Unit.tofuJSONTaxrate)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "unitConsumptionRate";
        }
    })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "validDate";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "vendor";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "vendorBatchSize";
        }
    })(TofuJSON.readMaybe(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "vendorCode";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "wastePercentage";
        }
    })(TofuJSON.readNumber)(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "estimationComponentId";
        }
    })(Types_Component.tofuJSONComponentId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "group";
        }
    })(TofuJSON.readMaybe(Types_ClassificationGroup.tofuJSONClassificationGroupCode))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "index";
        }
    })(TofuJSON.readMaybe(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "isCostClassResource";
        }
    })(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "name";
        }
    })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "orgResourcePriceId";
        }
    })(TofuJSON.readMaybe(TofuJSON.readInt))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "orgResourcePriceListId";
        }
    })(TofuJSON.readMaybe(Types_Component.tofuJSONOrgEstimationResourceId))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "price";
        }
    })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "unit";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "deleteResources";
        }
    })(TofuJSON.readArray(Types_Component.tofuJSONEstimationResourceId))(TofuJSON.nilWriteForeignFields)()()())()()()))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "AddEstimationResource";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "costClassCode";
        }
    })(Types_Newtypes.tofuJSONCostClassCode)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "details";
        }
    })(TofuJSON.readMaybe(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "changeDate";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "contract";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "currency";
        }
    })(TofuJSON.readMaybe(Types_Project.tofuJSONCurrency))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "discountGroupCode";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "discountPercentage";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "eanCode";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "multiplier";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "resourceClassificationGroup";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "sortingNumber";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "taxPercentage";
        }
    })(Types_Unit.tofuJSONTaxrate)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "unitConsumptionRate";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "validDate";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "vendor";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "vendorBatchSize";
        }
    })(TofuJSON.readMaybe(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "vendorCode";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "wastePercentage";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsNil)()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "changeDate";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "contract";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "currency";
        }
    })(TofuJSON.readMaybe(Types_Project.tofuJSONCurrency))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "discountGroupCode";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "discountPercentage";
        }
    })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "eanCode";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "multiplier";
        }
    })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "resourceClassificationGroup";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "sortingNumber";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "taxPercentage";
        }
    })(Types_Unit.tofuJSONTaxrate)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "unitConsumptionRate";
        }
    })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "validDate";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "vendor";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "vendorBatchSize";
        }
    })(TofuJSON.readMaybe(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "vendorCode";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "wastePercentage";
        }
    })(TofuJSON.readNumber)(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "estimationComponentId";
        }
    })(Types_Component.tofuJSONComponentId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "group";
        }
    })(TofuJSON.readMaybe(Types_ClassificationGroup.tofuJSONClassificationGroupCode))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "index";
        }
    })(TofuJSON.readMaybe(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "isCostClassResource";
        }
    })(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "name";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "orgResourcePriceId";
        }
    })(TofuJSON.readMaybe(TofuJSON.readInt))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "orgResourcePriceListId";
        }
    })(TofuJSON.readMaybe(Types_Component.tofuJSONOrgEstimationResourceId))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "price";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "unit";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.readFieldsNil)()())()())()())()())()())()())()())()())()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "costClassCode";
        }
    })(Types_Newtypes.tofuJSONCostClassCode)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "details";
        }
    })(TofuJSON.readMaybe(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "changeDate";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "contract";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "currency";
        }
    })(TofuJSON.readMaybe(Types_Project.tofuJSONCurrency))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "discountGroupCode";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "discountPercentage";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "eanCode";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "multiplier";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "resourceClassificationGroup";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "sortingNumber";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "taxPercentage";
        }
    })(Types_Unit.tofuJSONTaxrate)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "unitConsumptionRate";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "validDate";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "vendor";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "vendorBatchSize";
        }
    })(TofuJSON.readMaybe(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "vendorCode";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "wastePercentage";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsNil)()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "changeDate";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "contract";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "currency";
        }
    })(TofuJSON.readMaybe(Types_Project.tofuJSONCurrency))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "discountGroupCode";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "discountPercentage";
        }
    })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "eanCode";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "multiplier";
        }
    })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "resourceClassificationGroup";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "sortingNumber";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "taxPercentage";
        }
    })(Types_Unit.tofuJSONTaxrate)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "unitConsumptionRate";
        }
    })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "validDate";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "vendor";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "vendorBatchSize";
        }
    })(TofuJSON.readMaybe(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "vendorCode";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "wastePercentage";
        }
    })(TofuJSON.readNumber)(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "estimationComponentId";
        }
    })(Types_Component.tofuJSONComponentId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "group";
        }
    })(TofuJSON.readMaybe(Types_ClassificationGroup.tofuJSONClassificationGroupCode))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "index";
        }
    })(TofuJSON.readMaybe(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "isCostClassResource";
        }
    })(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "name";
        }
    })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "orgResourcePriceId";
        }
    })(TofuJSON.readMaybe(TofuJSON.readInt))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "orgResourcePriceListId";
        }
    })(TofuJSON.readMaybe(Types_Component.tofuJSONOrgEstimationResourceId))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "price";
        }
    })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "unit";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()()))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "AddResourcesToComponentFromOrgPriceList";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "componentId";
        }
    })(Types_Component.tofuJSONComponentId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "orgResourcePriceIds";
        }
    })(TofuJSON.readArray(TofuJSON.readInt))(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "componentId";
        }
    })(Types_Component.tofuJSONComponentId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "orgResourcePriceIds";
        }
    })(TofuJSON.readArray(TofuJSON.readInt))(TofuJSON.nilWriteForeignFields)()()())()()()))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "CopyResourcesToComponent";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "componentId";
        }
    })(Types_Component.tofuJSONComponentId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "resourceIds";
        }
    })(TofuJSON.readArray(Types_Component.tofuJSONEstimationResourceId))(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "componentId";
        }
    })(Types_Component.tofuJSONComponentId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "resourceIds";
        }
    })(TofuJSON.readArray(Types_Component.tofuJSONEstimationResourceId))(TofuJSON.nilWriteForeignFields)()()())()()()))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "DeleteEstimationResources";
        }
    })(TofuJSON.readArray(Types_Component.tofuJSONEstimationResourceId))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "EditEstimationCostClassPrice";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "componentId";
        }
    })(Types_Component.tofuJSONComponentId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "costClassCode";
        }
    })(Types_Newtypes.tofuJSONCostClassCode)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "pricePerUnit";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "resourceId";
        }
    })(TofuJSON.readMaybe(Types_Component.tofuJSONEstimationResourceId))(TofuJSON.readFieldsNil)()())()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "componentId";
        }
    })(Types_Component.tofuJSONComponentId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "costClassCode";
        }
    })(Types_Newtypes.tofuJSONCostClassCode)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "pricePerUnit";
        }
    })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "resourceId";
        }
    })(TofuJSON.readMaybe(Types_Component.tofuJSONEstimationResourceId))(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()()))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "EditEstimationCostClassPriceAndConsumptionRate";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "componentId";
        }
    })(Types_Component.tofuJSONComponentId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "costClassCode";
        }
    })(Types_Newtypes.tofuJSONCostClassCode)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "pricePerUnit";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "resourceId";
        }
    })(TofuJSON.readMaybe(Types_Component.tofuJSONEstimationResourceId))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "unitConsumptionRate";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsNil)()())()())()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "componentId";
        }
    })(Types_Component.tofuJSONComponentId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "costClassCode";
        }
    })(Types_Newtypes.tofuJSONCostClassCode)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "pricePerUnit";
        }
    })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "resourceId";
        }
    })(TofuJSON.readMaybe(Types_Component.tofuJSONEstimationResourceId))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "unitConsumptionRate";
        }
    })(TofuJSON.readNumber)(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())()()()))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "EditEstimationCostClassPriceConsumptionRate";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "componentId";
        }
    })(Types_Component.tofuJSONComponentId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "costClassCode";
        }
    })(Types_Newtypes.tofuJSONCostClassCode)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "resourceId";
        }
    })(TofuJSON.readMaybe(Types_Component.tofuJSONEstimationResourceId))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "unitConsumptionRate";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsNil)()())()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "componentId";
        }
    })(Types_Component.tofuJSONComponentId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "costClassCode";
        }
    })(Types_Newtypes.tofuJSONCostClassCode)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "resourceId";
        }
    })(TofuJSON.readMaybe(Types_Component.tofuJSONEstimationResourceId))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "unitConsumptionRate";
        }
    })(TofuJSON.readNumber)(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()()))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "EditEstimationResource";
        }
    })(TofuJSON.readArray(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "resourceId";
        }
    })(Types_Component.tofuJSONEstimationResourceId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "resourceParams";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "changedDate";
        }
    })(TofuJSON.readNullable(Types_JSDateString.tofuJSONJSDateString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "costClassCode";
        }
    })(TofuJSON.readNullable(Types_Newtypes.tofuJSONCostClassCode))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "currencyId";
        }
    })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "discountPercentage";
        }
    })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "eanCode";
        }
    })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "group";
        }
    })(TofuJSON.readNullable(Types_ClassificationGroup.tofuJSONClassificationGroupCode))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "index";
        }
    })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "name";
        }
    })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "orgResourcePriceId";
        }
    })(TofuJSON.readNullable(TofuJSON.readInt))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "orgResourcePriceListId";
        }
    })(TofuJSON.readNullable(Types_Component.tofuJSONOrgEstimationResourceId))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "pricePerUnit";
        }
    })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "resourceClassificationGroup";
        }
    })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "sortingNumber";
        }
    })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "taxRate";
        }
    })(TofuJSON.readNullable(Types_Unit.tofuJSONTaxrate))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "unit";
        }
    })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "unitConsumptionRate";
        }
    })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "validDate";
        }
    })(TofuJSON.readNullable(Types_JSDateString.tofuJSONJSDateString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "vendor";
        }
    })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "vendorBatchSize";
        }
    })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "vendorCode";
        }
    })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "vendorUnit";
        }
    })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "wastePercentage";
        }
    })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsNil)()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "changedDate";
        }
    })(TofuJSON.readNullable(Types_JSDateString.tofuJSONJSDateString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "costClassCode";
        }
    })(TofuJSON.readNullable(Types_Newtypes.tofuJSONCostClassCode))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "currencyId";
        }
    })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "discountPercentage";
        }
    })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "eanCode";
        }
    })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "group";
        }
    })(TofuJSON.readNullable(Types_ClassificationGroup.tofuJSONClassificationGroupCode))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "index";
        }
    })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "name";
        }
    })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "orgResourcePriceId";
        }
    })(TofuJSON.readNullable(TofuJSON.readInt))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "orgResourcePriceListId";
        }
    })(TofuJSON.readNullable(Types_Component.tofuJSONOrgEstimationResourceId))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "pricePerUnit";
        }
    })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "resourceClassificationGroup";
        }
    })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "sortingNumber";
        }
    })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "taxRate";
        }
    })(TofuJSON.readNullable(Types_Unit.tofuJSONTaxrate))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "unit";
        }
    })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "unitConsumptionRate";
        }
    })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "validDate";
        }
    })(TofuJSON.readNullable(Types_JSDateString.tofuJSONJSDateString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "vendor";
        }
    })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "vendorBatchSize";
        }
    })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "vendorCode";
        }
    })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "vendorUnit";
        }
    })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "wastePercentage";
        }
    })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()()))(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "resourceId";
        }
    })(Types_Component.tofuJSONEstimationResourceId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "resourceParams";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "changedDate";
        }
    })(TofuJSON.readNullable(Types_JSDateString.tofuJSONJSDateString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "costClassCode";
        }
    })(TofuJSON.readNullable(Types_Newtypes.tofuJSONCostClassCode))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "currencyId";
        }
    })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "discountPercentage";
        }
    })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "eanCode";
        }
    })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "group";
        }
    })(TofuJSON.readNullable(Types_ClassificationGroup.tofuJSONClassificationGroupCode))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "index";
        }
    })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "name";
        }
    })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "orgResourcePriceId";
        }
    })(TofuJSON.readNullable(TofuJSON.readInt))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "orgResourcePriceListId";
        }
    })(TofuJSON.readNullable(Types_Component.tofuJSONOrgEstimationResourceId))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "pricePerUnit";
        }
    })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "resourceClassificationGroup";
        }
    })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "sortingNumber";
        }
    })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "taxRate";
        }
    })(TofuJSON.readNullable(Types_Unit.tofuJSONTaxrate))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "unit";
        }
    })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "unitConsumptionRate";
        }
    })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "validDate";
        }
    })(TofuJSON.readNullable(Types_JSDateString.tofuJSONJSDateString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "vendor";
        }
    })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "vendorBatchSize";
        }
    })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "vendorCode";
        }
    })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "vendorUnit";
        }
    })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "wastePercentage";
        }
    })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsNil)()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "changedDate";
        }
    })(TofuJSON.readNullable(Types_JSDateString.tofuJSONJSDateString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "costClassCode";
        }
    })(TofuJSON.readNullable(Types_Newtypes.tofuJSONCostClassCode))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "currencyId";
        }
    })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "discountPercentage";
        }
    })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "eanCode";
        }
    })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "group";
        }
    })(TofuJSON.readNullable(Types_ClassificationGroup.tofuJSONClassificationGroupCode))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "index";
        }
    })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "name";
        }
    })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "orgResourcePriceId";
        }
    })(TofuJSON.readNullable(TofuJSON.readInt))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "orgResourcePriceListId";
        }
    })(TofuJSON.readNullable(Types_Component.tofuJSONOrgEstimationResourceId))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "pricePerUnit";
        }
    })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "resourceClassificationGroup";
        }
    })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "sortingNumber";
        }
    })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "taxRate";
        }
    })(TofuJSON.readNullable(Types_Unit.tofuJSONTaxrate))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "unit";
        }
    })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "unitConsumptionRate";
        }
    })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "validDate";
        }
    })(TofuJSON.readNullable(Types_JSDateString.tofuJSONJSDateString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "vendor";
        }
    })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "vendorBatchSize";
        }
    })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "vendorCode";
        }
    })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "vendorUnit";
        }
    })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "wastePercentage";
        }
    })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()()))(TofuJSON.nilWriteForeignFields)()()())()()())))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "ReplaceComponentResourceFromOrgPriceList";
        }
    })(TofuJSON.readArray(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "componentId";
        }
    })(Types_Component.tofuJSONComponentId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "orgResourcePriceIds";
        }
    })(TofuJSON.readArray(TofuJSON.readInt))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "replaceTargetResourceId";
        }
    })(Types_Component.tofuJSONEstimationResourceId)(TofuJSON.readFieldsNil)()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "componentId";
        }
    })(Types_Component.tofuJSONComponentId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "orgResourcePriceIds";
        }
    })(TofuJSON.readArray(TofuJSON.readInt))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "replaceTargetResourceId";
        }
    })(Types_Component.tofuJSONEstimationResourceId)(TofuJSON.nilWriteForeignFields)()()())()()())()()())))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "ReplaceComponentResourceFromProject";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "componentId";
        }
    })(Types_Component.tofuJSONComponentId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "newResourceId";
        }
    })(Types_Component.tofuJSONEstimationResourceId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "replaceTargetResourceId";
        }
    })(Types_Component.tofuJSONEstimationResourceId)(TofuJSON.readFieldsNil)()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "componentId";
        }
    })(Types_Component.tofuJSONComponentId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "newResourceId";
        }
    })(Types_Component.tofuJSONEstimationResourceId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "replaceTargetResourceId";
        }
    })(Types_Component.tofuJSONEstimationResourceId)(TofuJSON.nilWriteForeignFields)()()())()()())()()()))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "ReplaceComponentResources";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "componentId";
        }
    })(Types_Component.tofuJSONComponentId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "referenceComponentId";
        }
    })(Types_Component.tofuJSONComponentId)(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "componentId";
        }
    })(Types_Component.tofuJSONComponentId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "referenceComponentId";
        }
    })(Types_Component.tofuJSONComponentId)(TofuJSON.nilWriteForeignFields)()()())()()()))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "SetEstimationResourcesCostClass";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "costClassCode";
        }
    })(Types_Newtypes.tofuJSONCostClassCode)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "estimationResourceIds";
        }
    })(TofuJSON.readArray(Types_Component.tofuJSONEstimationResourceId))(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "costClassCode";
        }
    })(Types_Newtypes.tofuJSONCostClassCode)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "estimationResourceIds";
        }
    })(TofuJSON.readArray(Types_Component.tofuJSONEstimationResourceId))(TofuJSON.nilWriteForeignFields)()()())()()()))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "SetEstimationResourcesGroup";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "estimationResourceIds";
        }
    })(TofuJSON.readArray(Types_Component.tofuJSONEstimationResourceId))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "group";
        }
    })(TofuJSON.readNullable(Types_ClassificationGroup.tofuJSONClassificationGroupCode))(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "estimationResourceIds";
        }
    })(TofuJSON.readArray(Types_Component.tofuJSONEstimationResourceId))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "group";
        }
    })(TofuJSON.readNullable(Types_ClassificationGroup.tofuJSONClassificationGroupCode))(TofuJSON.nilWriteForeignFields)()()())()()()))()(TofuJSON.nilWriteForeignVariant)))))))))))))))
};
var genericEstimationLocationAction = {
    to: function (x) {
        if (x instanceof Data_Generic_Rep.Inl) {
            return new UpdateEstimationLocationAmount(x.value0);
        };
        if (x instanceof Data_Generic_Rep.Inr && x.value0 instanceof Data_Generic_Rep.Inl) {
            return new UpdateEstimationLocationCode(x.value0.value0);
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0 instanceof Data_Generic_Rep.Inl)) {
            return new UpdateEstimationLocationDescription(x.value0.value0.value0);
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0 instanceof Data_Generic_Rep.Inl))) {
            return new AddEstimationLocation(x.value0.value0.value0.value0);
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inl)))) {
            return new DeleteEstimationLocations(x.value0.value0.value0.value0.value0);
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr)))) {
            return new EstimationLocationsMatrixAction(x.value0.value0.value0.value0.value0);
        };
        throw new Error("Failed pattern match at Types.Estimation (line 696, column 1 - line 696, column 86): " + [ x.constructor.name ]);
    },
    from: function (x) {
        if (x instanceof UpdateEstimationLocationAmount) {
            return new Data_Generic_Rep.Inl(x.value0);
        };
        if (x instanceof UpdateEstimationLocationCode) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(x.value0));
        };
        if (x instanceof UpdateEstimationLocationDescription) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(x.value0)));
        };
        if (x instanceof AddEstimationLocation) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(x.value0))));
        };
        if (x instanceof DeleteEstimationLocations) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(x.value0)))));
        };
        if (x instanceof EstimationLocationsMatrixAction) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(x.value0)))));
        };
        throw new Error("Failed pattern match at Types.Estimation (line 696, column 1 - line 696, column 86): " + [ x.constructor.name ]);
    }
};
var tofuJSONEstimationLocationAction = {
    writeImpl: TofuJSON.writeGenericSum()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "AddEstimationLocation";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "componentId";
        }
    })(Types_Component.tofuJSONComponentId)(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "componentId";
        }
    })(Types_Component.tofuJSONComponentId)(TofuJSON.nilWriteForeignFields)()()()))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "DeleteEstimationLocations";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "componentId";
        }
    })(Types_Component.tofuJSONComponentId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "estimationLocationIds";
        }
    })(TofuJSON.readArray(Types_Component.tofuJSONEstimationLocationId))(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "componentId";
        }
    })(Types_Component.tofuJSONComponentId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "estimationLocationIds";
        }
    })(TofuJSON.readArray(Types_Component.tofuJSONEstimationLocationId))(TofuJSON.nilWriteForeignFields)()()())()()()))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "EstimationLocationsMatrixAction";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "componentId";
        }
    })(Types_Component.tofuJSONComponentId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "modifiedAmounts";
        }
    })(TofuJSON.readArray(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "amount";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "description";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "estimationLocationId";
        }
    })(Types_Component.tofuJSONEstimationLocationId)(TofuJSON.readFieldsNil)()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "amount";
        }
    })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "description";
        }
    })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "estimationLocationId";
        }
    })(Types_Component.tofuJSONEstimationLocationId)(TofuJSON.nilWriteForeignFields)()()())()()())()()())))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "newAmounts";
        }
    })(TofuJSON.readArray(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "amount";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "description";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "locationCode";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsNil)()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "amount";
        }
    })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "description";
        }
    })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "locationCode";
        }
    })(TofuJSON.readString)(TofuJSON.nilWriteForeignFields)()()())()()())()()())))(TofuJSON.readFieldsNil)()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "componentId";
        }
    })(Types_Component.tofuJSONComponentId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "modifiedAmounts";
        }
    })(TofuJSON.readArray(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "amount";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "description";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "estimationLocationId";
        }
    })(Types_Component.tofuJSONEstimationLocationId)(TofuJSON.readFieldsNil)()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "amount";
        }
    })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "description";
        }
    })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "estimationLocationId";
        }
    })(Types_Component.tofuJSONEstimationLocationId)(TofuJSON.nilWriteForeignFields)()()())()()())()()())))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "newAmounts";
        }
    })(TofuJSON.readArray(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "amount";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "description";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "locationCode";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsNil)()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "amount";
        }
    })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "description";
        }
    })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "locationCode";
        }
    })(TofuJSON.readString)(TofuJSON.nilWriteForeignFields)()()())()()())()()())))(TofuJSON.nilWriteForeignFields)()()())()()())()()()))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "UpdateEstimationLocationAmount";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "amount";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "componentId";
        }
    })(Types_Component.tofuJSONComponentId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "estimationLocationId";
        }
    })(Types_Component.tofuJSONEstimationLocationId)(TofuJSON.readFieldsNil)()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "amount";
        }
    })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "componentId";
        }
    })(Types_Component.tofuJSONComponentId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "estimationLocationId";
        }
    })(Types_Component.tofuJSONEstimationLocationId)(TofuJSON.nilWriteForeignFields)()()())()()())()()()))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "UpdateEstimationLocationCode";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "code";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "componentId";
        }
    })(Types_Component.tofuJSONComponentId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "estimationLocationId";
        }
    })(Types_Component.tofuJSONEstimationLocationId)(TofuJSON.readFieldsNil)()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "code";
        }
    })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "componentId";
        }
    })(Types_Component.tofuJSONComponentId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "estimationLocationId";
        }
    })(Types_Component.tofuJSONEstimationLocationId)(TofuJSON.nilWriteForeignFields)()()())()()())()()()))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "UpdateEstimationLocationDescription";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "componentId";
        }
    })(Types_Component.tofuJSONComponentId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "description";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "estimationLocationId";
        }
    })(Types_Component.tofuJSONEstimationLocationId)(TofuJSON.readFieldsNil)()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "componentId";
        }
    })(Types_Component.tofuJSONComponentId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "description";
        }
    })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "estimationLocationId";
        }
    })(Types_Component.tofuJSONEstimationLocationId)(TofuJSON.nilWriteForeignFields)()()())()()())()()()))()(TofuJSON.readVariantNil)))))))(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "AddEstimationLocation";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "componentId";
        }
    })(Types_Component.tofuJSONComponentId)(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "componentId";
        }
    })(Types_Component.tofuJSONComponentId)(TofuJSON.nilWriteForeignFields)()()()))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "DeleteEstimationLocations";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "componentId";
        }
    })(Types_Component.tofuJSONComponentId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "estimationLocationIds";
        }
    })(TofuJSON.readArray(Types_Component.tofuJSONEstimationLocationId))(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "componentId";
        }
    })(Types_Component.tofuJSONComponentId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "estimationLocationIds";
        }
    })(TofuJSON.readArray(Types_Component.tofuJSONEstimationLocationId))(TofuJSON.nilWriteForeignFields)()()())()()()))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "EstimationLocationsMatrixAction";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "componentId";
        }
    })(Types_Component.tofuJSONComponentId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "modifiedAmounts";
        }
    })(TofuJSON.readArray(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "amount";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "description";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "estimationLocationId";
        }
    })(Types_Component.tofuJSONEstimationLocationId)(TofuJSON.readFieldsNil)()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "amount";
        }
    })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "description";
        }
    })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "estimationLocationId";
        }
    })(Types_Component.tofuJSONEstimationLocationId)(TofuJSON.nilWriteForeignFields)()()())()()())()()())))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "newAmounts";
        }
    })(TofuJSON.readArray(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "amount";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "description";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "locationCode";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsNil)()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "amount";
        }
    })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "description";
        }
    })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "locationCode";
        }
    })(TofuJSON.readString)(TofuJSON.nilWriteForeignFields)()()())()()())()()())))(TofuJSON.readFieldsNil)()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "componentId";
        }
    })(Types_Component.tofuJSONComponentId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "modifiedAmounts";
        }
    })(TofuJSON.readArray(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "amount";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "description";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "estimationLocationId";
        }
    })(Types_Component.tofuJSONEstimationLocationId)(TofuJSON.readFieldsNil)()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "amount";
        }
    })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "description";
        }
    })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "estimationLocationId";
        }
    })(Types_Component.tofuJSONEstimationLocationId)(TofuJSON.nilWriteForeignFields)()()())()()())()()())))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "newAmounts";
        }
    })(TofuJSON.readArray(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "amount";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "description";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "locationCode";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsNil)()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "amount";
        }
    })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "description";
        }
    })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "locationCode";
        }
    })(TofuJSON.readString)(TofuJSON.nilWriteForeignFields)()()())()()())()()())))(TofuJSON.nilWriteForeignFields)()()())()()())()()()))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "UpdateEstimationLocationAmount";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "amount";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "componentId";
        }
    })(Types_Component.tofuJSONComponentId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "estimationLocationId";
        }
    })(Types_Component.tofuJSONEstimationLocationId)(TofuJSON.readFieldsNil)()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "amount";
        }
    })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "componentId";
        }
    })(Types_Component.tofuJSONComponentId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "estimationLocationId";
        }
    })(Types_Component.tofuJSONEstimationLocationId)(TofuJSON.nilWriteForeignFields)()()())()()())()()()))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "UpdateEstimationLocationCode";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "code";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "componentId";
        }
    })(Types_Component.tofuJSONComponentId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "estimationLocationId";
        }
    })(Types_Component.tofuJSONEstimationLocationId)(TofuJSON.readFieldsNil)()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "code";
        }
    })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "componentId";
        }
    })(Types_Component.tofuJSONComponentId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "estimationLocationId";
        }
    })(Types_Component.tofuJSONEstimationLocationId)(TofuJSON.nilWriteForeignFields)()()())()()())()()()))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "UpdateEstimationLocationDescription";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "componentId";
        }
    })(Types_Component.tofuJSONComponentId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "description";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "estimationLocationId";
        }
    })(Types_Component.tofuJSONEstimationLocationId)(TofuJSON.readFieldsNil)()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "componentId";
        }
    })(Types_Component.tofuJSONComponentId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "description";
        }
    })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "estimationLocationId";
        }
    })(Types_Component.tofuJSONEstimationLocationId)(TofuJSON.nilWriteForeignFields)()()())()()())()()()))()(TofuJSON.nilWriteForeignVariant)))))))(genericEstimationLocationAction)(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "UpdateEstimationLocationAmount";
        }
    })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "UpdateEstimationLocationCode";
        }
    })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "UpdateEstimationLocationDescription";
        }
    })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "AddEstimationLocation";
        }
    })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "DeleteEstimationLocations";
        }
    })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "EstimationLocationsMatrixAction";
        }
    })()(Kishimen.genericSumToVariantArgArguments))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "DeleteEstimationLocations";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "EstimationLocationsMatrixAction";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "AddEstimationLocation";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "EstimationLocationsMatrixAction";
        }
    }))({
        reflectSymbol: function () {
            return "DeleteEstimationLocations";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "UpdateEstimationLocationDescription";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "EstimationLocationsMatrixAction";
        }
    }))({
        reflectSymbol: function () {
            return "DeleteEstimationLocations";
        }
    }))({
        reflectSymbol: function () {
            return "AddEstimationLocation";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "UpdateEstimationLocationCode";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "UpdateEstimationLocationDescription";
        }
    }))({
        reflectSymbol: function () {
            return "EstimationLocationsMatrixAction";
        }
    }))({
        reflectSymbol: function () {
            return "DeleteEstimationLocations";
        }
    }))({
        reflectSymbol: function () {
            return "AddEstimationLocation";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "UpdateEstimationLocationAmount";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "UpdateEstimationLocationDescription";
        }
    }))({
        reflectSymbol: function () {
            return "UpdateEstimationLocationCode";
        }
    }))({
        reflectSymbol: function () {
            return "EstimationLocationsMatrixAction";
        }
    }))({
        reflectSymbol: function () {
            return "DeleteEstimationLocations";
        }
    }))({
        reflectSymbol: function () {
            return "AddEstimationLocation";
        }
    }))),
    readImpl: TofuJSON.readGenericSum(genericEstimationLocationAction)(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "UpdateEstimationLocationAmount";
        }
    })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "UpdateEstimationLocationCode";
        }
    })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "UpdateEstimationLocationDescription";
        }
    })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "AddEstimationLocation";
        }
    })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "DeleteEstimationLocations";
        }
    })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "EstimationLocationsMatrixAction";
        }
    })()(Kishimen.genericSumToVariantArgArguments))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "DeleteEstimationLocations";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "EstimationLocationsMatrixAction";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "AddEstimationLocation";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "EstimationLocationsMatrixAction";
        }
    }))({
        reflectSymbol: function () {
            return "DeleteEstimationLocations";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "UpdateEstimationLocationDescription";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "EstimationLocationsMatrixAction";
        }
    }))({
        reflectSymbol: function () {
            return "DeleteEstimationLocations";
        }
    }))({
        reflectSymbol: function () {
            return "AddEstimationLocation";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "UpdateEstimationLocationCode";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "UpdateEstimationLocationDescription";
        }
    }))({
        reflectSymbol: function () {
            return "EstimationLocationsMatrixAction";
        }
    }))({
        reflectSymbol: function () {
            return "DeleteEstimationLocations";
        }
    }))({
        reflectSymbol: function () {
            return "AddEstimationLocation";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "UpdateEstimationLocationAmount";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "UpdateEstimationLocationDescription";
        }
    }))({
        reflectSymbol: function () {
            return "UpdateEstimationLocationCode";
        }
    }))({
        reflectSymbol: function () {
            return "EstimationLocationsMatrixAction";
        }
    }))({
        reflectSymbol: function () {
            return "DeleteEstimationLocations";
        }
    }))({
        reflectSymbol: function () {
            return "AddEstimationLocation";
        }
    })))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "AddEstimationLocation";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "componentId";
        }
    })(Types_Component.tofuJSONComponentId)(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "componentId";
        }
    })(Types_Component.tofuJSONComponentId)(TofuJSON.nilWriteForeignFields)()()()))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "DeleteEstimationLocations";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "componentId";
        }
    })(Types_Component.tofuJSONComponentId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "estimationLocationIds";
        }
    })(TofuJSON.readArray(Types_Component.tofuJSONEstimationLocationId))(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "componentId";
        }
    })(Types_Component.tofuJSONComponentId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "estimationLocationIds";
        }
    })(TofuJSON.readArray(Types_Component.tofuJSONEstimationLocationId))(TofuJSON.nilWriteForeignFields)()()())()()()))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "EstimationLocationsMatrixAction";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "componentId";
        }
    })(Types_Component.tofuJSONComponentId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "modifiedAmounts";
        }
    })(TofuJSON.readArray(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "amount";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "description";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "estimationLocationId";
        }
    })(Types_Component.tofuJSONEstimationLocationId)(TofuJSON.readFieldsNil)()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "amount";
        }
    })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "description";
        }
    })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "estimationLocationId";
        }
    })(Types_Component.tofuJSONEstimationLocationId)(TofuJSON.nilWriteForeignFields)()()())()()())()()())))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "newAmounts";
        }
    })(TofuJSON.readArray(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "amount";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "description";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "locationCode";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsNil)()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "amount";
        }
    })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "description";
        }
    })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "locationCode";
        }
    })(TofuJSON.readString)(TofuJSON.nilWriteForeignFields)()()())()()())()()())))(TofuJSON.readFieldsNil)()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "componentId";
        }
    })(Types_Component.tofuJSONComponentId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "modifiedAmounts";
        }
    })(TofuJSON.readArray(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "amount";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "description";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "estimationLocationId";
        }
    })(Types_Component.tofuJSONEstimationLocationId)(TofuJSON.readFieldsNil)()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "amount";
        }
    })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "description";
        }
    })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "estimationLocationId";
        }
    })(Types_Component.tofuJSONEstimationLocationId)(TofuJSON.nilWriteForeignFields)()()())()()())()()())))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "newAmounts";
        }
    })(TofuJSON.readArray(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "amount";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "description";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "locationCode";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsNil)()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "amount";
        }
    })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "description";
        }
    })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "locationCode";
        }
    })(TofuJSON.readString)(TofuJSON.nilWriteForeignFields)()()())()()())()()())))(TofuJSON.nilWriteForeignFields)()()())()()())()()()))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "UpdateEstimationLocationAmount";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "amount";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "componentId";
        }
    })(Types_Component.tofuJSONComponentId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "estimationLocationId";
        }
    })(Types_Component.tofuJSONEstimationLocationId)(TofuJSON.readFieldsNil)()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "amount";
        }
    })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "componentId";
        }
    })(Types_Component.tofuJSONComponentId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "estimationLocationId";
        }
    })(Types_Component.tofuJSONEstimationLocationId)(TofuJSON.nilWriteForeignFields)()()())()()())()()()))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "UpdateEstimationLocationCode";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "code";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "componentId";
        }
    })(Types_Component.tofuJSONComponentId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "estimationLocationId";
        }
    })(Types_Component.tofuJSONEstimationLocationId)(TofuJSON.readFieldsNil)()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "code";
        }
    })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "componentId";
        }
    })(Types_Component.tofuJSONComponentId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "estimationLocationId";
        }
    })(Types_Component.tofuJSONEstimationLocationId)(TofuJSON.nilWriteForeignFields)()()())()()())()()()))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "UpdateEstimationLocationDescription";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "componentId";
        }
    })(Types_Component.tofuJSONComponentId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "description";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "estimationLocationId";
        }
    })(Types_Component.tofuJSONEstimationLocationId)(TofuJSON.readFieldsNil)()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "componentId";
        }
    })(Types_Component.tofuJSONComponentId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "description";
        }
    })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "estimationLocationId";
        }
    })(Types_Component.tofuJSONEstimationLocationId)(TofuJSON.nilWriteForeignFields)()()())()()())()()()))()(TofuJSON.readVariantNil)))))))(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "AddEstimationLocation";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "componentId";
        }
    })(Types_Component.tofuJSONComponentId)(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "componentId";
        }
    })(Types_Component.tofuJSONComponentId)(TofuJSON.nilWriteForeignFields)()()()))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "DeleteEstimationLocations";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "componentId";
        }
    })(Types_Component.tofuJSONComponentId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "estimationLocationIds";
        }
    })(TofuJSON.readArray(Types_Component.tofuJSONEstimationLocationId))(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "componentId";
        }
    })(Types_Component.tofuJSONComponentId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "estimationLocationIds";
        }
    })(TofuJSON.readArray(Types_Component.tofuJSONEstimationLocationId))(TofuJSON.nilWriteForeignFields)()()())()()()))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "EstimationLocationsMatrixAction";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "componentId";
        }
    })(Types_Component.tofuJSONComponentId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "modifiedAmounts";
        }
    })(TofuJSON.readArray(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "amount";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "description";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "estimationLocationId";
        }
    })(Types_Component.tofuJSONEstimationLocationId)(TofuJSON.readFieldsNil)()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "amount";
        }
    })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "description";
        }
    })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "estimationLocationId";
        }
    })(Types_Component.tofuJSONEstimationLocationId)(TofuJSON.nilWriteForeignFields)()()())()()())()()())))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "newAmounts";
        }
    })(TofuJSON.readArray(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "amount";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "description";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "locationCode";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsNil)()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "amount";
        }
    })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "description";
        }
    })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "locationCode";
        }
    })(TofuJSON.readString)(TofuJSON.nilWriteForeignFields)()()())()()())()()())))(TofuJSON.readFieldsNil)()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "componentId";
        }
    })(Types_Component.tofuJSONComponentId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "modifiedAmounts";
        }
    })(TofuJSON.readArray(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "amount";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "description";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "estimationLocationId";
        }
    })(Types_Component.tofuJSONEstimationLocationId)(TofuJSON.readFieldsNil)()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "amount";
        }
    })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "description";
        }
    })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "estimationLocationId";
        }
    })(Types_Component.tofuJSONEstimationLocationId)(TofuJSON.nilWriteForeignFields)()()())()()())()()())))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "newAmounts";
        }
    })(TofuJSON.readArray(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "amount";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "description";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "locationCode";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsNil)()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "amount";
        }
    })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "description";
        }
    })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "locationCode";
        }
    })(TofuJSON.readString)(TofuJSON.nilWriteForeignFields)()()())()()())()()())))(TofuJSON.nilWriteForeignFields)()()())()()())()()()))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "UpdateEstimationLocationAmount";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "amount";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "componentId";
        }
    })(Types_Component.tofuJSONComponentId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "estimationLocationId";
        }
    })(Types_Component.tofuJSONEstimationLocationId)(TofuJSON.readFieldsNil)()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "amount";
        }
    })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "componentId";
        }
    })(Types_Component.tofuJSONComponentId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "estimationLocationId";
        }
    })(Types_Component.tofuJSONEstimationLocationId)(TofuJSON.nilWriteForeignFields)()()())()()())()()()))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "UpdateEstimationLocationCode";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "code";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "componentId";
        }
    })(Types_Component.tofuJSONComponentId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "estimationLocationId";
        }
    })(Types_Component.tofuJSONEstimationLocationId)(TofuJSON.readFieldsNil)()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "code";
        }
    })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "componentId";
        }
    })(Types_Component.tofuJSONComponentId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "estimationLocationId";
        }
    })(Types_Component.tofuJSONEstimationLocationId)(TofuJSON.nilWriteForeignFields)()()())()()())()()()))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "UpdateEstimationLocationDescription";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "componentId";
        }
    })(Types_Component.tofuJSONComponentId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "description";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "estimationLocationId";
        }
    })(Types_Component.tofuJSONEstimationLocationId)(TofuJSON.readFieldsNil)()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "componentId";
        }
    })(Types_Component.tofuJSONComponentId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "description";
        }
    })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "estimationLocationId";
        }
    })(Types_Component.tofuJSONEstimationLocationId)(TofuJSON.nilWriteForeignFields)()()())()()())()()()))()(TofuJSON.nilWriteForeignVariant)))))))
};
var genericEstimationElementCopyOperationType = {
    to: function (x) {
        if (x instanceof Data_Generic_Rep.Inl) {
            return CopyElementOnly.value;
        };
        if (x instanceof Data_Generic_Rep.Inr && x.value0 instanceof Data_Generic_Rep.Inl) {
            return new CopyElementWithComponents(x.value0.value0);
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0 instanceof Data_Generic_Rep.Inl)) {
            return CopyElementWithinProject.value;
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0 instanceof Data_Generic_Rep.Inr)) {
            return new AddElementSpecs(x.value0.value0.value0);
        };
        throw new Error("Failed pattern match at Types.Estimation (line 672, column 1 - line 672, column 106): " + [ x.constructor.name ]);
    },
    from: function (x) {
        if (x instanceof CopyElementOnly) {
            return new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value);
        };
        if (x instanceof CopyElementWithComponents) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(x.value0));
        };
        if (x instanceof CopyElementWithinProject) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value)));
        };
        if (x instanceof AddElementSpecs) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(x.value0)));
        };
        throw new Error("Failed pattern match at Types.Estimation (line 672, column 1 - line 672, column 106): " + [ x.constructor.name ]);
    }
};
var tofuJSONEstimationElementCopyOperationType = {
    writeImpl: TofuJSON.writeGenericSum()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "AddElementSpecs";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "copyResources";
        }
    })(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "elementSpecIds";
        }
    })(TofuJSON.readArray(Types_Newtypes.tofuJSONElementSpecId))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "noCopyingForMeasures";
        }
    })(TofuJSON.readArray(Types_Element.tofuJSONElementMeasure))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "targetElementId";
        }
    })(Types_Element.tofuJSONElementId)(TofuJSON.readFieldsNil)()())()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "copyResources";
        }
    })(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "elementSpecIds";
        }
    })(TofuJSON.readArray(Types_Newtypes.tofuJSONElementSpecId))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "noCopyingForMeasures";
        }
    })(TofuJSON.readArray(Types_Element.tofuJSONElementMeasure))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "targetElementId";
        }
    })(Types_Element.tofuJSONElementId)(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()()))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "CopyElementOnly";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "CopyElementWithComponents";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "addWildcardLocation";
        }
    })(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "copyResources";
        }
    })(TofuJSON.readBoolean)(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "addWildcardLocation";
        }
    })(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "copyResources";
        }
    })(TofuJSON.readBoolean)(TofuJSON.nilWriteForeignFields)()()())()()()))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "CopyElementWithinProject";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.readVariantNil)))))(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "AddElementSpecs";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "copyResources";
        }
    })(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "elementSpecIds";
        }
    })(TofuJSON.readArray(Types_Newtypes.tofuJSONElementSpecId))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "noCopyingForMeasures";
        }
    })(TofuJSON.readArray(Types_Element.tofuJSONElementMeasure))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "targetElementId";
        }
    })(Types_Element.tofuJSONElementId)(TofuJSON.readFieldsNil)()())()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "copyResources";
        }
    })(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "elementSpecIds";
        }
    })(TofuJSON.readArray(Types_Newtypes.tofuJSONElementSpecId))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "noCopyingForMeasures";
        }
    })(TofuJSON.readArray(Types_Element.tofuJSONElementMeasure))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "targetElementId";
        }
    })(Types_Element.tofuJSONElementId)(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()()))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "CopyElementOnly";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "CopyElementWithComponents";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "addWildcardLocation";
        }
    })(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "copyResources";
        }
    })(TofuJSON.readBoolean)(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "addWildcardLocation";
        }
    })(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "copyResources";
        }
    })(TofuJSON.readBoolean)(TofuJSON.nilWriteForeignFields)()()())()()()))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "CopyElementWithinProject";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.nilWriteForeignVariant)))))(genericEstimationElementCopyOperationType)(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "CopyElementOnly";
        }
    })()(Kishimen.genericSumToVariantArgNoArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "CopyElementWithComponents";
        }
    })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "CopyElementWithinProject";
        }
    })()(Kishimen.genericSumToVariantArgNoArguments))(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "AddElementSpecs";
        }
    })()(Kishimen.genericSumToVariantArgArguments))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "CopyElementWithinProject";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "AddElementSpecs";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "CopyElementWithComponents";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "CopyElementWithinProject";
        }
    }))({
        reflectSymbol: function () {
            return "AddElementSpecs";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "CopyElementOnly";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "CopyElementWithinProject";
        }
    }))({
        reflectSymbol: function () {
            return "CopyElementWithComponents";
        }
    }))({
        reflectSymbol: function () {
            return "AddElementSpecs";
        }
    }))),
    readImpl: TofuJSON.readGenericSum(genericEstimationElementCopyOperationType)(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "CopyElementOnly";
        }
    })()(Kishimen.genericSumToVariantArgNoArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "CopyElementWithComponents";
        }
    })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "CopyElementWithinProject";
        }
    })()(Kishimen.genericSumToVariantArgNoArguments))(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "AddElementSpecs";
        }
    })()(Kishimen.genericSumToVariantArgArguments))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "CopyElementWithinProject";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "AddElementSpecs";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "CopyElementWithComponents";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "CopyElementWithinProject";
        }
    }))({
        reflectSymbol: function () {
            return "AddElementSpecs";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "CopyElementOnly";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "CopyElementWithinProject";
        }
    }))({
        reflectSymbol: function () {
            return "CopyElementWithComponents";
        }
    }))({
        reflectSymbol: function () {
            return "AddElementSpecs";
        }
    })))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "AddElementSpecs";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "copyResources";
        }
    })(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "elementSpecIds";
        }
    })(TofuJSON.readArray(Types_Newtypes.tofuJSONElementSpecId))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "noCopyingForMeasures";
        }
    })(TofuJSON.readArray(Types_Element.tofuJSONElementMeasure))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "targetElementId";
        }
    })(Types_Element.tofuJSONElementId)(TofuJSON.readFieldsNil)()())()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "copyResources";
        }
    })(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "elementSpecIds";
        }
    })(TofuJSON.readArray(Types_Newtypes.tofuJSONElementSpecId))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "noCopyingForMeasures";
        }
    })(TofuJSON.readArray(Types_Element.tofuJSONElementMeasure))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "targetElementId";
        }
    })(Types_Element.tofuJSONElementId)(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()()))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "CopyElementOnly";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "CopyElementWithComponents";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "addWildcardLocation";
        }
    })(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "copyResources";
        }
    })(TofuJSON.readBoolean)(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "addWildcardLocation";
        }
    })(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "copyResources";
        }
    })(TofuJSON.readBoolean)(TofuJSON.nilWriteForeignFields)()()())()()()))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "CopyElementWithinProject";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.readVariantNil)))))(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "AddElementSpecs";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "copyResources";
        }
    })(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "elementSpecIds";
        }
    })(TofuJSON.readArray(Types_Newtypes.tofuJSONElementSpecId))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "noCopyingForMeasures";
        }
    })(TofuJSON.readArray(Types_Element.tofuJSONElementMeasure))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "targetElementId";
        }
    })(Types_Element.tofuJSONElementId)(TofuJSON.readFieldsNil)()())()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "copyResources";
        }
    })(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "elementSpecIds";
        }
    })(TofuJSON.readArray(Types_Newtypes.tofuJSONElementSpecId))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "noCopyingForMeasures";
        }
    })(TofuJSON.readArray(Types_Element.tofuJSONElementMeasure))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "targetElementId";
        }
    })(Types_Element.tofuJSONElementId)(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()()))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "CopyElementOnly";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "CopyElementWithComponents";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "addWildcardLocation";
        }
    })(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "copyResources";
        }
    })(TofuJSON.readBoolean)(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "addWildcardLocation";
        }
    })(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "copyResources";
        }
    })(TofuJSON.readBoolean)(TofuJSON.nilWriteForeignFields)()()())()()()))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "CopyElementWithinProject";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.nilWriteForeignVariant)))))
};
var genericEstimationElementAction = {
    to: function (x) {
        if (x instanceof Data_Generic_Rep.Inl) {
            return new AddEstimationElement(x.value0);
        };
        if (x instanceof Data_Generic_Rep.Inr && x.value0 instanceof Data_Generic_Rep.Inl) {
            return new EditEstimationElement(x.value0.value0);
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0 instanceof Data_Generic_Rep.Inl)) {
            return new AddExistingProjectComponentsToElement(x.value0.value0.value0);
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0 instanceof Data_Generic_Rep.Inl))) {
            return new AddExistingProjectElementSpecsToElement(x.value0.value0.value0.value0);
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inl)))) {
            return new AddReferenceProjectComponentToElement(x.value0.value0.value0.value0.value0);
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inl))))) {
            return new AddReferenceProjectComponentsToElementWithoutDuplicates(x.value0.value0.value0.value0.value0.value0);
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inl)))))) {
            return new AddNewProjectComponentToElement(x.value0.value0.value0.value0.value0.value0.value0);
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inl))))))) {
            return new CopyEstimationElementsToProject(x.value0.value0.value0.value0.value0.value0.value0.value0);
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inl)))))))) {
            return new CopySelectedElements(x.value0.value0.value0.value0.value0.value0.value0.value0.value0);
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inl))))))))) {
            return new EditElementLocationCode(x.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0);
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inl)))))))))) {
            return new EditElementLocationDescription(x.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0);
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inl))))))))))) {
            return new EditElementLocationAmount(x.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0);
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inl)))))))))))) {
            return new ElementLocationsMatrixAction(x.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0);
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inl))))))))))))) {
            return new AddElementLocation(x.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0);
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr))))))))))))) {
            return new DeleteElementLocations(x.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0);
        };
        throw new Error("Failed pattern match at Types.Estimation (line 684, column 1 - line 684, column 84): " + [ x.constructor.name ]);
    },
    from: function (x) {
        if (x instanceof AddEstimationElement) {
            return new Data_Generic_Rep.Inl(x.value0);
        };
        if (x instanceof EditEstimationElement) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(x.value0));
        };
        if (x instanceof AddExistingProjectComponentsToElement) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(x.value0)));
        };
        if (x instanceof AddExistingProjectElementSpecsToElement) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(x.value0))));
        };
        if (x instanceof AddReferenceProjectComponentToElement) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(x.value0)))));
        };
        if (x instanceof AddReferenceProjectComponentsToElementWithoutDuplicates) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(x.value0))))));
        };
        if (x instanceof AddNewProjectComponentToElement) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(x.value0)))))));
        };
        if (x instanceof CopyEstimationElementsToProject) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(x.value0))))))));
        };
        if (x instanceof CopySelectedElements) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(x.value0)))))))));
        };
        if (x instanceof EditElementLocationCode) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(x.value0))))))))));
        };
        if (x instanceof EditElementLocationDescription) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(x.value0)))))))))));
        };
        if (x instanceof EditElementLocationAmount) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(x.value0))))))))))));
        };
        if (x instanceof ElementLocationsMatrixAction) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(x.value0)))))))))))));
        };
        if (x instanceof AddElementLocation) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(x.value0))))))))))))));
        };
        if (x instanceof DeleteElementLocations) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(x.value0))))))))))))));
        };
        throw new Error("Failed pattern match at Types.Estimation (line 684, column 1 - line 684, column 84): " + [ x.constructor.name ]);
    }
};
var genericEstimationComponentCopyOperationType = {
    to: function (x) {
        if (x instanceof Data_Generic_Rep.Inl) {
            return CopyComponentOnly.value;
        };
        if (x instanceof Data_Generic_Rep.Inr && x.value0 instanceof Data_Generic_Rep.Inl) {
            return CopyComponentWithResources.value;
        };
        if (x instanceof Data_Generic_Rep.Inr && x.value0 instanceof Data_Generic_Rep.Inr) {
            return CopyComponentWithResourcesAndLocations.value;
        };
        throw new Error("Failed pattern match at Types.Estimation (line 656, column 1 - line 656, column 110): " + [ x.constructor.name ]);
    },
    from: function (x) {
        if (x instanceof CopyComponentOnly) {
            return new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value);
        };
        if (x instanceof CopyComponentWithResources) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value));
        };
        if (x instanceof CopyComponentWithResourcesAndLocations) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(Data_Generic_Rep.NoArguments.value));
        };
        throw new Error("Failed pattern match at Types.Estimation (line 656, column 1 - line 656, column 110): " + [ x.constructor.name ]);
    }
};
var tofuJSONEstimationComponentCopyOperationType = {
    writeImpl: TofuJSON.writeGenericSum()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "CopyComponentOnly";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "CopyComponentWithResources";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "CopyComponentWithResourcesAndLocations";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.readVariantNil))))(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "CopyComponentOnly";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "CopyComponentWithResources";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "CopyComponentWithResourcesAndLocations";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.nilWriteForeignVariant))))(genericEstimationComponentCopyOperationType)(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "CopyComponentOnly";
        }
    })()(Kishimen.genericSumToVariantArgNoArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "CopyComponentWithResources";
        }
    })()(Kishimen.genericSumToVariantArgNoArguments))(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "CopyComponentWithResourcesAndLocations";
        }
    })()(Kishimen.genericSumToVariantArgNoArguments))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "CopyComponentWithResources";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "CopyComponentWithResourcesAndLocations";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "CopyComponentOnly";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "CopyComponentWithResourcesAndLocations";
        }
    }))({
        reflectSymbol: function () {
            return "CopyComponentWithResources";
        }
    }))),
    readImpl: TofuJSON.readGenericSum(genericEstimationComponentCopyOperationType)(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "CopyComponentOnly";
        }
    })()(Kishimen.genericSumToVariantArgNoArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "CopyComponentWithResources";
        }
    })()(Kishimen.genericSumToVariantArgNoArguments))(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "CopyComponentWithResourcesAndLocations";
        }
    })()(Kishimen.genericSumToVariantArgNoArguments))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "CopyComponentWithResources";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "CopyComponentWithResourcesAndLocations";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "CopyComponentOnly";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "CopyComponentWithResourcesAndLocations";
        }
    }))({
        reflectSymbol: function () {
            return "CopyComponentWithResources";
        }
    })))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "CopyComponentOnly";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "CopyComponentWithResources";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "CopyComponentWithResourcesAndLocations";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.readVariantNil))))(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "CopyComponentOnly";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "CopyComponentWithResources";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "CopyComponentWithResourcesAndLocations";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.nilWriteForeignVariant))))
};
var genericEstimationComponentAction = {
    to: function (x) {
        if (x instanceof Data_Generic_Rep.Inl) {
            return new AddEstimationComponent(x.value0);
        };
        if (x instanceof Data_Generic_Rep.Inr && x.value0 instanceof Data_Generic_Rep.Inl) {
            return new EditEstimationComponent(x.value0.value0);
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0 instanceof Data_Generic_Rep.Inl)) {
            return new EditEstimationComponents(x.value0.value0.value0);
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0 instanceof Data_Generic_Rep.Inl))) {
            return new ResetEstimationLocations(x.value0.value0.value0.value0);
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inl)))) {
            return new CopyEstimationComponentsToProject(x.value0.value0.value0.value0.value0);
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inl))))) {
            return new DeleteEstimationComponents(x.value0.value0.value0.value0.value0.value0);
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inl)))))) {
            return new ReplaceEstimationComponent(x.value0.value0.value0.value0.value0.value0.value0);
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inl))))))) {
            return new CopyComponentAndApplyEditAction(x.value0.value0.value0.value0.value0.value0.value0.value0);
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr))))))) {
            return new CopyMultiLinkedComponentAndApplyEditAction(x.value0.value0.value0.value0.value0.value0.value0.value0);
        };
        throw new Error("Failed pattern match at Types.Estimation (line 664, column 1 - line 664, column 88): " + [ x.constructor.name ]);
    },
    from: function (x) {
        if (x instanceof AddEstimationComponent) {
            return new Data_Generic_Rep.Inl(x.value0);
        };
        if (x instanceof EditEstimationComponent) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(x.value0));
        };
        if (x instanceof EditEstimationComponents) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(x.value0)));
        };
        if (x instanceof ResetEstimationLocations) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(x.value0))));
        };
        if (x instanceof CopyEstimationComponentsToProject) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(x.value0)))));
        };
        if (x instanceof DeleteEstimationComponents) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(x.value0))))));
        };
        if (x instanceof ReplaceEstimationComponent) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(x.value0)))))));
        };
        if (x instanceof CopyComponentAndApplyEditAction) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(x.value0))))))));
        };
        if (x instanceof CopyMultiLinkedComponentAndApplyEditAction) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(x.value0))))))));
        };
        throw new Error("Failed pattern match at Types.Estimation (line 664, column 1 - line 664, column 88): " + [ x.constructor.name ]);
    }
};
var genericElementUpdateParams = {
    to: function (x) {
        if (x instanceof Data_Generic_Rep.Inl) {
            return new ElementUpdateAddElementSpecs(x.value0);
        };
        if (x instanceof Data_Generic_Rep.Inr && x.value0 instanceof Data_Generic_Rep.Inl) {
            return new ElementUpdateReplaceElementSpecs(x.value0.value0);
        };
        if (x instanceof Data_Generic_Rep.Inr && x.value0 instanceof Data_Generic_Rep.Inr) {
            return new ElementUpdateReplaceElement(x.value0.value0);
        };
        throw new Error("Failed pattern match at Types.Estimation (line 676, column 1 - line 676, column 76): " + [ x.constructor.name ]);
    },
    from: function (x) {
        if (x instanceof ElementUpdateAddElementSpecs) {
            return new Data_Generic_Rep.Inl(x.value0);
        };
        if (x instanceof ElementUpdateReplaceElementSpecs) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(x.value0));
        };
        if (x instanceof ElementUpdateReplaceElement) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(x.value0));
        };
        throw new Error("Failed pattern match at Types.Estimation (line 676, column 1 - line 676, column 76): " + [ x.constructor.name ]);
    }
};
var tofuJSONElementUpdateParams = {
    writeImpl: TofuJSON.writeGenericSum()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "ElementUpdateAddElementSpecs";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "copyResources";
        }
    })(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "elementSpecIds";
        }
    })(TofuJSON.readArray(Types_Newtypes.tofuJSONElementSpecId))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "noCopyingForMeasures";
        }
    })(TofuJSON.readArray(Types_Element.tofuJSONElementMeasure))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "targetElementId";
        }
    })(Types_Element.tofuJSONElementId)(TofuJSON.readFieldsNil)()())()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "copyResources";
        }
    })(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "elementSpecIds";
        }
    })(TofuJSON.readArray(Types_Newtypes.tofuJSONElementSpecId))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "noCopyingForMeasures";
        }
    })(TofuJSON.readArray(Types_Element.tofuJSONElementMeasure))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "targetElementId";
        }
    })(Types_Element.tofuJSONElementId)(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()()))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "ElementUpdateReplaceElement";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "elementSpecIds";
        }
    })(TofuJSON.readArray(Types_Newtypes.tofuJSONElementSpecId))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "targetElementId";
        }
    })(Types_Element.tofuJSONElementId)(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "elementSpecIds";
        }
    })(TofuJSON.readArray(Types_Newtypes.tofuJSONElementSpecId))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "targetElementId";
        }
    })(Types_Element.tofuJSONElementId)(TofuJSON.nilWriteForeignFields)()()())()()()))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "ElementUpdateReplaceElementSpecs";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "copyResources";
        }
    })(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "elementSpecIds";
        }
    })(TofuJSON.readArray(Types_Newtypes.tofuJSONElementSpecId))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "noCopyingForMeasures";
        }
    })(TofuJSON.readArray(Types_Element.tofuJSONElementMeasure))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "targetElementId";
        }
    })(Types_Element.tofuJSONElementId)(TofuJSON.readFieldsNil)()())()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "copyResources";
        }
    })(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "elementSpecIds";
        }
    })(TofuJSON.readArray(Types_Newtypes.tofuJSONElementSpecId))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "noCopyingForMeasures";
        }
    })(TofuJSON.readArray(Types_Element.tofuJSONElementMeasure))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "targetElementId";
        }
    })(Types_Element.tofuJSONElementId)(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()()))()(TofuJSON.readVariantNil))))(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "ElementUpdateAddElementSpecs";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "copyResources";
        }
    })(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "elementSpecIds";
        }
    })(TofuJSON.readArray(Types_Newtypes.tofuJSONElementSpecId))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "noCopyingForMeasures";
        }
    })(TofuJSON.readArray(Types_Element.tofuJSONElementMeasure))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "targetElementId";
        }
    })(Types_Element.tofuJSONElementId)(TofuJSON.readFieldsNil)()())()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "copyResources";
        }
    })(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "elementSpecIds";
        }
    })(TofuJSON.readArray(Types_Newtypes.tofuJSONElementSpecId))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "noCopyingForMeasures";
        }
    })(TofuJSON.readArray(Types_Element.tofuJSONElementMeasure))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "targetElementId";
        }
    })(Types_Element.tofuJSONElementId)(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()()))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "ElementUpdateReplaceElement";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "elementSpecIds";
        }
    })(TofuJSON.readArray(Types_Newtypes.tofuJSONElementSpecId))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "targetElementId";
        }
    })(Types_Element.tofuJSONElementId)(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "elementSpecIds";
        }
    })(TofuJSON.readArray(Types_Newtypes.tofuJSONElementSpecId))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "targetElementId";
        }
    })(Types_Element.tofuJSONElementId)(TofuJSON.nilWriteForeignFields)()()())()()()))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "ElementUpdateReplaceElementSpecs";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "copyResources";
        }
    })(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "elementSpecIds";
        }
    })(TofuJSON.readArray(Types_Newtypes.tofuJSONElementSpecId))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "noCopyingForMeasures";
        }
    })(TofuJSON.readArray(Types_Element.tofuJSONElementMeasure))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "targetElementId";
        }
    })(Types_Element.tofuJSONElementId)(TofuJSON.readFieldsNil)()())()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "copyResources";
        }
    })(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "elementSpecIds";
        }
    })(TofuJSON.readArray(Types_Newtypes.tofuJSONElementSpecId))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "noCopyingForMeasures";
        }
    })(TofuJSON.readArray(Types_Element.tofuJSONElementMeasure))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "targetElementId";
        }
    })(Types_Element.tofuJSONElementId)(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()()))()(TofuJSON.nilWriteForeignVariant))))(genericElementUpdateParams)(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "ElementUpdateAddElementSpecs";
        }
    })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "ElementUpdateReplaceElementSpecs";
        }
    })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "ElementUpdateReplaceElement";
        }
    })()(Kishimen.genericSumToVariantArgArguments))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "ElementUpdateReplaceElementSpecs";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "ElementUpdateReplaceElement";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "ElementUpdateAddElementSpecs";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "ElementUpdateReplaceElementSpecs";
        }
    }))({
        reflectSymbol: function () {
            return "ElementUpdateReplaceElement";
        }
    }))),
    readImpl: TofuJSON.readGenericSum(genericElementUpdateParams)(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "ElementUpdateAddElementSpecs";
        }
    })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "ElementUpdateReplaceElementSpecs";
        }
    })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "ElementUpdateReplaceElement";
        }
    })()(Kishimen.genericSumToVariantArgArguments))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "ElementUpdateReplaceElementSpecs";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "ElementUpdateReplaceElement";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "ElementUpdateAddElementSpecs";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "ElementUpdateReplaceElementSpecs";
        }
    }))({
        reflectSymbol: function () {
            return "ElementUpdateReplaceElement";
        }
    })))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "ElementUpdateAddElementSpecs";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "copyResources";
        }
    })(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "elementSpecIds";
        }
    })(TofuJSON.readArray(Types_Newtypes.tofuJSONElementSpecId))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "noCopyingForMeasures";
        }
    })(TofuJSON.readArray(Types_Element.tofuJSONElementMeasure))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "targetElementId";
        }
    })(Types_Element.tofuJSONElementId)(TofuJSON.readFieldsNil)()())()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "copyResources";
        }
    })(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "elementSpecIds";
        }
    })(TofuJSON.readArray(Types_Newtypes.tofuJSONElementSpecId))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "noCopyingForMeasures";
        }
    })(TofuJSON.readArray(Types_Element.tofuJSONElementMeasure))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "targetElementId";
        }
    })(Types_Element.tofuJSONElementId)(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()()))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "ElementUpdateReplaceElement";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "elementSpecIds";
        }
    })(TofuJSON.readArray(Types_Newtypes.tofuJSONElementSpecId))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "targetElementId";
        }
    })(Types_Element.tofuJSONElementId)(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "elementSpecIds";
        }
    })(TofuJSON.readArray(Types_Newtypes.tofuJSONElementSpecId))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "targetElementId";
        }
    })(Types_Element.tofuJSONElementId)(TofuJSON.nilWriteForeignFields)()()())()()()))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "ElementUpdateReplaceElementSpecs";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "copyResources";
        }
    })(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "elementSpecIds";
        }
    })(TofuJSON.readArray(Types_Newtypes.tofuJSONElementSpecId))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "noCopyingForMeasures";
        }
    })(TofuJSON.readArray(Types_Element.tofuJSONElementMeasure))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "targetElementId";
        }
    })(Types_Element.tofuJSONElementId)(TofuJSON.readFieldsNil)()())()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "copyResources";
        }
    })(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "elementSpecIds";
        }
    })(TofuJSON.readArray(Types_Newtypes.tofuJSONElementSpecId))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "noCopyingForMeasures";
        }
    })(TofuJSON.readArray(Types_Element.tofuJSONElementMeasure))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "targetElementId";
        }
    })(Types_Element.tofuJSONElementId)(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()()))()(TofuJSON.readVariantNil))))(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "ElementUpdateAddElementSpecs";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "copyResources";
        }
    })(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "elementSpecIds";
        }
    })(TofuJSON.readArray(Types_Newtypes.tofuJSONElementSpecId))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "noCopyingForMeasures";
        }
    })(TofuJSON.readArray(Types_Element.tofuJSONElementMeasure))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "targetElementId";
        }
    })(Types_Element.tofuJSONElementId)(TofuJSON.readFieldsNil)()())()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "copyResources";
        }
    })(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "elementSpecIds";
        }
    })(TofuJSON.readArray(Types_Newtypes.tofuJSONElementSpecId))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "noCopyingForMeasures";
        }
    })(TofuJSON.readArray(Types_Element.tofuJSONElementMeasure))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "targetElementId";
        }
    })(Types_Element.tofuJSONElementId)(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()()))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "ElementUpdateReplaceElement";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "elementSpecIds";
        }
    })(TofuJSON.readArray(Types_Newtypes.tofuJSONElementSpecId))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "targetElementId";
        }
    })(Types_Element.tofuJSONElementId)(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "elementSpecIds";
        }
    })(TofuJSON.readArray(Types_Newtypes.tofuJSONElementSpecId))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "targetElementId";
        }
    })(Types_Element.tofuJSONElementId)(TofuJSON.nilWriteForeignFields)()()())()()()))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "ElementUpdateReplaceElementSpecs";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "copyResources";
        }
    })(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "elementSpecIds";
        }
    })(TofuJSON.readArray(Types_Newtypes.tofuJSONElementSpecId))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "noCopyingForMeasures";
        }
    })(TofuJSON.readArray(Types_Element.tofuJSONElementMeasure))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "targetElementId";
        }
    })(Types_Element.tofuJSONElementId)(TofuJSON.readFieldsNil)()())()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "copyResources";
        }
    })(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "elementSpecIds";
        }
    })(TofuJSON.readArray(Types_Newtypes.tofuJSONElementSpecId))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "noCopyingForMeasures";
        }
    })(TofuJSON.readArray(Types_Element.tofuJSONElementMeasure))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "targetElementId";
        }
    })(Types_Element.tofuJSONElementId)(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()()))()(TofuJSON.nilWriteForeignVariant))))
};
var genericEditEstimationElementAction = {
    to: function (x) {
        if (x instanceof Data_Generic_Rep.Inl) {
            return new EditEstimationElementDescription(x.value0);
        };
        if (x instanceof Data_Generic_Rep.Inr && x.value0 instanceof Data_Generic_Rep.Inl) {
            return new EditEstimationElementRtCode(x.value0.value0);
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0 instanceof Data_Generic_Rep.Inl)) {
            return new EditEstimationElementRtDescription(x.value0.value0.value0);
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0 instanceof Data_Generic_Rep.Inl))) {
            return new EditEstimationElementUnit(x.value0.value0.value0.value0);
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inl)))) {
            return new EditEstimationElementMemo(x.value0.value0.value0.value0.value0);
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inl))))) {
            return new EditEstimationElementSubprojectId(x.value0.value0.value0.value0.value0.value0);
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inl)))))) {
            return new EditEstimationElementCode(x.value0.value0.value0.value0.value0.value0.value0);
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inl))))))) {
            return new AddEstimationElementAmountForWildcardLocation(x.value0.value0.value0.value0.value0.value0.value0.value0);
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inl)))))))) {
            return new EditEstimationElementAmountForSingleLocation(x.value0.value0.value0.value0.value0.value0.value0.value0.value0);
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr)))))))) {
            return new EditEstimationElementGroupFlag(x.value0.value0.value0.value0.value0.value0.value0.value0.value0);
        };
        throw new Error("Failed pattern match at Types.Estimation (line 668, column 1 - line 668, column 92): " + [ x.constructor.name ]);
    },
    from: function (x) {
        if (x instanceof EditEstimationElementDescription) {
            return new Data_Generic_Rep.Inl(x.value0);
        };
        if (x instanceof EditEstimationElementRtCode) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(x.value0));
        };
        if (x instanceof EditEstimationElementRtDescription) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(x.value0)));
        };
        if (x instanceof EditEstimationElementUnit) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(x.value0))));
        };
        if (x instanceof EditEstimationElementMemo) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(x.value0)))));
        };
        if (x instanceof EditEstimationElementSubprojectId) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(x.value0))))));
        };
        if (x instanceof EditEstimationElementCode) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(x.value0)))))));
        };
        if (x instanceof AddEstimationElementAmountForWildcardLocation) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(x.value0))))))));
        };
        if (x instanceof EditEstimationElementAmountForSingleLocation) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(x.value0)))))))));
        };
        if (x instanceof EditEstimationElementGroupFlag) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(x.value0)))))))));
        };
        throw new Error("Failed pattern match at Types.Estimation (line 668, column 1 - line 668, column 92): " + [ x.constructor.name ]);
    }
};
var tofuJSONEditEstimationElementAction = {
    writeImpl: TofuJSON.writeGenericSum()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "AddEstimationElementAmountForWildcardLocation";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "amount";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "amount";
        }
    })(TofuJSON.readNumber)(TofuJSON.nilWriteForeignFields)()()()))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "EditEstimationElementAmountForSingleLocation";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "amount";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "elementLocationId";
        }
    })(Types_Element.tofuJSONElementLocationId)(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "amount";
        }
    })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "elementLocationId";
        }
    })(Types_Element.tofuJSONElementLocationId)(TofuJSON.nilWriteForeignFields)()()())()()()))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "EditEstimationElementCode";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "code";
        }
    })(Types_Element.tofuJSONElementCode)(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "code";
        }
    })(Types_Element.tofuJSONElementCode)(TofuJSON.nilWriteForeignFields)()()()))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "EditEstimationElementDescription";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "description";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "description";
        }
    })(TofuJSON.readString)(TofuJSON.nilWriteForeignFields)()()()))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "EditEstimationElementGroupFlag";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "groupFlag";
        }
    })(Types_Element.tofuJSONGroupFlag)(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "groupFlag";
        }
    })(Types_Element.tofuJSONGroupFlag)(TofuJSON.nilWriteForeignFields)()()()))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "EditEstimationElementMemo";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "memo";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "memo";
        }
    })(TofuJSON.readString)(TofuJSON.nilWriteForeignFields)()()()))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "EditEstimationElementRtCode";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "rtCode";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "rtCode";
        }
    })(TofuJSON.readString)(TofuJSON.nilWriteForeignFields)()()()))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "EditEstimationElementRtDescription";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "rtDescription";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "rtDescription";
        }
    })(TofuJSON.readString)(TofuJSON.nilWriteForeignFields)()()()))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "EditEstimationElementSubprojectId";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "subprojectId";
        }
    })(Types_Project.tofuJSONSubprojectId)(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "subprojectId";
        }
    })(Types_Project.tofuJSONSubprojectId)(TofuJSON.nilWriteForeignFields)()()()))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "EditEstimationElementUnit";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "unit";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "unit";
        }
    })(TofuJSON.readString)(TofuJSON.nilWriteForeignFields)()()()))()(TofuJSON.readVariantNil)))))))))))(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "AddEstimationElementAmountForWildcardLocation";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "amount";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "amount";
        }
    })(TofuJSON.readNumber)(TofuJSON.nilWriteForeignFields)()()()))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "EditEstimationElementAmountForSingleLocation";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "amount";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "elementLocationId";
        }
    })(Types_Element.tofuJSONElementLocationId)(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "amount";
        }
    })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "elementLocationId";
        }
    })(Types_Element.tofuJSONElementLocationId)(TofuJSON.nilWriteForeignFields)()()())()()()))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "EditEstimationElementCode";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "code";
        }
    })(Types_Element.tofuJSONElementCode)(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "code";
        }
    })(Types_Element.tofuJSONElementCode)(TofuJSON.nilWriteForeignFields)()()()))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "EditEstimationElementDescription";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "description";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "description";
        }
    })(TofuJSON.readString)(TofuJSON.nilWriteForeignFields)()()()))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "EditEstimationElementGroupFlag";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "groupFlag";
        }
    })(Types_Element.tofuJSONGroupFlag)(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "groupFlag";
        }
    })(Types_Element.tofuJSONGroupFlag)(TofuJSON.nilWriteForeignFields)()()()))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "EditEstimationElementMemo";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "memo";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "memo";
        }
    })(TofuJSON.readString)(TofuJSON.nilWriteForeignFields)()()()))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "EditEstimationElementRtCode";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "rtCode";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "rtCode";
        }
    })(TofuJSON.readString)(TofuJSON.nilWriteForeignFields)()()()))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "EditEstimationElementRtDescription";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "rtDescription";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "rtDescription";
        }
    })(TofuJSON.readString)(TofuJSON.nilWriteForeignFields)()()()))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "EditEstimationElementSubprojectId";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "subprojectId";
        }
    })(Types_Project.tofuJSONSubprojectId)(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "subprojectId";
        }
    })(Types_Project.tofuJSONSubprojectId)(TofuJSON.nilWriteForeignFields)()()()))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "EditEstimationElementUnit";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "unit";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "unit";
        }
    })(TofuJSON.readString)(TofuJSON.nilWriteForeignFields)()()()))()(TofuJSON.nilWriteForeignVariant)))))))))))(genericEditEstimationElementAction)(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "EditEstimationElementDescription";
        }
    })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "EditEstimationElementRtCode";
        }
    })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "EditEstimationElementRtDescription";
        }
    })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "EditEstimationElementUnit";
        }
    })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "EditEstimationElementMemo";
        }
    })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "EditEstimationElementSubprojectId";
        }
    })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "EditEstimationElementCode";
        }
    })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "AddEstimationElementAmountForWildcardLocation";
        }
    })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "EditEstimationElementAmountForSingleLocation";
        }
    })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "EditEstimationElementGroupFlag";
        }
    })()(Kishimen.genericSumToVariantArgArguments))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "EditEstimationElementAmountForSingleLocation";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "EditEstimationElementGroupFlag";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "AddEstimationElementAmountForWildcardLocation";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "EditEstimationElementGroupFlag";
        }
    }))({
        reflectSymbol: function () {
            return "EditEstimationElementAmountForSingleLocation";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "EditEstimationElementCode";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "EditEstimationElementGroupFlag";
        }
    }))({
        reflectSymbol: function () {
            return "EditEstimationElementAmountForSingleLocation";
        }
    }))({
        reflectSymbol: function () {
            return "AddEstimationElementAmountForWildcardLocation";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "EditEstimationElementSubprojectId";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "EditEstimationElementGroupFlag";
        }
    }))({
        reflectSymbol: function () {
            return "EditEstimationElementCode";
        }
    }))({
        reflectSymbol: function () {
            return "EditEstimationElementAmountForSingleLocation";
        }
    }))({
        reflectSymbol: function () {
            return "AddEstimationElementAmountForWildcardLocation";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "EditEstimationElementMemo";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "EditEstimationElementSubprojectId";
        }
    }))({
        reflectSymbol: function () {
            return "EditEstimationElementGroupFlag";
        }
    }))({
        reflectSymbol: function () {
            return "EditEstimationElementCode";
        }
    }))({
        reflectSymbol: function () {
            return "EditEstimationElementAmountForSingleLocation";
        }
    }))({
        reflectSymbol: function () {
            return "AddEstimationElementAmountForWildcardLocation";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "EditEstimationElementUnit";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "EditEstimationElementSubprojectId";
        }
    }))({
        reflectSymbol: function () {
            return "EditEstimationElementMemo";
        }
    }))({
        reflectSymbol: function () {
            return "EditEstimationElementGroupFlag";
        }
    }))({
        reflectSymbol: function () {
            return "EditEstimationElementCode";
        }
    }))({
        reflectSymbol: function () {
            return "EditEstimationElementAmountForSingleLocation";
        }
    }))({
        reflectSymbol: function () {
            return "AddEstimationElementAmountForWildcardLocation";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "EditEstimationElementRtDescription";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "EditEstimationElementUnit";
        }
    }))({
        reflectSymbol: function () {
            return "EditEstimationElementSubprojectId";
        }
    }))({
        reflectSymbol: function () {
            return "EditEstimationElementMemo";
        }
    }))({
        reflectSymbol: function () {
            return "EditEstimationElementGroupFlag";
        }
    }))({
        reflectSymbol: function () {
            return "EditEstimationElementCode";
        }
    }))({
        reflectSymbol: function () {
            return "EditEstimationElementAmountForSingleLocation";
        }
    }))({
        reflectSymbol: function () {
            return "AddEstimationElementAmountForWildcardLocation";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "EditEstimationElementRtCode";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "EditEstimationElementUnit";
        }
    }))({
        reflectSymbol: function () {
            return "EditEstimationElementSubprojectId";
        }
    }))({
        reflectSymbol: function () {
            return "EditEstimationElementRtDescription";
        }
    }))({
        reflectSymbol: function () {
            return "EditEstimationElementMemo";
        }
    }))({
        reflectSymbol: function () {
            return "EditEstimationElementGroupFlag";
        }
    }))({
        reflectSymbol: function () {
            return "EditEstimationElementCode";
        }
    }))({
        reflectSymbol: function () {
            return "EditEstimationElementAmountForSingleLocation";
        }
    }))({
        reflectSymbol: function () {
            return "AddEstimationElementAmountForWildcardLocation";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "EditEstimationElementDescription";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "EditEstimationElementUnit";
        }
    }))({
        reflectSymbol: function () {
            return "EditEstimationElementSubprojectId";
        }
    }))({
        reflectSymbol: function () {
            return "EditEstimationElementRtDescription";
        }
    }))({
        reflectSymbol: function () {
            return "EditEstimationElementRtCode";
        }
    }))({
        reflectSymbol: function () {
            return "EditEstimationElementMemo";
        }
    }))({
        reflectSymbol: function () {
            return "EditEstimationElementGroupFlag";
        }
    }))({
        reflectSymbol: function () {
            return "EditEstimationElementCode";
        }
    }))({
        reflectSymbol: function () {
            return "EditEstimationElementAmountForSingleLocation";
        }
    }))({
        reflectSymbol: function () {
            return "AddEstimationElementAmountForWildcardLocation";
        }
    }))),
    readImpl: TofuJSON.readGenericSum(genericEditEstimationElementAction)(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "EditEstimationElementDescription";
        }
    })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "EditEstimationElementRtCode";
        }
    })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "EditEstimationElementRtDescription";
        }
    })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "EditEstimationElementUnit";
        }
    })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "EditEstimationElementMemo";
        }
    })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "EditEstimationElementSubprojectId";
        }
    })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "EditEstimationElementCode";
        }
    })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "AddEstimationElementAmountForWildcardLocation";
        }
    })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "EditEstimationElementAmountForSingleLocation";
        }
    })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "EditEstimationElementGroupFlag";
        }
    })()(Kishimen.genericSumToVariantArgArguments))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "EditEstimationElementAmountForSingleLocation";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "EditEstimationElementGroupFlag";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "AddEstimationElementAmountForWildcardLocation";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "EditEstimationElementGroupFlag";
        }
    }))({
        reflectSymbol: function () {
            return "EditEstimationElementAmountForSingleLocation";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "EditEstimationElementCode";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "EditEstimationElementGroupFlag";
        }
    }))({
        reflectSymbol: function () {
            return "EditEstimationElementAmountForSingleLocation";
        }
    }))({
        reflectSymbol: function () {
            return "AddEstimationElementAmountForWildcardLocation";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "EditEstimationElementSubprojectId";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "EditEstimationElementGroupFlag";
        }
    }))({
        reflectSymbol: function () {
            return "EditEstimationElementCode";
        }
    }))({
        reflectSymbol: function () {
            return "EditEstimationElementAmountForSingleLocation";
        }
    }))({
        reflectSymbol: function () {
            return "AddEstimationElementAmountForWildcardLocation";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "EditEstimationElementMemo";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "EditEstimationElementSubprojectId";
        }
    }))({
        reflectSymbol: function () {
            return "EditEstimationElementGroupFlag";
        }
    }))({
        reflectSymbol: function () {
            return "EditEstimationElementCode";
        }
    }))({
        reflectSymbol: function () {
            return "EditEstimationElementAmountForSingleLocation";
        }
    }))({
        reflectSymbol: function () {
            return "AddEstimationElementAmountForWildcardLocation";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "EditEstimationElementUnit";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "EditEstimationElementSubprojectId";
        }
    }))({
        reflectSymbol: function () {
            return "EditEstimationElementMemo";
        }
    }))({
        reflectSymbol: function () {
            return "EditEstimationElementGroupFlag";
        }
    }))({
        reflectSymbol: function () {
            return "EditEstimationElementCode";
        }
    }))({
        reflectSymbol: function () {
            return "EditEstimationElementAmountForSingleLocation";
        }
    }))({
        reflectSymbol: function () {
            return "AddEstimationElementAmountForWildcardLocation";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "EditEstimationElementRtDescription";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "EditEstimationElementUnit";
        }
    }))({
        reflectSymbol: function () {
            return "EditEstimationElementSubprojectId";
        }
    }))({
        reflectSymbol: function () {
            return "EditEstimationElementMemo";
        }
    }))({
        reflectSymbol: function () {
            return "EditEstimationElementGroupFlag";
        }
    }))({
        reflectSymbol: function () {
            return "EditEstimationElementCode";
        }
    }))({
        reflectSymbol: function () {
            return "EditEstimationElementAmountForSingleLocation";
        }
    }))({
        reflectSymbol: function () {
            return "AddEstimationElementAmountForWildcardLocation";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "EditEstimationElementRtCode";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "EditEstimationElementUnit";
        }
    }))({
        reflectSymbol: function () {
            return "EditEstimationElementSubprojectId";
        }
    }))({
        reflectSymbol: function () {
            return "EditEstimationElementRtDescription";
        }
    }))({
        reflectSymbol: function () {
            return "EditEstimationElementMemo";
        }
    }))({
        reflectSymbol: function () {
            return "EditEstimationElementGroupFlag";
        }
    }))({
        reflectSymbol: function () {
            return "EditEstimationElementCode";
        }
    }))({
        reflectSymbol: function () {
            return "EditEstimationElementAmountForSingleLocation";
        }
    }))({
        reflectSymbol: function () {
            return "AddEstimationElementAmountForWildcardLocation";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "EditEstimationElementDescription";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "EditEstimationElementUnit";
        }
    }))({
        reflectSymbol: function () {
            return "EditEstimationElementSubprojectId";
        }
    }))({
        reflectSymbol: function () {
            return "EditEstimationElementRtDescription";
        }
    }))({
        reflectSymbol: function () {
            return "EditEstimationElementRtCode";
        }
    }))({
        reflectSymbol: function () {
            return "EditEstimationElementMemo";
        }
    }))({
        reflectSymbol: function () {
            return "EditEstimationElementGroupFlag";
        }
    }))({
        reflectSymbol: function () {
            return "EditEstimationElementCode";
        }
    }))({
        reflectSymbol: function () {
            return "EditEstimationElementAmountForSingleLocation";
        }
    }))({
        reflectSymbol: function () {
            return "AddEstimationElementAmountForWildcardLocation";
        }
    })))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "AddEstimationElementAmountForWildcardLocation";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "amount";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "amount";
        }
    })(TofuJSON.readNumber)(TofuJSON.nilWriteForeignFields)()()()))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "EditEstimationElementAmountForSingleLocation";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "amount";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "elementLocationId";
        }
    })(Types_Element.tofuJSONElementLocationId)(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "amount";
        }
    })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "elementLocationId";
        }
    })(Types_Element.tofuJSONElementLocationId)(TofuJSON.nilWriteForeignFields)()()())()()()))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "EditEstimationElementCode";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "code";
        }
    })(Types_Element.tofuJSONElementCode)(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "code";
        }
    })(Types_Element.tofuJSONElementCode)(TofuJSON.nilWriteForeignFields)()()()))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "EditEstimationElementDescription";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "description";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "description";
        }
    })(TofuJSON.readString)(TofuJSON.nilWriteForeignFields)()()()))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "EditEstimationElementGroupFlag";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "groupFlag";
        }
    })(Types_Element.tofuJSONGroupFlag)(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "groupFlag";
        }
    })(Types_Element.tofuJSONGroupFlag)(TofuJSON.nilWriteForeignFields)()()()))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "EditEstimationElementMemo";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "memo";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "memo";
        }
    })(TofuJSON.readString)(TofuJSON.nilWriteForeignFields)()()()))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "EditEstimationElementRtCode";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "rtCode";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "rtCode";
        }
    })(TofuJSON.readString)(TofuJSON.nilWriteForeignFields)()()()))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "EditEstimationElementRtDescription";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "rtDescription";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "rtDescription";
        }
    })(TofuJSON.readString)(TofuJSON.nilWriteForeignFields)()()()))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "EditEstimationElementSubprojectId";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "subprojectId";
        }
    })(Types_Project.tofuJSONSubprojectId)(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "subprojectId";
        }
    })(Types_Project.tofuJSONSubprojectId)(TofuJSON.nilWriteForeignFields)()()()))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "EditEstimationElementUnit";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "unit";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "unit";
        }
    })(TofuJSON.readString)(TofuJSON.nilWriteForeignFields)()()()))()(TofuJSON.readVariantNil)))))))))))(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "AddEstimationElementAmountForWildcardLocation";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "amount";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "amount";
        }
    })(TofuJSON.readNumber)(TofuJSON.nilWriteForeignFields)()()()))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "EditEstimationElementAmountForSingleLocation";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "amount";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "elementLocationId";
        }
    })(Types_Element.tofuJSONElementLocationId)(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "amount";
        }
    })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "elementLocationId";
        }
    })(Types_Element.tofuJSONElementLocationId)(TofuJSON.nilWriteForeignFields)()()())()()()))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "EditEstimationElementCode";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "code";
        }
    })(Types_Element.tofuJSONElementCode)(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "code";
        }
    })(Types_Element.tofuJSONElementCode)(TofuJSON.nilWriteForeignFields)()()()))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "EditEstimationElementDescription";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "description";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "description";
        }
    })(TofuJSON.readString)(TofuJSON.nilWriteForeignFields)()()()))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "EditEstimationElementGroupFlag";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "groupFlag";
        }
    })(Types_Element.tofuJSONGroupFlag)(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "groupFlag";
        }
    })(Types_Element.tofuJSONGroupFlag)(TofuJSON.nilWriteForeignFields)()()()))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "EditEstimationElementMemo";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "memo";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "memo";
        }
    })(TofuJSON.readString)(TofuJSON.nilWriteForeignFields)()()()))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "EditEstimationElementRtCode";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "rtCode";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "rtCode";
        }
    })(TofuJSON.readString)(TofuJSON.nilWriteForeignFields)()()()))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "EditEstimationElementRtDescription";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "rtDescription";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "rtDescription";
        }
    })(TofuJSON.readString)(TofuJSON.nilWriteForeignFields)()()()))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "EditEstimationElementSubprojectId";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "subprojectId";
        }
    })(Types_Project.tofuJSONSubprojectId)(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "subprojectId";
        }
    })(Types_Project.tofuJSONSubprojectId)(TofuJSON.nilWriteForeignFields)()()()))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "EditEstimationElementUnit";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "unit";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "unit";
        }
    })(TofuJSON.readString)(TofuJSON.nilWriteForeignFields)()()()))()(TofuJSON.nilWriteForeignVariant)))))))))))
};
var genericEditEstimationComponentAction = {
    to: function (x) {
        if (x instanceof Data_Generic_Rep.Inl) {
            return new EditEstimationComponentDescription(x.value0);
        };
        if (x instanceof Data_Generic_Rep.Inr && x.value0 instanceof Data_Generic_Rep.Inl) {
            return new EditEstimationComponentCode(x.value0.value0);
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0 instanceof Data_Generic_Rep.Inl)) {
            return new EditEstimationComponentGroup(x.value0.value0.value0);
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0 instanceof Data_Generic_Rep.Inl))) {
            return new EditEstimationComponentCostGroup(x.value0.value0.value0.value0);
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inl)))) {
            return new EditEstimationComponentSubprojectId(x.value0.value0.value0.value0.value0);
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inl))))) {
            return new EditEstimationComponentUnit(x.value0.value0.value0.value0.value0.value0);
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inl)))))) {
            return new EditEstimationComponentChecked(x.value0.value0.value0.value0.value0.value0.value0);
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inl))))))) {
            return new EditEstimationComponentFlagged(x.value0.value0.value0.value0.value0.value0.value0.value0);
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inl)))))))) {
            return new EditEstimationComponentQuantity(x.value0.value0.value0.value0.value0.value0.value0.value0.value0);
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inl))))))))) {
            return new EditEstimationComponentMemo(x.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0);
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr))))))))) {
            return new EditEstimationComponentClass(x.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0);
        };
        throw new Error("Failed pattern match at Types.Estimation (line 660, column 1 - line 660, column 96): " + [ x.constructor.name ]);
    },
    from: function (x) {
        if (x instanceof EditEstimationComponentDescription) {
            return new Data_Generic_Rep.Inl(x.value0);
        };
        if (x instanceof EditEstimationComponentCode) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(x.value0));
        };
        if (x instanceof EditEstimationComponentGroup) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(x.value0)));
        };
        if (x instanceof EditEstimationComponentCostGroup) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(x.value0))));
        };
        if (x instanceof EditEstimationComponentSubprojectId) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(x.value0)))));
        };
        if (x instanceof EditEstimationComponentUnit) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(x.value0))))));
        };
        if (x instanceof EditEstimationComponentChecked) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(x.value0)))))));
        };
        if (x instanceof EditEstimationComponentFlagged) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(x.value0))))))));
        };
        if (x instanceof EditEstimationComponentQuantity) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(x.value0)))))))));
        };
        if (x instanceof EditEstimationComponentMemo) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(x.value0))))))))));
        };
        if (x instanceof EditEstimationComponentClass) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(x.value0))))))))));
        };
        throw new Error("Failed pattern match at Types.Estimation (line 660, column 1 - line 660, column 96): " + [ x.constructor.name ]);
    }
};
var tofuJSONEditEstimationComponentAction = {
    writeImpl: TofuJSON.writeGenericSum()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "EditEstimationComponentChecked";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "checked";
        }
    })(TofuJSON.readBoolean)(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "checked";
        }
    })(TofuJSON.readBoolean)(TofuJSON.nilWriteForeignFields)()()()))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "EditEstimationComponentClass";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "codeClass";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "codeClass";
        }
    })(TofuJSON.readString)(TofuJSON.nilWriteForeignFields)()()()))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "EditEstimationComponentCode";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "code";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "subprojectId";
        }
    })(TofuJSON.readMaybe(Types_Project.tofuJSONSubprojectId))(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "code";
        }
    })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "subprojectId";
        }
    })(TofuJSON.readMaybe(Types_Project.tofuJSONSubprojectId))(TofuJSON.nilWriteForeignFields)()()())()()()))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "EditEstimationComponentCostGroup";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "costGroupCode";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "costGroupCode";
        }
    })(TofuJSON.readString)(TofuJSON.nilWriteForeignFields)()()()))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "EditEstimationComponentDescription";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "description";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "description";
        }
    })(TofuJSON.readString)(TofuJSON.nilWriteForeignFields)()()()))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "EditEstimationComponentFlagged";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "flagged";
        }
    })(TofuJSON.readBoolean)(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "flagged";
        }
    })(TofuJSON.readBoolean)(TofuJSON.nilWriteForeignFields)()()()))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "EditEstimationComponentGroup";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "group";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "group";
        }
    })(TofuJSON.readString)(TofuJSON.nilWriteForeignFields)()()()))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "EditEstimationComponentMemo";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "memo";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "memo";
        }
    })(TofuJSON.readString)(TofuJSON.nilWriteForeignFields)()()()))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "EditEstimationComponentQuantity";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "quantity";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "quantity";
        }
    })(TofuJSON.readNumber)(TofuJSON.nilWriteForeignFields)()()()))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "EditEstimationComponentSubprojectId";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "code";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "subprojectId";
        }
    })(Types_Project.tofuJSONSubprojectId)(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "code";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "subprojectId";
        }
    })(Types_Project.tofuJSONSubprojectId)(TofuJSON.nilWriteForeignFields)()()())()()()))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "EditEstimationComponentUnit";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "unit";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "unit";
        }
    })(TofuJSON.readString)(TofuJSON.nilWriteForeignFields)()()()))()(TofuJSON.readVariantNil))))))))))))(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "EditEstimationComponentChecked";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "checked";
        }
    })(TofuJSON.readBoolean)(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "checked";
        }
    })(TofuJSON.readBoolean)(TofuJSON.nilWriteForeignFields)()()()))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "EditEstimationComponentClass";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "codeClass";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "codeClass";
        }
    })(TofuJSON.readString)(TofuJSON.nilWriteForeignFields)()()()))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "EditEstimationComponentCode";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "code";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "subprojectId";
        }
    })(TofuJSON.readMaybe(Types_Project.tofuJSONSubprojectId))(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "code";
        }
    })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "subprojectId";
        }
    })(TofuJSON.readMaybe(Types_Project.tofuJSONSubprojectId))(TofuJSON.nilWriteForeignFields)()()())()()()))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "EditEstimationComponentCostGroup";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "costGroupCode";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "costGroupCode";
        }
    })(TofuJSON.readString)(TofuJSON.nilWriteForeignFields)()()()))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "EditEstimationComponentDescription";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "description";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "description";
        }
    })(TofuJSON.readString)(TofuJSON.nilWriteForeignFields)()()()))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "EditEstimationComponentFlagged";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "flagged";
        }
    })(TofuJSON.readBoolean)(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "flagged";
        }
    })(TofuJSON.readBoolean)(TofuJSON.nilWriteForeignFields)()()()))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "EditEstimationComponentGroup";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "group";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "group";
        }
    })(TofuJSON.readString)(TofuJSON.nilWriteForeignFields)()()()))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "EditEstimationComponentMemo";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "memo";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "memo";
        }
    })(TofuJSON.readString)(TofuJSON.nilWriteForeignFields)()()()))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "EditEstimationComponentQuantity";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "quantity";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "quantity";
        }
    })(TofuJSON.readNumber)(TofuJSON.nilWriteForeignFields)()()()))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "EditEstimationComponentSubprojectId";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "code";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "subprojectId";
        }
    })(Types_Project.tofuJSONSubprojectId)(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "code";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "subprojectId";
        }
    })(Types_Project.tofuJSONSubprojectId)(TofuJSON.nilWriteForeignFields)()()())()()()))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "EditEstimationComponentUnit";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "unit";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "unit";
        }
    })(TofuJSON.readString)(TofuJSON.nilWriteForeignFields)()()()))()(TofuJSON.nilWriteForeignVariant))))))))))))(genericEditEstimationComponentAction)(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "EditEstimationComponentDescription";
        }
    })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "EditEstimationComponentCode";
        }
    })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "EditEstimationComponentGroup";
        }
    })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "EditEstimationComponentCostGroup";
        }
    })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "EditEstimationComponentSubprojectId";
        }
    })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "EditEstimationComponentUnit";
        }
    })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "EditEstimationComponentChecked";
        }
    })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "EditEstimationComponentFlagged";
        }
    })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "EditEstimationComponentQuantity";
        }
    })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "EditEstimationComponentMemo";
        }
    })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "EditEstimationComponentClass";
        }
    })()(Kishimen.genericSumToVariantArgArguments))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "EditEstimationComponentMemo";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "EditEstimationComponentClass";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "EditEstimationComponentQuantity";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "EditEstimationComponentMemo";
        }
    }))({
        reflectSymbol: function () {
            return "EditEstimationComponentClass";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "EditEstimationComponentFlagged";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "EditEstimationComponentQuantity";
        }
    }))({
        reflectSymbol: function () {
            return "EditEstimationComponentMemo";
        }
    }))({
        reflectSymbol: function () {
            return "EditEstimationComponentClass";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "EditEstimationComponentChecked";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "EditEstimationComponentQuantity";
        }
    }))({
        reflectSymbol: function () {
            return "EditEstimationComponentMemo";
        }
    }))({
        reflectSymbol: function () {
            return "EditEstimationComponentFlagged";
        }
    }))({
        reflectSymbol: function () {
            return "EditEstimationComponentClass";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "EditEstimationComponentUnit";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "EditEstimationComponentQuantity";
        }
    }))({
        reflectSymbol: function () {
            return "EditEstimationComponentMemo";
        }
    }))({
        reflectSymbol: function () {
            return "EditEstimationComponentFlagged";
        }
    }))({
        reflectSymbol: function () {
            return "EditEstimationComponentClass";
        }
    }))({
        reflectSymbol: function () {
            return "EditEstimationComponentChecked";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "EditEstimationComponentSubprojectId";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "EditEstimationComponentUnit";
        }
    }))({
        reflectSymbol: function () {
            return "EditEstimationComponentQuantity";
        }
    }))({
        reflectSymbol: function () {
            return "EditEstimationComponentMemo";
        }
    }))({
        reflectSymbol: function () {
            return "EditEstimationComponentFlagged";
        }
    }))({
        reflectSymbol: function () {
            return "EditEstimationComponentClass";
        }
    }))({
        reflectSymbol: function () {
            return "EditEstimationComponentChecked";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "EditEstimationComponentCostGroup";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "EditEstimationComponentUnit";
        }
    }))({
        reflectSymbol: function () {
            return "EditEstimationComponentSubprojectId";
        }
    }))({
        reflectSymbol: function () {
            return "EditEstimationComponentQuantity";
        }
    }))({
        reflectSymbol: function () {
            return "EditEstimationComponentMemo";
        }
    }))({
        reflectSymbol: function () {
            return "EditEstimationComponentFlagged";
        }
    }))({
        reflectSymbol: function () {
            return "EditEstimationComponentClass";
        }
    }))({
        reflectSymbol: function () {
            return "EditEstimationComponentChecked";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "EditEstimationComponentGroup";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "EditEstimationComponentUnit";
        }
    }))({
        reflectSymbol: function () {
            return "EditEstimationComponentSubprojectId";
        }
    }))({
        reflectSymbol: function () {
            return "EditEstimationComponentQuantity";
        }
    }))({
        reflectSymbol: function () {
            return "EditEstimationComponentMemo";
        }
    }))({
        reflectSymbol: function () {
            return "EditEstimationComponentFlagged";
        }
    }))({
        reflectSymbol: function () {
            return "EditEstimationComponentCostGroup";
        }
    }))({
        reflectSymbol: function () {
            return "EditEstimationComponentClass";
        }
    }))({
        reflectSymbol: function () {
            return "EditEstimationComponentChecked";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "EditEstimationComponentCode";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "EditEstimationComponentUnit";
        }
    }))({
        reflectSymbol: function () {
            return "EditEstimationComponentSubprojectId";
        }
    }))({
        reflectSymbol: function () {
            return "EditEstimationComponentQuantity";
        }
    }))({
        reflectSymbol: function () {
            return "EditEstimationComponentMemo";
        }
    }))({
        reflectSymbol: function () {
            return "EditEstimationComponentGroup";
        }
    }))({
        reflectSymbol: function () {
            return "EditEstimationComponentFlagged";
        }
    }))({
        reflectSymbol: function () {
            return "EditEstimationComponentCostGroup";
        }
    }))({
        reflectSymbol: function () {
            return "EditEstimationComponentClass";
        }
    }))({
        reflectSymbol: function () {
            return "EditEstimationComponentChecked";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "EditEstimationComponentDescription";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "EditEstimationComponentUnit";
        }
    }))({
        reflectSymbol: function () {
            return "EditEstimationComponentSubprojectId";
        }
    }))({
        reflectSymbol: function () {
            return "EditEstimationComponentQuantity";
        }
    }))({
        reflectSymbol: function () {
            return "EditEstimationComponentMemo";
        }
    }))({
        reflectSymbol: function () {
            return "EditEstimationComponentGroup";
        }
    }))({
        reflectSymbol: function () {
            return "EditEstimationComponentFlagged";
        }
    }))({
        reflectSymbol: function () {
            return "EditEstimationComponentCostGroup";
        }
    }))({
        reflectSymbol: function () {
            return "EditEstimationComponentCode";
        }
    }))({
        reflectSymbol: function () {
            return "EditEstimationComponentClass";
        }
    }))({
        reflectSymbol: function () {
            return "EditEstimationComponentChecked";
        }
    }))),
    readImpl: TofuJSON.readGenericSum(genericEditEstimationComponentAction)(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "EditEstimationComponentDescription";
        }
    })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "EditEstimationComponentCode";
        }
    })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "EditEstimationComponentGroup";
        }
    })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "EditEstimationComponentCostGroup";
        }
    })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "EditEstimationComponentSubprojectId";
        }
    })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "EditEstimationComponentUnit";
        }
    })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "EditEstimationComponentChecked";
        }
    })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "EditEstimationComponentFlagged";
        }
    })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "EditEstimationComponentQuantity";
        }
    })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "EditEstimationComponentMemo";
        }
    })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "EditEstimationComponentClass";
        }
    })()(Kishimen.genericSumToVariantArgArguments))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "EditEstimationComponentMemo";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "EditEstimationComponentClass";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "EditEstimationComponentQuantity";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "EditEstimationComponentMemo";
        }
    }))({
        reflectSymbol: function () {
            return "EditEstimationComponentClass";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "EditEstimationComponentFlagged";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "EditEstimationComponentQuantity";
        }
    }))({
        reflectSymbol: function () {
            return "EditEstimationComponentMemo";
        }
    }))({
        reflectSymbol: function () {
            return "EditEstimationComponentClass";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "EditEstimationComponentChecked";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "EditEstimationComponentQuantity";
        }
    }))({
        reflectSymbol: function () {
            return "EditEstimationComponentMemo";
        }
    }))({
        reflectSymbol: function () {
            return "EditEstimationComponentFlagged";
        }
    }))({
        reflectSymbol: function () {
            return "EditEstimationComponentClass";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "EditEstimationComponentUnit";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "EditEstimationComponentQuantity";
        }
    }))({
        reflectSymbol: function () {
            return "EditEstimationComponentMemo";
        }
    }))({
        reflectSymbol: function () {
            return "EditEstimationComponentFlagged";
        }
    }))({
        reflectSymbol: function () {
            return "EditEstimationComponentClass";
        }
    }))({
        reflectSymbol: function () {
            return "EditEstimationComponentChecked";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "EditEstimationComponentSubprojectId";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "EditEstimationComponentUnit";
        }
    }))({
        reflectSymbol: function () {
            return "EditEstimationComponentQuantity";
        }
    }))({
        reflectSymbol: function () {
            return "EditEstimationComponentMemo";
        }
    }))({
        reflectSymbol: function () {
            return "EditEstimationComponentFlagged";
        }
    }))({
        reflectSymbol: function () {
            return "EditEstimationComponentClass";
        }
    }))({
        reflectSymbol: function () {
            return "EditEstimationComponentChecked";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "EditEstimationComponentCostGroup";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "EditEstimationComponentUnit";
        }
    }))({
        reflectSymbol: function () {
            return "EditEstimationComponentSubprojectId";
        }
    }))({
        reflectSymbol: function () {
            return "EditEstimationComponentQuantity";
        }
    }))({
        reflectSymbol: function () {
            return "EditEstimationComponentMemo";
        }
    }))({
        reflectSymbol: function () {
            return "EditEstimationComponentFlagged";
        }
    }))({
        reflectSymbol: function () {
            return "EditEstimationComponentClass";
        }
    }))({
        reflectSymbol: function () {
            return "EditEstimationComponentChecked";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "EditEstimationComponentGroup";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "EditEstimationComponentUnit";
        }
    }))({
        reflectSymbol: function () {
            return "EditEstimationComponentSubprojectId";
        }
    }))({
        reflectSymbol: function () {
            return "EditEstimationComponentQuantity";
        }
    }))({
        reflectSymbol: function () {
            return "EditEstimationComponentMemo";
        }
    }))({
        reflectSymbol: function () {
            return "EditEstimationComponentFlagged";
        }
    }))({
        reflectSymbol: function () {
            return "EditEstimationComponentCostGroup";
        }
    }))({
        reflectSymbol: function () {
            return "EditEstimationComponentClass";
        }
    }))({
        reflectSymbol: function () {
            return "EditEstimationComponentChecked";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "EditEstimationComponentCode";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "EditEstimationComponentUnit";
        }
    }))({
        reflectSymbol: function () {
            return "EditEstimationComponentSubprojectId";
        }
    }))({
        reflectSymbol: function () {
            return "EditEstimationComponentQuantity";
        }
    }))({
        reflectSymbol: function () {
            return "EditEstimationComponentMemo";
        }
    }))({
        reflectSymbol: function () {
            return "EditEstimationComponentGroup";
        }
    }))({
        reflectSymbol: function () {
            return "EditEstimationComponentFlagged";
        }
    }))({
        reflectSymbol: function () {
            return "EditEstimationComponentCostGroup";
        }
    }))({
        reflectSymbol: function () {
            return "EditEstimationComponentClass";
        }
    }))({
        reflectSymbol: function () {
            return "EditEstimationComponentChecked";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "EditEstimationComponentDescription";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "EditEstimationComponentUnit";
        }
    }))({
        reflectSymbol: function () {
            return "EditEstimationComponentSubprojectId";
        }
    }))({
        reflectSymbol: function () {
            return "EditEstimationComponentQuantity";
        }
    }))({
        reflectSymbol: function () {
            return "EditEstimationComponentMemo";
        }
    }))({
        reflectSymbol: function () {
            return "EditEstimationComponentGroup";
        }
    }))({
        reflectSymbol: function () {
            return "EditEstimationComponentFlagged";
        }
    }))({
        reflectSymbol: function () {
            return "EditEstimationComponentCostGroup";
        }
    }))({
        reflectSymbol: function () {
            return "EditEstimationComponentCode";
        }
    }))({
        reflectSymbol: function () {
            return "EditEstimationComponentClass";
        }
    }))({
        reflectSymbol: function () {
            return "EditEstimationComponentChecked";
        }
    })))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "EditEstimationComponentChecked";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "checked";
        }
    })(TofuJSON.readBoolean)(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "checked";
        }
    })(TofuJSON.readBoolean)(TofuJSON.nilWriteForeignFields)()()()))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "EditEstimationComponentClass";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "codeClass";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "codeClass";
        }
    })(TofuJSON.readString)(TofuJSON.nilWriteForeignFields)()()()))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "EditEstimationComponentCode";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "code";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "subprojectId";
        }
    })(TofuJSON.readMaybe(Types_Project.tofuJSONSubprojectId))(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "code";
        }
    })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "subprojectId";
        }
    })(TofuJSON.readMaybe(Types_Project.tofuJSONSubprojectId))(TofuJSON.nilWriteForeignFields)()()())()()()))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "EditEstimationComponentCostGroup";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "costGroupCode";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "costGroupCode";
        }
    })(TofuJSON.readString)(TofuJSON.nilWriteForeignFields)()()()))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "EditEstimationComponentDescription";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "description";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "description";
        }
    })(TofuJSON.readString)(TofuJSON.nilWriteForeignFields)()()()))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "EditEstimationComponentFlagged";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "flagged";
        }
    })(TofuJSON.readBoolean)(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "flagged";
        }
    })(TofuJSON.readBoolean)(TofuJSON.nilWriteForeignFields)()()()))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "EditEstimationComponentGroup";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "group";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "group";
        }
    })(TofuJSON.readString)(TofuJSON.nilWriteForeignFields)()()()))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "EditEstimationComponentMemo";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "memo";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "memo";
        }
    })(TofuJSON.readString)(TofuJSON.nilWriteForeignFields)()()()))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "EditEstimationComponentQuantity";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "quantity";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "quantity";
        }
    })(TofuJSON.readNumber)(TofuJSON.nilWriteForeignFields)()()()))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "EditEstimationComponentSubprojectId";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "code";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "subprojectId";
        }
    })(Types_Project.tofuJSONSubprojectId)(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "code";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "subprojectId";
        }
    })(Types_Project.tofuJSONSubprojectId)(TofuJSON.nilWriteForeignFields)()()())()()()))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "EditEstimationComponentUnit";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "unit";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "unit";
        }
    })(TofuJSON.readString)(TofuJSON.nilWriteForeignFields)()()()))()(TofuJSON.readVariantNil))))))))))))(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "EditEstimationComponentChecked";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "checked";
        }
    })(TofuJSON.readBoolean)(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "checked";
        }
    })(TofuJSON.readBoolean)(TofuJSON.nilWriteForeignFields)()()()))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "EditEstimationComponentClass";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "codeClass";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "codeClass";
        }
    })(TofuJSON.readString)(TofuJSON.nilWriteForeignFields)()()()))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "EditEstimationComponentCode";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "code";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "subprojectId";
        }
    })(TofuJSON.readMaybe(Types_Project.tofuJSONSubprojectId))(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "code";
        }
    })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "subprojectId";
        }
    })(TofuJSON.readMaybe(Types_Project.tofuJSONSubprojectId))(TofuJSON.nilWriteForeignFields)()()())()()()))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "EditEstimationComponentCostGroup";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "costGroupCode";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "costGroupCode";
        }
    })(TofuJSON.readString)(TofuJSON.nilWriteForeignFields)()()()))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "EditEstimationComponentDescription";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "description";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "description";
        }
    })(TofuJSON.readString)(TofuJSON.nilWriteForeignFields)()()()))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "EditEstimationComponentFlagged";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "flagged";
        }
    })(TofuJSON.readBoolean)(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "flagged";
        }
    })(TofuJSON.readBoolean)(TofuJSON.nilWriteForeignFields)()()()))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "EditEstimationComponentGroup";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "group";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "group";
        }
    })(TofuJSON.readString)(TofuJSON.nilWriteForeignFields)()()()))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "EditEstimationComponentMemo";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "memo";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "memo";
        }
    })(TofuJSON.readString)(TofuJSON.nilWriteForeignFields)()()()))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "EditEstimationComponentQuantity";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "quantity";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "quantity";
        }
    })(TofuJSON.readNumber)(TofuJSON.nilWriteForeignFields)()()()))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "EditEstimationComponentSubprojectId";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "code";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "subprojectId";
        }
    })(Types_Project.tofuJSONSubprojectId)(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "code";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "subprojectId";
        }
    })(Types_Project.tofuJSONSubprojectId)(TofuJSON.nilWriteForeignFields)()()())()()()))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "EditEstimationComponentUnit";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "unit";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "unit";
        }
    })(TofuJSON.readString)(TofuJSON.nilWriteForeignFields)()()()))()(TofuJSON.nilWriteForeignVariant))))))))))))
};
var tofuJSONEstimationComponentAction = {
    writeImpl: TofuJSON.writeGenericSum()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "AddEstimationComponent";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "code";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "componentType";
        }
    })(Types_Component.tofuJSONComponentType)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "description";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "memo";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "quantity";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "subProjectId";
        }
    })(Types_Project.tofuJSONSubprojectId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "unit";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.readFieldsNil)()())()())()())()())()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "code";
        }
    })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "componentType";
        }
    })(Types_Component.tofuJSONComponentType)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "description";
        }
    })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "memo";
        }
    })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "quantity";
        }
    })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "subProjectId";
        }
    })(Types_Project.tofuJSONSubprojectId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "unit";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())()()())()()())()()()))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "CopyComponentAndApplyEditAction";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "editActionParams";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "componentId";
        }
    })(Types_Component.tofuJSONComponentId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "editAction";
        }
    })(tofuJSONEditEstimationComponentAction)(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "componentId";
        }
    })(Types_Component.tofuJSONComponentId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "editAction";
        }
    })(tofuJSONEditEstimationComponentAction)(TofuJSON.nilWriteForeignFields)()()())()()()))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "elementId";
        }
    })(Types_Element.tofuJSONElementId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "elementSpecId";
        }
    })(Types_Newtypes.tofuJSONElementSpecId)(TofuJSON.readFieldsNil)()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "editActionParams";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "componentId";
        }
    })(Types_Component.tofuJSONComponentId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "editAction";
        }
    })(tofuJSONEditEstimationComponentAction)(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "componentId";
        }
    })(Types_Component.tofuJSONComponentId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "editAction";
        }
    })(tofuJSONEditEstimationComponentAction)(TofuJSON.nilWriteForeignFields)()()())()()()))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "elementId";
        }
    })(Types_Element.tofuJSONElementId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "elementSpecId";
        }
    })(Types_Newtypes.tofuJSONElementSpecId)(TofuJSON.nilWriteForeignFields)()()())()()())()()()))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "CopyEstimationComponentsToProject";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "allowDuplicates";
        }
    })(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "copyOperationType";
        }
    })(tofuJSONEstimationComponentCopyOperationType)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "estimationComponentIds";
        }
    })(TofuJSON.readArray(Types_Component.tofuJSONComponentId))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "targetProjectId";
        }
    })(Types_Project.tofuJSONProjectId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "targetSubprojectId";
        }
    })(Types_Project.tofuJSONSubprojectId)(TofuJSON.readFieldsNil)()())()())()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "allowDuplicates";
        }
    })(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "copyOperationType";
        }
    })(tofuJSONEstimationComponentCopyOperationType)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "estimationComponentIds";
        }
    })(TofuJSON.readArray(Types_Component.tofuJSONComponentId))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "targetProjectId";
        }
    })(Types_Project.tofuJSONProjectId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "targetSubprojectId";
        }
    })(Types_Project.tofuJSONSubprojectId)(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())()()()))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "CopyMultiLinkedComponentAndApplyEditAction";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "editActionParams";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "componentId";
        }
    })(Types_Component.tofuJSONComponentId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "editAction";
        }
    })(tofuJSONEditEstimationComponentAction)(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "componentId";
        }
    })(Types_Component.tofuJSONComponentId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "editAction";
        }
    })(tofuJSONEditEstimationComponentAction)(TofuJSON.nilWriteForeignFields)()()())()()()))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "elementId";
        }
    })(Types_Element.tofuJSONElementId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "elementSpecId";
        }
    })(Types_Newtypes.tofuJSONElementSpecId)(TofuJSON.readFieldsNil)()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "editActionParams";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "componentId";
        }
    })(Types_Component.tofuJSONComponentId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "editAction";
        }
    })(tofuJSONEditEstimationComponentAction)(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "componentId";
        }
    })(Types_Component.tofuJSONComponentId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "editAction";
        }
    })(tofuJSONEditEstimationComponentAction)(TofuJSON.nilWriteForeignFields)()()())()()()))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "elementId";
        }
    })(Types_Element.tofuJSONElementId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "elementSpecId";
        }
    })(Types_Newtypes.tofuJSONElementSpecId)(TofuJSON.nilWriteForeignFields)()()())()()())()()()))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "DeleteEstimationComponents";
        }
    })(TofuJSON.readArray(Types_Component.tofuJSONComponentId))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "EditEstimationComponent";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "componentId";
        }
    })(Types_Component.tofuJSONComponentId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "editAction";
        }
    })(tofuJSONEditEstimationComponentAction)(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "componentId";
        }
    })(Types_Component.tofuJSONComponentId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "editAction";
        }
    })(tofuJSONEditEstimationComponentAction)(TofuJSON.nilWriteForeignFields)()()())()()()))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "EditEstimationComponents";
        }
    })(TofuJSON.readArray(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "componentId";
        }
    })(Types_Component.tofuJSONComponentId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "editAction";
        }
    })(tofuJSONEditEstimationComponentAction)(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "componentId";
        }
    })(Types_Component.tofuJSONComponentId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "editAction";
        }
    })(tofuJSONEditEstimationComponentAction)(TofuJSON.nilWriteForeignFields)()()())()()())))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "ReplaceEstimationComponent";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "projectId";
        }
    })(Types_Project.tofuJSONProjectId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "referenceComponentId";
        }
    })(Types_Component.tofuJSONComponentId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "targetComponentId";
        }
    })(Types_Component.tofuJSONComponentId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "targetElementId";
        }
    })(TofuJSON.readMaybe(Types_Element.tofuJSONElementId))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "targetElementSpecId";
        }
    })(TofuJSON.readMaybe(Types_Newtypes.tofuJSONElementSpecId))(TofuJSON.readFieldsNil)()())()())()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "projectId";
        }
    })(Types_Project.tofuJSONProjectId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "referenceComponentId";
        }
    })(Types_Component.tofuJSONComponentId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "targetComponentId";
        }
    })(Types_Component.tofuJSONComponentId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "targetElementId";
        }
    })(TofuJSON.readMaybe(Types_Element.tofuJSONElementId))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "targetElementSpecId";
        }
    })(TofuJSON.readMaybe(Types_Newtypes.tofuJSONElementSpecId))(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())()()()))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "ResetEstimationLocations";
        }
    })(TofuJSON.readArray(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "componentId";
        }
    })(Types_Component.tofuJSONComponentId)(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "componentId";
        }
    })(Types_Component.tofuJSONComponentId)(TofuJSON.nilWriteForeignFields)()()())))()(TofuJSON.readVariantNil))))))))))(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "AddEstimationComponent";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "code";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "componentType";
        }
    })(Types_Component.tofuJSONComponentType)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "description";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "memo";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "quantity";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "subProjectId";
        }
    })(Types_Project.tofuJSONSubprojectId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "unit";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.readFieldsNil)()())()())()())()())()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "code";
        }
    })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "componentType";
        }
    })(Types_Component.tofuJSONComponentType)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "description";
        }
    })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "memo";
        }
    })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "quantity";
        }
    })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "subProjectId";
        }
    })(Types_Project.tofuJSONSubprojectId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "unit";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())()()())()()())()()()))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "CopyComponentAndApplyEditAction";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "editActionParams";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "componentId";
        }
    })(Types_Component.tofuJSONComponentId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "editAction";
        }
    })(tofuJSONEditEstimationComponentAction)(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "componentId";
        }
    })(Types_Component.tofuJSONComponentId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "editAction";
        }
    })(tofuJSONEditEstimationComponentAction)(TofuJSON.nilWriteForeignFields)()()())()()()))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "elementId";
        }
    })(Types_Element.tofuJSONElementId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "elementSpecId";
        }
    })(Types_Newtypes.tofuJSONElementSpecId)(TofuJSON.readFieldsNil)()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "editActionParams";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "componentId";
        }
    })(Types_Component.tofuJSONComponentId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "editAction";
        }
    })(tofuJSONEditEstimationComponentAction)(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "componentId";
        }
    })(Types_Component.tofuJSONComponentId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "editAction";
        }
    })(tofuJSONEditEstimationComponentAction)(TofuJSON.nilWriteForeignFields)()()())()()()))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "elementId";
        }
    })(Types_Element.tofuJSONElementId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "elementSpecId";
        }
    })(Types_Newtypes.tofuJSONElementSpecId)(TofuJSON.nilWriteForeignFields)()()())()()())()()()))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "CopyEstimationComponentsToProject";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "allowDuplicates";
        }
    })(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "copyOperationType";
        }
    })(tofuJSONEstimationComponentCopyOperationType)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "estimationComponentIds";
        }
    })(TofuJSON.readArray(Types_Component.tofuJSONComponentId))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "targetProjectId";
        }
    })(Types_Project.tofuJSONProjectId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "targetSubprojectId";
        }
    })(Types_Project.tofuJSONSubprojectId)(TofuJSON.readFieldsNil)()())()())()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "allowDuplicates";
        }
    })(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "copyOperationType";
        }
    })(tofuJSONEstimationComponentCopyOperationType)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "estimationComponentIds";
        }
    })(TofuJSON.readArray(Types_Component.tofuJSONComponentId))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "targetProjectId";
        }
    })(Types_Project.tofuJSONProjectId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "targetSubprojectId";
        }
    })(Types_Project.tofuJSONSubprojectId)(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())()()()))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "CopyMultiLinkedComponentAndApplyEditAction";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "editActionParams";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "componentId";
        }
    })(Types_Component.tofuJSONComponentId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "editAction";
        }
    })(tofuJSONEditEstimationComponentAction)(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "componentId";
        }
    })(Types_Component.tofuJSONComponentId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "editAction";
        }
    })(tofuJSONEditEstimationComponentAction)(TofuJSON.nilWriteForeignFields)()()())()()()))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "elementId";
        }
    })(Types_Element.tofuJSONElementId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "elementSpecId";
        }
    })(Types_Newtypes.tofuJSONElementSpecId)(TofuJSON.readFieldsNil)()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "editActionParams";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "componentId";
        }
    })(Types_Component.tofuJSONComponentId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "editAction";
        }
    })(tofuJSONEditEstimationComponentAction)(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "componentId";
        }
    })(Types_Component.tofuJSONComponentId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "editAction";
        }
    })(tofuJSONEditEstimationComponentAction)(TofuJSON.nilWriteForeignFields)()()())()()()))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "elementId";
        }
    })(Types_Element.tofuJSONElementId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "elementSpecId";
        }
    })(Types_Newtypes.tofuJSONElementSpecId)(TofuJSON.nilWriteForeignFields)()()())()()())()()()))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "DeleteEstimationComponents";
        }
    })(TofuJSON.readArray(Types_Component.tofuJSONComponentId))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "EditEstimationComponent";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "componentId";
        }
    })(Types_Component.tofuJSONComponentId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "editAction";
        }
    })(tofuJSONEditEstimationComponentAction)(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "componentId";
        }
    })(Types_Component.tofuJSONComponentId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "editAction";
        }
    })(tofuJSONEditEstimationComponentAction)(TofuJSON.nilWriteForeignFields)()()())()()()))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "EditEstimationComponents";
        }
    })(TofuJSON.readArray(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "componentId";
        }
    })(Types_Component.tofuJSONComponentId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "editAction";
        }
    })(tofuJSONEditEstimationComponentAction)(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "componentId";
        }
    })(Types_Component.tofuJSONComponentId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "editAction";
        }
    })(tofuJSONEditEstimationComponentAction)(TofuJSON.nilWriteForeignFields)()()())()()())))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "ReplaceEstimationComponent";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "projectId";
        }
    })(Types_Project.tofuJSONProjectId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "referenceComponentId";
        }
    })(Types_Component.tofuJSONComponentId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "targetComponentId";
        }
    })(Types_Component.tofuJSONComponentId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "targetElementId";
        }
    })(TofuJSON.readMaybe(Types_Element.tofuJSONElementId))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "targetElementSpecId";
        }
    })(TofuJSON.readMaybe(Types_Newtypes.tofuJSONElementSpecId))(TofuJSON.readFieldsNil)()())()())()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "projectId";
        }
    })(Types_Project.tofuJSONProjectId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "referenceComponentId";
        }
    })(Types_Component.tofuJSONComponentId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "targetComponentId";
        }
    })(Types_Component.tofuJSONComponentId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "targetElementId";
        }
    })(TofuJSON.readMaybe(Types_Element.tofuJSONElementId))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "targetElementSpecId";
        }
    })(TofuJSON.readMaybe(Types_Newtypes.tofuJSONElementSpecId))(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())()()()))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "ResetEstimationLocations";
        }
    })(TofuJSON.readArray(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "componentId";
        }
    })(Types_Component.tofuJSONComponentId)(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "componentId";
        }
    })(Types_Component.tofuJSONComponentId)(TofuJSON.nilWriteForeignFields)()()())))()(TofuJSON.nilWriteForeignVariant))))))))))(genericEstimationComponentAction)(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "AddEstimationComponent";
        }
    })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "EditEstimationComponent";
        }
    })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "EditEstimationComponents";
        }
    })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "ResetEstimationLocations";
        }
    })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "CopyEstimationComponentsToProject";
        }
    })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "DeleteEstimationComponents";
        }
    })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "ReplaceEstimationComponent";
        }
    })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "CopyComponentAndApplyEditAction";
        }
    })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "CopyMultiLinkedComponentAndApplyEditAction";
        }
    })()(Kishimen.genericSumToVariantArgArguments))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "CopyComponentAndApplyEditAction";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "CopyMultiLinkedComponentAndApplyEditAction";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "ReplaceEstimationComponent";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "CopyMultiLinkedComponentAndApplyEditAction";
        }
    }))({
        reflectSymbol: function () {
            return "CopyComponentAndApplyEditAction";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "DeleteEstimationComponents";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "ReplaceEstimationComponent";
        }
    }))({
        reflectSymbol: function () {
            return "CopyMultiLinkedComponentAndApplyEditAction";
        }
    }))({
        reflectSymbol: function () {
            return "CopyComponentAndApplyEditAction";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "CopyEstimationComponentsToProject";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "ReplaceEstimationComponent";
        }
    }))({
        reflectSymbol: function () {
            return "DeleteEstimationComponents";
        }
    }))({
        reflectSymbol: function () {
            return "CopyMultiLinkedComponentAndApplyEditAction";
        }
    }))({
        reflectSymbol: function () {
            return "CopyComponentAndApplyEditAction";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "ResetEstimationLocations";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "ReplaceEstimationComponent";
        }
    }))({
        reflectSymbol: function () {
            return "DeleteEstimationComponents";
        }
    }))({
        reflectSymbol: function () {
            return "CopyMultiLinkedComponentAndApplyEditAction";
        }
    }))({
        reflectSymbol: function () {
            return "CopyEstimationComponentsToProject";
        }
    }))({
        reflectSymbol: function () {
            return "CopyComponentAndApplyEditAction";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "EditEstimationComponents";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "ResetEstimationLocations";
        }
    }))({
        reflectSymbol: function () {
            return "ReplaceEstimationComponent";
        }
    }))({
        reflectSymbol: function () {
            return "DeleteEstimationComponents";
        }
    }))({
        reflectSymbol: function () {
            return "CopyMultiLinkedComponentAndApplyEditAction";
        }
    }))({
        reflectSymbol: function () {
            return "CopyEstimationComponentsToProject";
        }
    }))({
        reflectSymbol: function () {
            return "CopyComponentAndApplyEditAction";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "EditEstimationComponent";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "ResetEstimationLocations";
        }
    }))({
        reflectSymbol: function () {
            return "ReplaceEstimationComponent";
        }
    }))({
        reflectSymbol: function () {
            return "EditEstimationComponents";
        }
    }))({
        reflectSymbol: function () {
            return "DeleteEstimationComponents";
        }
    }))({
        reflectSymbol: function () {
            return "CopyMultiLinkedComponentAndApplyEditAction";
        }
    }))({
        reflectSymbol: function () {
            return "CopyEstimationComponentsToProject";
        }
    }))({
        reflectSymbol: function () {
            return "CopyComponentAndApplyEditAction";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "AddEstimationComponent";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "ResetEstimationLocations";
        }
    }))({
        reflectSymbol: function () {
            return "ReplaceEstimationComponent";
        }
    }))({
        reflectSymbol: function () {
            return "EditEstimationComponents";
        }
    }))({
        reflectSymbol: function () {
            return "EditEstimationComponent";
        }
    }))({
        reflectSymbol: function () {
            return "DeleteEstimationComponents";
        }
    }))({
        reflectSymbol: function () {
            return "CopyMultiLinkedComponentAndApplyEditAction";
        }
    }))({
        reflectSymbol: function () {
            return "CopyEstimationComponentsToProject";
        }
    }))({
        reflectSymbol: function () {
            return "CopyComponentAndApplyEditAction";
        }
    }))),
    readImpl: TofuJSON.readGenericSum(genericEstimationComponentAction)(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "AddEstimationComponent";
        }
    })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "EditEstimationComponent";
        }
    })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "EditEstimationComponents";
        }
    })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "ResetEstimationLocations";
        }
    })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "CopyEstimationComponentsToProject";
        }
    })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "DeleteEstimationComponents";
        }
    })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "ReplaceEstimationComponent";
        }
    })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "CopyComponentAndApplyEditAction";
        }
    })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "CopyMultiLinkedComponentAndApplyEditAction";
        }
    })()(Kishimen.genericSumToVariantArgArguments))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "CopyComponentAndApplyEditAction";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "CopyMultiLinkedComponentAndApplyEditAction";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "ReplaceEstimationComponent";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "CopyMultiLinkedComponentAndApplyEditAction";
        }
    }))({
        reflectSymbol: function () {
            return "CopyComponentAndApplyEditAction";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "DeleteEstimationComponents";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "ReplaceEstimationComponent";
        }
    }))({
        reflectSymbol: function () {
            return "CopyMultiLinkedComponentAndApplyEditAction";
        }
    }))({
        reflectSymbol: function () {
            return "CopyComponentAndApplyEditAction";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "CopyEstimationComponentsToProject";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "ReplaceEstimationComponent";
        }
    }))({
        reflectSymbol: function () {
            return "DeleteEstimationComponents";
        }
    }))({
        reflectSymbol: function () {
            return "CopyMultiLinkedComponentAndApplyEditAction";
        }
    }))({
        reflectSymbol: function () {
            return "CopyComponentAndApplyEditAction";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "ResetEstimationLocations";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "ReplaceEstimationComponent";
        }
    }))({
        reflectSymbol: function () {
            return "DeleteEstimationComponents";
        }
    }))({
        reflectSymbol: function () {
            return "CopyMultiLinkedComponentAndApplyEditAction";
        }
    }))({
        reflectSymbol: function () {
            return "CopyEstimationComponentsToProject";
        }
    }))({
        reflectSymbol: function () {
            return "CopyComponentAndApplyEditAction";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "EditEstimationComponents";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "ResetEstimationLocations";
        }
    }))({
        reflectSymbol: function () {
            return "ReplaceEstimationComponent";
        }
    }))({
        reflectSymbol: function () {
            return "DeleteEstimationComponents";
        }
    }))({
        reflectSymbol: function () {
            return "CopyMultiLinkedComponentAndApplyEditAction";
        }
    }))({
        reflectSymbol: function () {
            return "CopyEstimationComponentsToProject";
        }
    }))({
        reflectSymbol: function () {
            return "CopyComponentAndApplyEditAction";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "EditEstimationComponent";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "ResetEstimationLocations";
        }
    }))({
        reflectSymbol: function () {
            return "ReplaceEstimationComponent";
        }
    }))({
        reflectSymbol: function () {
            return "EditEstimationComponents";
        }
    }))({
        reflectSymbol: function () {
            return "DeleteEstimationComponents";
        }
    }))({
        reflectSymbol: function () {
            return "CopyMultiLinkedComponentAndApplyEditAction";
        }
    }))({
        reflectSymbol: function () {
            return "CopyEstimationComponentsToProject";
        }
    }))({
        reflectSymbol: function () {
            return "CopyComponentAndApplyEditAction";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "AddEstimationComponent";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "ResetEstimationLocations";
        }
    }))({
        reflectSymbol: function () {
            return "ReplaceEstimationComponent";
        }
    }))({
        reflectSymbol: function () {
            return "EditEstimationComponents";
        }
    }))({
        reflectSymbol: function () {
            return "EditEstimationComponent";
        }
    }))({
        reflectSymbol: function () {
            return "DeleteEstimationComponents";
        }
    }))({
        reflectSymbol: function () {
            return "CopyMultiLinkedComponentAndApplyEditAction";
        }
    }))({
        reflectSymbol: function () {
            return "CopyEstimationComponentsToProject";
        }
    }))({
        reflectSymbol: function () {
            return "CopyComponentAndApplyEditAction";
        }
    })))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "AddEstimationComponent";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "code";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "componentType";
        }
    })(Types_Component.tofuJSONComponentType)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "description";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "memo";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "quantity";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "subProjectId";
        }
    })(Types_Project.tofuJSONSubprojectId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "unit";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.readFieldsNil)()())()())()())()())()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "code";
        }
    })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "componentType";
        }
    })(Types_Component.tofuJSONComponentType)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "description";
        }
    })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "memo";
        }
    })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "quantity";
        }
    })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "subProjectId";
        }
    })(Types_Project.tofuJSONSubprojectId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "unit";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())()()())()()())()()()))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "CopyComponentAndApplyEditAction";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "editActionParams";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "componentId";
        }
    })(Types_Component.tofuJSONComponentId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "editAction";
        }
    })(tofuJSONEditEstimationComponentAction)(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "componentId";
        }
    })(Types_Component.tofuJSONComponentId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "editAction";
        }
    })(tofuJSONEditEstimationComponentAction)(TofuJSON.nilWriteForeignFields)()()())()()()))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "elementId";
        }
    })(Types_Element.tofuJSONElementId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "elementSpecId";
        }
    })(Types_Newtypes.tofuJSONElementSpecId)(TofuJSON.readFieldsNil)()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "editActionParams";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "componentId";
        }
    })(Types_Component.tofuJSONComponentId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "editAction";
        }
    })(tofuJSONEditEstimationComponentAction)(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "componentId";
        }
    })(Types_Component.tofuJSONComponentId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "editAction";
        }
    })(tofuJSONEditEstimationComponentAction)(TofuJSON.nilWriteForeignFields)()()())()()()))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "elementId";
        }
    })(Types_Element.tofuJSONElementId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "elementSpecId";
        }
    })(Types_Newtypes.tofuJSONElementSpecId)(TofuJSON.nilWriteForeignFields)()()())()()())()()()))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "CopyEstimationComponentsToProject";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "allowDuplicates";
        }
    })(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "copyOperationType";
        }
    })(tofuJSONEstimationComponentCopyOperationType)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "estimationComponentIds";
        }
    })(TofuJSON.readArray(Types_Component.tofuJSONComponentId))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "targetProjectId";
        }
    })(Types_Project.tofuJSONProjectId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "targetSubprojectId";
        }
    })(Types_Project.tofuJSONSubprojectId)(TofuJSON.readFieldsNil)()())()())()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "allowDuplicates";
        }
    })(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "copyOperationType";
        }
    })(tofuJSONEstimationComponentCopyOperationType)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "estimationComponentIds";
        }
    })(TofuJSON.readArray(Types_Component.tofuJSONComponentId))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "targetProjectId";
        }
    })(Types_Project.tofuJSONProjectId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "targetSubprojectId";
        }
    })(Types_Project.tofuJSONSubprojectId)(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())()()()))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "CopyMultiLinkedComponentAndApplyEditAction";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "editActionParams";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "componentId";
        }
    })(Types_Component.tofuJSONComponentId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "editAction";
        }
    })(tofuJSONEditEstimationComponentAction)(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "componentId";
        }
    })(Types_Component.tofuJSONComponentId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "editAction";
        }
    })(tofuJSONEditEstimationComponentAction)(TofuJSON.nilWriteForeignFields)()()())()()()))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "elementId";
        }
    })(Types_Element.tofuJSONElementId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "elementSpecId";
        }
    })(Types_Newtypes.tofuJSONElementSpecId)(TofuJSON.readFieldsNil)()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "editActionParams";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "componentId";
        }
    })(Types_Component.tofuJSONComponentId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "editAction";
        }
    })(tofuJSONEditEstimationComponentAction)(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "componentId";
        }
    })(Types_Component.tofuJSONComponentId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "editAction";
        }
    })(tofuJSONEditEstimationComponentAction)(TofuJSON.nilWriteForeignFields)()()())()()()))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "elementId";
        }
    })(Types_Element.tofuJSONElementId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "elementSpecId";
        }
    })(Types_Newtypes.tofuJSONElementSpecId)(TofuJSON.nilWriteForeignFields)()()())()()())()()()))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "DeleteEstimationComponents";
        }
    })(TofuJSON.readArray(Types_Component.tofuJSONComponentId))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "EditEstimationComponent";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "componentId";
        }
    })(Types_Component.tofuJSONComponentId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "editAction";
        }
    })(tofuJSONEditEstimationComponentAction)(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "componentId";
        }
    })(Types_Component.tofuJSONComponentId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "editAction";
        }
    })(tofuJSONEditEstimationComponentAction)(TofuJSON.nilWriteForeignFields)()()())()()()))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "EditEstimationComponents";
        }
    })(TofuJSON.readArray(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "componentId";
        }
    })(Types_Component.tofuJSONComponentId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "editAction";
        }
    })(tofuJSONEditEstimationComponentAction)(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "componentId";
        }
    })(Types_Component.tofuJSONComponentId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "editAction";
        }
    })(tofuJSONEditEstimationComponentAction)(TofuJSON.nilWriteForeignFields)()()())()()())))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "ReplaceEstimationComponent";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "projectId";
        }
    })(Types_Project.tofuJSONProjectId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "referenceComponentId";
        }
    })(Types_Component.tofuJSONComponentId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "targetComponentId";
        }
    })(Types_Component.tofuJSONComponentId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "targetElementId";
        }
    })(TofuJSON.readMaybe(Types_Element.tofuJSONElementId))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "targetElementSpecId";
        }
    })(TofuJSON.readMaybe(Types_Newtypes.tofuJSONElementSpecId))(TofuJSON.readFieldsNil)()())()())()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "projectId";
        }
    })(Types_Project.tofuJSONProjectId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "referenceComponentId";
        }
    })(Types_Component.tofuJSONComponentId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "targetComponentId";
        }
    })(Types_Component.tofuJSONComponentId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "targetElementId";
        }
    })(TofuJSON.readMaybe(Types_Element.tofuJSONElementId))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "targetElementSpecId";
        }
    })(TofuJSON.readMaybe(Types_Newtypes.tofuJSONElementSpecId))(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())()()()))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "ResetEstimationLocations";
        }
    })(TofuJSON.readArray(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "componentId";
        }
    })(Types_Component.tofuJSONComponentId)(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "componentId";
        }
    })(Types_Component.tofuJSONComponentId)(TofuJSON.nilWriteForeignFields)()()())))()(TofuJSON.readVariantNil))))))))))(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "AddEstimationComponent";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "code";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "componentType";
        }
    })(Types_Component.tofuJSONComponentType)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "description";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "memo";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "quantity";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "subProjectId";
        }
    })(Types_Project.tofuJSONSubprojectId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "unit";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.readFieldsNil)()())()())()())()())()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "code";
        }
    })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "componentType";
        }
    })(Types_Component.tofuJSONComponentType)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "description";
        }
    })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "memo";
        }
    })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "quantity";
        }
    })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "subProjectId";
        }
    })(Types_Project.tofuJSONSubprojectId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "unit";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())()()())()()())()()()))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "CopyComponentAndApplyEditAction";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "editActionParams";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "componentId";
        }
    })(Types_Component.tofuJSONComponentId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "editAction";
        }
    })(tofuJSONEditEstimationComponentAction)(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "componentId";
        }
    })(Types_Component.tofuJSONComponentId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "editAction";
        }
    })(tofuJSONEditEstimationComponentAction)(TofuJSON.nilWriteForeignFields)()()())()()()))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "elementId";
        }
    })(Types_Element.tofuJSONElementId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "elementSpecId";
        }
    })(Types_Newtypes.tofuJSONElementSpecId)(TofuJSON.readFieldsNil)()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "editActionParams";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "componentId";
        }
    })(Types_Component.tofuJSONComponentId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "editAction";
        }
    })(tofuJSONEditEstimationComponentAction)(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "componentId";
        }
    })(Types_Component.tofuJSONComponentId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "editAction";
        }
    })(tofuJSONEditEstimationComponentAction)(TofuJSON.nilWriteForeignFields)()()())()()()))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "elementId";
        }
    })(Types_Element.tofuJSONElementId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "elementSpecId";
        }
    })(Types_Newtypes.tofuJSONElementSpecId)(TofuJSON.nilWriteForeignFields)()()())()()())()()()))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "CopyEstimationComponentsToProject";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "allowDuplicates";
        }
    })(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "copyOperationType";
        }
    })(tofuJSONEstimationComponentCopyOperationType)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "estimationComponentIds";
        }
    })(TofuJSON.readArray(Types_Component.tofuJSONComponentId))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "targetProjectId";
        }
    })(Types_Project.tofuJSONProjectId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "targetSubprojectId";
        }
    })(Types_Project.tofuJSONSubprojectId)(TofuJSON.readFieldsNil)()())()())()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "allowDuplicates";
        }
    })(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "copyOperationType";
        }
    })(tofuJSONEstimationComponentCopyOperationType)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "estimationComponentIds";
        }
    })(TofuJSON.readArray(Types_Component.tofuJSONComponentId))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "targetProjectId";
        }
    })(Types_Project.tofuJSONProjectId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "targetSubprojectId";
        }
    })(Types_Project.tofuJSONSubprojectId)(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())()()()))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "CopyMultiLinkedComponentAndApplyEditAction";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "editActionParams";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "componentId";
        }
    })(Types_Component.tofuJSONComponentId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "editAction";
        }
    })(tofuJSONEditEstimationComponentAction)(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "componentId";
        }
    })(Types_Component.tofuJSONComponentId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "editAction";
        }
    })(tofuJSONEditEstimationComponentAction)(TofuJSON.nilWriteForeignFields)()()())()()()))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "elementId";
        }
    })(Types_Element.tofuJSONElementId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "elementSpecId";
        }
    })(Types_Newtypes.tofuJSONElementSpecId)(TofuJSON.readFieldsNil)()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "editActionParams";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "componentId";
        }
    })(Types_Component.tofuJSONComponentId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "editAction";
        }
    })(tofuJSONEditEstimationComponentAction)(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "componentId";
        }
    })(Types_Component.tofuJSONComponentId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "editAction";
        }
    })(tofuJSONEditEstimationComponentAction)(TofuJSON.nilWriteForeignFields)()()())()()()))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "elementId";
        }
    })(Types_Element.tofuJSONElementId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "elementSpecId";
        }
    })(Types_Newtypes.tofuJSONElementSpecId)(TofuJSON.nilWriteForeignFields)()()())()()())()()()))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "DeleteEstimationComponents";
        }
    })(TofuJSON.readArray(Types_Component.tofuJSONComponentId))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "EditEstimationComponent";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "componentId";
        }
    })(Types_Component.tofuJSONComponentId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "editAction";
        }
    })(tofuJSONEditEstimationComponentAction)(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "componentId";
        }
    })(Types_Component.tofuJSONComponentId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "editAction";
        }
    })(tofuJSONEditEstimationComponentAction)(TofuJSON.nilWriteForeignFields)()()())()()()))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "EditEstimationComponents";
        }
    })(TofuJSON.readArray(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "componentId";
        }
    })(Types_Component.tofuJSONComponentId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "editAction";
        }
    })(tofuJSONEditEstimationComponentAction)(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "componentId";
        }
    })(Types_Component.tofuJSONComponentId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "editAction";
        }
    })(tofuJSONEditEstimationComponentAction)(TofuJSON.nilWriteForeignFields)()()())()()())))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "ReplaceEstimationComponent";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "projectId";
        }
    })(Types_Project.tofuJSONProjectId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "referenceComponentId";
        }
    })(Types_Component.tofuJSONComponentId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "targetComponentId";
        }
    })(Types_Component.tofuJSONComponentId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "targetElementId";
        }
    })(TofuJSON.readMaybe(Types_Element.tofuJSONElementId))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "targetElementSpecId";
        }
    })(TofuJSON.readMaybe(Types_Newtypes.tofuJSONElementSpecId))(TofuJSON.readFieldsNil)()())()())()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "projectId";
        }
    })(Types_Project.tofuJSONProjectId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "referenceComponentId";
        }
    })(Types_Component.tofuJSONComponentId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "targetComponentId";
        }
    })(Types_Component.tofuJSONComponentId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "targetElementId";
        }
    })(TofuJSON.readMaybe(Types_Element.tofuJSONElementId))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "targetElementSpecId";
        }
    })(TofuJSON.readMaybe(Types_Newtypes.tofuJSONElementSpecId))(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())()()()))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "ResetEstimationLocations";
        }
    })(TofuJSON.readArray(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "componentId";
        }
    })(Types_Component.tofuJSONComponentId)(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "componentId";
        }
    })(Types_Component.tofuJSONComponentId)(TofuJSON.nilWriteForeignFields)()()())))()(TofuJSON.nilWriteForeignVariant))))))))))
};
var genericCopySelectedElementsOperationType = {
    to: function (x) {
        if (x instanceof Data_Generic_Rep.Inl) {
            return DuplicateElementsWithinProject.value;
        };
        if (x instanceof Data_Generic_Rep.Inr && x.value0 instanceof Data_Generic_Rep.Inl) {
            return new CopyElementsToNewProject(x.value0.value0);
        };
        if (x instanceof Data_Generic_Rep.Inr && x.value0 instanceof Data_Generic_Rep.Inr) {
            return new UpdateExistingElement(x.value0.value0);
        };
        throw new Error("Failed pattern match at Types.Estimation (line 680, column 1 - line 680, column 104): " + [ x.constructor.name ]);
    },
    from: function (x) {
        if (x instanceof DuplicateElementsWithinProject) {
            return new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value);
        };
        if (x instanceof CopyElementsToNewProject) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(x.value0));
        };
        if (x instanceof UpdateExistingElement) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(x.value0));
        };
        throw new Error("Failed pattern match at Types.Estimation (line 680, column 1 - line 680, column 104): " + [ x.constructor.name ]);
    }
};
var tofuJSONCopySelectedElementsOperationType = {
    writeImpl: TofuJSON.writeGenericSum()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "CopyElementsToNewProject";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "addWildcardLocation";
        }
    })(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "copyResources";
        }
    })(TofuJSON.readBoolean)(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "addWildcardLocation";
        }
    })(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "copyResources";
        }
    })(TofuJSON.readBoolean)(TofuJSON.nilWriteForeignFields)()()())()()()))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "DuplicateElementsWithinProject";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "UpdateExistingElement";
        }
    })(tofuJSONElementUpdateParams)()(TofuJSON.readVariantNil))))(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "CopyElementsToNewProject";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "addWildcardLocation";
        }
    })(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "copyResources";
        }
    })(TofuJSON.readBoolean)(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "addWildcardLocation";
        }
    })(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "copyResources";
        }
    })(TofuJSON.readBoolean)(TofuJSON.nilWriteForeignFields)()()())()()()))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "DuplicateElementsWithinProject";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "UpdateExistingElement";
        }
    })(tofuJSONElementUpdateParams)()(TofuJSON.nilWriteForeignVariant))))(genericCopySelectedElementsOperationType)(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "DuplicateElementsWithinProject";
        }
    })()(Kishimen.genericSumToVariantArgNoArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "CopyElementsToNewProject";
        }
    })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "UpdateExistingElement";
        }
    })()(Kishimen.genericSumToVariantArgArguments))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "CopyElementsToNewProject";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "UpdateExistingElement";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "DuplicateElementsWithinProject";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "UpdateExistingElement";
        }
    }))({
        reflectSymbol: function () {
            return "CopyElementsToNewProject";
        }
    }))),
    readImpl: TofuJSON.readGenericSum(genericCopySelectedElementsOperationType)(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "DuplicateElementsWithinProject";
        }
    })()(Kishimen.genericSumToVariantArgNoArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "CopyElementsToNewProject";
        }
    })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "UpdateExistingElement";
        }
    })()(Kishimen.genericSumToVariantArgArguments))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "CopyElementsToNewProject";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "UpdateExistingElement";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "DuplicateElementsWithinProject";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "UpdateExistingElement";
        }
    }))({
        reflectSymbol: function () {
            return "CopyElementsToNewProject";
        }
    })))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "CopyElementsToNewProject";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "addWildcardLocation";
        }
    })(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "copyResources";
        }
    })(TofuJSON.readBoolean)(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "addWildcardLocation";
        }
    })(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "copyResources";
        }
    })(TofuJSON.readBoolean)(TofuJSON.nilWriteForeignFields)()()())()()()))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "DuplicateElementsWithinProject";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "UpdateExistingElement";
        }
    })(tofuJSONElementUpdateParams)()(TofuJSON.readVariantNil))))(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "CopyElementsToNewProject";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "addWildcardLocation";
        }
    })(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "copyResources";
        }
    })(TofuJSON.readBoolean)(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "addWildcardLocation";
        }
    })(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "copyResources";
        }
    })(TofuJSON.readBoolean)(TofuJSON.nilWriteForeignFields)()()())()()()))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "DuplicateElementsWithinProject";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "UpdateExistingElement";
        }
    })(tofuJSONElementUpdateParams)()(TofuJSON.nilWriteForeignVariant))))
};
var tofuJSONEstimationElementAction = {
    writeImpl: TofuJSON.writeGenericSum()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "AddElementLocation";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "elementId";
        }
    })(Types_Element.tofuJSONElementId)(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "elementId";
        }
    })(Types_Element.tofuJSONElementId)(TofuJSON.nilWriteForeignFields)()()()))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "AddEstimationElement";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "code";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "description";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "quantity";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "rtCode";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "rtDescription";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "subProjectId";
        }
    })(Types_Project.tofuJSONSubprojectId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "unit";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsNil)()())()())()())()())()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "code";
        }
    })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "description";
        }
    })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "quantity";
        }
    })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "rtCode";
        }
    })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "rtDescription";
        }
    })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "subProjectId";
        }
    })(Types_Project.tofuJSONSubprojectId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "unit";
        }
    })(TofuJSON.readString)(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())()()())()()())()()()))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "AddExistingProjectComponentsToElement";
        }
    })(TofuJSON.readArray(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "componentId";
        }
    })(Types_Component.tofuJSONComponentId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "elementId";
        }
    })(Types_Element.tofuJSONElementId)(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "componentId";
        }
    })(Types_Component.tofuJSONComponentId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "elementId";
        }
    })(Types_Element.tofuJSONElementId)(TofuJSON.nilWriteForeignFields)()()())()()())))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "AddExistingProjectElementSpecsToElement";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "elementSpecs";
        }
    })(TofuJSON.readArray(Types_Element.tofuJSONWasmElementSpec))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "targetElementId";
        }
    })(Types_Element.tofuJSONElementId)(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "elementSpecs";
        }
    })(TofuJSON.readArray(Types_Element.tofuJSONWasmElementSpec))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "targetElementId";
        }
    })(Types_Element.tofuJSONElementId)(TofuJSON.nilWriteForeignFields)()()())()()()))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "AddNewProjectComponentToElement";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "code";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "description";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "elementId";
        }
    })(Types_Element.tofuJSONElementId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "projectId";
        }
    })(Types_Project.tofuJSONProjectId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "subprojectId";
        }
    })(Types_Project.tofuJSONSubprojectId)(TofuJSON.readFieldsNil)()())()())()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "code";
        }
    })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "description";
        }
    })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "elementId";
        }
    })(Types_Element.tofuJSONElementId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "projectId";
        }
    })(Types_Project.tofuJSONProjectId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "subprojectId";
        }
    })(Types_Project.tofuJSONSubprojectId)(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())()()()))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "AddReferenceProjectComponentToElement";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "componentId";
        }
    })(Types_Component.tofuJSONComponentId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "elementId";
        }
    })(Types_Element.tofuJSONElementId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "targetProjectId";
        }
    })(Types_Project.tofuJSONProjectId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "targetSubprojectId";
        }
    })(Types_Project.tofuJSONSubprojectId)(TofuJSON.readFieldsNil)()())()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "componentId";
        }
    })(Types_Component.tofuJSONComponentId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "elementId";
        }
    })(Types_Element.tofuJSONElementId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "targetProjectId";
        }
    })(Types_Project.tofuJSONProjectId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "targetSubprojectId";
        }
    })(Types_Project.tofuJSONSubprojectId)(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()()))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "AddReferenceProjectComponentsToElementWithoutDuplicates";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "componentIds";
        }
    })(TofuJSON.readArray(Types_Component.tofuJSONComponentId))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "copyResources";
        }
    })(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "targetElementId";
        }
    })(Types_Element.tofuJSONElementId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "targetProjectId";
        }
    })(Types_Project.tofuJSONProjectId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "targetSubprojectId";
        }
    })(Types_Project.tofuJSONSubprojectId)(TofuJSON.readFieldsNil)()())()())()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "componentIds";
        }
    })(TofuJSON.readArray(Types_Component.tofuJSONComponentId))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "copyResources";
        }
    })(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "targetElementId";
        }
    })(Types_Element.tofuJSONElementId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "targetProjectId";
        }
    })(Types_Project.tofuJSONProjectId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "targetSubprojectId";
        }
    })(Types_Project.tofuJSONSubprojectId)(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())()()()))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "CopyEstimationElementsToProject";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "copyOperationType";
        }
    })(tofuJSONEstimationElementCopyOperationType)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "estimationElementIds";
        }
    })(TofuJSON.readArray(Types_Element.tofuJSONElementId))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "targetProjectId";
        }
    })(Types_Project.tofuJSONProjectId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "targetSubprojectId";
        }
    })(Types_Project.tofuJSONSubprojectId)(TofuJSON.readFieldsNil)()())()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "copyOperationType";
        }
    })(tofuJSONEstimationElementCopyOperationType)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "estimationElementIds";
        }
    })(TofuJSON.readArray(Types_Element.tofuJSONElementId))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "targetProjectId";
        }
    })(Types_Project.tofuJSONProjectId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "targetSubprojectId";
        }
    })(Types_Project.tofuJSONSubprojectId)(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()()))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "CopySelectedElements";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "copyOperationType";
        }
    })(tofuJSONCopySelectedElementsOperationType)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "estimationElementIds";
        }
    })(TofuJSON.readArray(Types_Element.tofuJSONElementId))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "targetProjectId";
        }
    })(Types_Project.tofuJSONProjectId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "targetSubprojectId";
        }
    })(Types_Project.tofuJSONSubprojectId)(TofuJSON.readFieldsNil)()())()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "copyOperationType";
        }
    })(tofuJSONCopySelectedElementsOperationType)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "estimationElementIds";
        }
    })(TofuJSON.readArray(Types_Element.tofuJSONElementId))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "targetProjectId";
        }
    })(Types_Project.tofuJSONProjectId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "targetSubprojectId";
        }
    })(Types_Project.tofuJSONSubprojectId)(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()()))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "DeleteElementLocations";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "elementId";
        }
    })(Types_Element.tofuJSONElementId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "elementLocationIds";
        }
    })(TofuJSON.readArray(Types_Element.tofuJSONElementLocationId))(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "elementId";
        }
    })(Types_Element.tofuJSONElementId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "elementLocationIds";
        }
    })(TofuJSON.readArray(Types_Element.tofuJSONElementLocationId))(TofuJSON.nilWriteForeignFields)()()())()()()))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "EditElementLocationAmount";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "amount";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "elementId";
        }
    })(Types_Element.tofuJSONElementId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "elementLocationId";
        }
    })(Types_Element.tofuJSONElementLocationId)(TofuJSON.readFieldsNil)()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "amount";
        }
    })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "elementId";
        }
    })(Types_Element.tofuJSONElementId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "elementLocationId";
        }
    })(Types_Element.tofuJSONElementLocationId)(TofuJSON.nilWriteForeignFields)()()())()()())()()()))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "EditElementLocationCode";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "elementId";
        }
    })(Types_Element.tofuJSONElementId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "elementLocationId";
        }
    })(Types_Element.tofuJSONElementLocationId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "locationCode";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsNil)()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "elementId";
        }
    })(Types_Element.tofuJSONElementId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "elementLocationId";
        }
    })(Types_Element.tofuJSONElementLocationId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "locationCode";
        }
    })(TofuJSON.readString)(TofuJSON.nilWriteForeignFields)()()())()()())()()()))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "EditElementLocationDescription";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "description";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "elementId";
        }
    })(Types_Element.tofuJSONElementId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "elementLocationId";
        }
    })(Types_Element.tofuJSONElementLocationId)(TofuJSON.readFieldsNil)()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "description";
        }
    })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "elementId";
        }
    })(Types_Element.tofuJSONElementId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "elementLocationId";
        }
    })(Types_Element.tofuJSONElementLocationId)(TofuJSON.nilWriteForeignFields)()()())()()())()()()))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "EditEstimationElement";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "editAction";
        }
    })(tofuJSONEditEstimationElementAction)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "elementId";
        }
    })(Types_Element.tofuJSONElementId)(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "editAction";
        }
    })(tofuJSONEditEstimationElementAction)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "elementId";
        }
    })(Types_Element.tofuJSONElementId)(TofuJSON.nilWriteForeignFields)()()())()()()))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "ElementLocationsMatrixAction";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "elementId";
        }
    })(Types_Element.tofuJSONElementId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "modifiedAmounts";
        }
    })(TofuJSON.readArray(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "amount";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "description";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "elementLocationId";
        }
    })(Types_Element.tofuJSONElementLocationId)(TofuJSON.readFieldsNil)()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "amount";
        }
    })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "description";
        }
    })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "elementLocationId";
        }
    })(Types_Element.tofuJSONElementLocationId)(TofuJSON.nilWriteForeignFields)()()())()()())()()())))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "newAmounts";
        }
    })(TofuJSON.readArray(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "amount";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "description";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "locationCode";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsNil)()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "amount";
        }
    })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "description";
        }
    })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "locationCode";
        }
    })(TofuJSON.readString)(TofuJSON.nilWriteForeignFields)()()())()()())()()())))(TofuJSON.readFieldsNil)()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "elementId";
        }
    })(Types_Element.tofuJSONElementId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "modifiedAmounts";
        }
    })(TofuJSON.readArray(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "amount";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "description";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "elementLocationId";
        }
    })(Types_Element.tofuJSONElementLocationId)(TofuJSON.readFieldsNil)()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "amount";
        }
    })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "description";
        }
    })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "elementLocationId";
        }
    })(Types_Element.tofuJSONElementLocationId)(TofuJSON.nilWriteForeignFields)()()())()()())()()())))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "newAmounts";
        }
    })(TofuJSON.readArray(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "amount";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "description";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "locationCode";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsNil)()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "amount";
        }
    })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "description";
        }
    })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "locationCode";
        }
    })(TofuJSON.readString)(TofuJSON.nilWriteForeignFields)()()())()()())()()())))(TofuJSON.nilWriteForeignFields)()()())()()())()()()))()(TofuJSON.readVariantNil))))))))))))))))(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "AddElementLocation";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "elementId";
        }
    })(Types_Element.tofuJSONElementId)(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "elementId";
        }
    })(Types_Element.tofuJSONElementId)(TofuJSON.nilWriteForeignFields)()()()))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "AddEstimationElement";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "code";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "description";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "quantity";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "rtCode";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "rtDescription";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "subProjectId";
        }
    })(Types_Project.tofuJSONSubprojectId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "unit";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsNil)()())()())()())()())()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "code";
        }
    })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "description";
        }
    })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "quantity";
        }
    })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "rtCode";
        }
    })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "rtDescription";
        }
    })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "subProjectId";
        }
    })(Types_Project.tofuJSONSubprojectId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "unit";
        }
    })(TofuJSON.readString)(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())()()())()()())()()()))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "AddExistingProjectComponentsToElement";
        }
    })(TofuJSON.readArray(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "componentId";
        }
    })(Types_Component.tofuJSONComponentId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "elementId";
        }
    })(Types_Element.tofuJSONElementId)(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "componentId";
        }
    })(Types_Component.tofuJSONComponentId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "elementId";
        }
    })(Types_Element.tofuJSONElementId)(TofuJSON.nilWriteForeignFields)()()())()()())))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "AddExistingProjectElementSpecsToElement";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "elementSpecs";
        }
    })(TofuJSON.readArray(Types_Element.tofuJSONWasmElementSpec))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "targetElementId";
        }
    })(Types_Element.tofuJSONElementId)(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "elementSpecs";
        }
    })(TofuJSON.readArray(Types_Element.tofuJSONWasmElementSpec))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "targetElementId";
        }
    })(Types_Element.tofuJSONElementId)(TofuJSON.nilWriteForeignFields)()()())()()()))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "AddNewProjectComponentToElement";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "code";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "description";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "elementId";
        }
    })(Types_Element.tofuJSONElementId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "projectId";
        }
    })(Types_Project.tofuJSONProjectId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "subprojectId";
        }
    })(Types_Project.tofuJSONSubprojectId)(TofuJSON.readFieldsNil)()())()())()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "code";
        }
    })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "description";
        }
    })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "elementId";
        }
    })(Types_Element.tofuJSONElementId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "projectId";
        }
    })(Types_Project.tofuJSONProjectId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "subprojectId";
        }
    })(Types_Project.tofuJSONSubprojectId)(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())()()()))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "AddReferenceProjectComponentToElement";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "componentId";
        }
    })(Types_Component.tofuJSONComponentId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "elementId";
        }
    })(Types_Element.tofuJSONElementId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "targetProjectId";
        }
    })(Types_Project.tofuJSONProjectId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "targetSubprojectId";
        }
    })(Types_Project.tofuJSONSubprojectId)(TofuJSON.readFieldsNil)()())()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "componentId";
        }
    })(Types_Component.tofuJSONComponentId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "elementId";
        }
    })(Types_Element.tofuJSONElementId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "targetProjectId";
        }
    })(Types_Project.tofuJSONProjectId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "targetSubprojectId";
        }
    })(Types_Project.tofuJSONSubprojectId)(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()()))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "AddReferenceProjectComponentsToElementWithoutDuplicates";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "componentIds";
        }
    })(TofuJSON.readArray(Types_Component.tofuJSONComponentId))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "copyResources";
        }
    })(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "targetElementId";
        }
    })(Types_Element.tofuJSONElementId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "targetProjectId";
        }
    })(Types_Project.tofuJSONProjectId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "targetSubprojectId";
        }
    })(Types_Project.tofuJSONSubprojectId)(TofuJSON.readFieldsNil)()())()())()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "componentIds";
        }
    })(TofuJSON.readArray(Types_Component.tofuJSONComponentId))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "copyResources";
        }
    })(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "targetElementId";
        }
    })(Types_Element.tofuJSONElementId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "targetProjectId";
        }
    })(Types_Project.tofuJSONProjectId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "targetSubprojectId";
        }
    })(Types_Project.tofuJSONSubprojectId)(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())()()()))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "CopyEstimationElementsToProject";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "copyOperationType";
        }
    })(tofuJSONEstimationElementCopyOperationType)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "estimationElementIds";
        }
    })(TofuJSON.readArray(Types_Element.tofuJSONElementId))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "targetProjectId";
        }
    })(Types_Project.tofuJSONProjectId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "targetSubprojectId";
        }
    })(Types_Project.tofuJSONSubprojectId)(TofuJSON.readFieldsNil)()())()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "copyOperationType";
        }
    })(tofuJSONEstimationElementCopyOperationType)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "estimationElementIds";
        }
    })(TofuJSON.readArray(Types_Element.tofuJSONElementId))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "targetProjectId";
        }
    })(Types_Project.tofuJSONProjectId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "targetSubprojectId";
        }
    })(Types_Project.tofuJSONSubprojectId)(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()()))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "CopySelectedElements";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "copyOperationType";
        }
    })(tofuJSONCopySelectedElementsOperationType)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "estimationElementIds";
        }
    })(TofuJSON.readArray(Types_Element.tofuJSONElementId))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "targetProjectId";
        }
    })(Types_Project.tofuJSONProjectId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "targetSubprojectId";
        }
    })(Types_Project.tofuJSONSubprojectId)(TofuJSON.readFieldsNil)()())()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "copyOperationType";
        }
    })(tofuJSONCopySelectedElementsOperationType)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "estimationElementIds";
        }
    })(TofuJSON.readArray(Types_Element.tofuJSONElementId))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "targetProjectId";
        }
    })(Types_Project.tofuJSONProjectId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "targetSubprojectId";
        }
    })(Types_Project.tofuJSONSubprojectId)(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()()))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "DeleteElementLocations";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "elementId";
        }
    })(Types_Element.tofuJSONElementId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "elementLocationIds";
        }
    })(TofuJSON.readArray(Types_Element.tofuJSONElementLocationId))(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "elementId";
        }
    })(Types_Element.tofuJSONElementId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "elementLocationIds";
        }
    })(TofuJSON.readArray(Types_Element.tofuJSONElementLocationId))(TofuJSON.nilWriteForeignFields)()()())()()()))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "EditElementLocationAmount";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "amount";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "elementId";
        }
    })(Types_Element.tofuJSONElementId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "elementLocationId";
        }
    })(Types_Element.tofuJSONElementLocationId)(TofuJSON.readFieldsNil)()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "amount";
        }
    })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "elementId";
        }
    })(Types_Element.tofuJSONElementId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "elementLocationId";
        }
    })(Types_Element.tofuJSONElementLocationId)(TofuJSON.nilWriteForeignFields)()()())()()())()()()))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "EditElementLocationCode";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "elementId";
        }
    })(Types_Element.tofuJSONElementId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "elementLocationId";
        }
    })(Types_Element.tofuJSONElementLocationId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "locationCode";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsNil)()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "elementId";
        }
    })(Types_Element.tofuJSONElementId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "elementLocationId";
        }
    })(Types_Element.tofuJSONElementLocationId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "locationCode";
        }
    })(TofuJSON.readString)(TofuJSON.nilWriteForeignFields)()()())()()())()()()))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "EditElementLocationDescription";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "description";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "elementId";
        }
    })(Types_Element.tofuJSONElementId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "elementLocationId";
        }
    })(Types_Element.tofuJSONElementLocationId)(TofuJSON.readFieldsNil)()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "description";
        }
    })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "elementId";
        }
    })(Types_Element.tofuJSONElementId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "elementLocationId";
        }
    })(Types_Element.tofuJSONElementLocationId)(TofuJSON.nilWriteForeignFields)()()())()()())()()()))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "EditEstimationElement";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "editAction";
        }
    })(tofuJSONEditEstimationElementAction)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "elementId";
        }
    })(Types_Element.tofuJSONElementId)(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "editAction";
        }
    })(tofuJSONEditEstimationElementAction)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "elementId";
        }
    })(Types_Element.tofuJSONElementId)(TofuJSON.nilWriteForeignFields)()()())()()()))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "ElementLocationsMatrixAction";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "elementId";
        }
    })(Types_Element.tofuJSONElementId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "modifiedAmounts";
        }
    })(TofuJSON.readArray(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "amount";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "description";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "elementLocationId";
        }
    })(Types_Element.tofuJSONElementLocationId)(TofuJSON.readFieldsNil)()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "amount";
        }
    })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "description";
        }
    })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "elementLocationId";
        }
    })(Types_Element.tofuJSONElementLocationId)(TofuJSON.nilWriteForeignFields)()()())()()())()()())))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "newAmounts";
        }
    })(TofuJSON.readArray(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "amount";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "description";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "locationCode";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsNil)()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "amount";
        }
    })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "description";
        }
    })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "locationCode";
        }
    })(TofuJSON.readString)(TofuJSON.nilWriteForeignFields)()()())()()())()()())))(TofuJSON.readFieldsNil)()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "elementId";
        }
    })(Types_Element.tofuJSONElementId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "modifiedAmounts";
        }
    })(TofuJSON.readArray(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "amount";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "description";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "elementLocationId";
        }
    })(Types_Element.tofuJSONElementLocationId)(TofuJSON.readFieldsNil)()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "amount";
        }
    })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "description";
        }
    })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "elementLocationId";
        }
    })(Types_Element.tofuJSONElementLocationId)(TofuJSON.nilWriteForeignFields)()()())()()())()()())))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "newAmounts";
        }
    })(TofuJSON.readArray(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "amount";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "description";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "locationCode";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsNil)()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "amount";
        }
    })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "description";
        }
    })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "locationCode";
        }
    })(TofuJSON.readString)(TofuJSON.nilWriteForeignFields)()()())()()())()()())))(TofuJSON.nilWriteForeignFields)()()())()()())()()()))()(TofuJSON.nilWriteForeignVariant))))))))))))))))(genericEstimationElementAction)(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "AddEstimationElement";
        }
    })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "EditEstimationElement";
        }
    })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "AddExistingProjectComponentsToElement";
        }
    })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "AddExistingProjectElementSpecsToElement";
        }
    })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "AddReferenceProjectComponentToElement";
        }
    })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "AddReferenceProjectComponentsToElementWithoutDuplicates";
        }
    })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "AddNewProjectComponentToElement";
        }
    })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "CopyEstimationElementsToProject";
        }
    })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "CopySelectedElements";
        }
    })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "EditElementLocationCode";
        }
    })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "EditElementLocationDescription";
        }
    })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "EditElementLocationAmount";
        }
    })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "ElementLocationsMatrixAction";
        }
    })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "AddElementLocation";
        }
    })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "DeleteElementLocations";
        }
    })()(Kishimen.genericSumToVariantArgArguments))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "AddElementLocation";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "DeleteElementLocations";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "ElementLocationsMatrixAction";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "DeleteElementLocations";
        }
    }))({
        reflectSymbol: function () {
            return "AddElementLocation";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "EditElementLocationAmount";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "ElementLocationsMatrixAction";
        }
    }))({
        reflectSymbol: function () {
            return "DeleteElementLocations";
        }
    }))({
        reflectSymbol: function () {
            return "AddElementLocation";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "EditElementLocationDescription";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "ElementLocationsMatrixAction";
        }
    }))({
        reflectSymbol: function () {
            return "EditElementLocationAmount";
        }
    }))({
        reflectSymbol: function () {
            return "DeleteElementLocations";
        }
    }))({
        reflectSymbol: function () {
            return "AddElementLocation";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "EditElementLocationCode";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "ElementLocationsMatrixAction";
        }
    }))({
        reflectSymbol: function () {
            return "EditElementLocationDescription";
        }
    }))({
        reflectSymbol: function () {
            return "EditElementLocationAmount";
        }
    }))({
        reflectSymbol: function () {
            return "DeleteElementLocations";
        }
    }))({
        reflectSymbol: function () {
            return "AddElementLocation";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "CopySelectedElements";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "ElementLocationsMatrixAction";
        }
    }))({
        reflectSymbol: function () {
            return "EditElementLocationDescription";
        }
    }))({
        reflectSymbol: function () {
            return "EditElementLocationCode";
        }
    }))({
        reflectSymbol: function () {
            return "EditElementLocationAmount";
        }
    }))({
        reflectSymbol: function () {
            return "DeleteElementLocations";
        }
    }))({
        reflectSymbol: function () {
            return "AddElementLocation";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "CopyEstimationElementsToProject";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "ElementLocationsMatrixAction";
        }
    }))({
        reflectSymbol: function () {
            return "EditElementLocationDescription";
        }
    }))({
        reflectSymbol: function () {
            return "EditElementLocationCode";
        }
    }))({
        reflectSymbol: function () {
            return "EditElementLocationAmount";
        }
    }))({
        reflectSymbol: function () {
            return "DeleteElementLocations";
        }
    }))({
        reflectSymbol: function () {
            return "CopySelectedElements";
        }
    }))({
        reflectSymbol: function () {
            return "AddElementLocation";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "AddNewProjectComponentToElement";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "ElementLocationsMatrixAction";
        }
    }))({
        reflectSymbol: function () {
            return "EditElementLocationDescription";
        }
    }))({
        reflectSymbol: function () {
            return "EditElementLocationCode";
        }
    }))({
        reflectSymbol: function () {
            return "EditElementLocationAmount";
        }
    }))({
        reflectSymbol: function () {
            return "DeleteElementLocations";
        }
    }))({
        reflectSymbol: function () {
            return "CopySelectedElements";
        }
    }))({
        reflectSymbol: function () {
            return "CopyEstimationElementsToProject";
        }
    }))({
        reflectSymbol: function () {
            return "AddElementLocation";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "AddReferenceProjectComponentsToElementWithoutDuplicates";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "ElementLocationsMatrixAction";
        }
    }))({
        reflectSymbol: function () {
            return "EditElementLocationDescription";
        }
    }))({
        reflectSymbol: function () {
            return "EditElementLocationCode";
        }
    }))({
        reflectSymbol: function () {
            return "EditElementLocationAmount";
        }
    }))({
        reflectSymbol: function () {
            return "DeleteElementLocations";
        }
    }))({
        reflectSymbol: function () {
            return "CopySelectedElements";
        }
    }))({
        reflectSymbol: function () {
            return "CopyEstimationElementsToProject";
        }
    }))({
        reflectSymbol: function () {
            return "AddNewProjectComponentToElement";
        }
    }))({
        reflectSymbol: function () {
            return "AddElementLocation";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "AddReferenceProjectComponentToElement";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "ElementLocationsMatrixAction";
        }
    }))({
        reflectSymbol: function () {
            return "EditElementLocationDescription";
        }
    }))({
        reflectSymbol: function () {
            return "EditElementLocationCode";
        }
    }))({
        reflectSymbol: function () {
            return "EditElementLocationAmount";
        }
    }))({
        reflectSymbol: function () {
            return "DeleteElementLocations";
        }
    }))({
        reflectSymbol: function () {
            return "CopySelectedElements";
        }
    }))({
        reflectSymbol: function () {
            return "CopyEstimationElementsToProject";
        }
    }))({
        reflectSymbol: function () {
            return "AddReferenceProjectComponentsToElementWithoutDuplicates";
        }
    }))({
        reflectSymbol: function () {
            return "AddNewProjectComponentToElement";
        }
    }))({
        reflectSymbol: function () {
            return "AddElementLocation";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "AddExistingProjectElementSpecsToElement";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "ElementLocationsMatrixAction";
        }
    }))({
        reflectSymbol: function () {
            return "EditElementLocationDescription";
        }
    }))({
        reflectSymbol: function () {
            return "EditElementLocationCode";
        }
    }))({
        reflectSymbol: function () {
            return "EditElementLocationAmount";
        }
    }))({
        reflectSymbol: function () {
            return "DeleteElementLocations";
        }
    }))({
        reflectSymbol: function () {
            return "CopySelectedElements";
        }
    }))({
        reflectSymbol: function () {
            return "CopyEstimationElementsToProject";
        }
    }))({
        reflectSymbol: function () {
            return "AddReferenceProjectComponentsToElementWithoutDuplicates";
        }
    }))({
        reflectSymbol: function () {
            return "AddReferenceProjectComponentToElement";
        }
    }))({
        reflectSymbol: function () {
            return "AddNewProjectComponentToElement";
        }
    }))({
        reflectSymbol: function () {
            return "AddElementLocation";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "AddExistingProjectComponentsToElement";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "ElementLocationsMatrixAction";
        }
    }))({
        reflectSymbol: function () {
            return "EditElementLocationDescription";
        }
    }))({
        reflectSymbol: function () {
            return "EditElementLocationCode";
        }
    }))({
        reflectSymbol: function () {
            return "EditElementLocationAmount";
        }
    }))({
        reflectSymbol: function () {
            return "DeleteElementLocations";
        }
    }))({
        reflectSymbol: function () {
            return "CopySelectedElements";
        }
    }))({
        reflectSymbol: function () {
            return "CopyEstimationElementsToProject";
        }
    }))({
        reflectSymbol: function () {
            return "AddReferenceProjectComponentsToElementWithoutDuplicates";
        }
    }))({
        reflectSymbol: function () {
            return "AddReferenceProjectComponentToElement";
        }
    }))({
        reflectSymbol: function () {
            return "AddNewProjectComponentToElement";
        }
    }))({
        reflectSymbol: function () {
            return "AddExistingProjectElementSpecsToElement";
        }
    }))({
        reflectSymbol: function () {
            return "AddElementLocation";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "EditEstimationElement";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "ElementLocationsMatrixAction";
        }
    }))({
        reflectSymbol: function () {
            return "EditElementLocationDescription";
        }
    }))({
        reflectSymbol: function () {
            return "EditElementLocationCode";
        }
    }))({
        reflectSymbol: function () {
            return "EditElementLocationAmount";
        }
    }))({
        reflectSymbol: function () {
            return "DeleteElementLocations";
        }
    }))({
        reflectSymbol: function () {
            return "CopySelectedElements";
        }
    }))({
        reflectSymbol: function () {
            return "CopyEstimationElementsToProject";
        }
    }))({
        reflectSymbol: function () {
            return "AddReferenceProjectComponentsToElementWithoutDuplicates";
        }
    }))({
        reflectSymbol: function () {
            return "AddReferenceProjectComponentToElement";
        }
    }))({
        reflectSymbol: function () {
            return "AddNewProjectComponentToElement";
        }
    }))({
        reflectSymbol: function () {
            return "AddExistingProjectElementSpecsToElement";
        }
    }))({
        reflectSymbol: function () {
            return "AddExistingProjectComponentsToElement";
        }
    }))({
        reflectSymbol: function () {
            return "AddElementLocation";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "AddEstimationElement";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "ElementLocationsMatrixAction";
        }
    }))({
        reflectSymbol: function () {
            return "EditEstimationElement";
        }
    }))({
        reflectSymbol: function () {
            return "EditElementLocationDescription";
        }
    }))({
        reflectSymbol: function () {
            return "EditElementLocationCode";
        }
    }))({
        reflectSymbol: function () {
            return "EditElementLocationAmount";
        }
    }))({
        reflectSymbol: function () {
            return "DeleteElementLocations";
        }
    }))({
        reflectSymbol: function () {
            return "CopySelectedElements";
        }
    }))({
        reflectSymbol: function () {
            return "CopyEstimationElementsToProject";
        }
    }))({
        reflectSymbol: function () {
            return "AddReferenceProjectComponentsToElementWithoutDuplicates";
        }
    }))({
        reflectSymbol: function () {
            return "AddReferenceProjectComponentToElement";
        }
    }))({
        reflectSymbol: function () {
            return "AddNewProjectComponentToElement";
        }
    }))({
        reflectSymbol: function () {
            return "AddExistingProjectElementSpecsToElement";
        }
    }))({
        reflectSymbol: function () {
            return "AddExistingProjectComponentsToElement";
        }
    }))({
        reflectSymbol: function () {
            return "AddElementLocation";
        }
    }))),
    readImpl: TofuJSON.readGenericSum(genericEstimationElementAction)(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "AddEstimationElement";
        }
    })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "EditEstimationElement";
        }
    })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "AddExistingProjectComponentsToElement";
        }
    })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "AddExistingProjectElementSpecsToElement";
        }
    })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "AddReferenceProjectComponentToElement";
        }
    })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "AddReferenceProjectComponentsToElementWithoutDuplicates";
        }
    })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "AddNewProjectComponentToElement";
        }
    })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "CopyEstimationElementsToProject";
        }
    })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "CopySelectedElements";
        }
    })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "EditElementLocationCode";
        }
    })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "EditElementLocationDescription";
        }
    })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "EditElementLocationAmount";
        }
    })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "ElementLocationsMatrixAction";
        }
    })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "AddElementLocation";
        }
    })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "DeleteElementLocations";
        }
    })()(Kishimen.genericSumToVariantArgArguments))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "AddElementLocation";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "DeleteElementLocations";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "ElementLocationsMatrixAction";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "DeleteElementLocations";
        }
    }))({
        reflectSymbol: function () {
            return "AddElementLocation";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "EditElementLocationAmount";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "ElementLocationsMatrixAction";
        }
    }))({
        reflectSymbol: function () {
            return "DeleteElementLocations";
        }
    }))({
        reflectSymbol: function () {
            return "AddElementLocation";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "EditElementLocationDescription";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "ElementLocationsMatrixAction";
        }
    }))({
        reflectSymbol: function () {
            return "EditElementLocationAmount";
        }
    }))({
        reflectSymbol: function () {
            return "DeleteElementLocations";
        }
    }))({
        reflectSymbol: function () {
            return "AddElementLocation";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "EditElementLocationCode";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "ElementLocationsMatrixAction";
        }
    }))({
        reflectSymbol: function () {
            return "EditElementLocationDescription";
        }
    }))({
        reflectSymbol: function () {
            return "EditElementLocationAmount";
        }
    }))({
        reflectSymbol: function () {
            return "DeleteElementLocations";
        }
    }))({
        reflectSymbol: function () {
            return "AddElementLocation";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "CopySelectedElements";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "ElementLocationsMatrixAction";
        }
    }))({
        reflectSymbol: function () {
            return "EditElementLocationDescription";
        }
    }))({
        reflectSymbol: function () {
            return "EditElementLocationCode";
        }
    }))({
        reflectSymbol: function () {
            return "EditElementLocationAmount";
        }
    }))({
        reflectSymbol: function () {
            return "DeleteElementLocations";
        }
    }))({
        reflectSymbol: function () {
            return "AddElementLocation";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "CopyEstimationElementsToProject";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "ElementLocationsMatrixAction";
        }
    }))({
        reflectSymbol: function () {
            return "EditElementLocationDescription";
        }
    }))({
        reflectSymbol: function () {
            return "EditElementLocationCode";
        }
    }))({
        reflectSymbol: function () {
            return "EditElementLocationAmount";
        }
    }))({
        reflectSymbol: function () {
            return "DeleteElementLocations";
        }
    }))({
        reflectSymbol: function () {
            return "CopySelectedElements";
        }
    }))({
        reflectSymbol: function () {
            return "AddElementLocation";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "AddNewProjectComponentToElement";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "ElementLocationsMatrixAction";
        }
    }))({
        reflectSymbol: function () {
            return "EditElementLocationDescription";
        }
    }))({
        reflectSymbol: function () {
            return "EditElementLocationCode";
        }
    }))({
        reflectSymbol: function () {
            return "EditElementLocationAmount";
        }
    }))({
        reflectSymbol: function () {
            return "DeleteElementLocations";
        }
    }))({
        reflectSymbol: function () {
            return "CopySelectedElements";
        }
    }))({
        reflectSymbol: function () {
            return "CopyEstimationElementsToProject";
        }
    }))({
        reflectSymbol: function () {
            return "AddElementLocation";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "AddReferenceProjectComponentsToElementWithoutDuplicates";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "ElementLocationsMatrixAction";
        }
    }))({
        reflectSymbol: function () {
            return "EditElementLocationDescription";
        }
    }))({
        reflectSymbol: function () {
            return "EditElementLocationCode";
        }
    }))({
        reflectSymbol: function () {
            return "EditElementLocationAmount";
        }
    }))({
        reflectSymbol: function () {
            return "DeleteElementLocations";
        }
    }))({
        reflectSymbol: function () {
            return "CopySelectedElements";
        }
    }))({
        reflectSymbol: function () {
            return "CopyEstimationElementsToProject";
        }
    }))({
        reflectSymbol: function () {
            return "AddNewProjectComponentToElement";
        }
    }))({
        reflectSymbol: function () {
            return "AddElementLocation";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "AddReferenceProjectComponentToElement";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "ElementLocationsMatrixAction";
        }
    }))({
        reflectSymbol: function () {
            return "EditElementLocationDescription";
        }
    }))({
        reflectSymbol: function () {
            return "EditElementLocationCode";
        }
    }))({
        reflectSymbol: function () {
            return "EditElementLocationAmount";
        }
    }))({
        reflectSymbol: function () {
            return "DeleteElementLocations";
        }
    }))({
        reflectSymbol: function () {
            return "CopySelectedElements";
        }
    }))({
        reflectSymbol: function () {
            return "CopyEstimationElementsToProject";
        }
    }))({
        reflectSymbol: function () {
            return "AddReferenceProjectComponentsToElementWithoutDuplicates";
        }
    }))({
        reflectSymbol: function () {
            return "AddNewProjectComponentToElement";
        }
    }))({
        reflectSymbol: function () {
            return "AddElementLocation";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "AddExistingProjectElementSpecsToElement";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "ElementLocationsMatrixAction";
        }
    }))({
        reflectSymbol: function () {
            return "EditElementLocationDescription";
        }
    }))({
        reflectSymbol: function () {
            return "EditElementLocationCode";
        }
    }))({
        reflectSymbol: function () {
            return "EditElementLocationAmount";
        }
    }))({
        reflectSymbol: function () {
            return "DeleteElementLocations";
        }
    }))({
        reflectSymbol: function () {
            return "CopySelectedElements";
        }
    }))({
        reflectSymbol: function () {
            return "CopyEstimationElementsToProject";
        }
    }))({
        reflectSymbol: function () {
            return "AddReferenceProjectComponentsToElementWithoutDuplicates";
        }
    }))({
        reflectSymbol: function () {
            return "AddReferenceProjectComponentToElement";
        }
    }))({
        reflectSymbol: function () {
            return "AddNewProjectComponentToElement";
        }
    }))({
        reflectSymbol: function () {
            return "AddElementLocation";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "AddExistingProjectComponentsToElement";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "ElementLocationsMatrixAction";
        }
    }))({
        reflectSymbol: function () {
            return "EditElementLocationDescription";
        }
    }))({
        reflectSymbol: function () {
            return "EditElementLocationCode";
        }
    }))({
        reflectSymbol: function () {
            return "EditElementLocationAmount";
        }
    }))({
        reflectSymbol: function () {
            return "DeleteElementLocations";
        }
    }))({
        reflectSymbol: function () {
            return "CopySelectedElements";
        }
    }))({
        reflectSymbol: function () {
            return "CopyEstimationElementsToProject";
        }
    }))({
        reflectSymbol: function () {
            return "AddReferenceProjectComponentsToElementWithoutDuplicates";
        }
    }))({
        reflectSymbol: function () {
            return "AddReferenceProjectComponentToElement";
        }
    }))({
        reflectSymbol: function () {
            return "AddNewProjectComponentToElement";
        }
    }))({
        reflectSymbol: function () {
            return "AddExistingProjectElementSpecsToElement";
        }
    }))({
        reflectSymbol: function () {
            return "AddElementLocation";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "EditEstimationElement";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "ElementLocationsMatrixAction";
        }
    }))({
        reflectSymbol: function () {
            return "EditElementLocationDescription";
        }
    }))({
        reflectSymbol: function () {
            return "EditElementLocationCode";
        }
    }))({
        reflectSymbol: function () {
            return "EditElementLocationAmount";
        }
    }))({
        reflectSymbol: function () {
            return "DeleteElementLocations";
        }
    }))({
        reflectSymbol: function () {
            return "CopySelectedElements";
        }
    }))({
        reflectSymbol: function () {
            return "CopyEstimationElementsToProject";
        }
    }))({
        reflectSymbol: function () {
            return "AddReferenceProjectComponentsToElementWithoutDuplicates";
        }
    }))({
        reflectSymbol: function () {
            return "AddReferenceProjectComponentToElement";
        }
    }))({
        reflectSymbol: function () {
            return "AddNewProjectComponentToElement";
        }
    }))({
        reflectSymbol: function () {
            return "AddExistingProjectElementSpecsToElement";
        }
    }))({
        reflectSymbol: function () {
            return "AddExistingProjectComponentsToElement";
        }
    }))({
        reflectSymbol: function () {
            return "AddElementLocation";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "AddEstimationElement";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "ElementLocationsMatrixAction";
        }
    }))({
        reflectSymbol: function () {
            return "EditEstimationElement";
        }
    }))({
        reflectSymbol: function () {
            return "EditElementLocationDescription";
        }
    }))({
        reflectSymbol: function () {
            return "EditElementLocationCode";
        }
    }))({
        reflectSymbol: function () {
            return "EditElementLocationAmount";
        }
    }))({
        reflectSymbol: function () {
            return "DeleteElementLocations";
        }
    }))({
        reflectSymbol: function () {
            return "CopySelectedElements";
        }
    }))({
        reflectSymbol: function () {
            return "CopyEstimationElementsToProject";
        }
    }))({
        reflectSymbol: function () {
            return "AddReferenceProjectComponentsToElementWithoutDuplicates";
        }
    }))({
        reflectSymbol: function () {
            return "AddReferenceProjectComponentToElement";
        }
    }))({
        reflectSymbol: function () {
            return "AddNewProjectComponentToElement";
        }
    }))({
        reflectSymbol: function () {
            return "AddExistingProjectElementSpecsToElement";
        }
    }))({
        reflectSymbol: function () {
            return "AddExistingProjectComponentsToElement";
        }
    }))({
        reflectSymbol: function () {
            return "AddElementLocation";
        }
    })))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "AddElementLocation";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "elementId";
        }
    })(Types_Element.tofuJSONElementId)(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "elementId";
        }
    })(Types_Element.tofuJSONElementId)(TofuJSON.nilWriteForeignFields)()()()))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "AddEstimationElement";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "code";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "description";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "quantity";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "rtCode";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "rtDescription";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "subProjectId";
        }
    })(Types_Project.tofuJSONSubprojectId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "unit";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsNil)()())()())()())()())()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "code";
        }
    })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "description";
        }
    })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "quantity";
        }
    })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "rtCode";
        }
    })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "rtDescription";
        }
    })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "subProjectId";
        }
    })(Types_Project.tofuJSONSubprojectId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "unit";
        }
    })(TofuJSON.readString)(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())()()())()()())()()()))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "AddExistingProjectComponentsToElement";
        }
    })(TofuJSON.readArray(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "componentId";
        }
    })(Types_Component.tofuJSONComponentId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "elementId";
        }
    })(Types_Element.tofuJSONElementId)(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "componentId";
        }
    })(Types_Component.tofuJSONComponentId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "elementId";
        }
    })(Types_Element.tofuJSONElementId)(TofuJSON.nilWriteForeignFields)()()())()()())))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "AddExistingProjectElementSpecsToElement";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "elementSpecs";
        }
    })(TofuJSON.readArray(Types_Element.tofuJSONWasmElementSpec))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "targetElementId";
        }
    })(Types_Element.tofuJSONElementId)(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "elementSpecs";
        }
    })(TofuJSON.readArray(Types_Element.tofuJSONWasmElementSpec))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "targetElementId";
        }
    })(Types_Element.tofuJSONElementId)(TofuJSON.nilWriteForeignFields)()()())()()()))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "AddNewProjectComponentToElement";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "code";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "description";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "elementId";
        }
    })(Types_Element.tofuJSONElementId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "projectId";
        }
    })(Types_Project.tofuJSONProjectId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "subprojectId";
        }
    })(Types_Project.tofuJSONSubprojectId)(TofuJSON.readFieldsNil)()())()())()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "code";
        }
    })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "description";
        }
    })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "elementId";
        }
    })(Types_Element.tofuJSONElementId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "projectId";
        }
    })(Types_Project.tofuJSONProjectId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "subprojectId";
        }
    })(Types_Project.tofuJSONSubprojectId)(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())()()()))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "AddReferenceProjectComponentToElement";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "componentId";
        }
    })(Types_Component.tofuJSONComponentId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "elementId";
        }
    })(Types_Element.tofuJSONElementId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "targetProjectId";
        }
    })(Types_Project.tofuJSONProjectId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "targetSubprojectId";
        }
    })(Types_Project.tofuJSONSubprojectId)(TofuJSON.readFieldsNil)()())()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "componentId";
        }
    })(Types_Component.tofuJSONComponentId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "elementId";
        }
    })(Types_Element.tofuJSONElementId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "targetProjectId";
        }
    })(Types_Project.tofuJSONProjectId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "targetSubprojectId";
        }
    })(Types_Project.tofuJSONSubprojectId)(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()()))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "AddReferenceProjectComponentsToElementWithoutDuplicates";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "componentIds";
        }
    })(TofuJSON.readArray(Types_Component.tofuJSONComponentId))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "copyResources";
        }
    })(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "targetElementId";
        }
    })(Types_Element.tofuJSONElementId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "targetProjectId";
        }
    })(Types_Project.tofuJSONProjectId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "targetSubprojectId";
        }
    })(Types_Project.tofuJSONSubprojectId)(TofuJSON.readFieldsNil)()())()())()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "componentIds";
        }
    })(TofuJSON.readArray(Types_Component.tofuJSONComponentId))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "copyResources";
        }
    })(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "targetElementId";
        }
    })(Types_Element.tofuJSONElementId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "targetProjectId";
        }
    })(Types_Project.tofuJSONProjectId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "targetSubprojectId";
        }
    })(Types_Project.tofuJSONSubprojectId)(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())()()()))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "CopyEstimationElementsToProject";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "copyOperationType";
        }
    })(tofuJSONEstimationElementCopyOperationType)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "estimationElementIds";
        }
    })(TofuJSON.readArray(Types_Element.tofuJSONElementId))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "targetProjectId";
        }
    })(Types_Project.tofuJSONProjectId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "targetSubprojectId";
        }
    })(Types_Project.tofuJSONSubprojectId)(TofuJSON.readFieldsNil)()())()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "copyOperationType";
        }
    })(tofuJSONEstimationElementCopyOperationType)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "estimationElementIds";
        }
    })(TofuJSON.readArray(Types_Element.tofuJSONElementId))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "targetProjectId";
        }
    })(Types_Project.tofuJSONProjectId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "targetSubprojectId";
        }
    })(Types_Project.tofuJSONSubprojectId)(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()()))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "CopySelectedElements";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "copyOperationType";
        }
    })(tofuJSONCopySelectedElementsOperationType)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "estimationElementIds";
        }
    })(TofuJSON.readArray(Types_Element.tofuJSONElementId))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "targetProjectId";
        }
    })(Types_Project.tofuJSONProjectId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "targetSubprojectId";
        }
    })(Types_Project.tofuJSONSubprojectId)(TofuJSON.readFieldsNil)()())()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "copyOperationType";
        }
    })(tofuJSONCopySelectedElementsOperationType)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "estimationElementIds";
        }
    })(TofuJSON.readArray(Types_Element.tofuJSONElementId))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "targetProjectId";
        }
    })(Types_Project.tofuJSONProjectId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "targetSubprojectId";
        }
    })(Types_Project.tofuJSONSubprojectId)(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()()))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "DeleteElementLocations";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "elementId";
        }
    })(Types_Element.tofuJSONElementId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "elementLocationIds";
        }
    })(TofuJSON.readArray(Types_Element.tofuJSONElementLocationId))(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "elementId";
        }
    })(Types_Element.tofuJSONElementId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "elementLocationIds";
        }
    })(TofuJSON.readArray(Types_Element.tofuJSONElementLocationId))(TofuJSON.nilWriteForeignFields)()()())()()()))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "EditElementLocationAmount";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "amount";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "elementId";
        }
    })(Types_Element.tofuJSONElementId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "elementLocationId";
        }
    })(Types_Element.tofuJSONElementLocationId)(TofuJSON.readFieldsNil)()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "amount";
        }
    })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "elementId";
        }
    })(Types_Element.tofuJSONElementId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "elementLocationId";
        }
    })(Types_Element.tofuJSONElementLocationId)(TofuJSON.nilWriteForeignFields)()()())()()())()()()))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "EditElementLocationCode";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "elementId";
        }
    })(Types_Element.tofuJSONElementId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "elementLocationId";
        }
    })(Types_Element.tofuJSONElementLocationId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "locationCode";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsNil)()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "elementId";
        }
    })(Types_Element.tofuJSONElementId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "elementLocationId";
        }
    })(Types_Element.tofuJSONElementLocationId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "locationCode";
        }
    })(TofuJSON.readString)(TofuJSON.nilWriteForeignFields)()()())()()())()()()))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "EditElementLocationDescription";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "description";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "elementId";
        }
    })(Types_Element.tofuJSONElementId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "elementLocationId";
        }
    })(Types_Element.tofuJSONElementLocationId)(TofuJSON.readFieldsNil)()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "description";
        }
    })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "elementId";
        }
    })(Types_Element.tofuJSONElementId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "elementLocationId";
        }
    })(Types_Element.tofuJSONElementLocationId)(TofuJSON.nilWriteForeignFields)()()())()()())()()()))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "EditEstimationElement";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "editAction";
        }
    })(tofuJSONEditEstimationElementAction)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "elementId";
        }
    })(Types_Element.tofuJSONElementId)(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "editAction";
        }
    })(tofuJSONEditEstimationElementAction)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "elementId";
        }
    })(Types_Element.tofuJSONElementId)(TofuJSON.nilWriteForeignFields)()()())()()()))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "ElementLocationsMatrixAction";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "elementId";
        }
    })(Types_Element.tofuJSONElementId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "modifiedAmounts";
        }
    })(TofuJSON.readArray(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "amount";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "description";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "elementLocationId";
        }
    })(Types_Element.tofuJSONElementLocationId)(TofuJSON.readFieldsNil)()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "amount";
        }
    })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "description";
        }
    })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "elementLocationId";
        }
    })(Types_Element.tofuJSONElementLocationId)(TofuJSON.nilWriteForeignFields)()()())()()())()()())))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "newAmounts";
        }
    })(TofuJSON.readArray(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "amount";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "description";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "locationCode";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsNil)()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "amount";
        }
    })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "description";
        }
    })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "locationCode";
        }
    })(TofuJSON.readString)(TofuJSON.nilWriteForeignFields)()()())()()())()()())))(TofuJSON.readFieldsNil)()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "elementId";
        }
    })(Types_Element.tofuJSONElementId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "modifiedAmounts";
        }
    })(TofuJSON.readArray(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "amount";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "description";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "elementLocationId";
        }
    })(Types_Element.tofuJSONElementLocationId)(TofuJSON.readFieldsNil)()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "amount";
        }
    })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "description";
        }
    })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "elementLocationId";
        }
    })(Types_Element.tofuJSONElementLocationId)(TofuJSON.nilWriteForeignFields)()()())()()())()()())))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "newAmounts";
        }
    })(TofuJSON.readArray(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "amount";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "description";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "locationCode";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsNil)()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "amount";
        }
    })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "description";
        }
    })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "locationCode";
        }
    })(TofuJSON.readString)(TofuJSON.nilWriteForeignFields)()()())()()())()()())))(TofuJSON.nilWriteForeignFields)()()())()()())()()()))()(TofuJSON.readVariantNil))))))))))))))))(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "AddElementLocation";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "elementId";
        }
    })(Types_Element.tofuJSONElementId)(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "elementId";
        }
    })(Types_Element.tofuJSONElementId)(TofuJSON.nilWriteForeignFields)()()()))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "AddEstimationElement";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "code";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "description";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "quantity";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "rtCode";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "rtDescription";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "subProjectId";
        }
    })(Types_Project.tofuJSONSubprojectId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "unit";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsNil)()())()())()())()())()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "code";
        }
    })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "description";
        }
    })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "quantity";
        }
    })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "rtCode";
        }
    })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "rtDescription";
        }
    })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "subProjectId";
        }
    })(Types_Project.tofuJSONSubprojectId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "unit";
        }
    })(TofuJSON.readString)(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())()()())()()())()()()))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "AddExistingProjectComponentsToElement";
        }
    })(TofuJSON.readArray(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "componentId";
        }
    })(Types_Component.tofuJSONComponentId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "elementId";
        }
    })(Types_Element.tofuJSONElementId)(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "componentId";
        }
    })(Types_Component.tofuJSONComponentId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "elementId";
        }
    })(Types_Element.tofuJSONElementId)(TofuJSON.nilWriteForeignFields)()()())()()())))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "AddExistingProjectElementSpecsToElement";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "elementSpecs";
        }
    })(TofuJSON.readArray(Types_Element.tofuJSONWasmElementSpec))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "targetElementId";
        }
    })(Types_Element.tofuJSONElementId)(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "elementSpecs";
        }
    })(TofuJSON.readArray(Types_Element.tofuJSONWasmElementSpec))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "targetElementId";
        }
    })(Types_Element.tofuJSONElementId)(TofuJSON.nilWriteForeignFields)()()())()()()))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "AddNewProjectComponentToElement";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "code";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "description";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "elementId";
        }
    })(Types_Element.tofuJSONElementId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "projectId";
        }
    })(Types_Project.tofuJSONProjectId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "subprojectId";
        }
    })(Types_Project.tofuJSONSubprojectId)(TofuJSON.readFieldsNil)()())()())()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "code";
        }
    })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "description";
        }
    })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "elementId";
        }
    })(Types_Element.tofuJSONElementId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "projectId";
        }
    })(Types_Project.tofuJSONProjectId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "subprojectId";
        }
    })(Types_Project.tofuJSONSubprojectId)(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())()()()))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "AddReferenceProjectComponentToElement";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "componentId";
        }
    })(Types_Component.tofuJSONComponentId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "elementId";
        }
    })(Types_Element.tofuJSONElementId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "targetProjectId";
        }
    })(Types_Project.tofuJSONProjectId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "targetSubprojectId";
        }
    })(Types_Project.tofuJSONSubprojectId)(TofuJSON.readFieldsNil)()())()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "componentId";
        }
    })(Types_Component.tofuJSONComponentId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "elementId";
        }
    })(Types_Element.tofuJSONElementId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "targetProjectId";
        }
    })(Types_Project.tofuJSONProjectId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "targetSubprojectId";
        }
    })(Types_Project.tofuJSONSubprojectId)(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()()))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "AddReferenceProjectComponentsToElementWithoutDuplicates";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "componentIds";
        }
    })(TofuJSON.readArray(Types_Component.tofuJSONComponentId))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "copyResources";
        }
    })(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "targetElementId";
        }
    })(Types_Element.tofuJSONElementId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "targetProjectId";
        }
    })(Types_Project.tofuJSONProjectId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "targetSubprojectId";
        }
    })(Types_Project.tofuJSONSubprojectId)(TofuJSON.readFieldsNil)()())()())()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "componentIds";
        }
    })(TofuJSON.readArray(Types_Component.tofuJSONComponentId))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "copyResources";
        }
    })(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "targetElementId";
        }
    })(Types_Element.tofuJSONElementId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "targetProjectId";
        }
    })(Types_Project.tofuJSONProjectId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "targetSubprojectId";
        }
    })(Types_Project.tofuJSONSubprojectId)(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())()()()))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "CopyEstimationElementsToProject";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "copyOperationType";
        }
    })(tofuJSONEstimationElementCopyOperationType)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "estimationElementIds";
        }
    })(TofuJSON.readArray(Types_Element.tofuJSONElementId))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "targetProjectId";
        }
    })(Types_Project.tofuJSONProjectId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "targetSubprojectId";
        }
    })(Types_Project.tofuJSONSubprojectId)(TofuJSON.readFieldsNil)()())()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "copyOperationType";
        }
    })(tofuJSONEstimationElementCopyOperationType)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "estimationElementIds";
        }
    })(TofuJSON.readArray(Types_Element.tofuJSONElementId))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "targetProjectId";
        }
    })(Types_Project.tofuJSONProjectId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "targetSubprojectId";
        }
    })(Types_Project.tofuJSONSubprojectId)(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()()))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "CopySelectedElements";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "copyOperationType";
        }
    })(tofuJSONCopySelectedElementsOperationType)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "estimationElementIds";
        }
    })(TofuJSON.readArray(Types_Element.tofuJSONElementId))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "targetProjectId";
        }
    })(Types_Project.tofuJSONProjectId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "targetSubprojectId";
        }
    })(Types_Project.tofuJSONSubprojectId)(TofuJSON.readFieldsNil)()())()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "copyOperationType";
        }
    })(tofuJSONCopySelectedElementsOperationType)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "estimationElementIds";
        }
    })(TofuJSON.readArray(Types_Element.tofuJSONElementId))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "targetProjectId";
        }
    })(Types_Project.tofuJSONProjectId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "targetSubprojectId";
        }
    })(Types_Project.tofuJSONSubprojectId)(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()()))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "DeleteElementLocations";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "elementId";
        }
    })(Types_Element.tofuJSONElementId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "elementLocationIds";
        }
    })(TofuJSON.readArray(Types_Element.tofuJSONElementLocationId))(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "elementId";
        }
    })(Types_Element.tofuJSONElementId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "elementLocationIds";
        }
    })(TofuJSON.readArray(Types_Element.tofuJSONElementLocationId))(TofuJSON.nilWriteForeignFields)()()())()()()))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "EditElementLocationAmount";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "amount";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "elementId";
        }
    })(Types_Element.tofuJSONElementId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "elementLocationId";
        }
    })(Types_Element.tofuJSONElementLocationId)(TofuJSON.readFieldsNil)()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "amount";
        }
    })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "elementId";
        }
    })(Types_Element.tofuJSONElementId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "elementLocationId";
        }
    })(Types_Element.tofuJSONElementLocationId)(TofuJSON.nilWriteForeignFields)()()())()()())()()()))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "EditElementLocationCode";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "elementId";
        }
    })(Types_Element.tofuJSONElementId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "elementLocationId";
        }
    })(Types_Element.tofuJSONElementLocationId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "locationCode";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsNil)()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "elementId";
        }
    })(Types_Element.tofuJSONElementId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "elementLocationId";
        }
    })(Types_Element.tofuJSONElementLocationId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "locationCode";
        }
    })(TofuJSON.readString)(TofuJSON.nilWriteForeignFields)()()())()()())()()()))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "EditElementLocationDescription";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "description";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "elementId";
        }
    })(Types_Element.tofuJSONElementId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "elementLocationId";
        }
    })(Types_Element.tofuJSONElementLocationId)(TofuJSON.readFieldsNil)()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "description";
        }
    })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "elementId";
        }
    })(Types_Element.tofuJSONElementId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "elementLocationId";
        }
    })(Types_Element.tofuJSONElementLocationId)(TofuJSON.nilWriteForeignFields)()()())()()())()()()))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "EditEstimationElement";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "editAction";
        }
    })(tofuJSONEditEstimationElementAction)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "elementId";
        }
    })(Types_Element.tofuJSONElementId)(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "editAction";
        }
    })(tofuJSONEditEstimationElementAction)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "elementId";
        }
    })(Types_Element.tofuJSONElementId)(TofuJSON.nilWriteForeignFields)()()())()()()))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "ElementLocationsMatrixAction";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "elementId";
        }
    })(Types_Element.tofuJSONElementId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "modifiedAmounts";
        }
    })(TofuJSON.readArray(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "amount";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "description";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "elementLocationId";
        }
    })(Types_Element.tofuJSONElementLocationId)(TofuJSON.readFieldsNil)()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "amount";
        }
    })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "description";
        }
    })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "elementLocationId";
        }
    })(Types_Element.tofuJSONElementLocationId)(TofuJSON.nilWriteForeignFields)()()())()()())()()())))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "newAmounts";
        }
    })(TofuJSON.readArray(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "amount";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "description";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "locationCode";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsNil)()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "amount";
        }
    })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "description";
        }
    })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "locationCode";
        }
    })(TofuJSON.readString)(TofuJSON.nilWriteForeignFields)()()())()()())()()())))(TofuJSON.readFieldsNil)()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "elementId";
        }
    })(Types_Element.tofuJSONElementId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "modifiedAmounts";
        }
    })(TofuJSON.readArray(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "amount";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "description";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "elementLocationId";
        }
    })(Types_Element.tofuJSONElementLocationId)(TofuJSON.readFieldsNil)()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "amount";
        }
    })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "description";
        }
    })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "elementLocationId";
        }
    })(Types_Element.tofuJSONElementLocationId)(TofuJSON.nilWriteForeignFields)()()())()()())()()())))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "newAmounts";
        }
    })(TofuJSON.readArray(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "amount";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "description";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "locationCode";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsNil)()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "amount";
        }
    })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "description";
        }
    })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "locationCode";
        }
    })(TofuJSON.readString)(TofuJSON.nilWriteForeignFields)()()())()()())()()())))(TofuJSON.nilWriteForeignFields)()()())()()())()()()))()(TofuJSON.nilWriteForeignVariant))))))))))))))))
};
var eqTargetComponentAction = {
    eq: function (x) {
        return function (y) {
            if (x instanceof InPlaceResourceUpdate && y instanceof InPlaceResourceUpdate) {
                return true;
            };
            if (x instanceof SplitElementComponent && y instanceof SplitElementComponent) {
                return Data_Eq.eq(Types_Component.eqComponentId)(x.value0.componentId)(y.value0.componentId) && Data_Eq.eq(Types_Element.eqElementId)(x.value0.elementId)(y.value0.elementId) && Data_Eq.eq(Types_Newtypes.eqElementSpecId)(x.value0.elementSpecId)(y.value0.elementSpecId);
            };
            return false;
        };
    }
};
var eqMatchingRules = {
    eq: function (x) {
        return function (y) {
            if (x instanceof InternalOrgResourcePriceIdMatch && y instanceof InternalOrgResourcePriceIdMatch) {
                return true;
            };
            if (x instanceof CostClassGroupSortingNumberMatch && y instanceof CostClassGroupSortingNumberMatch) {
                return true;
            };
            return false;
        };
    }
};
var eqEstimationResourceActionParams = {
    eq: function (x) {
        return function (y) {
            if (x instanceof AddEstimationResource && y instanceof AddEstimationResource) {
                return Data_Eq.eq(Types_Newtypes.eqCostClassCode)(x.value0.costClassCode)(y.value0.costClassCode) && Data_Eq.eq(Data_Maybe.eqMaybe(Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()({
                    reflectSymbol: function () {
                        return "wastePercentage";
                    }
                })(Data_Eq.eqNumber))()({
                    reflectSymbol: function () {
                        return "vendorCode";
                    }
                })(Data_Maybe.eqMaybe(Data_Eq.eqString)))()({
                    reflectSymbol: function () {
                        return "vendorBatchSize";
                    }
                })(Data_Maybe.eqMaybe(Data_Eq.eqNumber)))()({
                    reflectSymbol: function () {
                        return "vendor";
                    }
                })(Data_Maybe.eqMaybe(Data_Eq.eqString)))()({
                    reflectSymbol: function () {
                        return "validDate";
                    }
                })(Data_Maybe.eqMaybe(Data_Eq.eqString)))()({
                    reflectSymbol: function () {
                        return "unitConsumptionRate";
                    }
                })(Data_Eq.eqNumber))()({
                    reflectSymbol: function () {
                        return "taxPercentage";
                    }
                })(Types_Unit.eqTaxrate))()({
                    reflectSymbol: function () {
                        return "sortingNumber";
                    }
                })(Data_Maybe.eqMaybe(Data_Eq.eqString)))()({
                    reflectSymbol: function () {
                        return "resourceClassificationGroup";
                    }
                })(Data_Maybe.eqMaybe(Data_Eq.eqString)))()({
                    reflectSymbol: function () {
                        return "multiplier";
                    }
                })(Data_Eq.eqNumber))()({
                    reflectSymbol: function () {
                        return "eanCode";
                    }
                })(Data_Maybe.eqMaybe(Data_Eq.eqString)))()({
                    reflectSymbol: function () {
                        return "discountPercentage";
                    }
                })(Data_Eq.eqNumber))()({
                    reflectSymbol: function () {
                        return "discountGroupCode";
                    }
                })(Data_Maybe.eqMaybe(Data_Eq.eqString)))()({
                    reflectSymbol: function () {
                        return "currency";
                    }
                })(Data_Maybe.eqMaybe(Types_Project.eqCurrency)))()({
                    reflectSymbol: function () {
                        return "contract";
                    }
                })(Data_Maybe.eqMaybe(Data_Eq.eqString)))()({
                    reflectSymbol: function () {
                        return "changeDate";
                    }
                })(Data_Maybe.eqMaybe(Data_Eq.eqString)))))(x.value0.details)(y.value0.details) && Data_Eq.eq(Types_Component.eqComponentId)(x.value0.estimationComponentId)(y.value0.estimationComponentId) && Data_Eq.eq(Data_Maybe.eqMaybe(Types_ClassificationGroup.eqClassificationGroupCode))(x.value0.group)(y.value0.group) && Data_Eq.eq(Data_Maybe.eqMaybe(Data_Eq.eqNumber))(x.value0.index)(y.value0.index) && x.value0.isCostClassResource === y.value0.isCostClassResource && x.value0.name === y.value0.name && Data_Eq.eq(Data_Maybe.eqMaybe(Data_Eq.eqInt))(x.value0.orgResourcePriceId)(y.value0.orgResourcePriceId) && Data_Eq.eq(Data_Maybe.eqMaybe(Types_Component.eqOrgEstimationResourceId))(x.value0.orgResourcePriceListId)(y.value0.orgResourcePriceListId) && x.value0.price === y.value0.price && Data_Eq.eq(Data_Maybe.eqMaybe(Data_Eq.eqString))(x.value0.unit)(y.value0.unit);
            };
            if (x instanceof AddAndDeleteMultipleResources && y instanceof AddAndDeleteMultipleResources) {
                return Data_Eq.eq(Data_Eq.eqArray(Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()({
                    reflectSymbol: function () {
                        return "unit";
                    }
                })(Data_Maybe.eqMaybe(Data_Eq.eqString)))()({
                    reflectSymbol: function () {
                        return "price";
                    }
                })(Data_Eq.eqNumber))()({
                    reflectSymbol: function () {
                        return "orgResourcePriceListId";
                    }
                })(Data_Maybe.eqMaybe(Types_Component.eqOrgEstimationResourceId)))()({
                    reflectSymbol: function () {
                        return "orgResourcePriceId";
                    }
                })(Data_Maybe.eqMaybe(Data_Eq.eqInt)))()({
                    reflectSymbol: function () {
                        return "name";
                    }
                })(Data_Eq.eqString))()({
                    reflectSymbol: function () {
                        return "isCostClassResource";
                    }
                })(Data_Eq.eqBoolean))()({
                    reflectSymbol: function () {
                        return "index";
                    }
                })(Data_Maybe.eqMaybe(Data_Eq.eqNumber)))()({
                    reflectSymbol: function () {
                        return "group";
                    }
                })(Data_Maybe.eqMaybe(Types_ClassificationGroup.eqClassificationGroupCode)))()({
                    reflectSymbol: function () {
                        return "estimationComponentId";
                    }
                })(Types_Component.eqComponentId))()({
                    reflectSymbol: function () {
                        return "details";
                    }
                })(Data_Maybe.eqMaybe(Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()({
                    reflectSymbol: function () {
                        return "wastePercentage";
                    }
                })(Data_Eq.eqNumber))()({
                    reflectSymbol: function () {
                        return "vendorCode";
                    }
                })(Data_Maybe.eqMaybe(Data_Eq.eqString)))()({
                    reflectSymbol: function () {
                        return "vendorBatchSize";
                    }
                })(Data_Maybe.eqMaybe(Data_Eq.eqNumber)))()({
                    reflectSymbol: function () {
                        return "vendor";
                    }
                })(Data_Maybe.eqMaybe(Data_Eq.eqString)))()({
                    reflectSymbol: function () {
                        return "validDate";
                    }
                })(Data_Maybe.eqMaybe(Data_Eq.eqString)))()({
                    reflectSymbol: function () {
                        return "unitConsumptionRate";
                    }
                })(Data_Eq.eqNumber))()({
                    reflectSymbol: function () {
                        return "taxPercentage";
                    }
                })(Types_Unit.eqTaxrate))()({
                    reflectSymbol: function () {
                        return "sortingNumber";
                    }
                })(Data_Maybe.eqMaybe(Data_Eq.eqString)))()({
                    reflectSymbol: function () {
                        return "resourceClassificationGroup";
                    }
                })(Data_Maybe.eqMaybe(Data_Eq.eqString)))()({
                    reflectSymbol: function () {
                        return "multiplier";
                    }
                })(Data_Eq.eqNumber))()({
                    reflectSymbol: function () {
                        return "eanCode";
                    }
                })(Data_Maybe.eqMaybe(Data_Eq.eqString)))()({
                    reflectSymbol: function () {
                        return "discountPercentage";
                    }
                })(Data_Eq.eqNumber))()({
                    reflectSymbol: function () {
                        return "discountGroupCode";
                    }
                })(Data_Maybe.eqMaybe(Data_Eq.eqString)))()({
                    reflectSymbol: function () {
                        return "currency";
                    }
                })(Data_Maybe.eqMaybe(Types_Project.eqCurrency)))()({
                    reflectSymbol: function () {
                        return "contract";
                    }
                })(Data_Maybe.eqMaybe(Data_Eq.eqString)))()({
                    reflectSymbol: function () {
                        return "changeDate";
                    }
                })(Data_Maybe.eqMaybe(Data_Eq.eqString))))))()({
                    reflectSymbol: function () {
                        return "costClassCode";
                    }
                })(Types_Newtypes.eqCostClassCode))))(x.value0.addResources)(y.value0.addResources) && Data_Eq.eq(Data_Eq.eqArray(Types_Component.eqEstimationResourceId))(x.value0.deleteResources)(y.value0.deleteResources);
            };
            if (x instanceof CopyResourcesToComponent && y instanceof CopyResourcesToComponent) {
                return Data_Eq.eq(Types_Component.eqComponentId)(x.value0.componentId)(y.value0.componentId) && Data_Eq.eq(Data_Eq.eqArray(Types_Component.eqEstimationResourceId))(x.value0.resourceIds)(y.value0.resourceIds);
            };
            if (x instanceof ReplaceComponentResources && y instanceof ReplaceComponentResources) {
                return Data_Eq.eq(Types_Component.eqComponentId)(x.value0.componentId)(y.value0.componentId) && Data_Eq.eq(Types_Component.eqComponentId)(x.value0.referenceComponentId)(y.value0.referenceComponentId);
            };
            if (x instanceof AddResourcesToComponentFromOrgPriceList && y instanceof AddResourcesToComponentFromOrgPriceList) {
                return Data_Eq.eq(Types_Component.eqComponentId)(x.value0.componentId)(y.value0.componentId) && Data_Eq.eq(Data_Eq.eqArray(Data_Eq.eqInt))(x.value0.orgResourcePriceIds)(y.value0.orgResourcePriceIds);
            };
            if (x instanceof ReplaceComponentResourceFromOrgPriceList && y instanceof ReplaceComponentResourceFromOrgPriceList) {
                return Data_Eq.eq(Data_Eq.eqArray(Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()({
                    reflectSymbol: function () {
                        return "replaceTargetResourceId";
                    }
                })(Types_Component.eqEstimationResourceId))()({
                    reflectSymbol: function () {
                        return "orgResourcePriceIds";
                    }
                })(Data_Eq.eqArray(Data_Eq.eqInt)))()({
                    reflectSymbol: function () {
                        return "componentId";
                    }
                })(Types_Component.eqComponentId))))(x.value0)(y.value0);
            };
            if (x instanceof ReplaceComponentResourceFromProject && y instanceof ReplaceComponentResourceFromProject) {
                return Data_Eq.eq(Types_Component.eqComponentId)(x.value0.componentId)(y.value0.componentId) && Data_Eq.eq(Types_Component.eqEstimationResourceId)(x.value0.newResourceId)(y.value0.newResourceId) && Data_Eq.eq(Types_Component.eqEstimationResourceId)(x.value0.replaceTargetResourceId)(y.value0.replaceTargetResourceId);
            };
            if (x instanceof EditEstimationResource && y instanceof EditEstimationResource) {
                return Data_Eq.eq(Data_Eq.eqArray(Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()({
                    reflectSymbol: function () {
                        return "resourceParams";
                    }
                })(Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()({
                    reflectSymbol: function () {
                        return "wastePercentage";
                    }
                })(Data_Nullable.eqNullable(Data_Eq.eqNumber)))()({
                    reflectSymbol: function () {
                        return "vendorUnit";
                    }
                })(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
                    reflectSymbol: function () {
                        return "vendorCode";
                    }
                })(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
                    reflectSymbol: function () {
                        return "vendorBatchSize";
                    }
                })(Data_Nullable.eqNullable(Data_Eq.eqNumber)))()({
                    reflectSymbol: function () {
                        return "vendor";
                    }
                })(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
                    reflectSymbol: function () {
                        return "validDate";
                    }
                })(Data_Nullable.eqNullable(Types_JSDateString.eqJSDateString)))()({
                    reflectSymbol: function () {
                        return "unitConsumptionRate";
                    }
                })(Data_Nullable.eqNullable(Data_Eq.eqNumber)))()({
                    reflectSymbol: function () {
                        return "unit";
                    }
                })(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
                    reflectSymbol: function () {
                        return "taxRate";
                    }
                })(Data_Nullable.eqNullable(Types_Unit.eqTaxrate)))()({
                    reflectSymbol: function () {
                        return "sortingNumber";
                    }
                })(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
                    reflectSymbol: function () {
                        return "resourceClassificationGroup";
                    }
                })(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
                    reflectSymbol: function () {
                        return "pricePerUnit";
                    }
                })(Data_Nullable.eqNullable(Data_Eq.eqNumber)))()({
                    reflectSymbol: function () {
                        return "orgResourcePriceListId";
                    }
                })(Data_Nullable.eqNullable(Types_Component.eqOrgEstimationResourceId)))()({
                    reflectSymbol: function () {
                        return "orgResourcePriceId";
                    }
                })(Data_Nullable.eqNullable(Data_Eq.eqInt)))()({
                    reflectSymbol: function () {
                        return "name";
                    }
                })(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
                    reflectSymbol: function () {
                        return "index";
                    }
                })(Data_Nullable.eqNullable(Data_Eq.eqNumber)))()({
                    reflectSymbol: function () {
                        return "group";
                    }
                })(Data_Nullable.eqNullable(Types_ClassificationGroup.eqClassificationGroupCode)))()({
                    reflectSymbol: function () {
                        return "eanCode";
                    }
                })(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
                    reflectSymbol: function () {
                        return "discountPercentage";
                    }
                })(Data_Nullable.eqNullable(Data_Eq.eqNumber)))()({
                    reflectSymbol: function () {
                        return "currencyId";
                    }
                })(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
                    reflectSymbol: function () {
                        return "costClassCode";
                    }
                })(Data_Nullable.eqNullable(Types_Newtypes.eqCostClassCode)))()({
                    reflectSymbol: function () {
                        return "changedDate";
                    }
                })(Data_Nullable.eqNullable(Types_JSDateString.eqJSDateString)))))()({
                    reflectSymbol: function () {
                        return "resourceId";
                    }
                })(Types_Component.eqEstimationResourceId))))(x.value0)(y.value0);
            };
            if (x instanceof EditEstimationCostClassPrice && y instanceof EditEstimationCostClassPrice) {
                return Data_Eq.eq(Types_Component.eqComponentId)(x.value0.componentId)(y.value0.componentId) && Data_Eq.eq(Types_Newtypes.eqCostClassCode)(x.value0.costClassCode)(y.value0.costClassCode) && x.value0.pricePerUnit === y.value0.pricePerUnit && Data_Eq.eq(Data_Maybe.eqMaybe(Types_Component.eqEstimationResourceId))(x.value0.resourceId)(y.value0.resourceId);
            };
            if (x instanceof EditEstimationCostClassPriceConsumptionRate && y instanceof EditEstimationCostClassPriceConsumptionRate) {
                return Data_Eq.eq(Types_Component.eqComponentId)(x.value0.componentId)(y.value0.componentId) && Data_Eq.eq(Types_Newtypes.eqCostClassCode)(x.value0.costClassCode)(y.value0.costClassCode) && Data_Eq.eq(Data_Maybe.eqMaybe(Types_Component.eqEstimationResourceId))(x.value0.resourceId)(y.value0.resourceId) && x.value0.unitConsumptionRate === y.value0.unitConsumptionRate;
            };
            if (x instanceof EditEstimationCostClassPriceAndConsumptionRate && y instanceof EditEstimationCostClassPriceAndConsumptionRate) {
                return Data_Eq.eq(Types_Component.eqComponentId)(x.value0.componentId)(y.value0.componentId) && Data_Eq.eq(Types_Newtypes.eqCostClassCode)(x.value0.costClassCode)(y.value0.costClassCode) && x.value0.pricePerUnit === y.value0.pricePerUnit && Data_Eq.eq(Data_Maybe.eqMaybe(Types_Component.eqEstimationResourceId))(x.value0.resourceId)(y.value0.resourceId) && x.value0.unitConsumptionRate === y.value0.unitConsumptionRate;
            };
            if (x instanceof DeleteEstimationResources && y instanceof DeleteEstimationResources) {
                return Data_Eq.eq(Data_Eq.eqArray(Types_Component.eqEstimationResourceId))(x.value0)(y.value0);
            };
            if (x instanceof SetEstimationResourcesCostClass && y instanceof SetEstimationResourcesCostClass) {
                return Data_Eq.eq(Types_Newtypes.eqCostClassCode)(x.value0.costClassCode)(y.value0.costClassCode) && Data_Eq.eq(Data_Eq.eqArray(Types_Component.eqEstimationResourceId))(x.value0.estimationResourceIds)(y.value0.estimationResourceIds);
            };
            if (x instanceof SetEstimationResourcesGroup && y instanceof SetEstimationResourcesGroup) {
                return Data_Eq.eq(Data_Eq.eqArray(Types_Component.eqEstimationResourceId))(x.value0.estimationResourceIds)(y.value0.estimationResourceIds) && Data_Eq.eq(Data_Nullable.eqNullable(Types_ClassificationGroup.eqClassificationGroupCode))(x.value0.group)(y.value0.group);
            };
            return false;
        };
    }
};
var eqEstimationLocationAction = {
    eq: function (x) {
        return function (y) {
            if (x instanceof UpdateEstimationLocationAmount && y instanceof UpdateEstimationLocationAmount) {
                return x.value0.amount === y.value0.amount && Data_Eq.eq(Types_Component.eqComponentId)(x.value0.componentId)(y.value0.componentId) && Data_Eq.eq(Types_Component.eqEstimationLocationId)(x.value0.estimationLocationId)(y.value0.estimationLocationId);
            };
            if (x instanceof UpdateEstimationLocationCode && y instanceof UpdateEstimationLocationCode) {
                return x.value0.code === y.value0.code && Data_Eq.eq(Types_Component.eqComponentId)(x.value0.componentId)(y.value0.componentId) && Data_Eq.eq(Types_Component.eqEstimationLocationId)(x.value0.estimationLocationId)(y.value0.estimationLocationId);
            };
            if (x instanceof UpdateEstimationLocationDescription && y instanceof UpdateEstimationLocationDescription) {
                return Data_Eq.eq(Types_Component.eqComponentId)(x.value0.componentId)(y.value0.componentId) && x.value0.description === y.value0.description && Data_Eq.eq(Types_Component.eqEstimationLocationId)(x.value0.estimationLocationId)(y.value0.estimationLocationId);
            };
            if (x instanceof AddEstimationLocation && y instanceof AddEstimationLocation) {
                return Data_Eq.eq(Types_Component.eqComponentId)(x.value0.componentId)(y.value0.componentId);
            };
            if (x instanceof DeleteEstimationLocations && y instanceof DeleteEstimationLocations) {
                return Data_Eq.eq(Types_Component.eqComponentId)(x.value0.componentId)(y.value0.componentId) && Data_Eq.eq(Data_Eq.eqArray(Types_Component.eqEstimationLocationId))(x.value0.estimationLocationIds)(y.value0.estimationLocationIds);
            };
            if (x instanceof EstimationLocationsMatrixAction && y instanceof EstimationLocationsMatrixAction) {
                return Data_Eq.eq(Types_Component.eqComponentId)(x.value0.componentId)(y.value0.componentId) && Data_Eq.eq(Data_Eq.eqArray(Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()({
                    reflectSymbol: function () {
                        return "estimationLocationId";
                    }
                })(Types_Component.eqEstimationLocationId))()({
                    reflectSymbol: function () {
                        return "description";
                    }
                })(Data_Eq.eqString))()({
                    reflectSymbol: function () {
                        return "amount";
                    }
                })(Data_Eq.eqNumber))))(x.value0.modifiedAmounts)(y.value0.modifiedAmounts) && Data_Eq.eq(Data_Eq.eqArray(Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()({
                    reflectSymbol: function () {
                        return "locationCode";
                    }
                })(Data_Eq.eqString))()({
                    reflectSymbol: function () {
                        return "description";
                    }
                })(Data_Eq.eqString))()({
                    reflectSymbol: function () {
                        return "amount";
                    }
                })(Data_Eq.eqNumber))))(x.value0.newAmounts)(y.value0.newAmounts);
            };
            return false;
        };
    }
};
var eqEstimationElementCopyOperationType = {
    eq: function (x) {
        return function (y) {
            if (x instanceof CopyElementOnly && y instanceof CopyElementOnly) {
                return true;
            };
            if (x instanceof CopyElementWithComponents && y instanceof CopyElementWithComponents) {
                return x.value0.addWildcardLocation === y.value0.addWildcardLocation && x.value0.copyResources === y.value0.copyResources;
            };
            if (x instanceof CopyElementWithinProject && y instanceof CopyElementWithinProject) {
                return true;
            };
            if (x instanceof AddElementSpecs && y instanceof AddElementSpecs) {
                return x.value0.copyResources === y.value0.copyResources && Data_Eq.eq(Data_Eq.eqArray(Types_Newtypes.eqElementSpecId))(x.value0.elementSpecIds)(y.value0.elementSpecIds) && Data_Eq.eq(Data_Eq.eqArray(Types_Element.eqElementMeasure))(x.value0.noCopyingForMeasures)(y.value0.noCopyingForMeasures) && Data_Eq.eq(Types_Element.eqElementId)(x.value0.targetElementId)(y.value0.targetElementId);
            };
            return false;
        };
    }
};
var eqEstimationComponentCopyOperationType = {
    eq: function (x) {
        return function (y) {
            if (x instanceof CopyComponentOnly && y instanceof CopyComponentOnly) {
                return true;
            };
            if (x instanceof CopyComponentWithResources && y instanceof CopyComponentWithResources) {
                return true;
            };
            if (x instanceof CopyComponentWithResourcesAndLocations && y instanceof CopyComponentWithResourcesAndLocations) {
                return true;
            };
            return false;
        };
    }
};
var eqElementUpdateParamsType = {
    eq: function (x) {
        return function (y) {
            if (x instanceof ElementUpdateAddElementSpecs && y instanceof ElementUpdateAddElementSpecs) {
                return x.value0.copyResources === y.value0.copyResources && Data_Eq.eq(Data_Eq.eqArray(Types_Newtypes.eqElementSpecId))(x.value0.elementSpecIds)(y.value0.elementSpecIds) && Data_Eq.eq(Data_Eq.eqArray(Types_Element.eqElementMeasure))(x.value0.noCopyingForMeasures)(y.value0.noCopyingForMeasures) && Data_Eq.eq(Types_Element.eqElementId)(x.value0.targetElementId)(y.value0.targetElementId);
            };
            if (x instanceof ElementUpdateReplaceElementSpecs && y instanceof ElementUpdateReplaceElementSpecs) {
                return x.value0.copyResources === y.value0.copyResources && Data_Eq.eq(Data_Eq.eqArray(Types_Newtypes.eqElementSpecId))(x.value0.elementSpecIds)(y.value0.elementSpecIds) && Data_Eq.eq(Data_Eq.eqArray(Types_Element.eqElementMeasure))(x.value0.noCopyingForMeasures)(y.value0.noCopyingForMeasures) && Data_Eq.eq(Types_Element.eqElementId)(x.value0.targetElementId)(y.value0.targetElementId);
            };
            if (x instanceof ElementUpdateReplaceElement && y instanceof ElementUpdateReplaceElement) {
                return Data_Eq.eq(Data_Eq.eqArray(Types_Newtypes.eqElementSpecId))(x.value0.elementSpecIds)(y.value0.elementSpecIds) && Data_Eq.eq(Types_Element.eqElementId)(x.value0.targetElementId)(y.value0.targetElementId);
            };
            return false;
        };
    }
};
var eqEditEstimationElementAction = {
    eq: function (x) {
        return function (y) {
            if (x instanceof EditEstimationElementDescription && y instanceof EditEstimationElementDescription) {
                return x.value0.description === y.value0.description;
            };
            if (x instanceof EditEstimationElementRtCode && y instanceof EditEstimationElementRtCode) {
                return x.value0.rtCode === y.value0.rtCode;
            };
            if (x instanceof EditEstimationElementRtDescription && y instanceof EditEstimationElementRtDescription) {
                return x.value0.rtDescription === y.value0.rtDescription;
            };
            if (x instanceof EditEstimationElementUnit && y instanceof EditEstimationElementUnit) {
                return x.value0.unit === y.value0.unit;
            };
            if (x instanceof EditEstimationElementMemo && y instanceof EditEstimationElementMemo) {
                return x.value0.memo === y.value0.memo;
            };
            if (x instanceof EditEstimationElementSubprojectId && y instanceof EditEstimationElementSubprojectId) {
                return Data_Eq.eq(Types_Project.eqSubprojectId)(x.value0.subprojectId)(y.value0.subprojectId);
            };
            if (x instanceof EditEstimationElementCode && y instanceof EditEstimationElementCode) {
                return Data_Eq.eq(Types_Element.eqElementCode)(x.value0.code)(y.value0.code);
            };
            if (x instanceof AddEstimationElementAmountForWildcardLocation && y instanceof AddEstimationElementAmountForWildcardLocation) {
                return x.value0.amount === y.value0.amount;
            };
            if (x instanceof EditEstimationElementAmountForSingleLocation && y instanceof EditEstimationElementAmountForSingleLocation) {
                return x.value0.amount === y.value0.amount && Data_Eq.eq(Types_Element.eqElementLocationId)(x.value0.elementLocationId)(y.value0.elementLocationId);
            };
            if (x instanceof EditEstimationElementGroupFlag && y instanceof EditEstimationElementGroupFlag) {
                return Data_Eq.eq(Types_Element.eqGroupFlag)(x.value0.groupFlag)(y.value0.groupFlag);
            };
            return false;
        };
    }
};
var eqEditEstimationComponentAction = {
    eq: function (x) {
        return function (y) {
            if (x instanceof EditEstimationComponentDescription && y instanceof EditEstimationComponentDescription) {
                return x.value0.description === y.value0.description;
            };
            if (x instanceof EditEstimationComponentCode && y instanceof EditEstimationComponentCode) {
                return x.value0.code === y.value0.code && Data_Eq.eq(Data_Maybe.eqMaybe(Types_Project.eqSubprojectId))(x.value0.subprojectId)(y.value0.subprojectId);
            };
            if (x instanceof EditEstimationComponentGroup && y instanceof EditEstimationComponentGroup) {
                return x.value0.group === y.value0.group;
            };
            if (x instanceof EditEstimationComponentCostGroup && y instanceof EditEstimationComponentCostGroup) {
                return x.value0.costGroupCode === y.value0.costGroupCode;
            };
            if (x instanceof EditEstimationComponentSubprojectId && y instanceof EditEstimationComponentSubprojectId) {
                return Data_Eq.eq(Data_Maybe.eqMaybe(Data_Eq.eqString))(x.value0.code)(y.value0.code) && Data_Eq.eq(Types_Project.eqSubprojectId)(x.value0.subprojectId)(y.value0.subprojectId);
            };
            if (x instanceof EditEstimationComponentUnit && y instanceof EditEstimationComponentUnit) {
                return x.value0.unit === y.value0.unit;
            };
            if (x instanceof EditEstimationComponentChecked && y instanceof EditEstimationComponentChecked) {
                return x.value0.checked === y.value0.checked;
            };
            if (x instanceof EditEstimationComponentFlagged && y instanceof EditEstimationComponentFlagged) {
                return x.value0.flagged === y.value0.flagged;
            };
            if (x instanceof EditEstimationComponentQuantity && y instanceof EditEstimationComponentQuantity) {
                return x.value0.quantity === y.value0.quantity;
            };
            if (x instanceof EditEstimationComponentMemo && y instanceof EditEstimationComponentMemo) {
                return x.value0.memo === y.value0.memo;
            };
            if (x instanceof EditEstimationComponentClass && y instanceof EditEstimationComponentClass) {
                return x.value0.codeClass === y.value0.codeClass;
            };
            return false;
        };
    }
};
var eqEstimationComponentAction = {
    eq: function (x) {
        return function (y) {
            if (x instanceof AddEstimationComponent && y instanceof AddEstimationComponent) {
                return x.value0.code === y.value0.code && Data_Eq.eq(Types_Component.eqComponentType)(x.value0.componentType)(y.value0.componentType) && x.value0.description === y.value0.description && x.value0.memo === y.value0.memo && x.value0.quantity === y.value0.quantity && Data_Eq.eq(Types_Project.eqSubprojectId)(x.value0.subProjectId)(y.value0.subProjectId) && Data_Eq.eq(Data_Maybe.eqMaybe(Data_Eq.eqString))(x.value0.unit)(y.value0.unit);
            };
            if (x instanceof EditEstimationComponent && y instanceof EditEstimationComponent) {
                return Data_Eq.eq(Types_Component.eqComponentId)(x.value0.componentId)(y.value0.componentId) && Data_Eq.eq(eqEditEstimationComponentAction)(x.value0.editAction)(y.value0.editAction);
            };
            if (x instanceof EditEstimationComponents && y instanceof EditEstimationComponents) {
                return Data_Eq.eq(Data_Eq.eqArray(Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()({
                    reflectSymbol: function () {
                        return "editAction";
                    }
                })(eqEditEstimationComponentAction))()({
                    reflectSymbol: function () {
                        return "componentId";
                    }
                })(Types_Component.eqComponentId))))(x.value0)(y.value0);
            };
            if (x instanceof ResetEstimationLocations && y instanceof ResetEstimationLocations) {
                return Data_Eq.eq(Data_Eq.eqArray(Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()({
                    reflectSymbol: function () {
                        return "componentId";
                    }
                })(Types_Component.eqComponentId))))(x.value0)(y.value0);
            };
            if (x instanceof CopyEstimationComponentsToProject && y instanceof CopyEstimationComponentsToProject) {
                return x.value0.allowDuplicates === y.value0.allowDuplicates && Data_Eq.eq(eqEstimationComponentCopyOperationType)(x.value0.copyOperationType)(y.value0.copyOperationType) && Data_Eq.eq(Data_Eq.eqArray(Types_Component.eqComponentId))(x.value0.estimationComponentIds)(y.value0.estimationComponentIds) && Data_Eq.eq(Types_Project.eqProjectId)(x.value0.targetProjectId)(y.value0.targetProjectId) && Data_Eq.eq(Types_Project.eqSubprojectId)(x.value0.targetSubprojectId)(y.value0.targetSubprojectId);
            };
            if (x instanceof DeleteEstimationComponents && y instanceof DeleteEstimationComponents) {
                return Data_Eq.eq(Data_Eq.eqArray(Types_Component.eqComponentId))(x.value0)(y.value0);
            };
            if (x instanceof ReplaceEstimationComponent && y instanceof ReplaceEstimationComponent) {
                return Data_Eq.eq(Types_Project.eqProjectId)(x.value0.projectId)(y.value0.projectId) && Data_Eq.eq(Types_Component.eqComponentId)(x.value0.referenceComponentId)(y.value0.referenceComponentId) && Data_Eq.eq(Types_Component.eqComponentId)(x.value0.targetComponentId)(y.value0.targetComponentId) && Data_Eq.eq(Data_Maybe.eqMaybe(Types_Element.eqElementId))(x.value0.targetElementId)(y.value0.targetElementId) && Data_Eq.eq(Data_Maybe.eqMaybe(Types_Newtypes.eqElementSpecId))(x.value0.targetElementSpecId)(y.value0.targetElementSpecId);
            };
            if (x instanceof CopyComponentAndApplyEditAction && y instanceof CopyComponentAndApplyEditAction) {
                return Data_Eq.eq(Types_Component.eqComponentId)(x.value0.editActionParams.componentId)(y.value0.editActionParams.componentId) && Data_Eq.eq(eqEditEstimationComponentAction)(x.value0.editActionParams.editAction)(y.value0.editActionParams.editAction) && Data_Eq.eq(Types_Element.eqElementId)(x.value0.elementId)(y.value0.elementId) && Data_Eq.eq(Types_Newtypes.eqElementSpecId)(x.value0.elementSpecId)(y.value0.elementSpecId);
            };
            if (x instanceof CopyMultiLinkedComponentAndApplyEditAction && y instanceof CopyMultiLinkedComponentAndApplyEditAction) {
                return Data_Eq.eq(Types_Component.eqComponentId)(x.value0.editActionParams.componentId)(y.value0.editActionParams.componentId) && Data_Eq.eq(eqEditEstimationComponentAction)(x.value0.editActionParams.editAction)(y.value0.editActionParams.editAction) && Data_Eq.eq(Types_Element.eqElementId)(x.value0.elementId)(y.value0.elementId) && Data_Eq.eq(Types_Newtypes.eqElementSpecId)(x.value0.elementSpecId)(y.value0.elementSpecId);
            };
            return false;
        };
    }
};
var eqCopySelectedElementsOperationType = {
    eq: function (x) {
        return function (y) {
            if (x instanceof DuplicateElementsWithinProject && y instanceof DuplicateElementsWithinProject) {
                return true;
            };
            if (x instanceof CopyElementsToNewProject && y instanceof CopyElementsToNewProject) {
                return x.value0.addWildcardLocation === y.value0.addWildcardLocation && x.value0.copyResources === y.value0.copyResources;
            };
            if (x instanceof UpdateExistingElement && y instanceof UpdateExistingElement) {
                return Data_Eq.eq(eqElementUpdateParamsType)(x.value0)(y.value0);
            };
            return false;
        };
    }
};
var eqEstimationElementAction = {
    eq: function (x) {
        return function (y) {
            if (x instanceof AddEstimationElement && y instanceof AddEstimationElement) {
                return x.value0.code === y.value0.code && x.value0.description === y.value0.description && x.value0.quantity === y.value0.quantity && x.value0.rtCode === y.value0.rtCode && x.value0.rtDescription === y.value0.rtDescription && Data_Eq.eq(Types_Project.eqSubprojectId)(x.value0.subProjectId)(y.value0.subProjectId) && x.value0.unit === y.value0.unit;
            };
            if (x instanceof EditEstimationElement && y instanceof EditEstimationElement) {
                return Data_Eq.eq(eqEditEstimationElementAction)(x.value0.editAction)(y.value0.editAction) && Data_Eq.eq(Types_Element.eqElementId)(x.value0.elementId)(y.value0.elementId);
            };
            if (x instanceof AddExistingProjectComponentsToElement && y instanceof AddExistingProjectComponentsToElement) {
                return Data_Eq.eq(Data_Eq.eqArray(Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()({
                    reflectSymbol: function () {
                        return "elementId";
                    }
                })(Types_Element.eqElementId))()({
                    reflectSymbol: function () {
                        return "componentId";
                    }
                })(Types_Component.eqComponentId))))(x.value0)(y.value0);
            };
            if (x instanceof AddExistingProjectElementSpecsToElement && y instanceof AddExistingProjectElementSpecsToElement) {
                return Data_Eq.eq(Data_Eq.eqArray(Types_Element.eqWasmElementSpec))(x.value0.elementSpecs)(y.value0.elementSpecs) && Data_Eq.eq(Types_Element.eqElementId)(x.value0.targetElementId)(y.value0.targetElementId);
            };
            if (x instanceof AddReferenceProjectComponentToElement && y instanceof AddReferenceProjectComponentToElement) {
                return Data_Eq.eq(Types_Component.eqComponentId)(x.value0.componentId)(y.value0.componentId) && Data_Eq.eq(Types_Element.eqElementId)(x.value0.elementId)(y.value0.elementId) && Data_Eq.eq(Types_Project.eqProjectId)(x.value0.targetProjectId)(y.value0.targetProjectId) && Data_Eq.eq(Types_Project.eqSubprojectId)(x.value0.targetSubprojectId)(y.value0.targetSubprojectId);
            };
            if (x instanceof AddReferenceProjectComponentsToElementWithoutDuplicates && y instanceof AddReferenceProjectComponentsToElementWithoutDuplicates) {
                return Data_Eq.eq(Data_Eq.eqArray(Types_Component.eqComponentId))(x.value0.componentIds)(y.value0.componentIds) && x.value0.copyResources === y.value0.copyResources && Data_Eq.eq(Types_Element.eqElementId)(x.value0.targetElementId)(y.value0.targetElementId) && Data_Eq.eq(Types_Project.eqProjectId)(x.value0.targetProjectId)(y.value0.targetProjectId) && Data_Eq.eq(Types_Project.eqSubprojectId)(x.value0.targetSubprojectId)(y.value0.targetSubprojectId);
            };
            if (x instanceof AddNewProjectComponentToElement && y instanceof AddNewProjectComponentToElement) {
                return x.value0.code === y.value0.code && x.value0.description === y.value0.description && Data_Eq.eq(Types_Element.eqElementId)(x.value0.elementId)(y.value0.elementId) && Data_Eq.eq(Types_Project.eqProjectId)(x.value0.projectId)(y.value0.projectId) && Data_Eq.eq(Types_Project.eqSubprojectId)(x.value0.subprojectId)(y.value0.subprojectId);
            };
            if (x instanceof CopyEstimationElementsToProject && y instanceof CopyEstimationElementsToProject) {
                return Data_Eq.eq(eqEstimationElementCopyOperationType)(x.value0.copyOperationType)(y.value0.copyOperationType) && Data_Eq.eq(Data_Eq.eqArray(Types_Element.eqElementId))(x.value0.estimationElementIds)(y.value0.estimationElementIds) && Data_Eq.eq(Types_Project.eqProjectId)(x.value0.targetProjectId)(y.value0.targetProjectId) && Data_Eq.eq(Types_Project.eqSubprojectId)(x.value0.targetSubprojectId)(y.value0.targetSubprojectId);
            };
            if (x instanceof CopySelectedElements && y instanceof CopySelectedElements) {
                return Data_Eq.eq(eqCopySelectedElementsOperationType)(x.value0.copyOperationType)(y.value0.copyOperationType) && Data_Eq.eq(Data_Eq.eqArray(Types_Element.eqElementId))(x.value0.estimationElementIds)(y.value0.estimationElementIds) && Data_Eq.eq(Types_Project.eqProjectId)(x.value0.targetProjectId)(y.value0.targetProjectId) && Data_Eq.eq(Types_Project.eqSubprojectId)(x.value0.targetSubprojectId)(y.value0.targetSubprojectId);
            };
            if (x instanceof EditElementLocationCode && y instanceof EditElementLocationCode) {
                return Data_Eq.eq(Types_Element.eqElementId)(x.value0.elementId)(y.value0.elementId) && Data_Eq.eq(Types_Element.eqElementLocationId)(x.value0.elementLocationId)(y.value0.elementLocationId) && x.value0.locationCode === y.value0.locationCode;
            };
            if (x instanceof EditElementLocationDescription && y instanceof EditElementLocationDescription) {
                return x.value0.description === y.value0.description && Data_Eq.eq(Types_Element.eqElementId)(x.value0.elementId)(y.value0.elementId) && Data_Eq.eq(Types_Element.eqElementLocationId)(x.value0.elementLocationId)(y.value0.elementLocationId);
            };
            if (x instanceof EditElementLocationAmount && y instanceof EditElementLocationAmount) {
                return x.value0.amount === y.value0.amount && Data_Eq.eq(Types_Element.eqElementId)(x.value0.elementId)(y.value0.elementId) && Data_Eq.eq(Types_Element.eqElementLocationId)(x.value0.elementLocationId)(y.value0.elementLocationId);
            };
            if (x instanceof ElementLocationsMatrixAction && y instanceof ElementLocationsMatrixAction) {
                return Data_Eq.eq(Types_Element.eqElementId)(x.value0.elementId)(y.value0.elementId) && Data_Eq.eq(Data_Eq.eqArray(Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()({
                    reflectSymbol: function () {
                        return "elementLocationId";
                    }
                })(Types_Element.eqElementLocationId))()({
                    reflectSymbol: function () {
                        return "description";
                    }
                })(Data_Eq.eqString))()({
                    reflectSymbol: function () {
                        return "amount";
                    }
                })(Data_Eq.eqNumber))))(x.value0.modifiedAmounts)(y.value0.modifiedAmounts) && Data_Eq.eq(Data_Eq.eqArray(Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()({
                    reflectSymbol: function () {
                        return "locationCode";
                    }
                })(Data_Eq.eqString))()({
                    reflectSymbol: function () {
                        return "description";
                    }
                })(Data_Eq.eqString))()({
                    reflectSymbol: function () {
                        return "amount";
                    }
                })(Data_Eq.eqNumber))))(x.value0.newAmounts)(y.value0.newAmounts);
            };
            if (x instanceof AddElementLocation && y instanceof AddElementLocation) {
                return Data_Eq.eq(Types_Element.eqElementId)(x.value0.elementId)(y.value0.elementId);
            };
            if (x instanceof DeleteElementLocations && y instanceof DeleteElementLocations) {
                return Data_Eq.eq(Types_Element.eqElementId)(x.value0.elementId)(y.value0.elementId) && Data_Eq.eq(Data_Eq.eqArray(Types_Element.eqElementLocationId))(x.value0.elementLocationIds)(y.value0.elementLocationIds);
            };
            return false;
        };
    }
};
var editEstimationResourceDefaultParams = {
    name: Data_Nullable["null"],
    costClassCode: Data_Nullable["null"],
    pricePerUnit: Data_Nullable["null"],
    unit: Data_Nullable["null"],
    wastePercentage: Data_Nullable["null"],
    unitConsumptionRate: Data_Nullable["null"],
    group: Data_Nullable["null"],
    resourceClassificationGroup: Data_Nullable["null"],
    sortingNumber: Data_Nullable["null"],
    validDate: Data_Nullable["null"],
    changedDate: Data_Nullable["null"],
    discountPercentage: Data_Nullable["null"],
    eanCode: Data_Nullable["null"],
    taxRate: Data_Nullable["null"],
    index: Data_Nullable["null"],
    vendor: Data_Nullable["null"],
    vendorCode: Data_Nullable["null"],
    vendorUnit: Data_Nullable["null"],
    vendorBatchSize: Data_Nullable["null"],
    currencyId: Data_Nullable["null"],
    orgResourcePriceListId: Data_Nullable["null"],
    orgResourcePriceId: Data_Nullable["null"]
};
var defaultAddEstimationResourceParamsDetails = {
    unitConsumptionRate: 0.0,
    wastePercentage: 0.0,
    discountPercentage: 0.0,
    multiplier: 0.0,
    taxPercentage: 0.0,
    resourceClassificationGroup: Data_Maybe.Nothing.value,
    sortingNumber: Data_Maybe.Nothing.value,
    currency: Data_Maybe.Nothing.value,
    vendor: Data_Maybe.Nothing.value,
    discountGroupCode: Data_Maybe.Nothing.value,
    changeDate: Data_Maybe.Nothing.value,
    validDate: Data_Maybe.Nothing.value,
    contract: Data_Maybe.Nothing.value,
    eanCode: Data_Maybe.Nothing.value,
    vendorCode: Data_Maybe.Nothing.value,
    vendorBatchSize: Data_Maybe.Nothing.value
};
module.exports = {
    UpdateEstimationLocationAmount: UpdateEstimationLocationAmount,
    UpdateEstimationLocationCode: UpdateEstimationLocationCode,
    UpdateEstimationLocationDescription: UpdateEstimationLocationDescription,
    AddEstimationLocation: AddEstimationLocation,
    DeleteEstimationLocations: DeleteEstimationLocations,
    EstimationLocationsMatrixAction: EstimationLocationsMatrixAction,
    EditEstimationElementDescription: EditEstimationElementDescription,
    EditEstimationElementRtCode: EditEstimationElementRtCode,
    EditEstimationElementRtDescription: EditEstimationElementRtDescription,
    EditEstimationElementUnit: EditEstimationElementUnit,
    EditEstimationElementMemo: EditEstimationElementMemo,
    EditEstimationElementSubprojectId: EditEstimationElementSubprojectId,
    EditEstimationElementCode: EditEstimationElementCode,
    AddEstimationElementAmountForWildcardLocation: AddEstimationElementAmountForWildcardLocation,
    EditEstimationElementAmountForSingleLocation: EditEstimationElementAmountForSingleLocation,
    EditEstimationElementGroupFlag: EditEstimationElementGroupFlag,
    CopyElementOnly: CopyElementOnly,
    CopyElementWithComponents: CopyElementWithComponents,
    CopyElementWithinProject: CopyElementWithinProject,
    AddElementSpecs: AddElementSpecs,
    ElementUpdateAddElementSpecs: ElementUpdateAddElementSpecs,
    ElementUpdateReplaceElementSpecs: ElementUpdateReplaceElementSpecs,
    ElementUpdateReplaceElement: ElementUpdateReplaceElement,
    DuplicateElementsWithinProject: DuplicateElementsWithinProject,
    CopyElementsToNewProject: CopyElementsToNewProject,
    UpdateExistingElement: UpdateExistingElement,
    AddEstimationElement: AddEstimationElement,
    EditEstimationElement: EditEstimationElement,
    AddExistingProjectComponentsToElement: AddExistingProjectComponentsToElement,
    AddExistingProjectElementSpecsToElement: AddExistingProjectElementSpecsToElement,
    AddReferenceProjectComponentToElement: AddReferenceProjectComponentToElement,
    AddReferenceProjectComponentsToElementWithoutDuplicates: AddReferenceProjectComponentsToElementWithoutDuplicates,
    AddNewProjectComponentToElement: AddNewProjectComponentToElement,
    CopyEstimationElementsToProject: CopyEstimationElementsToProject,
    CopySelectedElements: CopySelectedElements,
    EditElementLocationCode: EditElementLocationCode,
    EditElementLocationDescription: EditElementLocationDescription,
    EditElementLocationAmount: EditElementLocationAmount,
    ElementLocationsMatrixAction: ElementLocationsMatrixAction,
    AddElementLocation: AddElementLocation,
    DeleteElementLocations: DeleteElementLocations,
    EditEstimationComponentDescription: EditEstimationComponentDescription,
    EditEstimationComponentCode: EditEstimationComponentCode,
    EditEstimationComponentGroup: EditEstimationComponentGroup,
    EditEstimationComponentCostGroup: EditEstimationComponentCostGroup,
    EditEstimationComponentSubprojectId: EditEstimationComponentSubprojectId,
    EditEstimationComponentUnit: EditEstimationComponentUnit,
    EditEstimationComponentChecked: EditEstimationComponentChecked,
    EditEstimationComponentFlagged: EditEstimationComponentFlagged,
    EditEstimationComponentQuantity: EditEstimationComponentQuantity,
    EditEstimationComponentMemo: EditEstimationComponentMemo,
    EditEstimationComponentClass: EditEstimationComponentClass,
    AddEstimationComponent: AddEstimationComponent,
    EditEstimationComponent: EditEstimationComponent,
    EditEstimationComponents: EditEstimationComponents,
    ResetEstimationLocations: ResetEstimationLocations,
    CopyEstimationComponentsToProject: CopyEstimationComponentsToProject,
    DeleteEstimationComponents: DeleteEstimationComponents,
    ReplaceEstimationComponent: ReplaceEstimationComponent,
    CopyComponentAndApplyEditAction: CopyComponentAndApplyEditAction,
    CopyMultiLinkedComponentAndApplyEditAction: CopyMultiLinkedComponentAndApplyEditAction,
    CopyComponentOnly: CopyComponentOnly,
    CopyComponentWithResources: CopyComponentWithResources,
    CopyComponentWithResourcesAndLocations: CopyComponentWithResourcesAndLocations,
    defaultAddEstimationResourceParamsDetails: defaultAddEstimationResourceParamsDetails,
    editEstimationResourceDefaultParams: editEstimationResourceDefaultParams,
    InPlaceResourceUpdate: InPlaceResourceUpdate,
    SplitElementComponent: SplitElementComponent,
    AddEstimationResource: AddEstimationResource,
    AddAndDeleteMultipleResources: AddAndDeleteMultipleResources,
    CopyResourcesToComponent: CopyResourcesToComponent,
    ReplaceComponentResources: ReplaceComponentResources,
    AddResourcesToComponentFromOrgPriceList: AddResourcesToComponentFromOrgPriceList,
    ReplaceComponentResourceFromOrgPriceList: ReplaceComponentResourceFromOrgPriceList,
    ReplaceComponentResourceFromProject: ReplaceComponentResourceFromProject,
    EditEstimationResource: EditEstimationResource,
    EditEstimationCostClassPrice: EditEstimationCostClassPrice,
    EditEstimationCostClassPriceConsumptionRate: EditEstimationCostClassPriceConsumptionRate,
    EditEstimationCostClassPriceAndConsumptionRate: EditEstimationCostClassPriceAndConsumptionRate,
    DeleteEstimationResources: DeleteEstimationResources,
    SetEstimationResourcesCostClass: SetEstimationResourcesCostClass,
    SetEstimationResourcesGroup: SetEstimationResourcesGroup,
    EditOrgResourcePrice: EditOrgResourcePrice,
    DeleteOrgResourcePrices: DeleteOrgResourcePrices,
    AddOrgResourceGrouping: AddOrgResourceGrouping,
    AddOrgResourceGroupingClassification: AddOrgResourceGroupingClassification,
    EditOrgResourceGroupingClassification: EditOrgResourceGroupingClassification,
    AddOrgPriceList: AddOrgPriceList,
    EditOrgPriceList: EditOrgPriceList,
    AddOrgPriceListResourceToComponent: AddOrgPriceListResourceToComponent,
    ReplaceComponentResource: ReplaceComponentResource,
    UpdateAllOrgResourceCostClasses: UpdateAllOrgResourceCostClasses,
    InternalOrgResourcePriceIdMatch: InternalOrgResourcePriceIdMatch,
    CostClassGroupSortingNumberMatch: CostClassGroupSortingNumberMatch,
    FindPriceMatchesForResources: FindPriceMatchesForResources,
    eqEstimationLocationAction: eqEstimationLocationAction,
    eqEditEstimationElementAction: eqEditEstimationElementAction,
    eqEstimationElementCopyOperationType: eqEstimationElementCopyOperationType,
    eqElementUpdateParamsType: eqElementUpdateParamsType,
    eqCopySelectedElementsOperationType: eqCopySelectedElementsOperationType,
    eqEstimationElementAction: eqEstimationElementAction,
    eqEditEstimationComponentAction: eqEditEstimationComponentAction,
    eqEstimationComponentAction: eqEstimationComponentAction,
    eqEstimationComponentCopyOperationType: eqEstimationComponentCopyOperationType,
    eqTargetComponentAction: eqTargetComponentAction,
    eqEstimationResourceActionParams: eqEstimationResourceActionParams,
    eqMatchingRules: eqMatchingRules,
    genericMatchingRules: genericMatchingRules,
    tofuJSONMatchingRules: tofuJSONMatchingRules,
    genericEstimationResourcePriceMatchAction: genericEstimationResourcePriceMatchAction,
    tofuJSONEstimationResourcePriceMatchAction: tofuJSONEstimationResourcePriceMatchAction,
    genericEstimationComponentCopyOperationType: genericEstimationComponentCopyOperationType,
    tofuJSONEstimationComponentCopyOperationType: tofuJSONEstimationComponentCopyOperationType,
    genericEditEstimationComponentAction: genericEditEstimationComponentAction,
    tofuJSONEditEstimationComponentAction: tofuJSONEditEstimationComponentAction,
    genericEstimationComponentAction: genericEstimationComponentAction,
    tofuJSONEstimationComponentAction: tofuJSONEstimationComponentAction,
    genericEditEstimationElementAction: genericEditEstimationElementAction,
    tofuJSONEditEstimationElementAction: tofuJSONEditEstimationElementAction,
    genericEstimationElementCopyOperationType: genericEstimationElementCopyOperationType,
    tofuJSONEstimationElementCopyOperationType: tofuJSONEstimationElementCopyOperationType,
    genericElementUpdateParams: genericElementUpdateParams,
    tofuJSONElementUpdateParams: tofuJSONElementUpdateParams,
    genericCopySelectedElementsOperationType: genericCopySelectedElementsOperationType,
    tofuJSONCopySelectedElementsOperationType: tofuJSONCopySelectedElementsOperationType,
    genericEstimationElementAction: genericEstimationElementAction,
    tofuJSONEstimationElementAction: tofuJSONEstimationElementAction,
    genericTargetComponentAction: genericTargetComponentAction,
    tofuJSONTargetComponentAction: tofuJSONTargetComponentAction,
    genericEstimationResourceActionParams: genericEstimationResourceActionParams,
    tofuJSONEstimationResourceActionParams: tofuJSONEstimationResourceActionParams,
    genericEstimationLocationAction: genericEstimationLocationAction,
    tofuJSONEstimationLocationAction: tofuJSONEstimationLocationAction,
    genericEstimationResourcePriceListAction: genericEstimationResourcePriceListAction,
    tofuJSONEstimationResourcePriceListAction: tofuJSONEstimationResourcePriceListAction
};
