import { ReportTemplate } from "@prisma/client";
import { AxiosError } from "axios";
import { useMutation, useQuery, UseQueryResult } from "react-query";
import axiosClient from "src/client-ts/axiosConfig";
import { reportError } from "src/client-ts/utils/error";
import { ReportTemplateWithUser } from "src/server-ts/super-admin/report-templates/SuperAdminReportTemplateQueries";

const baseUrl = "/super-admin/report-templates";

export function useGetReportTemplates(): UseQueryResult<
  ReportTemplateWithUser[]
> {
  return useQuery({
    queryKey: [baseUrl],
    queryFn: async () => {
      const { data } = await axiosClient.get<ReportTemplate[]>(baseUrl);
      return data;
    },
  });
}

export function useUpdateReportTemplate() {
  return useMutation({
    mutationFn: async ({
      name,
      template,
    }: Pick<ReportTemplateWithUser, "name" | "template">) => {
      const { data } = await axiosClient.put<ReportTemplate>(
        `${baseUrl}/${name}`,
        { template: template }
      );
      return data;
    },
    onError: (err: AxiosError) => {
      reportError(`Error while trying to save report template`, err);
    },
  });
}
