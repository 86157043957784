import React, { useEffect, useMemo } from "react";
import { Viewer } from "@mescius/activereportsjs-react";
import "@mescius/activereportsjs/pdfexport";
import "@mescius/activereportsjs/htmlexport";
import "@mescius/activereportsjs/tabulardataexport";
import "@mescius/activereportsjs/xlsxexport";
import { Rdl as ARJS } from "@mescius/activereportsjs/core";
import "@mescius/activereportsjs/styles/ar-js-ui.css";
import "@mescius/activereportsjs/styles/ar-js-viewer.css";
import { useWorkerState } from "../../../hooks/useWorkerState";
import { ProjectClassificationClass } from "../../../../../ts-bindings/ProjectClassificationClass";
import { ComponentWithResources } from "../../../../../ts-bindings/ComponentWithResources";
import { useTranslation } from "react-i18next";
import { ProjectDetailsData } from "../../../../../ts-bindings/ProjectDetailsData";
import { createC200Data } from "./C200";
import { TFunction } from "i18next";
import { Subproject } from "../../../../../ts-bindings/Subproject";

type ReportProps = {
  projectId: number;
};

export type ReportDetailsData = {
  title: string;
  name: string;
  subProjects?: string[];
};

export type FullReportData = {
  componentsClassData: ProjectClassificationClass[];
  componentsWithResourcesData: ComponentWithResources[];
  projectData: ProjectDetailsData;
  subProjects: Subproject[];
} | null;

const loadReport = async (
  t: TFunction,
  fullData: FullReportData
): Promise<ARJS.Report> => {
  return createC200Data(t, fullData) as ARJS.Report;
};

export const Report = ({ projectId }: ReportProps) => {
  const { t } = useTranslation("reports");

  const componentsClassData = useWorkerState(
    "ProjectClassificationClassesState",
    projectId
  );
  const componentsWithResourcesData = useWorkerState(
    "ComponentsWithResourcesState",
    projectId
  );

  const subProjects = useWorkerState("EstimationSubprojectsState", projectId);

  const projectData = useWorkerState("ProjectDetailsDataState", projectId);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const viewerRef = React.useRef<any>(null);

  const renderReport = (report: ARJS.Report) => {
    viewerRef.current.Viewer.open(report);
  };

  const allDataReady =
    componentsWithResourcesData !== null &&
    componentsClassData !== null &&
    projectData !== null &&
    subProjects !== null;

  const fullData = useMemo(() => {
    if (!allDataReady) {
      return null;
    }
    return {
      componentsClassData: componentsClassData,
      componentsWithResourcesData: componentsWithResourcesData,
      projectData: projectData,
      subProjects: subProjects,
    };
  }, [
    componentsClassData,
    componentsWithResourcesData,
    projectData,
    subProjects,
    allDataReady,
  ]);

  useEffect(() => {
    (async () => {
      if (allDataReady) {
        const report = await loadReport(t, fullData);
        renderReport(report);
      }
    })();
  }, [
    renderReport,
    allDataReady,
    componentsWithResourcesData,
    componentsClassData,
  ]);

  return (
    <div className="flex" style={{ height: "100%" }}>
      <div className="flex p-4" style={{ width: "25%" }}>
        <div className={"flex flex-col"}></div>
      </div>
      <div className="flex" style={{ width: "75%", height: "100%" }}>
        <div style={{ width: "100%", height: "100%" }}>
          <Viewer theme="ActiveReports" ref={viewerRef} />
        </div>
      </div>
    </div>
  );
};
