import { Designer } from "@mescius/activereportsjs-react";
import "@mescius/activereportsjs/styles/ar-js-designer.css";
import "@mescius/activereportsjs/styles/ar-js-ui.css";
import { useUpdateReportTemplate } from "./useReportTemplates";

interface ReportDesignerProps {
  reportName?: string;
}

export const ReportDesigner = ({
  reportName = "C200",
}: ReportDesignerProps) => {
  const saveReportTemplate = useUpdateReportTemplate();

  return (
    <Designer
      report={{
        // The API is a bit confusing, but the id field is actually a path. Alternatively use definition field.
        id: `/api/super-admin/report-templates/${reportName}/template`,
        displayName: "C200",
      }}
      onSave={async (info) => {
        const name = info.displayName ?? reportName;

        await saveReportTemplate.mutateAsync({
          name,
          template: info.definition,
        });

        return {
          displayName: name,
        };
      }}
    />
  );
};
