import { wasmEvalDixonMeasurementFormula } from '../../output/Wasm.FFI/index.js';
import { getAccessToken } from '../../src/client/auth/Auth.js';
import { get_stack } from './snippets/wasm-shared-eabc0c425b85aa22/inline0.js';
import { getDocument } from 'pdfjs-dist/webpack';

let wasm;

const heap = new Array(128).fill(undefined);

heap.push(undefined, null, true, false);

function getObject(idx) { return heap[idx]; }

let heap_next = heap.length;

function dropObject(idx) {
    if (idx < 132) return;
    heap[idx] = heap_next;
    heap_next = idx;
}

function takeObject(idx) {
    const ret = getObject(idx);
    dropObject(idx);
    return ret;
}

const cachedTextDecoder = new TextDecoder('utf-8', { ignoreBOM: true, fatal: true });

cachedTextDecoder.decode();

let cachedUint8Memory0 = null;

function getUint8Memory0() {
    if (cachedUint8Memory0 === null || cachedUint8Memory0.byteLength === 0) {
        cachedUint8Memory0 = new Uint8Array(wasm.memory.buffer);
    }
    return cachedUint8Memory0;
}

function getStringFromWasm0(ptr, len) {
    return cachedTextDecoder.decode(getUint8Memory0().subarray(ptr, ptr + len));
}

function addHeapObject(obj) {
    if (heap_next === heap.length) heap.push(heap.length + 1);
    const idx = heap_next;
    heap_next = heap[idx];

    heap[idx] = obj;
    return idx;
}

let WASM_VECTOR_LEN = 0;

const cachedTextEncoder = new TextEncoder('utf-8');

const encodeString = (typeof cachedTextEncoder.encodeInto === 'function'
    ? function (arg, view) {
    return cachedTextEncoder.encodeInto(arg, view);
}
    : function (arg, view) {
    const buf = cachedTextEncoder.encode(arg);
    view.set(buf);
    return {
        read: arg.length,
        written: buf.length
    };
});

function passStringToWasm0(arg, malloc, realloc) {

    if (realloc === undefined) {
        const buf = cachedTextEncoder.encode(arg);
        const ptr = malloc(buf.length);
        getUint8Memory0().subarray(ptr, ptr + buf.length).set(buf);
        WASM_VECTOR_LEN = buf.length;
        return ptr;
    }

    let len = arg.length;
    let ptr = malloc(len);

    const mem = getUint8Memory0();

    let offset = 0;

    for (; offset < len; offset++) {
        const code = arg.charCodeAt(offset);
        if (code > 0x7F) break;
        mem[ptr + offset] = code;
    }

    if (offset !== len) {
        if (offset !== 0) {
            arg = arg.slice(offset);
        }
        ptr = realloc(ptr, len, len = offset + arg.length * 3);
        const view = getUint8Memory0().subarray(ptr + offset, ptr + len);
        const ret = encodeString(arg, view);

        offset += ret.written;
    }

    WASM_VECTOR_LEN = offset;
    return ptr;
}

function isLikeNone(x) {
    return x === undefined || x === null;
}

let cachedInt32Memory0 = null;

function getInt32Memory0() {
    if (cachedInt32Memory0 === null || cachedInt32Memory0.byteLength === 0) {
        cachedInt32Memory0 = new Int32Array(wasm.memory.buffer);
    }
    return cachedInt32Memory0;
}

let cachedFloat64Memory0 = null;

function getFloat64Memory0() {
    if (cachedFloat64Memory0 === null || cachedFloat64Memory0.byteLength === 0) {
        cachedFloat64Memory0 = new Float64Array(wasm.memory.buffer);
    }
    return cachedFloat64Memory0;
}

function debugString(val) {
    // primitive types
    const type = typeof val;
    if (type == 'number' || type == 'boolean' || val == null) {
        return  `${val}`;
    }
    if (type == 'string') {
        return `"${val}"`;
    }
    if (type == 'symbol') {
        const description = val.description;
        if (description == null) {
            return 'Symbol';
        } else {
            return `Symbol(${description})`;
        }
    }
    if (type == 'function') {
        const name = val.name;
        if (typeof name == 'string' && name.length > 0) {
            return `Function(${name})`;
        } else {
            return 'Function';
        }
    }
    // objects
    if (Array.isArray(val)) {
        const length = val.length;
        let debug = '[';
        if (length > 0) {
            debug += debugString(val[0]);
        }
        for(let i = 1; i < length; i++) {
            debug += ', ' + debugString(val[i]);
        }
        debug += ']';
        return debug;
    }
    // Test for built-in
    const builtInMatches = /\[object ([^\]]+)\]/.exec(toString.call(val));
    let className;
    if (builtInMatches.length > 1) {
        className = builtInMatches[1];
    } else {
        // Failed to match the standard '[object ClassName]'
        return toString.call(val);
    }
    if (className == 'Object') {
        // we're a user defined class or Object
        // JSON.stringify avoids problems with cycles, and is generally much
        // easier than looping through ownProperties of `val`.
        try {
            return 'Object(' + JSON.stringify(val) + ')';
        } catch (_) {
            return 'Object';
        }
    }
    // errors
    if (val instanceof Error) {
        return `${val.name}: ${val.message}\n${val.stack}`;
    }
    // TODO we could test for more things here, like `Set`s and `Map`s.
    return className;
}

function makeMutClosure(arg0, arg1, dtor, f) {
    const state = { a: arg0, b: arg1, cnt: 1, dtor };
    const real = (...args) => {
        // First up with a closure we increment the internal reference
        // count. This ensures that the Rust closure environment won't
        // be deallocated while we're invoking it.
        state.cnt++;
        const a = state.a;
        state.a = 0;
        try {
            return f(a, state.b, ...args);
        } finally {
            if (--state.cnt === 0) {
                wasm.__wbindgen_export_2.get(state.dtor)(a, state.b);

            } else {
                state.a = a;
            }
        }
    };
    real.original = state;

    return real;
}
function __wbg_adapter_26(arg0, arg1) {
    wasm._dyn_core__ops__function__FnMut_____Output___R_as_wasm_bindgen__closure__WasmClosure___describe__invoke__h49b705aea715c8dc(arg0, arg1);
}

function makeClosure(arg0, arg1, dtor, f) {
    const state = { a: arg0, b: arg1, cnt: 1, dtor };
    const real = (...args) => {
        // First up with a closure we increment the internal reference
        // count. This ensures that the Rust closure environment won't
        // be deallocated while we're invoking it.
        state.cnt++;
        try {
            return f(state.a, state.b, ...args);
        } finally {
            if (--state.cnt === 0) {
                wasm.__wbindgen_export_2.get(state.dtor)(state.a, state.b);
                state.a = 0;

            }
        }
    };
    real.original = state;

    return real;
}
function __wbg_adapter_29(arg0, arg1) {
    wasm._dyn_core__ops__function__Fn_____Output___R_as_wasm_bindgen__closure__WasmClosure___describe__invoke__hf58cee39c1fe0ec2(arg0, arg1);
}

function __wbg_adapter_32(arg0, arg1, arg2) {
    wasm._dyn_core__ops__function__FnMut__A____Output___R_as_wasm_bindgen__closure__WasmClosure___describe__invoke__h4f899fdb95bbe8d9(arg0, arg1, addHeapObject(arg2));
}

function __wbg_adapter_41(arg0, arg1, arg2) {
    wasm._dyn_core__ops__function__FnMut__A____Output___R_as_wasm_bindgen__closure__WasmClosure___describe__invoke__h688a6dcd2193226a(arg0, arg1, addHeapObject(arg2));
}

function __wbg_adapter_44(arg0, arg1, arg2) {
    wasm._dyn_core__ops__function__FnMut__A____Output___R_as_wasm_bindgen__closure__WasmClosure___describe__invoke__h66d83022af00b398(arg0, arg1, addHeapObject(arg2));
}

function getArrayF64FromWasm0(ptr, len) {
    return getFloat64Memory0().subarray(ptr / 8, ptr / 8 + len);
}

function handleError(f, args) {
    try {
        return f.apply(this, args);
    } catch (e) {
        wasm.__wbindgen_exn_store(addHeapObject(e));
    }
}

let stack_pointer = 128;

function addBorrowedObject(obj) {
    if (stack_pointer == 1) throw new Error('out of js stack');
    heap[--stack_pointer] = obj;
    return stack_pointer;
}
/**
* @param {string} s
* @returns {string | undefined}
*/
export function serde_roundtrip_user_role(s) {
    try {
        const retptr = wasm.__wbindgen_add_to_stack_pointer(-16);
        const ptr0 = passStringToWasm0(s, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
        const len0 = WASM_VECTOR_LEN;
        wasm.serde_roundtrip_user_role(retptr, ptr0, len0);
        var r0 = getInt32Memory0()[retptr / 4 + 0];
        var r1 = getInt32Memory0()[retptr / 4 + 1];
        let v1;
        if (r0 !== 0) {
            v1 = getStringFromWasm0(r0, r1).slice();
            wasm.__wbindgen_free(r0, r1 * 1);
        }
        return v1;
    } finally {
        wasm.__wbindgen_add_to_stack_pointer(16);
    }
}

/**
* @param {string} s
* @returns {string | undefined}
*/
export function serde_roundtrip_user_role_purs_compat_enum(s) {
    try {
        const retptr = wasm.__wbindgen_add_to_stack_pointer(-16);
        const ptr0 = passStringToWasm0(s, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
        const len0 = WASM_VECTOR_LEN;
        wasm.serde_roundtrip_user_role_purs_compat_enum(retptr, ptr0, len0);
        var r0 = getInt32Memory0()[retptr / 4 + 0];
        var r1 = getInt32Memory0()[retptr / 4 + 1];
        let v1;
        if (r0 !== 0) {
            v1 = getStringFromWasm0(r0, r1).slice();
            wasm.__wbindgen_free(r0, r1 * 1);
        }
        return v1;
    } finally {
        wasm.__wbindgen_add_to_stack_pointer(16);
    }
}

/**
* @param {string} s
* @returns {string | undefined}
*/
export function serde_roundtrip_user_role_assignment_with_user_id(s) {
    try {
        const retptr = wasm.__wbindgen_add_to_stack_pointer(-16);
        const ptr0 = passStringToWasm0(s, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
        const len0 = WASM_VECTOR_LEN;
        wasm.serde_roundtrip_user_role_assignment_with_user_id(retptr, ptr0, len0);
        var r0 = getInt32Memory0()[retptr / 4 + 0];
        var r1 = getInt32Memory0()[retptr / 4 + 1];
        let v1;
        if (r0 !== 0) {
            v1 = getStringFromWasm0(r0, r1).slice();
            wasm.__wbindgen_free(r0, r1 * 1);
        }
        return v1;
    } finally {
        wasm.__wbindgen_add_to_stack_pointer(16);
    }
}

/**
* @param {string} s
* @returns {string | undefined}
*/
export function serde_roundtrip_user_app_access_rights(s) {
    try {
        const retptr = wasm.__wbindgen_add_to_stack_pointer(-16);
        const ptr0 = passStringToWasm0(s, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
        const len0 = WASM_VECTOR_LEN;
        wasm.serde_roundtrip_user_app_access_rights(retptr, ptr0, len0);
        var r0 = getInt32Memory0()[retptr / 4 + 0];
        var r1 = getInt32Memory0()[retptr / 4 + 1];
        let v1;
        if (r0 !== 0) {
            v1 = getStringFromWasm0(r0, r1).slice();
            wasm.__wbindgen_free(r0, r1 * 1);
        }
        return v1;
    } finally {
        wasm.__wbindgen_add_to_stack_pointer(16);
    }
}

/**
*/
export function init_wasm_console_error() {
    try {
        const retptr = wasm.__wbindgen_add_to_stack_pointer(-16);
        wasm.init_wasm_console_error(retptr);
        var r0 = getInt32Memory0()[retptr / 4 + 0];
        var r1 = getInt32Memory0()[retptr / 4 + 1];
        if (r1) {
            throw takeObject(r0);
        }
    } finally {
        wasm.__wbindgen_add_to_stack_pointer(16);
    }
}

/**
*/
export function main() {
    wasm.main();
}

/**
*/
export class MockCanvasCtx {

    static __wrap(ptr) {
        const obj = Object.create(MockCanvasCtx.prototype);
        obj.ptr = ptr;

        return obj;
    }

    __destroy_into_raw() {
        const ptr = this.ptr;
        this.ptr = 0;

        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_mockcanvasctx_free(ptr);
    }
    /**
    * @returns {HTMLCanvasElement}
    */
    get canvas() {
        const ret = wasm.mockcanvasctx_canvas(this.ptr);
        return takeObject(ret);
    }
    /**
    * @param {number} a
    * @param {number} b
    * @param {number} c
    * @param {number} d
    * @param {number} e
    * @param {number} f
    */
    transform(a, b, c, d, e, f) {
        wasm.mockcanvasctx_transform(this.ptr, a, b, c, d, e, f);
    }
    /**
    * @param {number} a
    * @param {number} b
    * @param {number} c
    * @param {number} d
    * @param {number} e
    * @param {number} f
    */
    setTransform(a, b, c, d, e, f) {
        wasm.mockcanvasctx_setTransform(this.ptr, a, b, c, d, e, f);
    }
    /**
    */
    resetTransform() {
        wasm.mockcanvasctx_resetTransform(this.ptr);
    }
    /**
    */
    save() {
        wasm.mockcanvasctx_save(this.ptr);
    }
    /**
    */
    restore() {
        wasm.mockcanvasctx_restore(this.ptr);
    }
    /**
    * @param {number} x
    * @param {number} y
    */
    lineTo(x, y) {
        wasm.mockcanvasctx_lineTo(this.ptr, x, y);
    }
    /**
    * @param {number} x
    * @param {number} y
    */
    moveTo(x, y) {
        wasm.mockcanvasctx_moveTo(this.ptr, x, y);
    }
    /**
    * @param {number} x
    * @param {number} y
    * @param {number} width
    * @param {number} height
    */
    fillRect(x, y, width, height) {
        wasm.mockcanvasctx_fillRect(this.ptr, x, y, width, height);
    }
    /**
    * @param {number} x
    * @param {number} y
    * @param {number} width
    * @param {number} height
    */
    clearRect(x, y, width, height) {
        wasm.mockcanvasctx_clearRect(this.ptr, x, y, width, height);
    }
    /**
    * @param {number} _x
    * @param {number} _y
    * @param {number} w
    * @param {number} h
    * @returns {Array<any>}
    */
    getImageData(_x, _y, w, h) {
        const ret = wasm.mockcanvasctx_getImageData(this.ptr, _x, _y, w, h);
        return takeObject(ret);
    }
    /**
    */
    putImageData() {
        wasm.mockcanvasctx_putImageData(this.ptr);
    }
    /**
    * @returns {Array<any>}
    */
    createImageData() {
        const ret = wasm.mockcanvasctx_createImageData(this.ptr);
        return takeObject(ret);
    }
    /**
    * @param {HTMLCanvasElement} canvas
    * @param {number | undefined} sx
    * @param {number | undefined} sy
    * @param {number | undefined} sw
    * @param {number | undefined} sh
    * @param {number | undefined} dx
    * @param {number | undefined} dy
    * @param {number | undefined} dw
    * @param {number | undefined} dh
    */
    drawImage(canvas, sx, sy, sw, sh, dx, dy, dw, dh) {
        wasm.mockcanvasctx_drawImage(this.ptr, addHeapObject(canvas), !isLikeNone(sx), isLikeNone(sx) ? 0 : sx, !isLikeNone(sy), isLikeNone(sy) ? 0 : sy, !isLikeNone(sw), isLikeNone(sw) ? 0 : sw, !isLikeNone(sh), isLikeNone(sh) ? 0 : sh, !isLikeNone(dx), isLikeNone(dx) ? 0 : dx, !isLikeNone(dy), isLikeNone(dy) ? 0 : dy, !isLikeNone(dw), isLikeNone(dw) ? 0 : dw, !isLikeNone(dh), isLikeNone(dh) ? 0 : dh);
    }
    /**
    * @param {string} text
    * @param {number} x
    * @param {number} y
    * @param {number | undefined} max_width
    */
    fillText(text, x, y, max_width) {
        const ptr0 = passStringToWasm0(text, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
        const len0 = WASM_VECTOR_LEN;
        wasm.mockcanvasctx_fillText(this.ptr, ptr0, len0, x, y, !isLikeNone(max_width), isLikeNone(max_width) ? 0 : max_width);
    }
    /**
    * @param {string} text
    * @param {number} x
    * @param {number} y
    * @param {number | undefined} max_width
    */
    strokeText(text, x, y, max_width) {
        const ptr0 = passStringToWasm0(text, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
        const len0 = WASM_VECTOR_LEN;
        wasm.mockcanvasctx_strokeText(this.ptr, ptr0, len0, x, y, !isLikeNone(max_width), isLikeNone(max_width) ? 0 : max_width);
    }
    /**
    */
    beginPath() {
        wasm.mockcanvasctx_beginPath(this.ptr);
    }
    /**
    */
    closePath() {
        wasm.mockcanvasctx_closePath(this.ptr);
    }
    /**
    */
    stroke() {
        wasm.mockcanvasctx_stroke(this.ptr);
    }
    /**
    * @param {number} x
    * @param {number} y
    */
    translate(x, y) {
        wasm.mockcanvasctx_translate(this.ptr, x, y);
    }
    /**
    * @param {number} x
    * @param {number} y
    */
    scale(x, y) {
        wasm.mockcanvasctx_scale(this.ptr, x, y);
    }
    /**
    * @param {number} angle
    */
    rotate(angle) {
        wasm.mockcanvasctx_rotate(this.ptr, angle);
    }
    /**
    */
    arc() {
        wasm.mockcanvasctx_arc(this.ptr);
    }
    /**
    * @param {any} winding
    */
    fill(winding) {
        wasm.mockcanvasctx_fill(this.ptr, addHeapObject(winding));
    }
    /**
    * @param {number} x
    * @param {number} y
    * @param {number} width
    * @param {number} height
    */
    rect(x, y, width, height) {
        wasm.mockcanvasctx_rect(this.ptr, x, y, width, height);
    }
    /**
    * @param {any} path_or_winding
    * @param {any} winding
    */
    clip(path_or_winding, winding) {
        wasm.mockcanvasctx_clip(this.ptr, addHeapObject(path_or_winding), addHeapObject(winding));
    }
    /**
    * @param {number} cp1x
    * @param {number} cp1y
    * @param {number} cp2x
    * @param {number} cp2y
    * @param {number} x
    * @param {number} y
    */
    bezierCurveTo(cp1x, cp1y, cp2x, cp2y, x, y) {
        wasm.mockcanvasctx_bezierCurveTo(this.ptr, cp1x, cp1y, cp2x, cp2y, x, y);
    }
    /**
    * @param {any} image
    * @param {string} repetition
    * @returns {CanvasPattern | undefined}
    */
    createPattern(image, repetition) {
        const ptr0 = passStringToWasm0(repetition, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
        const len0 = WASM_VECTOR_LEN;
        const ret = wasm.mockcanvasctx_createPattern(this.ptr, addHeapObject(image), ptr0, len0);
        return takeObject(ret);
    }
    /**
    * @param {string} text
    * @returns {TextMetrics | undefined}
    */
    measureText(text) {
        const ptr0 = passStringToWasm0(text, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
        const len0 = WASM_VECTOR_LEN;
        const ret = wasm.mockcanvasctx_measureText(this.ptr, ptr0, len0);
        return takeObject(ret);
    }
    /**
    * @param {any} value
    */
    set fillStyle(value) {
        wasm.mockcanvasctx_set_fillStyle(this.ptr, addHeapObject(value));
    }
    /**
    * @param {string} value
    */
    set font(value) {
        const ptr0 = passStringToWasm0(value, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
        const len0 = WASM_VECTOR_LEN;
        wasm.mockcanvasctx_set_font(this.ptr, ptr0, len0);
    }
    /**
    * @param {number} value
    */
    set globalAlpha(value) {
        wasm.mockcanvasctx_set_globalAlpha(this.ptr, value);
    }
    /**
    * @param {string} value
    */
    set globalCompositeOperation(value) {
        const ptr0 = passStringToWasm0(value, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
        const len0 = WASM_VECTOR_LEN;
        wasm.mockcanvasctx_set_globalCompositeOperation(this.ptr, ptr0, len0);
    }
    /**
    * @param {boolean} value
    */
    set imageSmoothingEnabled(value) {
        wasm.mockcanvasctx_set_imageSmoothingEnabled(this.ptr, value);
    }
    /**
    * @param {string} value
    */
    set lineCap(value) {
        const ptr0 = passStringToWasm0(value, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
        const len0 = WASM_VECTOR_LEN;
        wasm.mockcanvasctx_set_lineCap(this.ptr, ptr0, len0);
    }
    /**
    * @param {number} value
    */
    set lineDashOffset(value) {
        wasm.mockcanvasctx_set_lineDashOffset(this.ptr, value);
    }
    /**
    * @param {string} value
    */
    set lineJoin(value) {
        const ptr0 = passStringToWasm0(value, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
        const len0 = WASM_VECTOR_LEN;
        wasm.mockcanvasctx_set_lineJoin(this.ptr, ptr0, len0);
    }
    /**
    * @param {number} value
    */
    set lineWidth(value) {
        wasm.mockcanvasctx_set_lineWidth(this.ptr, value);
    }
    /**
    * @param {number} value
    */
    set miterLimit(value) {
        wasm.mockcanvasctx_set_miterLimit(this.ptr, value);
    }
    /**
    * @param {number} value
    */
    set shadowBlur(value) {
        wasm.mockcanvasctx_set_shadowBlur(this.ptr, value);
    }
    /**
    * @param {string} value
    */
    set shadowColor(value) {
        const ptr0 = passStringToWasm0(value, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
        const len0 = WASM_VECTOR_LEN;
        wasm.mockcanvasctx_set_shadowColor(this.ptr, ptr0, len0);
    }
    /**
    * @param {number} value
    */
    set shadowOffsetX(value) {
        wasm.mockcanvasctx_set_shadowOffsetX(this.ptr, value);
    }
    /**
    * @param {number} value
    */
    set shadowOffsetY(value) {
        wasm.mockcanvasctx_set_shadowOffsetY(this.ptr, value);
    }
    /**
    * @param {any} value
    */
    set strokeStyle(value) {
        wasm.mockcanvasctx_set_strokeStyle(this.ptr, addHeapObject(value));
    }
    /**
    * @param {string} value
    */
    set textAlign(value) {
        const ptr0 = passStringToWasm0(value, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
        const len0 = WASM_VECTOR_LEN;
        wasm.mockcanvasctx_set_textAlign(this.ptr, ptr0, len0);
    }
    /**
    * @param {string} value
    */
    set textBaseline(value) {
        const ptr0 = passStringToWasm0(value, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
        const len0 = WASM_VECTOR_LEN;
        wasm.mockcanvasctx_set_textBaseline(this.ptr, ptr0, len0);
    }
    /**
    * @param {number} x0
    * @param {number} y0
    * @param {number} x1
    * @param {number} y1
    * @returns {CanvasGradient}
    */
    createLinearGradient(x0, y0, x1, y1) {
        const ret = wasm.mockcanvasctx_createLinearGradient(this.ptr, x0, y0, x1, y1);
        return takeObject(ret);
    }
}
/**
*/
export class PdfJsGetViewportArgs {

    static __wrap(ptr) {
        const obj = Object.create(PdfJsGetViewportArgs.prototype);
        obj.ptr = ptr;

        return obj;
    }

    __destroy_into_raw() {
        const ptr = this.ptr;
        this.ptr = 0;

        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_pdfjsgetviewportargs_free(ptr);
    }
    /**
    * @returns {number}
    */
    get scale() {
        const ret = wasm.__wbg_get_pdfjsgetviewportargs_scale(this.ptr);
        return ret;
    }
    /**
    * @param {number} arg0
    */
    set scale(arg0) {
        wasm.__wbg_set_pdfjsgetviewportargs_scale(this.ptr, arg0);
    }
}
/**
*/
export class PdfJsRenderArgs {

    __destroy_into_raw() {
        const ptr = this.ptr;
        this.ptr = 0;

        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_pdfjsrenderargs_free(ptr);
    }
    /**
    * @returns {CanvasRenderingContext2D}
    */
    get canvasContext() {
        const ret = wasm.pdfjsrenderargs_canvasContext(this.ptr);
        return takeObject(ret);
    }
    /**
    * @returns {any}
    */
    get viewport() {
        const ret = wasm.pdfjsrenderargs_viewport(this.ptr);
        return takeObject(ret);
    }
    /**
    * @returns {string}
    */
    get background() {
        try {
            const retptr = wasm.__wbindgen_add_to_stack_pointer(-16);
            wasm.pdfjsrenderargs_background(retptr, this.ptr);
            var r0 = getInt32Memory0()[retptr / 4 + 0];
            var r1 = getInt32Memory0()[retptr / 4 + 1];
            return getStringFromWasm0(r0, r1);
        } finally {
            wasm.__wbindgen_add_to_stack_pointer(16);
            wasm.__wbindgen_free(r0, r1);
        }
    }
    /**
    * @returns {Float64Array}
    */
    get transform() {
        try {
            const retptr = wasm.__wbindgen_add_to_stack_pointer(-16);
            wasm.pdfjsrenderargs_transform(retptr, this.ptr);
            var r0 = getInt32Memory0()[retptr / 4 + 0];
            var r1 = getInt32Memory0()[retptr / 4 + 1];
            var v0 = getArrayF64FromWasm0(r0, r1).slice();
            wasm.__wbindgen_free(r0, r1 * 8);
            return v0;
        } finally {
            wasm.__wbindgen_add_to_stack_pointer(16);
        }
    }
}
/**
*/
export class PdfJsRenderArgsWithMockCanvasCtx {

    static __wrap(ptr) {
        const obj = Object.create(PdfJsRenderArgsWithMockCanvasCtx.prototype);
        obj.ptr = ptr;

        return obj;
    }

    __destroy_into_raw() {
        const ptr = this.ptr;
        this.ptr = 0;

        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_pdfjsrenderargswithmockcanvasctx_free(ptr);
    }
    /**
    * @returns {MockCanvasCtx}
    */
    get canvasContext() {
        const ret = wasm.pdfjsrenderargswithmockcanvasctx_canvasContext(this.ptr);
        return MockCanvasCtx.__wrap(ret);
    }
    /**
    * @returns {any}
    */
    get viewport() {
        const ret = wasm.pdfjsrenderargswithmockcanvasctx_viewport(this.ptr);
        return takeObject(ret);
    }
    /**
    * @returns {string}
    */
    get background() {
        try {
            const retptr = wasm.__wbindgen_add_to_stack_pointer(-16);
            wasm.pdfjsrenderargswithmockcanvasctx_background(retptr, this.ptr);
            var r0 = getInt32Memory0()[retptr / 4 + 0];
            var r1 = getInt32Memory0()[retptr / 4 + 1];
            return getStringFromWasm0(r0, r1);
        } finally {
            wasm.__wbindgen_add_to_stack_pointer(16);
            wasm.__wbindgen_free(r0, r1);
        }
    }
    /**
    * @returns {Float64Array}
    */
    get transform() {
        try {
            const retptr = wasm.__wbindgen_add_to_stack_pointer(-16);
            wasm.pdfjsrenderargswithmockcanvasctx_transform(retptr, this.ptr);
            var r0 = getInt32Memory0()[retptr / 4 + 0];
            var r1 = getInt32Memory0()[retptr / 4 + 1];
            var v0 = getArrayF64FromWasm0(r0, r1).slice();
            wasm.__wbindgen_free(r0, r1 * 8);
            return v0;
        } finally {
            wasm.__wbindgen_add_to_stack_pointer(16);
        }
    }
}
/**
*/
export class WasmDixonEditor {

    static __wrap(ptr) {
        const obj = Object.create(WasmDixonEditor.prototype);
        obj.ptr = ptr;

        return obj;
    }

    __destroy_into_raw() {
        const ptr = this.ptr;
        this.ptr = 0;

        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_wasmdixoneditor_free(ptr);
    }
    /**
    * Constructs a new DixonEditor and starts a request_animation_frame loop
    * that will call various methods on DixonEditor that should run once every
    * frame.
    *
    * app_dispatch can be called to dispatch actions to the Purescript app.
    * @param {Function} app_dispatch
    * @param {any} params
    * @returns {WasmDixonEditor}
    */
    static new(app_dispatch, params) {
        const ret = wasm.wasmdixoneditor_new(addHeapObject(app_dispatch), addHeapObject(params));
        return WasmDixonEditor.__wrap(ret);
    }
    /**
    * Returns serialized state of DixonEditor.
    * For consumption by the UI/React.
    * @returns {any}
    */
    get_state() {
        const ret = wasm.wasmdixoneditor_get_state(this.ptr);
        return takeObject(ret);
    }
    /**
    * @param {any} mode
    */
    set_mode(mode) {
        try {
            wasm.wasmdixoneditor_set_mode(this.ptr, addBorrowedObject(mode));
        } finally {
            heap[stack_pointer++] = undefined;
        }
    }
    /**
    * @param {number} amount
    */
    set_zoom_relative(amount) {
        wasm.wasmdixoneditor_set_zoom_relative(this.ptr, amount);
    }
    /**
    */
    fit_to_view() {
        wasm.wasmdixoneditor_fit_to_view(this.ptr);
    }
    /**
    * @param {any} file_id
    * @param {any} drawing_id
    * @param {any} measurement_group_id
    */
    set_query_params(file_id, drawing_id, measurement_group_id) {
        wasm.wasmdixoneditor_set_query_params(this.ptr, addHeapObject(file_id), addHeapObject(drawing_id), addHeapObject(measurement_group_id));
    }
    /**
    * @param {any} file_id
    */
    toggle_file(file_id) {
        wasm.wasmdixoneditor_toggle_file(this.ptr, addHeapObject(file_id));
    }
    /**
    * @param {FileList} files
    */
    upload_files(files) {
        wasm.wasmdixoneditor_upload_files(this.ptr, addHeapObject(files));
    }
    /**
    * @param {File} file
    * @param {any} old_revision_file_id
    */
    upload_file_revision(file, old_revision_file_id) {
        wasm.wasmdixoneditor_upload_file_revision(this.ptr, addHeapObject(file), addHeapObject(old_revision_file_id));
    }
    /**
    * @param {any} file_id
    */
    remove_file(file_id) {
        wasm.wasmdixoneditor_remove_file(this.ptr, addHeapObject(file_id));
    }
    /**
    * @param {any} file_id
    * @param {string} name
    */
    set_file_name(file_id, name) {
        const ptr0 = passStringToWasm0(name, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
        const len0 = WASM_VECTOR_LEN;
        wasm.wasmdixoneditor_set_file_name(this.ptr, addHeapObject(file_id), ptr0, len0);
    }
    /**
    * @param {any} file_id
    * @param {string} folder
    */
    set_file_folder(file_id, folder) {
        const ptr0 = passStringToWasm0(folder, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
        const len0 = WASM_VECTOR_LEN;
        wasm.wasmdixoneditor_set_file_folder(this.ptr, addHeapObject(file_id), ptr0, len0);
    }
    /**
    * @param {any} file_id
    */
    toggle_file_archived(file_id) {
        wasm.wasmdixoneditor_toggle_file_archived(this.ptr, addHeapObject(file_id));
    }
    /**
    * @param {any} file_id
    * @param {any} drawing_id
    */
    toggle_drawing(file_id, drawing_id) {
        wasm.wasmdixoneditor_toggle_drawing(this.ptr, addHeapObject(file_id), addHeapObject(drawing_id));
    }
    /**
    * @param {number} rotation
    */
    set_active_drawing_rotation(rotation) {
        wasm.wasmdixoneditor_set_active_drawing_rotation(this.ptr, rotation);
    }
    /**
    * @param {any} shape
    * @param {number} unscaled_measurement_value_px
    * @param {number} new_value
    * @param {boolean} move_existing_measurements
    */
    set_active_drawing_scale(shape, unscaled_measurement_value_px, new_value, move_existing_measurements) {
        wasm.wasmdixoneditor_set_active_drawing_scale(this.ptr, addHeapObject(shape), unscaled_measurement_value_px, new_value, move_existing_measurements);
    }
    /**
    * @param {any} file_id
    * @param {any} drawing_id
    * @param {string} name
    * @param {string} default_location
    * @param {string} default_formula
    */
    set_drawing_options(file_id, drawing_id, name, default_location, default_formula) {
        const ptr0 = passStringToWasm0(name, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
        const len0 = WASM_VECTOR_LEN;
        const ptr1 = passStringToWasm0(default_location, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
        const len1 = WASM_VECTOR_LEN;
        const ptr2 = passStringToWasm0(default_formula, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
        const len2 = WASM_VECTOR_LEN;
        wasm.wasmdixoneditor_set_drawing_options(this.ptr, addHeapObject(file_id), addHeapObject(drawing_id), ptr0, len0, ptr1, len1, ptr2, len2);
    }
    /**
    * @param {any} measurement_id
    * @param {string} name
    */
    set_measurement_name(measurement_id, name) {
        const ptr0 = passStringToWasm0(name, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
        const len0 = WASM_VECTOR_LEN;
        wasm.wasmdixoneditor_set_measurement_name(this.ptr, addHeapObject(measurement_id), ptr0, len0);
    }
    /**
    * @param {any} measurement_id
    * @param {boolean} highlighted
    */
    set_measurement_highlighted(measurement_id, highlighted) {
        wasm.wasmdixoneditor_set_measurement_highlighted(this.ptr, addHeapObject(measurement_id), highlighted);
    }
    /**
    * @param {any} measurement_id
    * @param {string} location
    */
    set_measurement_location(measurement_id, location) {
        const ptr0 = passStringToWasm0(location, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
        const len0 = WASM_VECTOR_LEN;
        wasm.wasmdixoneditor_set_measurement_location(this.ptr, addHeapObject(measurement_id), ptr0, len0);
    }
    /**
    * @param {any} measurement_id
    * @param {string} formula
    */
    set_measurement_formula(measurement_id, formula) {
        const ptr0 = passStringToWasm0(formula, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
        const len0 = WASM_VECTOR_LEN;
        wasm.wasmdixoneditor_set_measurement_formula(this.ptr, addHeapObject(measurement_id), ptr0, len0);
    }
    /**
    * @param {any} measurement_ids
    */
    delete_measurements(measurement_ids) {
        wasm.wasmdixoneditor_delete_measurements(this.ptr, addHeapObject(measurement_ids));
    }
    /**
    * @param {any} measurement_id
    */
    toggle_measurement(measurement_id) {
        wasm.wasmdixoneditor_toggle_measurement(this.ptr, addHeapObject(measurement_id));
    }
    /**
    */
    toggle_all_measurements() {
        wasm.wasmdixoneditor_toggle_all_measurements(this.ptr);
    }
    /**
    * @param {any} target_drawing_id
    * @param {any} measurements
    */
    paste_measurements(target_drawing_id, measurements) {
        wasm.wasmdixoneditor_paste_measurements(this.ptr, addHeapObject(target_drawing_id), addHeapObject(measurements));
    }
    /**
    * @param {any} components
    * @param {any} from_type
    */
    link_measurement_groups_to_component(components, from_type) {
        wasm.wasmdixoneditor_link_measurement_groups_to_component(this.ptr, addHeapObject(components), addHeapObject(from_type));
    }
    /**
    * @param {any} elements
    * @param {any} from_type
    */
    link_measurement_groups_to_element(elements, from_type) {
        wasm.wasmdixoneditor_link_measurement_groups_to_element(this.ptr, addHeapObject(elements), addHeapObject(from_type));
    }
    /**
    * @param {any} element_specs
    * @param {any} from_type
    * @param {string} element_spec_link_type
    */
    link_measurement_groups_to_element_spec(element_specs, from_type, element_spec_link_type) {
        const ptr0 = passStringToWasm0(element_spec_link_type, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
        const len0 = WASM_VECTOR_LEN;
        wasm.wasmdixoneditor_link_measurement_groups_to_element_spec(this.ptr, addHeapObject(element_specs), addHeapObject(from_type), ptr0, len0);
    }
    /**
    */
    new_measurement_group() {
        wasm.wasmdixoneditor_new_measurement_group(this.ptr);
    }
    /**
    */
    re_calc_all_measurements() {
        wasm.wasmdixoneditor_re_calc_all_measurements(this.ptr);
    }
    /**
    * @param {any} components
    */
    new_measurement_group_from_component(components) {
        wasm.wasmdixoneditor_new_measurement_group_from_component(this.ptr, addHeapObject(components));
    }
    /**
    * @param {any} elements
    */
    new_measurement_group_from_element(elements) {
        wasm.wasmdixoneditor_new_measurement_group_from_element(this.ptr, addHeapObject(elements));
    }
    /**
    * @param {any} element_specs
    * @param {any} components
    */
    new_measurement_group_from_element_spec(element_specs, components) {
        wasm.wasmdixoneditor_new_measurement_group_from_element_spec(this.ptr, addHeapObject(element_specs), addHeapObject(components));
    }
    /**
    * @param {any} measurement_group_ids
    */
    delete_measurement_groups(measurement_group_ids) {
        wasm.wasmdixoneditor_delete_measurement_groups(this.ptr, addHeapObject(measurement_group_ids));
    }
    /**
    * @param {any} measurement_group_id
    * @param {string} name
    */
    set_measurement_group_name(measurement_group_id, name) {
        const ptr0 = passStringToWasm0(name, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
        const len0 = WASM_VECTOR_LEN;
        wasm.wasmdixoneditor_set_measurement_group_name(this.ptr, addHeapObject(measurement_group_id), ptr0, len0);
    }
    /**
    * @param {any} measurement_group_id
    * @param {string} code
    */
    set_measurement_group_code(measurement_group_id, code) {
        const ptr0 = passStringToWasm0(code, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
        const len0 = WASM_VECTOR_LEN;
        wasm.wasmdixoneditor_set_measurement_group_code(this.ptr, addHeapObject(measurement_group_id), ptr0, len0);
    }
    /**
    * @param {any} measurement_group_id
    * @param {string} unit
    */
    set_measurement_group_unit(measurement_group_id, unit) {
        const ptr0 = passStringToWasm0(unit, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
        const len0 = WASM_VECTOR_LEN;
        wasm.wasmdixoneditor_set_measurement_group_unit(this.ptr, addHeapObject(measurement_group_id), ptr0, len0);
    }
    /**
    * @param {any} measurement_group_id
    * @param {string} default_location
    * @param {string} default_formula
    */
    set_measurement_group_defaults(measurement_group_id, default_location, default_formula) {
        const ptr0 = passStringToWasm0(default_location, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
        const len0 = WASM_VECTOR_LEN;
        const ptr1 = passStringToWasm0(default_formula, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
        const len1 = WASM_VECTOR_LEN;
        wasm.wasmdixoneditor_set_measurement_group_defaults(this.ptr, addHeapObject(measurement_group_id), ptr0, len0, ptr1, len1);
    }
    /**
    * @param {any} measurement_group_id
    * @param {string} color
    */
    set_measurement_group_color(measurement_group_id, color) {
        const ptr0 = passStringToWasm0(color, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
        const len0 = WASM_VECTOR_LEN;
        wasm.wasmdixoneditor_set_measurement_group_color(this.ptr, addHeapObject(measurement_group_id), ptr0, len0);
    }
    /**
    * @param {any} measurement_group_id
    */
    toggle_measurement_group(measurement_group_id) {
        wasm.wasmdixoneditor_toggle_measurement_group(this.ptr, addHeapObject(measurement_group_id));
    }
    /**
    * @param {any} measurement_group_id
    */
    set_active_measurement_group(measurement_group_id) {
        wasm.wasmdixoneditor_set_active_measurement_group(this.ptr, addHeapObject(measurement_group_id));
    }
    /**
    */
    toggle_all_measurement_groups() {
        wasm.wasmdixoneditor_toggle_all_measurement_groups(this.ptr);
    }
    /**
    * @param {any} measurement_group_link_ids
    */
    delete_measurement_group_links(measurement_group_link_ids) {
        wasm.wasmdixoneditor_delete_measurement_group_links(this.ptr, addHeapObject(measurement_group_link_ids));
    }
}

async function load(module, imports) {
    if (typeof Response === 'function' && module instanceof Response) {
        if (typeof WebAssembly.instantiateStreaming === 'function') {
            try {
                return await WebAssembly.instantiateStreaming(module, imports);

            } catch (e) {
                if (module.headers.get('Content-Type') != 'application/wasm') {
                    console.warn("`WebAssembly.instantiateStreaming` failed because your server does not serve wasm with `application/wasm` MIME type. Falling back to `WebAssembly.instantiate` which is slower. Original error:\n", e);

                } else {
                    throw e;
                }
            }
        }

        const bytes = await module.arrayBuffer();
        return await WebAssembly.instantiate(bytes, imports);

    } else {
        const instance = await WebAssembly.instantiate(module, imports);

        if (instance instanceof WebAssembly.Instance) {
            return { instance, module };

        } else {
            return instance;
        }
    }
}

function getImports() {
    const imports = {};
    imports.wbg = {};
    imports.wbg.__wbindgen_object_drop_ref = function(arg0) {
        takeObject(arg0);
    };
    imports.wbg.__wbg_getAccessToken_cdf5c7c6e28aa398 = function() { return handleError(function () {
        const ret = getAccessToken();
        return addHeapObject(ret);
    }, arguments) };
    imports.wbg.__wbindgen_string_new = function(arg0, arg1) {
        const ret = getStringFromWasm0(arg0, arg1);
        return addHeapObject(ret);
    };
    imports.wbg.__wbindgen_string_get = function(arg0, arg1) {
        const obj = getObject(arg1);
        const ret = typeof(obj) === 'string' ? obj : undefined;
        var ptr0 = isLikeNone(ret) ? 0 : passStringToWasm0(ret, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
        var len0 = WASM_VECTOR_LEN;
        getInt32Memory0()[arg0 / 4 + 1] = len0;
        getInt32Memory0()[arg0 / 4 + 0] = ptr0;
    };
    imports.wbg.__wbindgen_cb_drop = function(arg0) {
        const obj = takeObject(arg0).original;
        if (obj.cnt-- == 1) {
            obj.a = 0;
            return true;
        }
        const ret = false;
        return ret;
    };
    imports.wbg.__wbg_getDocument_53d077b52d7b1097 = function() { return handleError(function (arg0) {
        const ret = getDocument(getObject(arg0));
        return addHeapObject(ret);
    }, arguments) };
    imports.wbg.__wbg_promise_3aee9d56f81d4ff4 = function() { return handleError(function (arg0) {
        const ret = getObject(arg0).promise;
        return addHeapObject(ret);
    }, arguments) };
    imports.wbg.__wbg_numPages_9bb02dfe707bf0aa = function(arg0) {
        const ret = getObject(arg0).numPages;
        return ret;
    };
    imports.wbg.__wbg_getPage_b68300ba1292fa06 = function(arg0, arg1) {
        const ret = getObject(arg0).getPage(arg1 >>> 0);
        return addHeapObject(ret);
    };
    imports.wbg.__wbg_pageInfo_aa3077d724af66da = function(arg0) {
        const ret = getObject(arg0)._pageInfo;
        return addHeapObject(ret);
    };
    imports.wbg.__wbg_view_510e4b1d9924ea66 = function(arg0) {
        const ret = getObject(arg0).view;
        return addHeapObject(ret);
    };
    imports.wbg.__wbindgen_number_get = function(arg0, arg1) {
        const obj = getObject(arg1);
        const ret = typeof(obj) === 'number' ? obj : undefined;
        getFloat64Memory0()[arg0 / 8 + 1] = isLikeNone(ret) ? 0 : ret;
        getInt32Memory0()[arg0 / 4 + 0] = !isLikeNone(ret);
    };
    imports.wbg.__wbindgen_object_clone_ref = function(arg0) {
        const ret = getObject(arg0);
        return addHeapObject(ret);
    };
    imports.wbg.__wbg_destroy_04a8af9eeb6fc163 = function(arg0) {
        getObject(arg0).destroy();
    };
    imports.wbg.__wbg_promise_b2a8920ba4a3106f = function() { return handleError(function (arg0) {
        const ret = getObject(arg0).promise;
        return addHeapObject(ret);
    }, arguments) };
    imports.wbg.__wbindgen_is_undefined = function(arg0) {
        const ret = getObject(arg0) === undefined;
        return ret;
    };
    imports.wbg.__wbindgen_is_string = function(arg0) {
        const ret = typeof(getObject(arg0)) === 'string';
        return ret;
    };
    imports.wbg.__wbg_cancel_ab33f3793ddf0ec0 = function(arg0) {
        getObject(arg0).cancel();
    };
    imports.wbg.__wbg_getViewport_f01072975012c3c3 = function(arg0, arg1) {
        const ret = getObject(arg0).getViewport(PdfJsGetViewportArgs.__wrap(arg1));
        return addHeapObject(ret);
    };
    imports.wbg.__wbg_width_9f3ecb7d5cc83dcb = function(arg0) {
        const ret = getObject(arg0).width;
        return ret;
    };
    imports.wbg.__wbg_height_3ef2d18f20152e72 = function(arg0) {
        const ret = getObject(arg0).height;
        return ret;
    };
    imports.wbg.__wbg_render_e6679b8c8b3d08a7 = function(arg0, arg1) {
        const ret = getObject(arg0).render(PdfJsRenderArgsWithMockCanvasCtx.__wrap(arg1));
        return addHeapObject(ret);
    };
    imports.wbg.__wbindgen_number_new = function(arg0) {
        const ret = arg0;
        return addHeapObject(ret);
    };
    imports.wbg.__wbg_wasmEvalDixonMeasurementFormula_5689b3e4126a4f93 = function() { return handleError(function (arg0) {
        const ret = wasmEvalDixonMeasurementFormula(takeObject(arg0));
        return addHeapObject(ret);
    }, arguments) };
    imports.wbg.__wbg_getstack_b0095bed824adfc4 = function(arg0, arg1) {
        const ret = get_stack(getObject(arg1));
        var ptr0 = isLikeNone(ret) ? 0 : passStringToWasm0(ret, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
        var len0 = WASM_VECTOR_LEN;
        getInt32Memory0()[arg0 / 4 + 1] = len0;
        getInt32Memory0()[arg0 / 4 + 0] = ptr0;
    };
    imports.wbg.__wbg_new_5a226899a0c0d4fe = function() {
        const ret = new Error();
        return addHeapObject(ret);
    };
    imports.wbg.__wbg_stack_fe2c8d235e3ccc2b = function(arg0, arg1) {
        const ret = getObject(arg1).stack;
        const ptr0 = passStringToWasm0(ret, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
        const len0 = WASM_VECTOR_LEN;
        getInt32Memory0()[arg0 / 4 + 1] = len0;
        getInt32Memory0()[arg0 / 4 + 0] = ptr0;
    };
    imports.wbg.__wbg_error_bd742eacc1029e28 = function(arg0, arg1) {
        try {
            console.error(getStringFromWasm0(arg0, arg1));
        } finally {
            wasm.__wbindgen_free(arg0, arg1);
        }
    };
    imports.wbg.__wbg_instanceof_Window_e266f02eee43b570 = function(arg0) {
        let result;
        try {
            result = getObject(arg0) instanceof Window;
        } catch {
            result = false;
        }
        const ret = result;
        return ret;
    };
    imports.wbg.__wbg_document_950215a728589a2d = function(arg0) {
        const ret = getObject(arg0).document;
        return isLikeNone(ret) ? 0 : addHeapObject(ret);
    };
    imports.wbg.__wbg_performance_8629f414811abc46 = function(arg0) {
        const ret = getObject(arg0).performance;
        return isLikeNone(ret) ? 0 : addHeapObject(ret);
    };
    imports.wbg.__wbg_localStorage_42608208af988a02 = function() { return handleError(function (arg0) {
        const ret = getObject(arg0).localStorage;
        return isLikeNone(ret) ? 0 : addHeapObject(ret);
    }, arguments) };
    imports.wbg.__wbg_cancelAnimationFrame_d079cdb83bc43b26 = function() { return handleError(function (arg0, arg1) {
        getObject(arg0).cancelAnimationFrame(arg1);
    }, arguments) };
    imports.wbg.__wbg_requestAnimationFrame_afe426b568f84138 = function() { return handleError(function (arg0, arg1) {
        const ret = getObject(arg0).requestAnimationFrame(getObject(arg1));
        return ret;
    }, arguments) };
    imports.wbg.__wbg_setTimeout_7be467133e6d8314 = function() { return handleError(function (arg0, arg1) {
        const ret = getObject(arg0).setTimeout(getObject(arg1));
        return ret;
    }, arguments) };
    imports.wbg.__wbg_settitle_31c3ca7da21b37da = function(arg0, arg1, arg2) {
        getObject(arg0).title = getStringFromWasm0(arg1, arg2);
    };
    imports.wbg.__wbg_createElement_e2a0e21263eb5416 = function() { return handleError(function (arg0, arg1, arg2) {
        const ret = getObject(arg0).createElement(getStringFromWasm0(arg1, arg2));
        return addHeapObject(ret);
    }, arguments) };
    imports.wbg.__wbg_getElementById_eb93a47327bb5585 = function(arg0, arg1, arg2) {
        const ret = getObject(arg0).getElementById(getStringFromWasm0(arg1, arg2));
        return isLikeNone(ret) ? 0 : addHeapObject(ret);
    };
    imports.wbg.__wbg_data_af909e5dfe73e68c = function(arg0) {
        const ret = getObject(arg0).data;
        return addHeapObject(ret);
    };
    imports.wbg.__wbg_offsetX_413d9f02022e72ad = function(arg0) {
        const ret = getObject(arg0).offsetX;
        return ret;
    };
    imports.wbg.__wbg_offsetY_488f80a0a9666028 = function(arg0) {
        const ret = getObject(arg0).offsetY;
        return ret;
    };
    imports.wbg.__wbg_button_a1c470d5e4c997f2 = function(arg0) {
        const ret = getObject(arg0).button;
        return ret;
    };
    imports.wbg.__wbg_postMessage_85b17b465d6aacd6 = function() { return handleError(function (arg0, arg1) {
        getObject(arg0).postMessage(getObject(arg1));
    }, arguments) };
    imports.wbg.__wbg_name_ccf3024ae4e3ac54 = function(arg0, arg1) {
        const ret = getObject(arg1).name;
        const ptr0 = passStringToWasm0(ret, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
        const len0 = WASM_VECTOR_LEN;
        getInt32Memory0()[arg0 / 4 + 1] = len0;
        getInt32Memory0()[arg0 / 4 + 0] = ptr0;
    };
    imports.wbg.__wbg_new_1a6c42b397dc618f = function() { return handleError(function () {
        const ret = new FormData();
        return addHeapObject(ret);
    }, arguments) };
    imports.wbg.__wbg_append_fa1377805e69be17 = function() { return handleError(function (arg0, arg1, arg2, arg3) {
        getObject(arg0).append(getStringFromWasm0(arg1, arg2), getObject(arg3));
    }, arguments) };
    imports.wbg.__wbg_append_f61f426dc3d8ed3f = function() { return handleError(function (arg0, arg1, arg2, arg3, arg4) {
        getObject(arg0).append(getStringFromWasm0(arg1, arg2), getStringFromWasm0(arg3, arg4));
    }, arguments) };
    imports.wbg.__wbg_code_aed21120de275a12 = function(arg0, arg1) {
        const ret = getObject(arg1).code;
        const ptr0 = passStringToWasm0(ret, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
        const len0 = WASM_VECTOR_LEN;
        getInt32Memory0()[arg0 / 4 + 1] = len0;
        getInt32Memory0()[arg0 / 4 + 0] = ptr0;
    };
    imports.wbg.__wbg_preventDefault_16b2170b12f56317 = function(arg0) {
        getObject(arg0).preventDefault();
    };
    imports.wbg.__wbg_a_ba51426af54c677a = function(arg0) {
        const ret = getObject(arg0).a;
        return ret;
    };
    imports.wbg.__wbg_b_e353e5af8fd790ff = function(arg0) {
        const ret = getObject(arg0).b;
        return ret;
    };
    imports.wbg.__wbg_c_91d2dab8416e22a4 = function(arg0) {
        const ret = getObject(arg0).c;
        return ret;
    };
    imports.wbg.__wbg_d_9120f075783411ea = function(arg0) {
        const ret = getObject(arg0).d;
        return ret;
    };
    imports.wbg.__wbg_e_65a8131ab187edae = function(arg0) {
        const ret = getObject(arg0).e;
        return ret;
    };
    imports.wbg.__wbg_f_1302e186f63f1fc6 = function(arg0) {
        const ret = getObject(arg0).f;
        return ret;
    };
    imports.wbg.__wbg_parentElement_0e8c9afce5cb9d6e = function(arg0) {
        const ret = getObject(arg0).parentElement;
        return isLikeNone(ret) ? 0 : addHeapObject(ret);
    };
    imports.wbg.__wbg_get_460ba3644fab1c42 = function(arg0, arg1) {
        const ret = getObject(arg0)[arg1 >>> 0];
        return isLikeNone(ret) ? 0 : addHeapObject(ret);
    };
    imports.wbg.__wbg_get_b883881571048aa2 = function() { return handleError(function (arg0, arg1, arg2, arg3) {
        const ret = getObject(arg1).get(getStringFromWasm0(arg2, arg3));
        var ptr0 = isLikeNone(ret) ? 0 : passStringToWasm0(ret, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
        var len0 = WASM_VECTOR_LEN;
        getInt32Memory0()[arg0 / 4 + 1] = len0;
        getInt32Memory0()[arg0 / 4 + 0] = ptr0;
    }, arguments) };
    imports.wbg.__wbg_set_a5d34c36a1a4ebd1 = function() { return handleError(function (arg0, arg1, arg2, arg3, arg4) {
        getObject(arg0).set(getStringFromWasm0(arg1, arg2), getStringFromWasm0(arg3, arg4));
    }, arguments) };
    imports.wbg.__wbg_instanceof_Response_fb3a4df648c1859b = function(arg0) {
        let result;
        try {
            result = getObject(arg0) instanceof Response;
        } catch {
            result = false;
        }
        const ret = result;
        return ret;
    };
    imports.wbg.__wbg_status_d483a4ac847f380a = function(arg0) {
        const ret = getObject(arg0).status;
        return ret;
    };
    imports.wbg.__wbg_ok_1cd4c5ee1ccf4e0f = function(arg0) {
        const ret = getObject(arg0).ok;
        return ret;
    };
    imports.wbg.__wbg_arrayBuffer_cb886e06a9e36e4d = function() { return handleError(function (arg0) {
        const ret = getObject(arg0).arrayBuffer();
        return addHeapObject(ret);
    }, arguments) };
    imports.wbg.__wbg_text_f61464d781b099f0 = function() { return handleError(function (arg0) {
        const ret = getObject(arg0).text();
        return addHeapObject(ret);
    }, arguments) };
    imports.wbg.__wbg_getItem_f0d43fc4e780b652 = function() { return handleError(function (arg0, arg1, arg2, arg3) {
        const ret = getObject(arg1).getItem(getStringFromWasm0(arg2, arg3));
        var ptr0 = isLikeNone(ret) ? 0 : passStringToWasm0(ret, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
        var len0 = WASM_VECTOR_LEN;
        getInt32Memory0()[arg0 / 4 + 1] = len0;
        getInt32Memory0()[arg0 / 4 + 0] = ptr0;
    }, arguments) };
    imports.wbg.__wbg_setItem_f645824d6eface62 = function() { return handleError(function (arg0, arg1, arg2, arg3, arg4) {
        getObject(arg0).setItem(getStringFromWasm0(arg1, arg2), getStringFromWasm0(arg3, arg4));
    }, arguments) };
    imports.wbg.__wbg_clientWidth_1fa51f20a3bebbbf = function(arg0) {
        const ret = getObject(arg0).clientWidth;
        return ret;
    };
    imports.wbg.__wbg_clientHeight_0fe149fdad1fe046 = function(arg0) {
        const ret = getObject(arg0).clientHeight;
        return ret;
    };
    imports.wbg.__wbg_remove_b18bc815630b67ec = function(arg0) {
        getObject(arg0).remove();
    };
    imports.wbg.__wbg_fetch_661ffba2a4f2519c = function(arg0, arg1) {
        const ret = getObject(arg0).fetch(getObject(arg1));
        return addHeapObject(ret);
    };
    imports.wbg.__wbg_error_fe807da27c4a4ced = function(arg0) {
        console.error(getObject(arg0));
    };
    imports.wbg.__wbg_log_7bb108d119bafbc1 = function(arg0) {
        console.log(getObject(arg0));
    };
    imports.wbg.__wbg_log_d047cf0648d2678e = function(arg0, arg1) {
        console.log(getObject(arg0), getObject(arg1));
    };
    imports.wbg.__wbg_instanceof_CanvasRenderingContext2d_3e95629461ed9f67 = function(arg0) {
        let result;
        try {
            result = getObject(arg0) instanceof CanvasRenderingContext2D;
        } catch {
            result = false;
        }
        const ret = result;
        return ret;
    };
    imports.wbg.__wbg_setglobalAlpha_9b247016ca5b7baf = function(arg0, arg1) {
        getObject(arg0).globalAlpha = arg1;
    };
    imports.wbg.__wbg_setglobalCompositeOperation_7c8142daf951a34d = function() { return handleError(function (arg0, arg1, arg2) {
        getObject(arg0).globalCompositeOperation = getStringFromWasm0(arg1, arg2);
    }, arguments) };
    imports.wbg.__wbg_setstrokeStyle_073fc10b086c1727 = function(arg0, arg1) {
        getObject(arg0).strokeStyle = getObject(arg1);
    };
    imports.wbg.__wbg_setfillStyle_b065cfad34a78974 = function(arg0, arg1) {
        getObject(arg0).fillStyle = getObject(arg1);
    };
    imports.wbg.__wbg_setimageSmoothingEnabled_bf2992e4332c028e = function(arg0, arg1) {
        getObject(arg0).imageSmoothingEnabled = arg1 !== 0;
    };
    imports.wbg.__wbg_setlineWidth_2b5a5da5c4bf4084 = function(arg0, arg1) {
        getObject(arg0).lineWidth = arg1;
    };
    imports.wbg.__wbg_setlineCap_a2933c0b1d64ae1b = function(arg0, arg1, arg2) {
        getObject(arg0).lineCap = getStringFromWasm0(arg1, arg2);
    };
    imports.wbg.__wbg_setlineJoin_a630a26a037775c0 = function(arg0, arg1, arg2) {
        getObject(arg0).lineJoin = getStringFromWasm0(arg1, arg2);
    };
    imports.wbg.__wbg_setmiterLimit_9632926e0263a940 = function(arg0, arg1) {
        getObject(arg0).miterLimit = arg1;
    };
    imports.wbg.__wbg_setlineDashOffset_50bc8b133bbef444 = function(arg0, arg1) {
        getObject(arg0).lineDashOffset = arg1;
    };
    imports.wbg.__wbg_setshadowOffsetX_6d042474e07798fb = function(arg0, arg1) {
        getObject(arg0).shadowOffsetX = arg1;
    };
    imports.wbg.__wbg_setshadowOffsetY_0b66c496a3c6d32a = function(arg0, arg1) {
        getObject(arg0).shadowOffsetY = arg1;
    };
    imports.wbg.__wbg_setshadowBlur_0d9c1fbd74527ced = function(arg0, arg1) {
        getObject(arg0).shadowBlur = arg1;
    };
    imports.wbg.__wbg_setshadowColor_73290302787584de = function(arg0, arg1, arg2) {
        getObject(arg0).shadowColor = getStringFromWasm0(arg1, arg2);
    };
    imports.wbg.__wbg_setfont_5ff88bdcda655052 = function(arg0, arg1, arg2) {
        getObject(arg0).font = getStringFromWasm0(arg1, arg2);
    };
    imports.wbg.__wbg_settextAlign_aefb62085068cf7c = function(arg0, arg1, arg2) {
        getObject(arg0).textAlign = getStringFromWasm0(arg1, arg2);
    };
    imports.wbg.__wbg_settextBaseline_1415eff4e466c559 = function(arg0, arg1, arg2) {
        getObject(arg0).textBaseline = getStringFromWasm0(arg1, arg2);
    };
    imports.wbg.__wbg_drawImage_595c29d3eb1971b5 = function() { return handleError(function (arg0, arg1, arg2, arg3) {
        getObject(arg0).drawImage(getObject(arg1), arg2, arg3);
    }, arguments) };
    imports.wbg.__wbg_drawImage_2ed9b6486731716a = function() { return handleError(function (arg0, arg1, arg2, arg3, arg4, arg5) {
        getObject(arg0).drawImage(getObject(arg1), arg2, arg3, arg4, arg5);
    }, arguments) };
    imports.wbg.__wbg_drawImage_d7000ce70deaf2e6 = function() { return handleError(function (arg0, arg1, arg2, arg3, arg4, arg5, arg6, arg7, arg8, arg9) {
        getObject(arg0).drawImage(getObject(arg1), arg2, arg3, arg4, arg5, arg6, arg7, arg8, arg9);
    }, arguments) };
    imports.wbg.__wbg_beginPath_0948db80d0d23ce3 = function(arg0) {
        getObject(arg0).beginPath();
    };
    imports.wbg.__wbg_clip_72d61d70944d8e88 = function(arg0) {
        getObject(arg0).clip();
    };
    imports.wbg.__wbg_clip_47fd8994a6f4c456 = function(arg0, arg1) {
        getObject(arg0).clip(takeObject(arg1));
    };
    imports.wbg.__wbg_clip_ffdc8a9797ae41ae = function(arg0, arg1) {
        getObject(arg0).clip(getObject(arg1));
    };
    imports.wbg.__wbg_clip_3076bfd713f8dbdf = function(arg0, arg1, arg2) {
        getObject(arg0).clip(getObject(arg1), takeObject(arg2));
    };
    imports.wbg.__wbg_fill_ae4121c3dafa1f99 = function(arg0) {
        getObject(arg0).fill();
    };
    imports.wbg.__wbg_fill_ab8f5e7d4e52b45e = function(arg0, arg1) {
        getObject(arg0).fill(takeObject(arg1));
    };
    imports.wbg.__wbg_stroke_6e1b85c72caa53ea = function(arg0) {
        getObject(arg0).stroke();
    };
    imports.wbg.__wbg_createLinearGradient_07e75b1061695061 = function(arg0, arg1, arg2, arg3, arg4) {
        const ret = getObject(arg0).createLinearGradient(arg1, arg2, arg3, arg4);
        return addHeapObject(ret);
    };
    imports.wbg.__wbg_createPattern_ffbbf4d7c9d4d6ec = function() { return handleError(function (arg0, arg1, arg2, arg3) {
        const ret = getObject(arg0).createPattern(getObject(arg1), getStringFromWasm0(arg2, arg3));
        return isLikeNone(ret) ? 0 : addHeapObject(ret);
    }, arguments) };
    imports.wbg.__wbg_setLineDash_3684b640b26da01b = function() { return handleError(function (arg0, arg1) {
        getObject(arg0).setLineDash(getObject(arg1));
    }, arguments) };
    imports.wbg.__wbg_arc_37174ad97fea93a9 = function() { return handleError(function (arg0, arg1, arg2, arg3, arg4, arg5) {
        getObject(arg0).arc(arg1, arg2, arg3, arg4, arg5);
    }, arguments) };
    imports.wbg.__wbg_bezierCurveTo_4febb2cf3060ab81 = function(arg0, arg1, arg2, arg3, arg4, arg5, arg6) {
        getObject(arg0).bezierCurveTo(arg1, arg2, arg3, arg4, arg5, arg6);
    };
    imports.wbg.__wbg_closePath_ec8070a43cf8068c = function(arg0) {
        getObject(arg0).closePath();
    };
    imports.wbg.__wbg_lineTo_b902f35217ff96ab = function(arg0, arg1, arg2) {
        getObject(arg0).lineTo(arg1, arg2);
    };
    imports.wbg.__wbg_moveTo_b0d9695c44d309f0 = function(arg0, arg1, arg2) {
        getObject(arg0).moveTo(arg1, arg2);
    };
    imports.wbg.__wbg_quadraticCurveTo_207c0d15035b70d8 = function(arg0, arg1, arg2, arg3, arg4) {
        getObject(arg0).quadraticCurveTo(arg1, arg2, arg3, arg4);
    };
    imports.wbg.__wbg_rect_e75f4a88fd8225ff = function(arg0, arg1, arg2, arg3, arg4) {
        getObject(arg0).rect(arg1, arg2, arg3, arg4);
    };
    imports.wbg.__wbg_clearRect_92c5351269c74162 = function(arg0, arg1, arg2, arg3, arg4) {
        getObject(arg0).clearRect(arg1, arg2, arg3, arg4);
    };
    imports.wbg.__wbg_fillRect_f63ba845233f000a = function(arg0, arg1, arg2, arg3, arg4) {
        getObject(arg0).fillRect(arg1, arg2, arg3, arg4);
    };
    imports.wbg.__wbg_restore_38b3fc1b40a29757 = function(arg0) {
        getObject(arg0).restore();
    };
    imports.wbg.__wbg_save_bab01f1913b2e819 = function(arg0) {
        getObject(arg0).save();
    };
    imports.wbg.__wbg_fillText_1c8a94ba170ba3ee = function() { return handleError(function (arg0, arg1, arg2, arg3, arg4) {
        getObject(arg0).fillText(getStringFromWasm0(arg1, arg2), arg3, arg4);
    }, arguments) };
    imports.wbg.__wbg_fillText_be2a576729da2b6c = function() { return handleError(function (arg0, arg1, arg2, arg3, arg4, arg5) {
        getObject(arg0).fillText(getStringFromWasm0(arg1, arg2), arg3, arg4, arg5);
    }, arguments) };
    imports.wbg.__wbg_measureText_168eb9940caf55a2 = function() { return handleError(function (arg0, arg1, arg2) {
        const ret = getObject(arg0).measureText(getStringFromWasm0(arg1, arg2));
        return addHeapObject(ret);
    }, arguments) };
    imports.wbg.__wbg_strokeText_577ccfcd55ed680f = function() { return handleError(function (arg0, arg1, arg2, arg3, arg4) {
        getObject(arg0).strokeText(getStringFromWasm0(arg1, arg2), arg3, arg4);
    }, arguments) };
    imports.wbg.__wbg_strokeText_bd2f8f490e60c72a = function() { return handleError(function (arg0, arg1, arg2, arg3, arg4, arg5) {
        getObject(arg0).strokeText(getStringFromWasm0(arg1, arg2), arg3, arg4, arg5);
    }, arguments) };
    imports.wbg.__wbg_getTransform_bf0fb3c1a1d5dbd1 = function() { return handleError(function (arg0) {
        const ret = getObject(arg0).getTransform();
        return addHeapObject(ret);
    }, arguments) };
    imports.wbg.__wbg_resetTransform_cb5911b6d0ba3407 = function() { return handleError(function (arg0) {
        getObject(arg0).resetTransform();
    }, arguments) };
    imports.wbg.__wbg_rotate_6b035a321d3e851f = function() { return handleError(function (arg0, arg1) {
        getObject(arg0).rotate(arg1);
    }, arguments) };
    imports.wbg.__wbg_scale_02831dbd975be520 = function() { return handleError(function (arg0, arg1, arg2) {
        getObject(arg0).scale(arg1, arg2);
    }, arguments) };
    imports.wbg.__wbg_setTransform_a0b25774ef0bfce8 = function() { return handleError(function (arg0, arg1, arg2, arg3, arg4, arg5, arg6) {
        getObject(arg0).setTransform(arg1, arg2, arg3, arg4, arg5, arg6);
    }, arguments) };
    imports.wbg.__wbg_transform_a73a1d3cba69df05 = function() { return handleError(function (arg0, arg1, arg2, arg3, arg4, arg5, arg6) {
        getObject(arg0).transform(arg1, arg2, arg3, arg4, arg5, arg6);
    }, arguments) };
    imports.wbg.__wbg_translate_775b28f4f71b0a4a = function() { return handleError(function (arg0, arg1, arg2) {
        getObject(arg0).translate(arg1, arg2);
    }, arguments) };
    imports.wbg.__wbg_type_19f7cd16022318c9 = function(arg0, arg1) {
        const ret = getObject(arg1).type;
        const ptr0 = passStringToWasm0(ret, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
        const len0 = WASM_VECTOR_LEN;
        getInt32Memory0()[arg0 / 4 + 1] = len0;
        getInt32Memory0()[arg0 / 4 + 0] = ptr0;
    };
    imports.wbg.__wbg_newwithu8arraysequence_fede89c6795cc33e = function() { return handleError(function (arg0) {
        const ret = new Blob(getObject(arg0));
        return addHeapObject(ret);
    }, arguments) };
    imports.wbg.__wbg_addEventListener_615d4590d38da1c9 = function() { return handleError(function (arg0, arg1, arg2, arg3) {
        getObject(arg0).addEventListener(getStringFromWasm0(arg1, arg2), getObject(arg3));
    }, arguments) };
    imports.wbg.__wbg_removeEventListener_86fd19ed073cd1ed = function() { return handleError(function (arg0, arg1, arg2, arg3) {
        getObject(arg0).removeEventListener(getStringFromWasm0(arg1, arg2), getObject(arg3));
    }, arguments) };
    imports.wbg.__wbg_setonmessage_65a0320a125a4536 = function(arg0, arg1) {
        getObject(arg0).onmessage = getObject(arg1);
    };
    imports.wbg.__wbg_postMessage_47bfdf2b8441df36 = function() { return handleError(function (arg0, arg1) {
        getObject(arg0).postMessage(getObject(arg1));
    }, arguments) };
    imports.wbg.__wbg_new_e8730a93aa50c93f = function() { return handleError(function () {
        const ret = new TextDecoder();
        return addHeapObject(ret);
    }, arguments) };
    imports.wbg.__wbg_decode_4a71fedfc2b6189b = function() { return handleError(function (arg0, arg1, arg2) {
        const ret = getObject(arg1).decode(getObject(arg2));
        const ptr0 = passStringToWasm0(ret, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
        const len0 = WASM_VECTOR_LEN;
        getInt32Memory0()[arg0 / 4 + 1] = len0;
        getInt32Memory0()[arg0 / 4 + 0] = ptr0;
    }, arguments) };
    imports.wbg.__wbg_width_78220561fc65ddb3 = function(arg0) {
        const ret = getObject(arg0).width;
        return ret;
    };
    imports.wbg.__wbg_deltaY_b32fa858e16edcc0 = function(arg0) {
        const ret = getObject(arg0).deltaY;
        return ret;
    };
    imports.wbg.__wbg_result_4c6690478b5532e4 = function() { return handleError(function (arg0) {
        const ret = getObject(arg0).result;
        return addHeapObject(ret);
    }, arguments) };
    imports.wbg.__wbg_setonload_14ae8c68b7ee43ee = function(arg0, arg1) {
        getObject(arg0).onload = getObject(arg1);
    };
    imports.wbg.__wbg_new_8eef8a8754c6aae7 = function() { return handleError(function () {
        const ret = new FileReader();
        return addHeapObject(ret);
    }, arguments) };
    imports.wbg.__wbg_readAsArrayBuffer_bc9f4aff6d3e1bb1 = function() { return handleError(function (arg0, arg1) {
        getObject(arg0).readAsArrayBuffer(getObject(arg1));
    }, arguments) };
    imports.wbg.__wbg_instanceof_HtmlCanvasElement_f5f69dab93281ebe = function(arg0) {
        let result;
        try {
            result = getObject(arg0) instanceof HTMLCanvasElement;
        } catch {
            result = false;
        }
        const ret = result;
        return ret;
    };
    imports.wbg.__wbg_width_a40e21a22129b197 = function(arg0) {
        const ret = getObject(arg0).width;
        return ret;
    };
    imports.wbg.__wbg_setwidth_81c62bc806e0a727 = function(arg0, arg1) {
        getObject(arg0).width = arg1 >>> 0;
    };
    imports.wbg.__wbg_height_98d51321254345a5 = function(arg0) {
        const ret = getObject(arg0).height;
        return ret;
    };
    imports.wbg.__wbg_setheight_98cf0db22c40ef07 = function(arg0, arg1) {
        getObject(arg0).height = arg1 >>> 0;
    };
    imports.wbg.__wbg_getContext_3ae404b649cf9287 = function() { return handleError(function (arg0, arg1, arg2) {
        const ret = getObject(arg0).getContext(getStringFromWasm0(arg1, arg2));
        return isLikeNone(ret) ? 0 : addHeapObject(ret);
    }, arguments) };
    imports.wbg.__wbg_now_c644db5194be8437 = function(arg0) {
        const ret = getObject(arg0).now();
        return ret;
    };
    imports.wbg.__wbg_headers_ab5251d2727ac41e = function(arg0) {
        const ret = getObject(arg0).headers;
        return addHeapObject(ret);
    };
    imports.wbg.__wbg_newwithstr_533a2b691cd87b92 = function() { return handleError(function (arg0, arg1) {
        const ret = new Request(getStringFromWasm0(arg0, arg1));
        return addHeapObject(ret);
    }, arguments) };
    imports.wbg.__wbg_newwithstrandinit_c45f0dc6da26fd03 = function() { return handleError(function (arg0, arg1, arg2) {
        const ret = new Request(getStringFromWasm0(arg0, arg1), getObject(arg2));
        return addHeapObject(ret);
    }, arguments) };
    imports.wbg.__wbg_get_27fe3dac1c4d0224 = function(arg0, arg1) {
        const ret = getObject(arg0)[arg1 >>> 0];
        return addHeapObject(ret);
    };
    imports.wbg.__wbg_new_b525de17f44a8943 = function() {
        const ret = new Array();
        return addHeapObject(ret);
    };
    imports.wbg.__wbg_newnoargs_2b8b6bd7753c76ba = function(arg0, arg1) {
        const ret = new Function(getStringFromWasm0(arg0, arg1));
        return addHeapObject(ret);
    };
    imports.wbg.__wbg_get_baf4855f9a986186 = function() { return handleError(function (arg0, arg1) {
        const ret = Reflect.get(getObject(arg0), getObject(arg1));
        return addHeapObject(ret);
    }, arguments) };
    imports.wbg.__wbg_call_95d1ea488d03e4e8 = function() { return handleError(function (arg0, arg1) {
        const ret = getObject(arg0).call(getObject(arg1));
        return addHeapObject(ret);
    }, arguments) };
    imports.wbg.__wbg_new_f9876326328f45ed = function() {
        const ret = new Object();
        return addHeapObject(ret);
    };
    imports.wbg.__wbg_self_e7c1f827057f6584 = function() { return handleError(function () {
        const ret = self.self;
        return addHeapObject(ret);
    }, arguments) };
    imports.wbg.__wbg_window_a09ec664e14b1b81 = function() { return handleError(function () {
        const ret = window.window;
        return addHeapObject(ret);
    }, arguments) };
    imports.wbg.__wbg_globalThis_87cbb8506fecf3a9 = function() { return handleError(function () {
        const ret = globalThis.globalThis;
        return addHeapObject(ret);
    }, arguments) };
    imports.wbg.__wbg_global_c85a9259e621f3db = function() { return handleError(function () {
        const ret = global.global;
        return addHeapObject(ret);
    }, arguments) };
    imports.wbg.__wbg_newwithlength_0da6f12fbc1ab6eb = function(arg0) {
        const ret = new Array(arg0 >>> 0);
        return addHeapObject(ret);
    };
    imports.wbg.__wbg_push_49c286f04dd3bf59 = function(arg0, arg1) {
        const ret = getObject(arg0).push(getObject(arg1));
        return ret;
    };
    imports.wbg.__wbg_instanceof_ArrayBuffer_a69f02ee4c4f5065 = function(arg0) {
        let result;
        try {
            result = getObject(arg0) instanceof ArrayBuffer;
        } catch {
            result = false;
        }
        const ret = result;
        return ret;
    };
    imports.wbg.__wbg_new_15d3966e9981a196 = function(arg0, arg1) {
        const ret = new Error(getStringFromWasm0(arg0, arg1));
        return addHeapObject(ret);
    };
    imports.wbg.__wbg_toString_cec163b212643722 = function(arg0) {
        const ret = getObject(arg0).toString();
        return addHeapObject(ret);
    };
    imports.wbg.__wbg_call_9495de66fdbe016b = function() { return handleError(function (arg0, arg1, arg2) {
        const ret = getObject(arg0).call(getObject(arg1), getObject(arg2));
        return addHeapObject(ret);
    }, arguments) };
    imports.wbg.__wbg_exec_9d6be66864fc3c35 = function(arg0, arg1, arg2) {
        const ret = getObject(arg0).exec(getStringFromWasm0(arg1, arg2));
        return isLikeNone(ret) ? 0 : addHeapObject(ret);
    };
    imports.wbg.__wbg_new_bf04732a17426e8f = function(arg0, arg1, arg2, arg3) {
        const ret = new RegExp(getStringFromWasm0(arg0, arg1), getStringFromWasm0(arg2, arg3));
        return addHeapObject(ret);
    };
    imports.wbg.__wbg_test_83023494c96bb714 = function(arg0, arg1, arg2) {
        const ret = getObject(arg0).test(getStringFromWasm0(arg1, arg2));
        return ret;
    };
    imports.wbg.__wbg_replace_f95953aba4daa007 = function(arg0, arg1, arg2, arg3) {
        const ret = getObject(arg0).replace(getObject(arg1), getStringFromWasm0(arg2, arg3));
        return addHeapObject(ret);
    };
    imports.wbg.__wbg_resolve_fd40f858d9db1a04 = function(arg0) {
        const ret = Promise.resolve(getObject(arg0));
        return addHeapObject(ret);
    };
    imports.wbg.__wbg_then_ec5db6d509eb475f = function(arg0, arg1) {
        const ret = getObject(arg0).then(getObject(arg1));
        return addHeapObject(ret);
    };
    imports.wbg.__wbg_then_f753623316e2873a = function(arg0, arg1, arg2) {
        const ret = getObject(arg0).then(getObject(arg1), getObject(arg2));
        return addHeapObject(ret);
    };
    imports.wbg.__wbg_buffer_cf65c07de34b9a08 = function(arg0) {
        const ret = getObject(arg0).buffer;
        return addHeapObject(ret);
    };
    imports.wbg.__wbg_newwithbyteoffsetandlength_9fb2f11355ecadf5 = function(arg0, arg1, arg2) {
        const ret = new Uint8Array(getObject(arg0), arg1 >>> 0, arg2 >>> 0);
        return addHeapObject(ret);
    };
    imports.wbg.__wbg_new_537b7341ce90bb31 = function(arg0) {
        const ret = new Uint8Array(getObject(arg0));
        return addHeapObject(ret);
    };
    imports.wbg.__wbg_buffer_5f1fc856188c4b44 = function(arg0) {
        const ret = getObject(arg0).buffer;
        return addHeapObject(ret);
    };
    imports.wbg.__wbg_set_6aa458a4ebdb65cb = function() { return handleError(function (arg0, arg1, arg2) {
        const ret = Reflect.set(getObject(arg0), getObject(arg1), getObject(arg2));
        return ret;
    }, arguments) };
    imports.wbg.__wbg_parse_3ac95b51fc312db8 = function() { return handleError(function (arg0, arg1) {
        const ret = JSON.parse(getStringFromWasm0(arg0, arg1));
        return addHeapObject(ret);
    }, arguments) };
    imports.wbg.__wbg_stringify_029a979dfb73aa17 = function() { return handleError(function (arg0) {
        const ret = JSON.stringify(getObject(arg0));
        return addHeapObject(ret);
    }, arguments) };
    imports.wbg.__wbindgen_debug_string = function(arg0, arg1) {
        const ret = debugString(getObject(arg1));
        const ptr0 = passStringToWasm0(ret, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
        const len0 = WASM_VECTOR_LEN;
        getInt32Memory0()[arg0 / 4 + 1] = len0;
        getInt32Memory0()[arg0 / 4 + 0] = ptr0;
    };
    imports.wbg.__wbindgen_throw = function(arg0, arg1) {
        throw new Error(getStringFromWasm0(arg0, arg1));
    };
    imports.wbg.__wbindgen_memory = function() {
        const ret = wasm.memory;
        return addHeapObject(ret);
    };
    imports.wbg.__wbindgen_closure_wrapper1786 = function(arg0, arg1, arg2) {
        const ret = makeMutClosure(arg0, arg1, 358, __wbg_adapter_26);
        return addHeapObject(ret);
    };
    imports.wbg.__wbindgen_closure_wrapper1787 = function(arg0, arg1, arg2) {
        const ret = makeClosure(arg0, arg1, 358, __wbg_adapter_29);
        return addHeapObject(ret);
    };
    imports.wbg.__wbindgen_closure_wrapper1788 = function(arg0, arg1, arg2) {
        const ret = makeMutClosure(arg0, arg1, 358, __wbg_adapter_32);
        return addHeapObject(ret);
    };
    imports.wbg.__wbindgen_closure_wrapper1789 = function(arg0, arg1, arg2) {
        const ret = makeMutClosure(arg0, arg1, 358, __wbg_adapter_32);
        return addHeapObject(ret);
    };
    imports.wbg.__wbindgen_closure_wrapper1790 = function(arg0, arg1, arg2) {
        const ret = makeMutClosure(arg0, arg1, 358, __wbg_adapter_32);
        return addHeapObject(ret);
    };
    imports.wbg.__wbindgen_closure_wrapper1791 = function(arg0, arg1, arg2) {
        const ret = makeMutClosure(arg0, arg1, 358, __wbg_adapter_32);
        return addHeapObject(ret);
    };
    imports.wbg.__wbindgen_closure_wrapper4088 = function(arg0, arg1, arg2) {
        const ret = makeMutClosure(arg0, arg1, 950, __wbg_adapter_41);
        return addHeapObject(ret);
    };
    imports.wbg.__wbindgen_closure_wrapper10111 = function(arg0, arg1, arg2) {
        const ret = makeMutClosure(arg0, arg1, 2161, __wbg_adapter_44);
        return addHeapObject(ret);
    };

    return imports;
}

function initMemory(imports, maybe_memory) {

}

function finalizeInit(instance, module) {
    wasm = instance.exports;
    init.__wbindgen_wasm_module = module;
    cachedFloat64Memory0 = null;
    cachedInt32Memory0 = null;
    cachedUint8Memory0 = null;

    wasm.__wbindgen_start();
    return wasm;
}

function initSync(module) {
    const imports = getImports();

    initMemory(imports);

    if (!(module instanceof WebAssembly.Module)) {
        module = new WebAssembly.Module(module);
    }

    const instance = new WebAssembly.Instance(module, imports);

    return finalizeInit(instance, module);
}

async function init(input) {
    if (typeof input === 'undefined') {
        input = new URL('index_bg.wasm', import.meta.url);
    }
    const imports = getImports();

    if (typeof input === 'string' || (typeof Request === 'function' && input instanceof Request) || (typeof URL === 'function' && input instanceof URL)) {
        input = fetch(input);
    }

    initMemory(imports);

    const { instance, module } = await load(await input, imports);

    return finalizeInit(instance, module);
}

export { initSync }
export default init;
