// Generated by purs version 0.14.5
"use strict";
var Actions = require("../Actions/index.js");
var Box = require("../Box/index.js");
var Client_WASM = require("../Client.WASM/index.js");
var Common = require("../Common/index.js");
var ComponentCostGroupsTree = require("../ComponentCostGroupsTree/index.js");
var ComponentGroupsTree = require("../ComponentGroupsTree/index.js");
var ComponentSummaries = require("../ComponentSummaries/index.js");
var ComponentUtils = require("../ComponentUtils/index.js");
var ComponentsViewUtils = require("../ComponentsViewUtils/index.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Apply = require("../Control.Apply/index.js");
var Control_Bind = require("../Control.Bind/index.js");
var CostEstimation_CopyComponents = require("../CostEstimation.CopyComponents/index.js");
var CostEstimation_Filters = require("../CostEstimation.Filters/index.js");
var CostEstimation_Layout = require("../CostEstimation.Layout/index.js");
var CostEstimation_SelectedComponentDetails = require("../CostEstimation.SelectedComponentDetails/index.js");
var CostEstimation_SummariesPanel = require("../CostEstimation.SummariesPanel/index.js");
var CostEstimation_SummariesSidebar = require("../CostEstimation.SummariesSidebar/index.js");
var CostEstimation_Types = require("../CostEstimation.Types/index.js");
var CostEstimation_ViewControls = require("../CostEstimation.ViewControls/index.js");
var CostEstimationLayout_Types = require("../CostEstimationLayout.Types/index.js");
var DOMUtils = require("../DOMUtils/index.js");
var Data_Array = require("../Data.Array/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Foldable = require("../Data.Foldable/index.js");
var Data_Function = require("../Data.Function/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_HeytingAlgebra = require("../Data.HeytingAlgebra/index.js");
var Data_JSDate = require("../Data.JSDate/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Monoid = require("../Data.Monoid/index.js");
var Data_Newtype = require("../Data.Newtype/index.js");
var Data_Ord = require("../Data.Ord/index.js");
var Data_Set = require("../Data.Set/index.js");
var Data_String_Common = require("../Data.String.Common/index.js");
var Data_Symbol = require("../Data.Symbol/index.js");
var Data_Unfoldable = require("../Data.Unfoldable/index.js");
var Data_Unit = require("../Data.Unit/index.js");
var Data_Variant_Internal = require("../Data.Variant.Internal/index.js");
var Edited = require("../Edited/index.js");
var Effect = require("../Effect/index.js");
var Effect_Unsafe = require("../Effect.Unsafe/index.js");
var EstimationComponentFilter = require("../EstimationComponentFilter/index.js");
var EstimationComponentsTable = require("../EstimationComponentsTable/index.js");
var FeatureHooks = require("../FeatureHooks/index.js");
var FetchHooks = require("../FetchHooks/index.js");
var Formatters = require("../Formatters/index.js");
var Kishimen = require("../Kishimen/index.js");
var ProjectClassificationTable = require("../ProjectClassificationTable/index.js");
var ProjectLocationsTree = require("../ProjectLocationsTree/index.js");
var React_Basic = require("../React.Basic/index.js");
var ReactHooksUtils = require("../ReactHooksUtils/index.js");
var ReferenceLibrarySidebar = require("../ReferenceLibrarySidebar/index.js");
var ReferenceLibrarySidepanel = require("../ReferenceLibrarySidepanel/index.js");
var StateHooks = require("../StateHooks/index.js");
var StringUtils = require("../StringUtils/index.js");
var SubprojectsTree = require("../SubprojectsTree/index.js");
var TacoConstants = require("../TacoConstants/index.js");
var TacoLoader = require("../TacoLoader/index.js");
var TacoTable_Types = require("../TacoTable.Types/index.js");
var TofuHooks = require("../TofuHooks/index.js");
var TofuJSON = require("../TofuJSON/index.js");
var Type_Proxy = require("../Type.Proxy/index.js");
var Types_Component = require("../Types.Component/index.js");
var Types_ComponentsView = require("../Types.ComponentsView/index.js");
var Types_CostEstimation = require("../Types.CostEstimation/index.js");
var Types_Estimation = require("../Types.Estimation/index.js");
var Types_FrontEndRoutes = require("../Types.FrontEndRoutes/index.js");
var Types_Newtypes = require("../Types.Newtypes/index.js");
var Types_Project = require("../Types.Project/index.js");
var UseEstimationUiState = require("../UseEstimationUiState/index.js");
var UseLicenseFeature = require("../UseLicenseFeature/index.js");
var UseLocalStorage = require("../UseLocalStorage/index.js");
var UseNullableRef = require("../UseNullableRef/index.js");
var UseSelectedComponentScrollPosition = require("../UseSelectedComponentScrollPosition/index.js");
var UseSocialExpensesInCosts = require("../UseSocialExpensesInCosts/index.js");
var UseTargetCostGroup = require("../UseTargetCostGroup/index.js");
var UseWorkAchievement = require("../UseWorkAchievement/index.js");
var Utils = require("../Utils/index.js");
var WindowUtils = require("../WindowUtils/index.js");
var selectedEstimationComponentsWithResources = function (components) {
    return Data_Array.filter(ComponentsViewUtils.selected)(components);
};
var rowHeight = TacoTable_Types.RowMedium.value;
var dispatchEstimationComponentAction = function (appDispatch) {
    return function (action) {
        return appDispatch(new Actions.EstimationComponentActionRequest(action));
    };
};
var component = TofuHooks.mkHookComponent("EditComponentsView")(function (props) {
    return function __do() {
        var appDispatch = StateHooks.useDispatch();
        var v = Client_WASM.useEstimationProjects();
        var v1 = Client_WASM.useReferenceSubprojects(props.projectId)();
        var v2 = Client_WASM.useElementSpecs(props.projectId)();
        var v3 = Client_WASM.useSubprojects(props.projectId)();
        var v4 = Client_WASM.useEstimationComponents(props.projectId)();
        var v5 = Client_WASM.useEstimationElements(props.projectId)();
        var v6 = Client_WASM.useEstimationLocations(props.projectId)();
        var v7 = UseSocialExpensesInCosts.useSocialExpensesInCosts(props.projectId)();
        var v8 = UseWorkAchievement.useWorkAchievement(props.projectId)();
        var v9 = UseLocalStorage.useLocalStorageJSON(TofuJSON.readBoolean)("view-controls-edit-add-memo-to-excel")(false)(false)();
        var v10 = UseLocalStorage.useLocalStorageJSON(TofuJSON.readBoolean)("view-controls-edit-show-cost-class-total-price")(false)(false)();
        var v11 = UseLocalStorage.useLocalStorageJSON(TofuJSON.readBoolean)("view-controls-edit-show-cost-class-unit-price")(false)(false)();
        var v12 = UseLocalStorage.useLocalStorageJSON(TofuJSON.readBoolean)("view-controls-edit-show-cost-class-hours-per-unit")(false)(false)();
        var showCostClassColumns = v10.value0 || (v11.value0 || v12.value0);
        var v13 = UseLocalStorage.useLocalStorageJSON(TofuJSON.tofuJSONSet(Types_Newtypes.ordCostClassCode)(Types_Newtypes.tofuJSONCostClassCode))("view-controls-edit-show-cost-class-columns-selection")(Data_Set.empty)(false)();
        var v14 = UseLocalStorage.useLocalStorageJSON(TofuJSON.readBoolean)("view-controls-edit-show-hours-columns")(false)(false)();
        var v15 = FetchHooks.useCostClassesWithHash(props.projectId)();
        var v16 = Client_WASM.useComponentWithResources(props.projectId)();
        var v17 = Client_WASM.useClassificationGroups(props.projectId)();
        var v18 = Client_WASM.useProjectClassificationClasses(props.projectId)();
        var v19 = Client_WASM.usePlanningCostGroups(props.projectId)();
        var v20 = Client_WASM.useProjectLocations(props.projectId)();
        var buildingDescriptionBySpecId = ComponentsViewUtils.mkBuildingDescriptionBySpecId("EditView")(v5.value0)(v2.value0)();
        var v21 = UseEstimationUiState.useEstimationUiState(props.projectId)();
        var loading = v4.value1 || (v6.value1 || (v17.value1 || (v3.value1 || (v.value1 || (v1.value1 || v16.value1)))));
        var v22 = UseTargetCostGroup.useTargetCostGroup(props.projectId)();
        var initialSelectedComponentx = (function () {
            if (props.initialSelectedComponent instanceof Data_Maybe.Nothing && v21.value0.selectedComponentIdx instanceof Data_Maybe.Just) {
                return Edited.Edited.create(new Data_Maybe.Just(v21.value0.selectedComponentIdx.value0));
            };
            return Edited.Unedited.value;
        })();
        var v23 = TofuHooks.useState({
            selectedComponentIdx: initialSelectedComponentx,
            checkedComponents: Data_Set.empty,
            shouldScrollToSelection: true,
            editSortingCriteria: TacoTable_Types.SortingCriteria.create(TacoTable_Types.Ascending.value)(new Data_Maybe.Just(CostEstimation_Types.Code.value))(EstimationComponentsTable.getSortingValue(v7.socialExpensePercentagesByCostClass)),
            referenceSortingCriteria: TacoTable_Types.SortingCriteria.create(TacoTable_Types.Ascending.value)(new Data_Maybe.Just(CostEstimation_Types.Code.value))(EstimationComponentsTable.getSortingValue(v7.socialExpensePercentagesByCostClass)),
            unsortedComponents: Common.emptyArray,
            components: Common.emptyArray,
            editActiveFilters: v21.value0.componentFilters,
            lastCheckedItem: Data_Maybe.Nothing.value,
            addComponentModalOpen: false,
            updateSelectedComponentIdxOnTick: false,
            selectedInputColumn: Data_Maybe.Nothing.value,
            groupBy: v21.value0.componentGroupProperty
        })();
        TofuHooks.useEffect([ v7.socialExpensePercentagesByCostClassWithHash.hash ])(Data_Functor.voidRight(Effect.functorEffect)(Data_Monoid.mempty(Effect.monoidEffect(Data_Monoid.monoidUnit)))(v23.value1(function (s) {
            return {
                selectedComponentIdx: s.selectedComponentIdx,
                checkedComponents: s.checkedComponents,
                shouldScrollToSelection: s.shouldScrollToSelection,
                editSortingCriteria: EstimationComponentsTable.updateSortingCriteriaSEP(v7.socialExpensePercentagesByCostClassWithHash.value)(s.editSortingCriteria),
                referenceSortingCriteria: EstimationComponentsTable.updateSortingCriteriaSEP(v7.socialExpensePercentagesByCostClassWithHash.value)(s.referenceSortingCriteria),
                unsortedComponents: s.unsortedComponents,
                components: s.components,
                editActiveFilters: s.editActiveFilters,
                lastCheckedItem: s.lastCheckedItem,
                addComponentModalOpen: s.addComponentModalOpen,
                updateSelectedComponentIdxOnTick: s.updateSelectedComponentIdxOnTick,
                selectedInputColumn: s.selectedInputColumn,
                groupBy: s.groupBy
            };
        })))();
        var toggleGroupBy = function (property) {
            return function __do() {
                v21.value1(UseEstimationUiState.toggleUpdateGrouping(Types_ComponentsView.eqECGroupByProperty)({
                    reflectSymbol: function () {
                        return "componentGroupProperty";
                    }
                })()(Type_Proxy["Proxy"].value)(property))();
                return v23.value1(function (s) {
                    return {
                        selectedComponentIdx: s.selectedComponentIdx,
                        checkedComponents: s.checkedComponents,
                        shouldScrollToSelection: s.shouldScrollToSelection,
                        editSortingCriteria: s.editSortingCriteria,
                        referenceSortingCriteria: s.referenceSortingCriteria,
                        unsortedComponents: s.unsortedComponents,
                        components: s.components,
                        editActiveFilters: s.editActiveFilters,
                        lastCheckedItem: s.lastCheckedItem,
                        addComponentModalOpen: s.addComponentModalOpen,
                        updateSelectedComponentIdxOnTick: s.updateSelectedComponentIdxOnTick,
                        selectedInputColumn: s.selectedInputColumn,
                        groupBy: (function () {
                            var $136 = Data_Eq.eq(Data_Maybe.eqMaybe(Types_ComponentsView.eqECGroupByProperty))(new Data_Maybe.Just(property))(s.groupBy);
                            if ($136) {
                                return Data_Maybe.Nothing.value;
                            };
                            return new Data_Maybe.Just(property);
                        })()
                    };
                })();
            };
        };
        TofuHooks.useEffect([ props.checkedComponents ])(function __do() {
            (function () {
                if (props.checkedComponents instanceof Data_Maybe.Just) {
                    return Data_Monoid.guard(Data_Monoid.monoidFn(Effect.monoidEffect(Data_Monoid.monoidUnit)))(Data_Eq.notEq(Data_Set.eqSet(Types_Component.eqComponentId))(v23.value0.checkedComponents)(props.checkedComponents.value0))(v23.value1)(function (state$prime) {
                        return {
                            selectedComponentIdx: state$prime.selectedComponentIdx,
                            checkedComponents: props.checkedComponents.value0,
                            shouldScrollToSelection: state$prime.shouldScrollToSelection,
                            editSortingCriteria: state$prime.editSortingCriteria,
                            referenceSortingCriteria: state$prime.referenceSortingCriteria,
                            unsortedComponents: state$prime.unsortedComponents,
                            components: state$prime.components,
                            editActiveFilters: state$prime.editActiveFilters,
                            lastCheckedItem: state$prime.lastCheckedItem,
                            addComponentModalOpen: state$prime.addComponentModalOpen,
                            updateSelectedComponentIdxOnTick: state$prime.updateSelectedComponentIdxOnTick,
                            selectedInputColumn: state$prime.selectedInputColumn,
                            groupBy: state$prime.groupBy
                        };
                    })();
                };
                if (props.checkedComponents instanceof Data_Maybe.Nothing) {
                    return Data_Unit.unit;
                };
                throw new Error("Failed pattern match at EditView (line 201, column 5 - line 204, column 27): " + [ props.checkedComponents.constructor.name ]);
            })();
            return Data_Monoid.mempty(Effect.monoidEffect(Data_Monoid.monoidUnit));
        })();
        TofuHooks.useEffect([ v23.value0.checkedComponents ])((function () {
            if (props.checkedComponents instanceof Data_Maybe.Just && props.setCheckedComponents instanceof Data_Maybe.Just) {
                return function __do() {
                    Data_Monoid.guard(Data_Monoid.monoidFn(Effect.monoidEffect(Data_Monoid.monoidUnit)))(Data_Eq.notEq(Data_Set.eqSet(Types_Component.eqComponentId))(v23.value0.checkedComponents)(props.checkedComponents.value0))(props.setCheckedComponents.value0)(function (v24) {
                        return v23.value0.checkedComponents;
                    })();
                    return props.setCheckedComponents.value0(function (v24) {
                        return Data_Set.empty;
                    });
                };
            };
            return Control_Applicative.pure(Effect.applicativeEffect)(Data_Monoid.mempty(Effect.monoidEffect(Data_Monoid.monoidUnit)));
        })())();
        var v24 = TofuHooks.useState(Data_Set.empty)();
        Client_WASM.useSetActiveComponentFilters(v23.value0.editActiveFilters)();
        var findComponentIdx = function (cId) {
            return Data_Array.elemIndex(Types_Component.eqComponentId)(cId)(Data_Functor.map(Data_Functor.functorArray)((function () {
                var $301 = Data_Newtype.un()(Types_CostEstimation.EstimationComponentWithResources);
                return function ($302) {
                    return (function (v25) {
                        return v25.component.id;
                    })($301($302));
                };
            })())(v23.value0.components));
        };
        var selectedComponentIdx = TofuHooks.useMemo([ ReactHooksUtils.utf(v23.value0.selectedComponentIdx), ReactHooksUtils.utf(props.initialSelectedComponent), ReactHooksUtils.utf(v23.value0.components) ])(function (v25) {
            if (v23.value0.selectedComponentIdx instanceof Edited.Unedited && (props.initialSelectedComponent instanceof Data_Maybe.Just && Data_Eq.notEq(Types_Component.eqComponentId)(props.initialSelectedComponent.value0)(0))) {
                return findComponentIdx(props.initialSelectedComponent.value0);
            };
            if (v23.value0.selectedComponentIdx instanceof Edited.Unedited) {
                return Data_Maybe.Nothing.value;
            };
            if (v23.value0.selectedComponentIdx instanceof Edited.Edited) {
                return v23.value0.selectedComponentIdx.value0;
            };
            throw new Error("Failed pattern match at EditView (line 236, column 11 - line 239, column 23): " + [ v23.value0.selectedComponentIdx.constructor.name, props.initialSelectedComponent.constructor.name ]);
        })();
        TofuHooks.useEffect([ selectedComponentIdx ])(function __do() {
            v21.value1(function (v25) {
                return {
                    project: v25.project,
                    elementFilters: v25.elementFilters,
                    elementGroupProperty: v25.elementGroupProperty,
                    selectedElementIdx: v25.selectedElementIdx,
                    referenceElementFilters: v25.referenceElementFilters,
                    selectedReferenceElementIdx: v25.selectedReferenceElementIdx,
                    componentFilters: v25.componentFilters,
                    componentGroupProperty: v25.componentGroupProperty,
                    selectedComponentIdx: selectedComponentIdx,
                    referenceComponentFilters: v25.referenceComponentFilters,
                    selectedReferenceComponentIdx: v25.selectedReferenceComponentIdx,
                    referenceResourceFilters: v25.referenceResourceFilters,
                    selectedResourceId: v25.selectedResourceId
                };
            })();
            return Data_Monoid.mempty(Effect.monoidEffect(Data_Monoid.monoidUnit));
        })();
        var selectedComponent = TofuHooks.useMemo([ ReactHooksUtils.utf(selectedComponentIdx), ReactHooksUtils.utf(v23.value0.components) ])(function (v25) {
            return Control_Bind.bind(Data_Maybe.bindMaybe)(Control_Bind.bindFlipped(Data_Maybe.bindMaybe)(Data_Array.index(v23.value0.components))(selectedComponentIdx))(function (result) {
                var $149 = Data_Newtype.unwrap()((Types_CostEstimation.unECWR(result)).component.id) === 0;
                if ($149) {
                    return Data_Maybe.Nothing.value;
                };
                return new Data_Maybe.Just(result);
            });
        })();
        var unselectComponent = function __do() {
            DOMUtils.blurActiveElement();
            return v23.value1(function (v25) {
                return {
                    selectedComponentIdx: new Edited.Edited(Data_Maybe.Nothing.value),
                    checkedComponents: v25.checkedComponents,
                    shouldScrollToSelection: v25.shouldScrollToSelection,
                    editSortingCriteria: v25.editSortingCriteria,
                    referenceSortingCriteria: v25.referenceSortingCriteria,
                    unsortedComponents: v25.unsortedComponents,
                    components: v25.components,
                    editActiveFilters: v25.editActiveFilters,
                    lastCheckedItem: v25.lastCheckedItem,
                    addComponentModalOpen: v25.addComponentModalOpen,
                    updateSelectedComponentIdxOnTick: v25.updateSelectedComponentIdxOnTick,
                    selectedInputColumn: Data_Maybe.Nothing.value,
                    groupBy: v25.groupBy
                };
            })();
        };
        TofuHooks.useEffect([ ReactHooksUtils.utf(selectedComponent) ])(function __do() {
            Data_Monoid.guard(Effect.monoidEffect(Data_Monoid.monoidUnit))(Edited.isEdited(v23.value0.selectedComponentIdx) && Data_Maybe.isNothing(props.dixonOptions))(appDispatch(Actions.NavigateRoute.create(new Types_FrontEndRoutes.EstimationFERoute({
                projectId: props.projectId,
                initialSelectedComponent: Data_Functor.map(Data_Maybe.functorMaybe)((function () {
                    var $303 = Data_Newtype.un()(Types_CostEstimation.EstimationComponentWithResources);
                    return function ($304) {
                        return (function (v25) {
                            return v25.component.id;
                        })($303($304));
                    };
                })())(selectedComponent)
            }))))();
            return Data_Monoid.mempty(Effect.monoidEffect(Data_Monoid.monoidUnit));
        })();
        var selectComponent = TofuHooks.useMemo([  ])(function (v25) {
            return function (shouldScrollToSelection) {
                return function (mComponent) {
                    if (mComponent instanceof Data_Maybe.Just && (Types_CostEstimation.unECWR(mComponent.value0)).component.componentType !== 3) {
                        return v23.value1(function (s) {
                            return {
                                selectedComponentIdx: (function () {
                                    var v27 = Data_Array.elemIndex(Types_CostEstimation.eqEstimationComponentWithResources)(mComponent.value0)(s.components);
                                    var v28 = Edited.flattenEdited(s.selectedComponentIdx);
                                    if (v28 instanceof Data_Maybe.Just && (v27 instanceof Data_Maybe.Just && v28.value0 === v27.value0)) {
                                        return new Edited.Edited(Data_Maybe.Nothing.value);
                                    };
                                    return new Edited.Edited(v27);
                                })(),
                                checkedComponents: s.checkedComponents,
                                shouldScrollToSelection: (function () {
                                    if (s.selectedComponentIdx instanceof Edited.Edited && s.selectedComponentIdx.value0 instanceof Data_Maybe.Nothing) {
                                        return true;
                                    };
                                    if (s.selectedComponentIdx instanceof Edited.Unedited) {
                                        return true;
                                    };
                                    return shouldScrollToSelection;
                                })(),
                                editSortingCriteria: s.editSortingCriteria,
                                referenceSortingCriteria: s.referenceSortingCriteria,
                                unsortedComponents: s.unsortedComponents,
                                components: s.components,
                                editActiveFilters: s.editActiveFilters,
                                lastCheckedItem: s.lastCheckedItem,
                                addComponentModalOpen: s.addComponentModalOpen,
                                updateSelectedComponentIdxOnTick: s.updateSelectedComponentIdxOnTick,
                                selectedInputColumn: s.selectedInputColumn,
                                groupBy: s.groupBy
                            };
                        });
                    };
                    return v23.value1(function (s) {
                        return {
                            selectedComponentIdx: new Edited.Edited(Data_Maybe.Nothing.value),
                            checkedComponents: s.checkedComponents,
                            shouldScrollToSelection: shouldScrollToSelection,
                            editSortingCriteria: s.editSortingCriteria,
                            referenceSortingCriteria: s.referenceSortingCriteria,
                            unsortedComponents: s.unsortedComponents,
                            components: s.components,
                            editActiveFilters: s.editActiveFilters,
                            lastCheckedItem: s.lastCheckedItem,
                            addComponentModalOpen: s.addComponentModalOpen,
                            updateSelectedComponentIdxOnTick: s.updateSelectedComponentIdxOnTick,
                            selectedInputColumn: s.selectedInputColumn,
                            groupBy: s.groupBy
                        };
                    });
                };
            };
        })();
        var setSorting = function (s) {
            return function (newProperty) {
                var getNewSortingCriteria = function (v25) {
                    return function (mySortingCriteria) {
                        var $159 = Data_Eq.eq(Data_Maybe.eqMaybe(CostEstimation_Types.eqECSortProperty))(mySortingCriteria.value1)(new Data_Maybe.Just(newProperty));
                        if ($159) {
                            return new TacoTable_Types.SortingCriteria(TacoTable_Types.reverse(mySortingCriteria.value0), mySortingCriteria.value1, mySortingCriteria.value2);
                        };
                        return new TacoTable_Types.SortingCriteria(mySortingCriteria.value0, new Data_Maybe.Just(newProperty), mySortingCriteria.value2);
                    };
                };
                return {
                    newReferenceSortingCriteria: getNewSortingCriteria(Types_CostEstimation.ReferenceMode.value)(s.referenceSortingCriteria),
                    newEditSortingCriteria: getNewSortingCriteria(Types_CostEstimation.EditMode.value)(s.editSortingCriteria)
                };
            };
        };
        var setSortingCriteria = TofuHooks.useMemo([ ReactHooksUtils.utf(loading) ])(function (v25) {
            return function (newProperty) {
                return v23.value1(function (s) {
                    var v26 = setSorting(s)(newProperty);
                    return {
                        selectedComponentIdx: s.selectedComponentIdx,
                        checkedComponents: s.checkedComponents,
                        shouldScrollToSelection: s.shouldScrollToSelection,
                        editSortingCriteria: v26.newEditSortingCriteria,
                        referenceSortingCriteria: v26.newReferenceSortingCriteria,
                        unsortedComponents: s.unsortedComponents,
                        components: s.components,
                        editActiveFilters: s.editActiveFilters,
                        lastCheckedItem: Data_Maybe.Nothing.value,
                        addComponentModalOpen: s.addComponentModalOpen,
                        updateSelectedComponentIdxOnTick: true,
                        selectedInputColumn: s.selectedInputColumn,
                        groupBy: s.groupBy
                    };
                });
            };
        })();
        var selectComponentRelative = TofuHooks.useMemo([  ])(function (v25) {
            return function (diff) {
                return v23.value1(function (s) {
                    var targetIdx = diff + Data_Maybe.fromMaybe(-diff | 0)(Edited.flattenEdited(s.selectedComponentIdx)) | 0;
                    return {
                        selectedComponentIdx: Edited.Edited.create(Data_Maybe.Just.create(Data_Ord.max(Data_Ord.ordInt)(0)(Data_Ord.min(Data_Ord.ordInt)(targetIdx)(Data_Array.length(s.components) - 1 | 0)))),
                        checkedComponents: s.checkedComponents,
                        shouldScrollToSelection: true,
                        editSortingCriteria: s.editSortingCriteria,
                        referenceSortingCriteria: s.referenceSortingCriteria,
                        unsortedComponents: s.unsortedComponents,
                        components: s.components,
                        editActiveFilters: s.editActiveFilters,
                        lastCheckedItem: s.lastCheckedItem,
                        addComponentModalOpen: s.addComponentModalOpen,
                        updateSelectedComponentIdxOnTick: s.updateSelectedComponentIdxOnTick,
                        selectedInputColumn: s.selectedInputColumn,
                        groupBy: s.groupBy
                    };
                });
            };
        })();
        var setUnsortedComponents = TofuHooks.useMemo([  ])(function (v25) {
            return function (unsorted) {
                return v23.value1(function (v26) {
                    return {
                        selectedComponentIdx: v26.selectedComponentIdx,
                        checkedComponents: v26.checkedComponents,
                        shouldScrollToSelection: v26.shouldScrollToSelection,
                        editSortingCriteria: v26.editSortingCriteria,
                        referenceSortingCriteria: v26.referenceSortingCriteria,
                        unsortedComponents: unsorted,
                        components: v26.components,
                        editActiveFilters: v26.editActiveFilters,
                        lastCheckedItem: v26.lastCheckedItem,
                        addComponentModalOpen: v26.addComponentModalOpen,
                        updateSelectedComponentIdxOnTick: v26.updateSelectedComponentIdxOnTick,
                        selectedInputColumn: v26.selectedInputColumn,
                        groupBy: v26.groupBy
                    };
                });
            };
        })();
        var setComponentsList = TofuHooks.useMemo([  ])(function (v25) {
            return function (list) {
                return v23.value1(function (s) {
                    if (s.updateSelectedComponentIdxOnTick) {
                        return {
                            selectedComponentIdx: Edited.Edited.create(ComponentsViewUtils.findNewIdxOfSelectedComponent(Edited.flattenEdited(s.selectedComponentIdx))(s.components)(list)),
                            checkedComponents: s.checkedComponents,
                            shouldScrollToSelection: s.shouldScrollToSelection,
                            editSortingCriteria: s.editSortingCriteria,
                            referenceSortingCriteria: s.referenceSortingCriteria,
                            unsortedComponents: s.unsortedComponents,
                            components: list,
                            editActiveFilters: s.editActiveFilters,
                            lastCheckedItem: s.lastCheckedItem,
                            addComponentModalOpen: s.addComponentModalOpen,
                            updateSelectedComponentIdxOnTick: false,
                            selectedInputColumn: s.selectedInputColumn,
                            groupBy: s.groupBy
                        };
                    };
                    return {
                        selectedComponentIdx: s.selectedComponentIdx,
                        checkedComponents: s.checkedComponents,
                        shouldScrollToSelection: s.shouldScrollToSelection,
                        editSortingCriteria: s.editSortingCriteria,
                        referenceSortingCriteria: s.referenceSortingCriteria,
                        unsortedComponents: s.unsortedComponents,
                        components: list,
                        editActiveFilters: s.editActiveFilters,
                        lastCheckedItem: s.lastCheckedItem,
                        addComponentModalOpen: s.addComponentModalOpen,
                        updateSelectedComponentIdxOnTick: s.updateSelectedComponentIdxOnTick,
                        selectedInputColumn: s.selectedInputColumn,
                        groupBy: s.groupBy
                    };
                });
            };
        })();
        var setActiveComponentFilters = function (filters) {
            return function __do() {
                v21.value1(function (v25) {
                    return {
                        project: v25.project,
                        elementFilters: v25.elementFilters,
                        elementGroupProperty: v25.elementGroupProperty,
                        selectedElementIdx: v25.selectedElementIdx,
                        referenceElementFilters: v25.referenceElementFilters,
                        selectedReferenceElementIdx: v25.selectedReferenceElementIdx,
                        componentFilters: filters,
                        componentGroupProperty: v25.componentGroupProperty,
                        selectedComponentIdx: v25.selectedComponentIdx,
                        referenceComponentFilters: v25.referenceComponentFilters,
                        selectedReferenceComponentIdx: v25.selectedReferenceComponentIdx,
                        referenceResourceFilters: v25.referenceResourceFilters,
                        selectedResourceId: v25.selectedResourceId
                    };
                })();
                return v23.value1(function (v25) {
                    return {
                        selectedComponentIdx: v25.selectedComponentIdx,
                        checkedComponents: v25.checkedComponents,
                        shouldScrollToSelection: v25.shouldScrollToSelection,
                        editSortingCriteria: v25.editSortingCriteria,
                        referenceSortingCriteria: v25.referenceSortingCriteria,
                        unsortedComponents: v25.unsortedComponents,
                        components: v25.components,
                        editActiveFilters: filters,
                        lastCheckedItem: v25.lastCheckedItem,
                        addComponentModalOpen: v25.addComponentModalOpen,
                        updateSelectedComponentIdxOnTick: v25.updateSelectedComponentIdxOnTick,
                        selectedInputColumn: v25.selectedInputColumn,
                        groupBy: v25.groupBy
                    };
                })();
            };
        };
        var toggleCheckbox$prime = TofuHooks.useMemo([  ])(function (v25) {
            return function (compId) {
                return function (s) {
                    return {
                        checkedComponents: Utils.toggleInSet(Types_Component.ordComponentId)(compId)(s.checkedComponents),
                        lastCheckedItem: new Data_Maybe.Just(compId),
                        addComponentModalOpen: s.addComponentModalOpen,
                        components: s.components,
                        editActiveFilters: s.editActiveFilters,
                        editSortingCriteria: s.editSortingCriteria,
                        groupBy: s.groupBy,
                        referenceSortingCriteria: s.referenceSortingCriteria,
                        selectedComponentIdx: s.selectedComponentIdx,
                        selectedInputColumn: s.selectedInputColumn,
                        shouldScrollToSelection: s.shouldScrollToSelection,
                        unsortedComponents: s.unsortedComponents,
                        updateSelectedComponentIdxOnTick: s.updateSelectedComponentIdxOnTick
                    };
                };
            };
        })();
        var toggleCheckbox = TofuHooks.useMemo([  ])(function (v25) {
            return function (compId) {
                return v23.value1(function (s) {
                    return toggleCheckbox$prime(compId)(s);
                });
            };
        })();
        var toggleAreaOfCheckboxes = TofuHooks.useMemo([  ])(function (v25) {
            return function (thisId) {
                return Control_Apply.applyFirst(Effect.applyEffect)(WindowUtils.selectionRemoveAllRanges)(v23.value1(function (s) {
                    if (s.lastCheckedItem instanceof Data_Maybe.Just) {
                        var notTopicComponent = Data_HeytingAlgebra.not(Data_HeytingAlgebra.heytingAlgebraFunction(Data_HeytingAlgebra.heytingAlgebraBoolean))((function () {
                            var $305 = Data_Eq.eq(Types_Component.eqComponentType)(Types_Component.TopicComponent.value);
                            return function ($306) {
                                return $305(Types_Component.intToComponentType((function (v26) {
                                    return v26.component.componentType;
                                })($306)));
                            };
                        })());
                        var components$prime = Data_Functor.map(Data_Functor.functorArray)(Data_Newtype.un()(Types_CostEstimation.EstimationComponentWithResources))(s.components);
                        var idToIndex = function (id) {
                            return Data_Array.findIndex(function (x) {
                                return Data_Eq.eq(Types_Component.eqComponentId)(id)(x.component.id);
                            })(components$prime);
                        };
                        var areaInIds = function (fstIdx) {
                            return function (sndIdx) {
                                return Data_Functor.map(Data_Functor.functorArray)(function (v26) {
                                    return v26.component.id;
                                })(Data_Array.filter(notTopicComponent)(Data_Array.catMaybes(Data_Functor.map(Data_Functor.functorArray)(Data_Array.index(components$prime))(Data_Array.range(fstIdx)(sndIdx)))));
                            };
                        };
                        var v26 = idToIndex(s.lastCheckedItem.value0);
                        var v27 = idToIndex(thisId);
                        if (v27 instanceof Data_Maybe.Just && v26 instanceof Data_Maybe.Just) {
                            return {
                                selectedComponentIdx: s.selectedComponentIdx,
                                checkedComponents: Data_Set.union(Types_Component.ordComponentId)(s.checkedComponents)(Data_Set.fromFoldable(Data_Foldable.foldableArray)(Types_Component.ordComponentId)(areaInIds(v27.value0)(v26.value0))),
                                shouldScrollToSelection: s.shouldScrollToSelection,
                                editSortingCriteria: s.editSortingCriteria,
                                referenceSortingCriteria: s.referenceSortingCriteria,
                                unsortedComponents: s.unsortedComponents,
                                components: s.components,
                                editActiveFilters: s.editActiveFilters,
                                lastCheckedItem: new Data_Maybe.Just(thisId),
                                addComponentModalOpen: s.addComponentModalOpen,
                                updateSelectedComponentIdxOnTick: s.updateSelectedComponentIdxOnTick,
                                selectedInputColumn: s.selectedInputColumn,
                                groupBy: s.groupBy
                            };
                        };
                        return s;
                    };
                    if (s.lastCheckedItem instanceof Data_Maybe.Nothing) {
                        return toggleCheckbox$prime(thisId)(s);
                    };
                    throw new Error("Failed pattern match at EditView (line 332, column 5 - line 342, column 42): " + [ s.lastCheckedItem.constructor.name ]);
                }));
            };
        })();
        var unselectAllComponents = TofuHooks.useMemo([  ])(function (v25) {
            return v23.value1(function (s) {
                return {
                    selectedComponentIdx: new Edited.Edited(Data_Maybe.Nothing.value),
                    checkedComponents: Data_Set.empty,
                    shouldScrollToSelection: s.shouldScrollToSelection,
                    editSortingCriteria: s.editSortingCriteria,
                    referenceSortingCriteria: s.referenceSortingCriteria,
                    unsortedComponents: s.unsortedComponents,
                    components: s.components,
                    editActiveFilters: s.editActiveFilters,
                    lastCheckedItem: s.lastCheckedItem,
                    addComponentModalOpen: s.addComponentModalOpen,
                    updateSelectedComponentIdxOnTick: s.updateSelectedComponentIdxOnTick,
                    selectedInputColumn: s.selectedInputColumn,
                    groupBy: s.groupBy
                };
            });
        })();
        var onShowAddComponentModal = TofuHooks.useMemo([  ])(function (v25) {
            return function (show) {
                return v23.value1(function (s) {
                    return {
                        selectedComponentIdx: s.selectedComponentIdx,
                        checkedComponents: s.checkedComponents,
                        shouldScrollToSelection: s.shouldScrollToSelection,
                        editSortingCriteria: s.editSortingCriteria,
                        referenceSortingCriteria: s.referenceSortingCriteria,
                        unsortedComponents: s.unsortedComponents,
                        components: s.components,
                        editActiveFilters: s.editActiveFilters,
                        lastCheckedItem: s.lastCheckedItem,
                        addComponentModalOpen: show,
                        updateSelectedComponentIdxOnTick: s.updateSelectedComponentIdxOnTick,
                        selectedInputColumn: s.selectedInputColumn,
                        groupBy: s.groupBy
                    };
                });
            };
        })();
        var componentIdxFromClassificationCode = TofuHooks.useMemo([  ])(function (v25) {
            return function (clCode) {
                return function (components1) {
                    return Data_Array.findIndex(function (v26) {
                        return StringUtils.startsWith(clCode)(Utils.fromNullable("")(v26.component.code));
                    })(components1);
                };
            };
        })();
        var selectWithAClassificationCode = TofuHooks.useMemo([  ])(function (v25) {
            return function (code) {
                return v23.value1(function (s) {
                    return {
                        selectedComponentIdx: Edited.Edited.create(componentIdxFromClassificationCode(code)(s.components)),
                        checkedComponents: s.checkedComponents,
                        shouldScrollToSelection: true,
                        editSortingCriteria: s.editSortingCriteria,
                        referenceSortingCriteria: s.referenceSortingCriteria,
                        unsortedComponents: s.unsortedComponents,
                        components: s.components,
                        editActiveFilters: s.editActiveFilters,
                        lastCheckedItem: s.lastCheckedItem,
                        addComponentModalOpen: s.addComponentModalOpen,
                        updateSelectedComponentIdxOnTick: s.updateSelectedComponentIdxOnTick,
                        selectedInputColumn: s.selectedInputColumn,
                        groupBy: s.groupBy
                    };
                });
            };
        })();
        Client_WASM.useSetSelectedComponents(Data_Set.toUnfoldable(Data_Unfoldable.unfoldableArray)(v23.value0.checkedComponents))();
        ComponentsViewUtils.updateComponentsList({
            viewMode: Types_CostEstimation.EditMode.value,
            loading: false,
            unsortedComponents: v23.value0.unsortedComponents,
            checkedComponents: v23.value0.checkedComponents,
            setUnsortedComponents: setUnsortedComponents,
            setComponentsList: setComponentsList,
            sortingCriteria: v23.value0.editSortingCriteria,
            activeComponentFilters: v23.value0.editActiveFilters,
            projectId: props.projectId,
            socialExpensePercentagesByCostClassWithHash: v7.socialExpensePercentagesByCostClassWithHash,
            costClassesWithHash: v15.value0,
            estimationProjects: v.value0,
            referenceSubprojects: v1.value0,
            groupBy: v23.value0.groupBy,
            classificationGroups: v17.value0,
            classificationClasses: v18.value0,
            planningCostGroups: v19.value0,
            projectLocations: v20.value0,
            unselectComponent: unselectComponent,
            selectedComponentIdx: selectedComponentIdx,
            selectedComponentId: Data_Functor.map(Data_Maybe.functorMaybe)(ComponentUtils.getECWRId)(selectedComponent)
        })();
        var notTopicComponent = function (v25) {
            return Data_Eq.notEq(Types_Component.eqComponentType)(Types_Component.intToComponentType(v25.component.componentType))(Types_Component.TopicComponent.value);
        };
        ComponentsViewUtils.handleCursorNavigation({
            selectComponentRelative: selectComponentRelative,
            selectComponent: selectComponent,
            isActive: true
        })();
        var tableRef = UseNullableRef.useNullableRef();
        UseSelectedComponentScrollPosition.useSelectedComponentScrollPosition(v23.value0.shouldScrollToSelection)(TacoTable_Types.rowHeightPixels(rowHeight))(selectedComponentIdx)(tableRef)();
        var v25 = TofuHooks.useState(Data_Maybe.Nothing.value)();
        TofuHooks.useEffect([ v23.value0.components ])((function () {
            var match = Control_Bind.bind(Data_Maybe.bindMaybe)(v25.value0)(function (componentId) {
                return Data_Array.find(function (c) {
                    return Data_Eq.eq(Types_Component.eqComponentId)(ComponentUtils.getECWRId(c))(componentId);
                })(v23.value0.components);
            });
            return function __do() {
                (function () {
                    var $176 = Data_Maybe.isJust(match);
                    if ($176) {
                        selectComponent(v23.value0.shouldScrollToSelection)(match)();
                        return v25.value1(function (v26) {
                            return Data_Maybe.Nothing.value;
                        })();
                    };
                    return Data_Unit.unit;
                })();
                return Data_Monoid.mempty(Effect.monoidEffect(Data_Monoid.monoidUnit));
            };
        })())();
        var selectedRows = ReactHooksUtils.memoizeFn2(Data_Array.filter)(ComponentsViewUtils.selected)(v23.value0.unsortedComponents)();
        var v26 = TofuHooks.useState("")();
        var v27 = TofuHooks.useState(0)();
        TofuHooks.useEffectTimeout([ ReactHooksUtils.utf(v26.value0) ])(1000)((function () {
            var results = Data_Array.filter(function (v28) {
                return StringUtils.startsWith(v26.value0)(Utils.fromNullable("")(v28.component.code));
            })(v23.value0.components);
            return function __do() {
                v27.value1(function (v28) {
                    return Data_Array.length(results);
                })();
                Data_Monoid.guard(Data_Monoid.monoidFn(Data_Monoid.monoidFn(Effect.monoidEffect(Data_Monoid.monoidUnit))))(v26.value0 !== "")(selectComponent)(true)(Data_Array.head(results))();
                return Data_Monoid.mempty(Effect.monoidEffect(Data_Monoid.monoidUnit));
            };
        })())();
        var v28 = TofuHooks.useState("")();
        TofuHooks.useEffectTimeout([ ReactHooksUtils.utf(v28.value0) ])(500)(function __do() {
            v23.value1(function (s) {
                var notSearch = function (x) {
                    if (x instanceof CostEstimation_Filters.FilterByComponentSearch) {
                        return false;
                    };
                    return true;
                };
                var removedSearch = Data_Array.filter(notSearch)(s.editActiveFilters);
                var addedSearch = (function () {
                    var $183 = Data_String_Common["null"](v28.value0);
                    if ($183) {
                        return removedSearch;
                    };
                    return Data_Array.snoc(removedSearch)(new CostEstimation_Filters.FilterByComponentSearch(v28.value0));
                })();
                return {
                    selectedComponentIdx: s.selectedComponentIdx,
                    checkedComponents: s.checkedComponents,
                    shouldScrollToSelection: s.shouldScrollToSelection,
                    editSortingCriteria: s.editSortingCriteria,
                    referenceSortingCriteria: s.referenceSortingCriteria,
                    unsortedComponents: s.unsortedComponents,
                    components: s.components,
                    editActiveFilters: addedSearch,
                    lastCheckedItem: s.lastCheckedItem,
                    addComponentModalOpen: s.addComponentModalOpen,
                    updateSelectedComponentIdxOnTick: s.updateSelectedComponentIdxOnTick,
                    selectedInputColumn: s.selectedInputColumn,
                    groupBy: s.groupBy
                };
            })();
            return Data_Monoid.mempty(Effect.monoidEffect(Effect.monoidEffect(Data_Monoid.monoidUnit)))();
        })();
        var subprojectsEnabled = UseLicenseFeature.useLicenseFeature(UseLicenseFeature.Subprojects.value)();
        var locationsEnabled = UseLicenseFeature.useLicenseFeature(UseLicenseFeature.Locations.value)();
        var componentGroupsEnabled = UseLicenseFeature.useLicenseFeature(UseLicenseFeature.ComponentGroups.value)();
        var exportResourcesDisabled = FeatureHooks.useFeatureEnabled("disable-xml-export-resources")();
        var toggleFilter = EstimationComponentFilter.mkToggleFilter(CostEstimation_Filters.genericEstimationComponentPropertyFilter)(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
            reflectSymbol: function () {
                return "FilterByComponentGroup";
            }
        })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
            reflectSymbol: function () {
                return "FilterByComponentWithoutGroup";
            }
        })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
            reflectSymbol: function () {
                return "FilterByComponentCostGroupCode";
            }
        })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
            reflectSymbol: function () {
                return "FilterByComponentCode";
            }
        })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
            reflectSymbol: function () {
                return "FilterByComponentDescription";
            }
        })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
            reflectSymbol: function () {
                return "FilterByComponentElement";
            }
        })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
            reflectSymbol: function () {
                return "FilterByComponentSubProjectCode";
            }
        })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
            reflectSymbol: function () {
                return "FilterByComponentProjectOfOrigin";
            }
        })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
            reflectSymbol: function () {
                return "FilterByComponentLocationCode";
            }
        })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
            reflectSymbol: function () {
                return "FilterByComponentSelected";
            }
        })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
            reflectSymbol: function () {
                return "FilterByComponentSearch";
            }
        })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
            reflectSymbol: function () {
                return "FilterByComponentHasFlag";
            }
        })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
            reflectSymbol: function () {
                return "FilterByComponentHasCheck";
            }
        })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
            reflectSymbol: function () {
                return "FilterByComponentHasMemo";
            }
        })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
            reflectSymbol: function () {
                return "FilterByComponentNoFlag";
            }
        })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
            reflectSymbol: function () {
                return "FilterByComponentNoCheck";
            }
        })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
            reflectSymbol: function () {
                return "FilterByComponentNoMemo";
            }
        })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
            reflectSymbol: function () {
                return "FilterByComponentWithoutCostGroup";
            }
        })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
            reflectSymbol: function () {
                return "FilterByComponentNoCodeMatch";
            }
        })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantConstructor({
            reflectSymbol: function () {
                return "FilterByComponentNonMatchingCostGroup";
            }
        })()(Kishimen.genericSumToVariantArgArguments))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
            reflectSymbol: function () {
                return "FilterByComponentNoCodeMatch";
            }
        }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
            reflectSymbol: function () {
                return "FilterByComponentNonMatchingCostGroup";
            }
        })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
            reflectSymbol: function () {
                return "FilterByComponentWithoutCostGroup";
            }
        }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
            reflectSymbol: function () {
                return "FilterByComponentNonMatchingCostGroup";
            }
        }))({
            reflectSymbol: function () {
                return "FilterByComponentNoCodeMatch";
            }
        })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
            reflectSymbol: function () {
                return "FilterByComponentNoMemo";
            }
        }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
            reflectSymbol: function () {
                return "FilterByComponentWithoutCostGroup";
            }
        }))({
            reflectSymbol: function () {
                return "FilterByComponentNonMatchingCostGroup";
            }
        }))({
            reflectSymbol: function () {
                return "FilterByComponentNoCodeMatch";
            }
        })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
            reflectSymbol: function () {
                return "FilterByComponentNoCheck";
            }
        }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
            reflectSymbol: function () {
                return "FilterByComponentWithoutCostGroup";
            }
        }))({
            reflectSymbol: function () {
                return "FilterByComponentNonMatchingCostGroup";
            }
        }))({
            reflectSymbol: function () {
                return "FilterByComponentNoMemo";
            }
        }))({
            reflectSymbol: function () {
                return "FilterByComponentNoCodeMatch";
            }
        })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
            reflectSymbol: function () {
                return "FilterByComponentNoFlag";
            }
        }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
            reflectSymbol: function () {
                return "FilterByComponentWithoutCostGroup";
            }
        }))({
            reflectSymbol: function () {
                return "FilterByComponentNonMatchingCostGroup";
            }
        }))({
            reflectSymbol: function () {
                return "FilterByComponentNoMemo";
            }
        }))({
            reflectSymbol: function () {
                return "FilterByComponentNoCodeMatch";
            }
        }))({
            reflectSymbol: function () {
                return "FilterByComponentNoCheck";
            }
        })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
            reflectSymbol: function () {
                return "FilterByComponentHasMemo";
            }
        }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
            reflectSymbol: function () {
                return "FilterByComponentWithoutCostGroup";
            }
        }))({
            reflectSymbol: function () {
                return "FilterByComponentNonMatchingCostGroup";
            }
        }))({
            reflectSymbol: function () {
                return "FilterByComponentNoMemo";
            }
        }))({
            reflectSymbol: function () {
                return "FilterByComponentNoFlag";
            }
        }))({
            reflectSymbol: function () {
                return "FilterByComponentNoCodeMatch";
            }
        }))({
            reflectSymbol: function () {
                return "FilterByComponentNoCheck";
            }
        })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
            reflectSymbol: function () {
                return "FilterByComponentHasCheck";
            }
        }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
            reflectSymbol: function () {
                return "FilterByComponentWithoutCostGroup";
            }
        }))({
            reflectSymbol: function () {
                return "FilterByComponentNonMatchingCostGroup";
            }
        }))({
            reflectSymbol: function () {
                return "FilterByComponentNoMemo";
            }
        }))({
            reflectSymbol: function () {
                return "FilterByComponentNoFlag";
            }
        }))({
            reflectSymbol: function () {
                return "FilterByComponentNoCodeMatch";
            }
        }))({
            reflectSymbol: function () {
                return "FilterByComponentNoCheck";
            }
        }))({
            reflectSymbol: function () {
                return "FilterByComponentHasMemo";
            }
        })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
            reflectSymbol: function () {
                return "FilterByComponentHasFlag";
            }
        }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
            reflectSymbol: function () {
                return "FilterByComponentWithoutCostGroup";
            }
        }))({
            reflectSymbol: function () {
                return "FilterByComponentNonMatchingCostGroup";
            }
        }))({
            reflectSymbol: function () {
                return "FilterByComponentNoMemo";
            }
        }))({
            reflectSymbol: function () {
                return "FilterByComponentNoFlag";
            }
        }))({
            reflectSymbol: function () {
                return "FilterByComponentNoCodeMatch";
            }
        }))({
            reflectSymbol: function () {
                return "FilterByComponentNoCheck";
            }
        }))({
            reflectSymbol: function () {
                return "FilterByComponentHasMemo";
            }
        }))({
            reflectSymbol: function () {
                return "FilterByComponentHasCheck";
            }
        })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
            reflectSymbol: function () {
                return "FilterByComponentSearch";
            }
        }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
            reflectSymbol: function () {
                return "FilterByComponentWithoutCostGroup";
            }
        }))({
            reflectSymbol: function () {
                return "FilterByComponentNonMatchingCostGroup";
            }
        }))({
            reflectSymbol: function () {
                return "FilterByComponentNoMemo";
            }
        }))({
            reflectSymbol: function () {
                return "FilterByComponentNoFlag";
            }
        }))({
            reflectSymbol: function () {
                return "FilterByComponentNoCodeMatch";
            }
        }))({
            reflectSymbol: function () {
                return "FilterByComponentNoCheck";
            }
        }))({
            reflectSymbol: function () {
                return "FilterByComponentHasMemo";
            }
        }))({
            reflectSymbol: function () {
                return "FilterByComponentHasFlag";
            }
        }))({
            reflectSymbol: function () {
                return "FilterByComponentHasCheck";
            }
        })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
            reflectSymbol: function () {
                return "FilterByComponentSelected";
            }
        }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
            reflectSymbol: function () {
                return "FilterByComponentWithoutCostGroup";
            }
        }))({
            reflectSymbol: function () {
                return "FilterByComponentSearch";
            }
        }))({
            reflectSymbol: function () {
                return "FilterByComponentNonMatchingCostGroup";
            }
        }))({
            reflectSymbol: function () {
                return "FilterByComponentNoMemo";
            }
        }))({
            reflectSymbol: function () {
                return "FilterByComponentNoFlag";
            }
        }))({
            reflectSymbol: function () {
                return "FilterByComponentNoCodeMatch";
            }
        }))({
            reflectSymbol: function () {
                return "FilterByComponentNoCheck";
            }
        }))({
            reflectSymbol: function () {
                return "FilterByComponentHasMemo";
            }
        }))({
            reflectSymbol: function () {
                return "FilterByComponentHasFlag";
            }
        }))({
            reflectSymbol: function () {
                return "FilterByComponentHasCheck";
            }
        })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
            reflectSymbol: function () {
                return "FilterByComponentLocationCode";
            }
        }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
            reflectSymbol: function () {
                return "FilterByComponentWithoutCostGroup";
            }
        }))({
            reflectSymbol: function () {
                return "FilterByComponentSelected";
            }
        }))({
            reflectSymbol: function () {
                return "FilterByComponentSearch";
            }
        }))({
            reflectSymbol: function () {
                return "FilterByComponentNonMatchingCostGroup";
            }
        }))({
            reflectSymbol: function () {
                return "FilterByComponentNoMemo";
            }
        }))({
            reflectSymbol: function () {
                return "FilterByComponentNoFlag";
            }
        }))({
            reflectSymbol: function () {
                return "FilterByComponentNoCodeMatch";
            }
        }))({
            reflectSymbol: function () {
                return "FilterByComponentNoCheck";
            }
        }))({
            reflectSymbol: function () {
                return "FilterByComponentHasMemo";
            }
        }))({
            reflectSymbol: function () {
                return "FilterByComponentHasFlag";
            }
        }))({
            reflectSymbol: function () {
                return "FilterByComponentHasCheck";
            }
        })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
            reflectSymbol: function () {
                return "FilterByComponentProjectOfOrigin";
            }
        }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
            reflectSymbol: function () {
                return "FilterByComponentWithoutCostGroup";
            }
        }))({
            reflectSymbol: function () {
                return "FilterByComponentSelected";
            }
        }))({
            reflectSymbol: function () {
                return "FilterByComponentSearch";
            }
        }))({
            reflectSymbol: function () {
                return "FilterByComponentNonMatchingCostGroup";
            }
        }))({
            reflectSymbol: function () {
                return "FilterByComponentNoMemo";
            }
        }))({
            reflectSymbol: function () {
                return "FilterByComponentNoFlag";
            }
        }))({
            reflectSymbol: function () {
                return "FilterByComponentNoCodeMatch";
            }
        }))({
            reflectSymbol: function () {
                return "FilterByComponentNoCheck";
            }
        }))({
            reflectSymbol: function () {
                return "FilterByComponentLocationCode";
            }
        }))({
            reflectSymbol: function () {
                return "FilterByComponentHasMemo";
            }
        }))({
            reflectSymbol: function () {
                return "FilterByComponentHasFlag";
            }
        }))({
            reflectSymbol: function () {
                return "FilterByComponentHasCheck";
            }
        })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
            reflectSymbol: function () {
                return "FilterByComponentSubProjectCode";
            }
        }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
            reflectSymbol: function () {
                return "FilterByComponentWithoutCostGroup";
            }
        }))({
            reflectSymbol: function () {
                return "FilterByComponentSelected";
            }
        }))({
            reflectSymbol: function () {
                return "FilterByComponentSearch";
            }
        }))({
            reflectSymbol: function () {
                return "FilterByComponentProjectOfOrigin";
            }
        }))({
            reflectSymbol: function () {
                return "FilterByComponentNonMatchingCostGroup";
            }
        }))({
            reflectSymbol: function () {
                return "FilterByComponentNoMemo";
            }
        }))({
            reflectSymbol: function () {
                return "FilterByComponentNoFlag";
            }
        }))({
            reflectSymbol: function () {
                return "FilterByComponentNoCodeMatch";
            }
        }))({
            reflectSymbol: function () {
                return "FilterByComponentNoCheck";
            }
        }))({
            reflectSymbol: function () {
                return "FilterByComponentLocationCode";
            }
        }))({
            reflectSymbol: function () {
                return "FilterByComponentHasMemo";
            }
        }))({
            reflectSymbol: function () {
                return "FilterByComponentHasFlag";
            }
        }))({
            reflectSymbol: function () {
                return "FilterByComponentHasCheck";
            }
        })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
            reflectSymbol: function () {
                return "FilterByComponentElement";
            }
        }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
            reflectSymbol: function () {
                return "FilterByComponentWithoutCostGroup";
            }
        }))({
            reflectSymbol: function () {
                return "FilterByComponentSubProjectCode";
            }
        }))({
            reflectSymbol: function () {
                return "FilterByComponentSelected";
            }
        }))({
            reflectSymbol: function () {
                return "FilterByComponentSearch";
            }
        }))({
            reflectSymbol: function () {
                return "FilterByComponentProjectOfOrigin";
            }
        }))({
            reflectSymbol: function () {
                return "FilterByComponentNonMatchingCostGroup";
            }
        }))({
            reflectSymbol: function () {
                return "FilterByComponentNoMemo";
            }
        }))({
            reflectSymbol: function () {
                return "FilterByComponentNoFlag";
            }
        }))({
            reflectSymbol: function () {
                return "FilterByComponentNoCodeMatch";
            }
        }))({
            reflectSymbol: function () {
                return "FilterByComponentNoCheck";
            }
        }))({
            reflectSymbol: function () {
                return "FilterByComponentLocationCode";
            }
        }))({
            reflectSymbol: function () {
                return "FilterByComponentHasMemo";
            }
        }))({
            reflectSymbol: function () {
                return "FilterByComponentHasFlag";
            }
        }))({
            reflectSymbol: function () {
                return "FilterByComponentHasCheck";
            }
        })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
            reflectSymbol: function () {
                return "FilterByComponentDescription";
            }
        }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
            reflectSymbol: function () {
                return "FilterByComponentWithoutCostGroup";
            }
        }))({
            reflectSymbol: function () {
                return "FilterByComponentSubProjectCode";
            }
        }))({
            reflectSymbol: function () {
                return "FilterByComponentSelected";
            }
        }))({
            reflectSymbol: function () {
                return "FilterByComponentSearch";
            }
        }))({
            reflectSymbol: function () {
                return "FilterByComponentProjectOfOrigin";
            }
        }))({
            reflectSymbol: function () {
                return "FilterByComponentNonMatchingCostGroup";
            }
        }))({
            reflectSymbol: function () {
                return "FilterByComponentNoMemo";
            }
        }))({
            reflectSymbol: function () {
                return "FilterByComponentNoFlag";
            }
        }))({
            reflectSymbol: function () {
                return "FilterByComponentNoCodeMatch";
            }
        }))({
            reflectSymbol: function () {
                return "FilterByComponentNoCheck";
            }
        }))({
            reflectSymbol: function () {
                return "FilterByComponentLocationCode";
            }
        }))({
            reflectSymbol: function () {
                return "FilterByComponentHasMemo";
            }
        }))({
            reflectSymbol: function () {
                return "FilterByComponentHasFlag";
            }
        }))({
            reflectSymbol: function () {
                return "FilterByComponentHasCheck";
            }
        }))({
            reflectSymbol: function () {
                return "FilterByComponentElement";
            }
        })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
            reflectSymbol: function () {
                return "FilterByComponentCode";
            }
        }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
            reflectSymbol: function () {
                return "FilterByComponentWithoutCostGroup";
            }
        }))({
            reflectSymbol: function () {
                return "FilterByComponentSubProjectCode";
            }
        }))({
            reflectSymbol: function () {
                return "FilterByComponentSelected";
            }
        }))({
            reflectSymbol: function () {
                return "FilterByComponentSearch";
            }
        }))({
            reflectSymbol: function () {
                return "FilterByComponentProjectOfOrigin";
            }
        }))({
            reflectSymbol: function () {
                return "FilterByComponentNonMatchingCostGroup";
            }
        }))({
            reflectSymbol: function () {
                return "FilterByComponentNoMemo";
            }
        }))({
            reflectSymbol: function () {
                return "FilterByComponentNoFlag";
            }
        }))({
            reflectSymbol: function () {
                return "FilterByComponentNoCodeMatch";
            }
        }))({
            reflectSymbol: function () {
                return "FilterByComponentNoCheck";
            }
        }))({
            reflectSymbol: function () {
                return "FilterByComponentLocationCode";
            }
        }))({
            reflectSymbol: function () {
                return "FilterByComponentHasMemo";
            }
        }))({
            reflectSymbol: function () {
                return "FilterByComponentHasFlag";
            }
        }))({
            reflectSymbol: function () {
                return "FilterByComponentHasCheck";
            }
        }))({
            reflectSymbol: function () {
                return "FilterByComponentElement";
            }
        }))({
            reflectSymbol: function () {
                return "FilterByComponentDescription";
            }
        })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
            reflectSymbol: function () {
                return "FilterByComponentCostGroupCode";
            }
        }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
            reflectSymbol: function () {
                return "FilterByComponentWithoutCostGroup";
            }
        }))({
            reflectSymbol: function () {
                return "FilterByComponentSubProjectCode";
            }
        }))({
            reflectSymbol: function () {
                return "FilterByComponentSelected";
            }
        }))({
            reflectSymbol: function () {
                return "FilterByComponentSearch";
            }
        }))({
            reflectSymbol: function () {
                return "FilterByComponentProjectOfOrigin";
            }
        }))({
            reflectSymbol: function () {
                return "FilterByComponentNonMatchingCostGroup";
            }
        }))({
            reflectSymbol: function () {
                return "FilterByComponentNoMemo";
            }
        }))({
            reflectSymbol: function () {
                return "FilterByComponentNoFlag";
            }
        }))({
            reflectSymbol: function () {
                return "FilterByComponentNoCodeMatch";
            }
        }))({
            reflectSymbol: function () {
                return "FilterByComponentNoCheck";
            }
        }))({
            reflectSymbol: function () {
                return "FilterByComponentLocationCode";
            }
        }))({
            reflectSymbol: function () {
                return "FilterByComponentHasMemo";
            }
        }))({
            reflectSymbol: function () {
                return "FilterByComponentHasFlag";
            }
        }))({
            reflectSymbol: function () {
                return "FilterByComponentHasCheck";
            }
        }))({
            reflectSymbol: function () {
                return "FilterByComponentElement";
            }
        }))({
            reflectSymbol: function () {
                return "FilterByComponentDescription";
            }
        }))({
            reflectSymbol: function () {
                return "FilterByComponentCode";
            }
        })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
            reflectSymbol: function () {
                return "FilterByComponentWithoutGroup";
            }
        }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
            reflectSymbol: function () {
                return "FilterByComponentWithoutCostGroup";
            }
        }))({
            reflectSymbol: function () {
                return "FilterByComponentSubProjectCode";
            }
        }))({
            reflectSymbol: function () {
                return "FilterByComponentSelected";
            }
        }))({
            reflectSymbol: function () {
                return "FilterByComponentSearch";
            }
        }))({
            reflectSymbol: function () {
                return "FilterByComponentProjectOfOrigin";
            }
        }))({
            reflectSymbol: function () {
                return "FilterByComponentNonMatchingCostGroup";
            }
        }))({
            reflectSymbol: function () {
                return "FilterByComponentNoMemo";
            }
        }))({
            reflectSymbol: function () {
                return "FilterByComponentNoFlag";
            }
        }))({
            reflectSymbol: function () {
                return "FilterByComponentNoCodeMatch";
            }
        }))({
            reflectSymbol: function () {
                return "FilterByComponentNoCheck";
            }
        }))({
            reflectSymbol: function () {
                return "FilterByComponentLocationCode";
            }
        }))({
            reflectSymbol: function () {
                return "FilterByComponentHasMemo";
            }
        }))({
            reflectSymbol: function () {
                return "FilterByComponentHasFlag";
            }
        }))({
            reflectSymbol: function () {
                return "FilterByComponentHasCheck";
            }
        }))({
            reflectSymbol: function () {
                return "FilterByComponentElement";
            }
        }))({
            reflectSymbol: function () {
                return "FilterByComponentDescription";
            }
        }))({
            reflectSymbol: function () {
                return "FilterByComponentCostGroupCode";
            }
        }))({
            reflectSymbol: function () {
                return "FilterByComponentCode";
            }
        })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
            reflectSymbol: function () {
                return "FilterByComponentGroup";
            }
        }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
            reflectSymbol: function () {
                return "FilterByComponentWithoutGroup";
            }
        }))({
            reflectSymbol: function () {
                return "FilterByComponentWithoutCostGroup";
            }
        }))({
            reflectSymbol: function () {
                return "FilterByComponentSubProjectCode";
            }
        }))({
            reflectSymbol: function () {
                return "FilterByComponentSelected";
            }
        }))({
            reflectSymbol: function () {
                return "FilterByComponentSearch";
            }
        }))({
            reflectSymbol: function () {
                return "FilterByComponentProjectOfOrigin";
            }
        }))({
            reflectSymbol: function () {
                return "FilterByComponentNonMatchingCostGroup";
            }
        }))({
            reflectSymbol: function () {
                return "FilterByComponentNoMemo";
            }
        }))({
            reflectSymbol: function () {
                return "FilterByComponentNoFlag";
            }
        }))({
            reflectSymbol: function () {
                return "FilterByComponentNoCodeMatch";
            }
        }))({
            reflectSymbol: function () {
                return "FilterByComponentNoCheck";
            }
        }))({
            reflectSymbol: function () {
                return "FilterByComponentLocationCode";
            }
        }))({
            reflectSymbol: function () {
                return "FilterByComponentHasMemo";
            }
        }))({
            reflectSymbol: function () {
                return "FilterByComponentHasFlag";
            }
        }))({
            reflectSymbol: function () {
                return "FilterByComponentHasCheck";
            }
        }))({
            reflectSymbol: function () {
                return "FilterByComponentElement";
            }
        }))({
            reflectSymbol: function () {
                return "FilterByComponentDescription";
            }
        }))({
            reflectSymbol: function () {
                return "FilterByComponentCostGroupCode";
            }
        }))({
            reflectSymbol: function () {
                return "FilterByComponentCode";
            }
        })))(v23.value0.editActiveFilters)(setActiveComponentFilters);
        var mkFilterStrings = function (filters) {
            return Data_Function.flip(Data_Array.mapMaybe)(filters)(function (filter) {
                var listing = function (xs) {
                    return Data_Array.intercalate(Data_Monoid.monoidString)(", ")(xs);
                };
                var getProjectCodesOfIds = function (xs) {
                    return Data_Function.flip(Data_Array.mapMaybe)(xs)(function (id) {
                        return Control_Bind.bind(Data_Maybe.bindMaybe)(Data_Function.flip(Data_Array.find)(v.value0)(function (p) {
                            return Data_Eq.eq(Types_Project.eqProjectId)(p.id)(id);
                        }))(function (found) {
                            return Control_Applicative.pure(Data_Maybe.applicativeMaybe)(found.name + (" " + (Data_Newtype.unwrap()(found.code) + (" " + found.version))));
                        });
                    });
                };
                if (filter instanceof CostEstimation_Filters.FilterByComponentGroup) {
                    return Data_Maybe.Just.create("Ryhm\xe4: " + listing(filter.value0.values));
                };
                if (filter instanceof CostEstimation_Filters.FilterByComponentCostGroupCode) {
                    return Data_Maybe.Just.create("Littera: " + listing(filter.value0.values));
                };
                if (filter instanceof CostEstimation_Filters.FilterByComponentCode) {
                    return Data_Maybe.Just.create("Koodi: " + listing(filter.value0.values));
                };
                if (filter instanceof CostEstimation_Filters.FilterByComponentDescription) {
                    return Data_Maybe.Just.create("Selite: " + listing(filter.value0.values));
                };
                if (filter instanceof CostEstimation_Filters.FilterByComponentSubProjectCode) {
                    return Data_Maybe.Just.create("Alakohde: " + listing(filter.value0.values));
                };
                if (filter instanceof CostEstimation_Filters.FilterByComponentProjectOfOrigin) {
                    return Data_Maybe.Just.create("Projekti: " + Data_Array.intercalate(Data_Monoid.monoidString)(", ")(getProjectCodesOfIds(filter.value0)));
                };
                if (filter instanceof CostEstimation_Filters.FilterByComponentLocationCode) {
                    return Data_Maybe.Just.create("Sijainti: " + listing(filter.value0.values));
                };
                if (filter instanceof CostEstimation_Filters.FilterByComponentSearch) {
                    return Data_Maybe.Just.create("Hae: " + filter.value0);
                };
                if (filter instanceof CostEstimation_Filters.FilterByComponentSelected) {
                    return Data_Maybe.Nothing.value;
                };
                if (filter instanceof CostEstimation_Filters.FilterByComponentElement) {
                    return Data_Maybe.Nothing.value;
                };
                if (filter instanceof CostEstimation_Filters.FilterByComponentHasMemo) {
                    return Data_Maybe.Just.create("On muistio");
                };
                if (filter instanceof CostEstimation_Filters.FilterByComponentNoMemo) {
                    return Data_Maybe.Just.create("Ei muistiota");
                };
                if (filter instanceof CostEstimation_Filters.FilterByComponentHasFlag) {
                    return Data_Maybe.Just.create("On lippu");
                };
                if (filter instanceof CostEstimation_Filters.FilterByComponentNoFlag) {
                    return Data_Maybe.Just.create("Ei lippua");
                };
                if (filter instanceof CostEstimation_Filters.FilterByComponentHasCheck) {
                    return Data_Maybe.Just.create("On merkki");
                };
                if (filter instanceof CostEstimation_Filters.FilterByComponentNoCheck) {
                    return Data_Maybe.Just.create("Ei merkki\xe4");
                };
                if (filter instanceof CostEstimation_Filters.FilterByComponentWithoutCostGroup) {
                    return Data_Maybe.Just.create("Litteroimattomat");
                };
                if (filter instanceof CostEstimation_Filters.FilterByComponentNoCodeMatch) {
                    return Data_Maybe.Just.create("Muu koodi");
                };
                if (filter instanceof CostEstimation_Filters.FilterByComponentWithoutGroup) {
                    return Data_Maybe.Just.create("Ryhmittelem\xe4tt\xf6m\xe4t");
                };
                if (filter instanceof CostEstimation_Filters.FilterByComponentNonMatchingCostGroup) {
                    return Data_Maybe.Just.create("Litterat, joita ei ole litteranimikkeist\xf6ss\xe4");
                };
                throw new Error("Failed pattern match at EditView (line 452, column 7 - line 472, column 105): " + [ filter.constructor.name ]);
            });
        };
        var componentControls = Box.box("controlsWrapper")([ new Box.FlexGrow(1), new Box.Padding(TacoConstants.S.value) ])([ CostEstimation_ViewControls.controls({
            projectId: props.projectId,
            project: props.project,
            viewMode: Types_CostEstimation.EditMode.value,
            programType: props.project.programType,
            activeComponentFilters: v23.value0.editActiveFilters,
            components: v23.value0.components,
            showSocialExpensesInCosts: v7.showSocialExpensesInCosts,
            showSocialExpensesToggleControl: true,
            addMemoToExcel: v9.value0,
            onAddMemoToExcelToggle: v9.value1,
            onSocialExpensesToggle: v7.onSocialExpensesToggle,
            showWorkAchievement: v8.showWorkAchievement,
            showWorkAchievementToggleControl: true,
            onWorkAchievementToggle: v8.onWorkAchievementToggle,
            showCostClassColumnsControl: true,
            showCostClassColumns: showCostClassColumns,
            showCostClassTotalPrice: v10.value0,
            showCostClassUnitPrice: v11.value0,
            showCostClassHoursPerUnit: v12.value0,
            setShowCostClassTotalPrice: v10.value1,
            setShowCostClassUnitPrice: v11.value1,
            setShowCostClassHoursPerUnit: v12.value1,
            costClasses: v15.value0.value,
            seletedShowCostClassCodes: v13.value0,
            setSeletedShowCostClassCodes: v13.value1,
            showHoursColumnsControl: true,
            showHoursColumns: v14.value0,
            setShowHoursColumns: v14.value1,
            showTargetCostGroup: v22.showTargetCostGroup,
            showTargetCostGroupControl: true,
            onTargetCostGroupToggle: v22.onTargetCostGroupToggle,
            setActiveComponentFilters: setActiveComponentFilters,
            searchValue: v28.value0,
            setSearchValue: v28.value1,
            exactSearchValue: v26.value0,
            exactSearchResultCount: v27.value0,
            setExactSearchValue: v26.value1,
            referenceModeFollowOptions: Data_Maybe.Nothing.value,
            mkExport: new Data_Maybe.Just(function (v29) {
                return {
                    project: props.project,
                    elementsData: Data_Maybe.Nothing.value,
                    elementMeasures: Data_Maybe.Nothing.value,
                    componentsWithResources: new Data_Maybe.Just(v23.value0.components),
                    socialExpensePercentagesByCostClass: v7.socialExpensePercentagesByCostClass,
                    showSocialExpensesInCosts: v7.showSocialExpensesInCosts,
                    showCostClassColumns: showCostClassColumns,
                    showHoursColumns: v14.value0,
                    showWorkAchievementColumns: v8.showWorkAchievement,
                    costClasses: v15.value0.value,
                    addMemoToExcel: v9.value0,
                    showTargetCostGroup: v22.showTargetCostGroup,
                    showSubprojects: subprojectsEnabled,
                    showGroups: componentGroupsEnabled
                };
            }),
            mkQuantityExport: new Data_Maybe.Just(function (v29) {
                return {
                    project: props.project,
                    componentsWithResources: v23.value0.components,
                    dateString: Effect_Unsafe.unsafePerformEffect(function __do() {
                        var jsdate = Data_JSDate.now();
                        return Formatters.formatDateValue(jsdate);
                    }),
                    filterStrings: mkFilterStrings(v23.value0.editActiveFilters),
                    addMemoToExcel: v9.value0
                };
            }),
            mkQuantityByLocationExport: new Data_Maybe.Just(function (v29) {
                return {
                    project: props.project,
                    componentsWithResources: v23.value0.unsortedComponents,
                    projectLocations: v20.value0,
                    dateString: Effect_Unsafe.unsafePerformEffect(function __do() {
                        var jsdate = Data_JSDate.now();
                        return Formatters.formatDateValue(jsdate);
                    })
                };
            }),
            mkQuantityByCostClassExport: new Data_Maybe.Just(function (v29) {
                return {
                    project: props.project,
                    componentsWithResources: v23.value0.unsortedComponents,
                    costClasses: v15.value0.value,
                    dateString: Effect_Unsafe.unsafePerformEffect(function __do() {
                        var jsdate = Data_JSDate.now();
                        return Formatters.formatDateValue(jsdate);
                    }),
                    showSubprojects: subprojectsEnabled
                };
            }),
            mkComponentsExport: new Data_Maybe.Just(function (v29) {
                return {
                    project: props.project,
                    componentsWithResources: v23.value0.components,
                    dateString: Effect_Unsafe.unsafePerformEffect(function __do() {
                        var jsdate = Data_JSDate.now();
                        return Formatters.formatDateValue(jsdate);
                    }),
                    filterStrings: mkFilterStrings(v23.value0.editActiveFilters),
                    socialExpensePercentagesByCostClass: v7.socialExpensePercentagesByCostClass,
                    showCostClassColumns: showCostClassColumns,
                    showHoursColumns: v14.value0,
                    showCostClassHoursPerUnitColumns: v12.value0,
                    showCostClassUnitPriceColumns: v11.value0,
                    showCostClassTotalPriceColumns: v10.value0,
                    showSubprojects: subprojectsEnabled,
                    showGroups: componentGroupsEnabled,
                    costClasses: v15.value0.value,
                    showSocialExpensesInCosts: v7.showSocialExpensesInCosts,
                    groupBy: v23.value0.groupBy,
                    addMemoToExcel: v9.value0,
                    showTargetCostGroup: v22.showTargetCostGroup
                };
            }),
            mkExportPlanningCostGroupsExportParams: new Data_Maybe.Just(function (v29) {
                return {
                    project: props.project,
                    costClasses: v15.value0.value,
                    componentsWithResources: v23.value0.components,
                    planningCostGroups: v19.value0,
                    socialExpensePercentagesByCostClass: v7.socialExpensePercentagesByCostClass,
                    includeResources: !exportResourcesDisabled
                };
            }),
            groupBy: v23.value0.groupBy,
            toggleGroupBy: toggleGroupBy,
            showGroupByControls: true,
            setSortingCriteria: setSortingCriteria
        }) ]);
        var toggleCodeFilter = TofuHooks.useMemo([ v23.value0.editActiveFilters ])(function (v29) {
            return function (classificationCode) {
                return toggleFilter({
                    reflectSymbol: function () {
                        return "FilterByComponentCode";
                    }
                })()(classificationCode)(Data_Symbol.SProxy.value);
            };
        })();
        var toggleNoCodeMatchFilter = TofuHooks.useMemo([ v23.value0.editActiveFilters ])(function (v29) {
            return function (v30) {
                return toggleFilter({
                    reflectSymbol: function () {
                        return "FilterByComponentNoCodeMatch";
                    }
                })()("no-code-match")(Data_Symbol.SProxy.value);
            };
        })();
        var toggleSubprojectFilter = TofuHooks.useMemo([ v23.value0.editActiveFilters ])(function (v29) {
            return function (subprojectCode) {
                return toggleFilter({
                    reflectSymbol: function () {
                        return "FilterByComponentSubProjectCode";
                    }
                })()("\"" + (subprojectCode + "\""))(Data_Symbol.SProxy.value);
            };
        })();
        var toggleLocationFilter = TofuHooks.useMemo([ v23.value0.editActiveFilters ])(function (v29) {
            return function (locationCode) {
                return toggleFilter({
                    reflectSymbol: function () {
                        return "FilterByComponentLocationCode";
                    }
                })()(locationCode)(Data_Symbol.SProxy.value);
            };
        })();
        var toggleGroupFilter = TofuHooks.useMemo([ v23.value0.editActiveFilters ])(function (v29) {
            return function (v30) {
                return toggleFilter({
                    reflectSymbol: function () {
                        return "FilterByComponentGroup";
                    }
                })()("\"" + (v30 + "\""))(Data_Symbol.SProxy.value);
            };
        })();
        var toggleWithoutGroupFilter = TofuHooks.useMemo([ v23.value0.editActiveFilters ])(function (v29) {
            return function (v30) {
                return toggleFilter({
                    reflectSymbol: function () {
                        return "FilterByComponentWithoutGroup";
                    }
                })()("no-group")(Data_Symbol.SProxy.value);
            };
        })();
        var toggleCostGroupFilter = TofuHooks.useMemo([ v23.value0.editActiveFilters ])(function (v29) {
            return function (costGroupCode) {
                return toggleFilter({
                    reflectSymbol: function () {
                        return "FilterByComponentCostGroupCode";
                    }
                })()("\"" + (costGroupCode + "\""))(Data_Symbol.SProxy.value);
            };
        })();
        var toggleWithoutCostGroupFilter = TofuHooks.useMemo([ v23.value0.editActiveFilters ])(function (v29) {
            return function (v30) {
                return toggleFilter({
                    reflectSymbol: function () {
                        return "FilterByComponentWithoutCostGroup";
                    }
                })()("no-cost-group")(Data_Symbol.SProxy.value);
            };
        })();
        var panelProps$prime = CostEstimation_Layout.useCostEstimationLayout({
            leftPanelInitMode: CostEstimationLayout_Types.MinimizedMode.value,
            rightPanelInitMode: CostEstimationLayout_Types.MinimizedMode.value,
            detailsPanelInitMode: (function () {
                var $206 = Data_Maybe.fromMaybe(false)(Data_Functor.map(Data_Maybe.functorMaybe)(function (v29) {
                    return v29.hideResources;
                })(props.dixonOptions));
                if ($206) {
                    return CostEstimationLayout_Types.MinimizedMode.value;
                };
                return CostEstimationLayout_Types.SmallMode.value;
            })(),
            localStorageKey: "ComponentsEditView"
        })();
        var panelProps = (function () {
            if (props.dixonOptions instanceof Data_Maybe.Just) {
                return props.dixonOptions.value0.panelProps;
            };
            if (props.dixonOptions instanceof Data_Maybe.Nothing) {
                return panelProps$prime;
            };
            throw new Error("Failed pattern match at EditView (line 619, column 18 - line 621, column 29): " + [ props.dixonOptions.constructor.name ]);
        })();
        var onToggleSelectAll = v23.value1(function (s) {
            var newCheckedComponents = (function () {
                var $209 = Data_Set.isEmpty(s.checkedComponents);
                if ($209) {
                    return Data_Set.fromFoldable(Data_Foldable.foldableArray)(Types_Component.ordComponentId)(Data_Functor.map(Data_Functor.functorArray)(ComponentsViewUtils.ecwrComponentId)(v23.value0.components));
                };
                return Data_Set.empty;
            })();
            return {
                selectedComponentIdx: s.selectedComponentIdx,
                checkedComponents: newCheckedComponents,
                shouldScrollToSelection: s.shouldScrollToSelection,
                editSortingCriteria: s.editSortingCriteria,
                referenceSortingCriteria: s.referenceSortingCriteria,
                unsortedComponents: s.unsortedComponents,
                components: s.components,
                editActiveFilters: s.editActiveFilters,
                lastCheckedItem: s.lastCheckedItem,
                addComponentModalOpen: s.addComponentModalOpen,
                updateSelectedComponentIdxOnTick: s.updateSelectedComponentIdxOnTick,
                selectedInputColumn: s.selectedInputColumn,
                groupBy: s.groupBy
            };
        });
        var isSelectionEmpty = Data_Array["null"](selectedRows);
        var initialScrollRow = (function () {
            if (props.initialSelectedComponent instanceof Data_Maybe.Nothing) {
                return v21.value0.selectedComponentIdx;
            };
            if (props.initialSelectedComponent instanceof Data_Maybe.Just) {
                var cs = Data_Functor.map(Data_Functor.functorArray)(Data_Newtype.unwrap())(v23.value0.components);
                var idx = Data_Array.findIndex(function (x) {
                    return Data_Eq.eq(Types_Component.eqComponentId)(props.initialSelectedComponent.value0)(x.component.id);
                })(cs);
                return idx;
            };
            throw new Error("Failed pattern match at EditView (line 675, column 24 - line 681, column 12): " + [ props.initialSelectedComponent.constructor.name ]);
        })();
        var copySelectedComponentsToOtherProject = function (copyOperationType) {
            var v29 = Data_Array.head(v3.value0);
            if (v29 instanceof Data_Maybe.Just) {
                return appDispatch(CostEstimation_CopyComponents.mkCopyComponentsAction({
                    componentIds: CostEstimation_CopyComponents.selectedECWRComponentIds(v23.value0.components),
                    copyOperationType: copyOperationType,
                    targetProjectId: props.projectId,
                    targetSubprojectId: v29.value0.id,
                    resultHandler: function (v30) {
                        return Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit);
                    }
                }));
            };
            if (v29 instanceof Data_Maybe.Nothing) {
                return Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit);
            };
            throw new Error("Failed pattern match at EditView (line 663, column 7 - line 673, column 29): " + [ v29.constructor.name ]);
        };
        var componentsTable = EstimationComponentsTable.table({
            projectId: props.projectId,
            viewMode: Types_CostEstimation.EditMode.value,
            programType: props.project.programType,
            isReportingProject: props.project.isReportingProject,
            isPanel: false,
            selectedEditViewElement: Data_Maybe.Nothing.value,
            selectedComponent: selectedComponent,
            selectedComponentIdx: selectedComponentIdx,
            sortedComponents: v23.value0.components,
            sortingCriteria: v23.value0.editSortingCriteria,
            rowHeight: rowHeight,
            socialExpensePercentagesByCostClass: v7.socialExpensePercentagesByCostClass,
            costClasses: v15.value0.value,
            showCostClassColumns: showCostClassColumns,
            showCostClassTotalPrice: v10.value0,
            showCostClassUnitPrice: v11.value0,
            showCostClassHoursPerUnit: v12.value0,
            seletedShowCostClassCodes: v13.value0,
            showHoursColumns: v14.value0,
            showSocialExpensesInCosts: v7.showSocialExpensesInCosts,
            showTargetCostGroup: v22.showTargetCostGroup,
            containerRef: tableRef,
            setSortingCriteria: setSortingCriteria,
            selectComponent: selectComponent,
            toggleAreaOfCheckboxes: toggleAreaOfCheckboxes,
            toggleCheckbox: toggleCheckbox,
            shortenReferenceProjectDetails: false,
            setNewComponentId: v25.value1,
            onShowAddComponentModal: onShowAddComponentModal,
            addComponentModalOpen: v23.value0.addComponentModalOpen,
            onDeleteSelectedComponents: function __do() {
                dispatchEstimationComponentAction(appDispatch)({
                    projectId: props.projectId,
                    params: Types_Estimation.DeleteEstimationComponents.create(CostEstimation_CopyComponents.selectedECWRComponentIds(v23.value0.components)),
                    handler: Control_Applicative.pure(Control_Applicative.applicativeFn)(Data_Monoid.mempty(Effect.monoidEffect(Data_Monoid.monoidUnit)))
                })();
                return unselectAllComponents();
            },
            selectedRows: selectedEstimationComponentsWithResources(v23.value0.unsortedComponents),
            projectFullName: Data_Newtype.un()(Types_Project.ProjectCode)(props.project.code) + (" " + props.project.name),
            copySelectedComponentsToOtherProject: copySelectedComponentsToOtherProject,
            selectedInputColumn: v23.value0.selectedInputColumn,
            setSelectedInputColumn: function (mCol) {
                return v23.value1(function (v29) {
                    return {
                        selectedComponentIdx: v29.selectedComponentIdx,
                        checkedComponents: v29.checkedComponents,
                        shouldScrollToSelection: v29.shouldScrollToSelection,
                        editSortingCriteria: v29.editSortingCriteria,
                        referenceSortingCriteria: v29.referenceSortingCriteria,
                        unsortedComponents: v29.unsortedComponents,
                        components: v29.components,
                        editActiveFilters: v29.editActiveFilters,
                        lastCheckedItem: v29.lastCheckedItem,
                        addComponentModalOpen: v29.addComponentModalOpen,
                        updateSelectedComponentIdxOnTick: v29.updateSelectedComponentIdxOnTick,
                        selectedInputColumn: mCol,
                        groupBy: v29.groupBy
                    };
                });
            },
            selectionProps: {
                onToggleSelectAll: onToggleSelectAll,
                isSelectionEmpty: isSelectionEmpty
            },
            dixonOptions: props.dixonOptions,
            showSplitComponentWithMissingResourcesColumn: Data_Eq.eq(Data_Maybe.eqMaybe(Types_ComponentsView.eqECGroupByProperty))(v23.value0.groupBy)(new Data_Maybe.Just(Types_ComponentsView.GroupByGroup.value)),
            initialScrollRow: initialScrollRow,
            projectCurrency: props.project.currency
        });
        var componentDetails = (function () {
            if (selectedComponent instanceof Data_Maybe.Just && notTopicComponent(selectedComponent.value0)) {
                return Data_Maybe.Just.create(CostEstimation_SelectedComponentDetails.selectedComponentDetails({
                    updateStatuses: props.updateStatuses,
                    selectedComponent: selectedComponent.value0,
                    componentSplitParams: Data_Maybe.Nothing.value,
                    projectId: props.projectId,
                    project: props.project,
                    viewMode: Types_CostEstimation.EditMode.value,
                    programType: props.project.programType,
                    isReportingProject: props.project.isReportingProject,
                    socialExpensePercentagesByCostClass: v7.socialExpensePercentagesByCostClass,
                    showSocialExpensesInCosts: v7.showSocialExpensesInCosts,
                    showWorkAchievement: v8.showWorkAchievement,
                    buildingDescriptionBySpecId: buildingDescriptionBySpecId,
                    selectComponent: selectComponent,
                    toggleCheckbox: toggleCheckbox,
                    closeDetails: selectComponent(v23.value0.shouldScrollToSelection)(Data_Maybe.Nothing.value),
                    referencePanelOptions: Data_Maybe.Nothing.value,
                    panelMode: panelProps.detailsPanelMode,
                    setPanelMode: panelProps.setDetailsPanelMode,
                    setParentSelectedResourceIds: new Data_Maybe.Just(v24.value1),
                    hideResources: Data_Maybe.fromMaybe(false)(Data_Functor.map(Data_Maybe.functorMaybe)(function (v29) {
                        return v29.hideResources;
                    })(props.dixonOptions))
                }));
            };
            return Data_Maybe.Nothing.value;
        })();
        var v29 = ReferenceLibrarySidepanel.useActiveRefMode();
        var v30 = TacoLoader.useLoading(false)();
        var leftPanel = CostEstimation_SummariesPanel.component({
            panelMode: panelProps.leftPanelMode,
            getContents: function (v31) {
                return React_Basic.fragment((function () {
                    var summaries = ComponentSummaries.component({
                        filteredRows: v23.value0.unsortedComponents,
                        selectedRows: selectedRows,
                        estimationViewMode: Types_CostEstimation.EditMode.value,
                        projectId: props.projectId,
                        socialExpensePercentagesByCostClassWithHash: v7.socialExpensePercentagesByCostClassWithHash,
                        unfilteredSocialExpensePercentagesByCostClass: v7.unfilteredSocialExpensePercentagesByCostClass,
                        showSocialExpensesInCosts: v7.showSocialExpensesInCosts,
                        costClassesWithHash: v15.value0,
                        activeComponentFilters: v23.value0.editActiveFilters,
                        setLoading: v30.setLoading,
                        projectCurrency: props.project.currency
                    });
                    var subprojectsList = Data_Monoid.guard(React_Basic.monoidJSX)(subprojectsEnabled)(SubprojectsTree.subprojectsTree({
                        socialExpensePercentagesByCostClassWithHash: v7.socialExpensePercentagesByCostClassWithHash,
                        unfilteredSocialExpensePercentagesByCostClass: v7.unfilteredSocialExpensePercentagesByCostClass,
                        showSocialExpensesInCosts: v7.showSocialExpensesInCosts,
                        subprojects: v3.value0,
                        computedComponentWithResources: v16.value0,
                        toggleSubprojectFilter: toggleSubprojectFilter,
                        estimationElements: v5.value0,
                        estimationComponents: v4.value0,
                        projectId: props.projectId,
                        setLoading: v30.setLoading,
                        projectCurrency: props.project.currency
                    }));
                    var locationsList = Data_Monoid.guard(React_Basic.monoidJSX)(locationsEnabled)(ProjectLocationsTree.projectLocationsTree({
                        projectId: props.projectId,
                        projectCurrency: props.project.currency,
                        socialExpensePercentagesByCostClassWithHash: v7.socialExpensePercentagesByCostClassWithHash,
                        unfilteredSocialExpensePercentagesByCostClass: v7.unfilteredSocialExpensePercentagesByCostClass,
                        showSocialExpensesInCosts: v7.showSocialExpensesInCosts,
                        estimationLocations: v6.value0,
                        computedComponentWithResources: v16.value0,
                        toggleLocationFilter: toggleLocationFilter,
                        setLoading: v30.setLoading
                    }));
                    var groupsTree = Data_Monoid.guard(React_Basic.monoidJSX)(componentGroupsEnabled)(ComponentGroupsTree.componentGroupsTree({
                        socialExpensePercentagesByCostClassWithHash: v7.socialExpensePercentagesByCostClassWithHash,
                        unfilteredSocialExpensePercentagesByCostClass: v7.unfilteredSocialExpensePercentagesByCostClass,
                        showSocialExpensesInCosts: v7.showSocialExpensesInCosts,
                        projectId: props.projectId,
                        toggleGroupFilter: toggleGroupFilter,
                        toggleWithoutGroupFilter: toggleWithoutGroupFilter,
                        computedComponentWithResources: v16.value0,
                        projectCurrency: props.project.currency,
                        setLoading: v30.setLoading
                    }));
                    var costGroupsTree = (function () {
                        if (v22.showTargetCostGroup) {
                            return ComponentCostGroupsTree.componentCostGroupsTree({
                                showSocialExpensesInCosts: v7.showSocialExpensesInCosts,
                                projectId: props.projectId,
                                toggleCostGroupFilter: toggleCostGroupFilter,
                                toggleWithoutCostGroupFilter: toggleWithoutCostGroupFilter,
                                setLoading: v30.setLoading,
                                projectCurrency: props.project.currency
                            });
                        };
                        return React_Basic.empty;
                    })();
                    var classificationTable = ProjectClassificationTable.projectClassificationTable({
                        selectWithAClassificationCode: selectWithAClassificationCode,
                        showSocialExpensesInCosts: v7.showSocialExpensesInCosts,
                        projectId: props.projectId,
                        project: props.project,
                        toggleCodeFilter: toggleCodeFilter,
                        toggleNoCodeMatchFilter: toggleNoCodeMatchFilter,
                        setLoading: v30.setLoading
                    });
                    return [ summaries, classificationTable, subprojectsList, locationsList, groupsTree, costGroupsTree ];
                })());
            }
        });
        var sideBar = CostEstimation_SummariesSidebar.component({
            panelMode: panelProps.leftPanelMode,
            setPanelMode: panelProps.setLeftPanelMode
        });
        return React_Basic.fragment([ CostEstimation_Layout.component({
            controls: new Data_Maybe.Just(componentControls),
            controlsLoading: (function () {
                if (loading) {
                    return CostEstimationLayout_Types.LoadingBarDimmedContents.value;
                };
                return CostEstimationLayout_Types.LoadingFinished.value;
            })(),
            leftPanel: Data_Maybe.Just.create(leftPanel),
            leftSidebar: Data_Maybe.Just.create(sideBar),
            main: componentsTable,
            rightPanel: (function () {
                var $220 = Data_Maybe.isNothing(props.dixonOptions);
                if ($220) {
                    return Data_Maybe.Just.create(ReferenceLibrarySidepanel.component({
                        selectedEditViewElement: Data_Maybe.Nothing.value,
                        selectedEditViewElementMeasures: Data_Maybe.Nothing.value,
                        selectedEditViewElementSpecId: Data_Maybe.Nothing.value,
                        selectedEditViewComponent: selectedComponent,
                        selectedEditViewResource: Data_Maybe.Nothing.value,
                        selectedEditViewResourceIds: v24.value0,
                        projectId: props.projectId,
                        project: props.project,
                        activeRefMode: v29.activeRefMode
                    }));
                };
                return Data_Maybe.Nothing.value;
            })(),
            rightSidebar: (function () {
                var $221 = Data_Maybe.isNothing(props.dixonOptions);
                if ($221) {
                    return Data_Maybe.Just.create(ReferenceLibrarySidebar.component({
                        activeRefMode: v29.activeRefMode,
                        setActiveRefMode: v29.setActiveRefMode
                    }));
                };
                return Data_Maybe.Nothing.value;
            })(),
            details: componentDetails,
            panelProps: {
                leftPanelMode: panelProps.leftPanelMode,
                setLeftPanelMode: panelProps.setLeftPanelMode,
                rightPanelMode: (function () {
                    if (v29.hasActiveRefMode) {
                        return CostEstimationLayout_Types.NormalMode.value;
                    };
                    return CostEstimationLayout_Types.MinimizedMode.value;
                })(),
                setRightPanelMode: panelProps.setRightPanelMode,
                detailsPanelMode: panelProps.detailsPanelMode,
                setDetailsPanelMode: panelProps.setDetailsPanelMode
            },
            leftPanelLoading: (function () {
                if (v30.isLoading) {
                    return CostEstimationLayout_Types.LoadingBarDimmedContents.value;
                };
                return CostEstimationLayout_Types.LoadingFinished.value;
            })(),
            mRefPanelMode: Data_Maybe.Nothing.value
        }) ]);
    };
});
module.exports = {
    component: component
};
