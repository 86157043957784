import { TFunction } from "react-i18next";
import { FullReportData } from "./Report";

export const createC200Data = (t: TFunction, fullData: FullReportData) => {
  const componentsWithClasses = fullData?.componentsWithResourcesData.map(
    (data) => {
      const codeStartsWith = data.component.code?.slice(0, 2);
      const componentsClass = fullData?.componentsClassData?.find(
        (c) => c.code === codeStartsWith
      );
      const componentsCostClasses = data.totalPriceByCostClasses.filter(
        (cc) => cc.totalPrice !== 0
      );
      const hours = componentsCostClasses
        .map((cc) => cc.hours)
        .reduce((a, b) => a + b, 0);
      const hoursPerUnit = hours / data.component.amount;
      const pricePerHour = data.totalPrice / hours;
      const pricePerUnit = data.totalPrice / data.component.amount;
      const costClassesCodes = componentsCostClasses.map(
        (cc) => cc.costClassCode
      );
      return {
        code: data.component.code,
        description: data.component.description,
        subProject: data.component.subProjectCode,
        quantity: data.component.amount,
        unit: data.component.unit,
        hours: hours,
        hoursPerUnit: hoursPerUnit,
        pricePerHour: pricePerHour,
        cc: costClassesCodes,
        pricePerUnit: pricePerUnit,
        totalPrice: data.totalPrice,
        costGroupCode: componentsClass?.code,
        costGroupDescription: componentsClass?.description,
      };
    }
  );

  const componentDataAsStr = JSON.stringify(componentsWithClasses);
  const projectDataAsStr = JSON.stringify(fullData?.projectData);
  const subProjectsDataAsStr = JSON.stringify(fullData?.subProjects);
  const connectComponentsString = `jsondata=${componentDataAsStr}`;
  const connectProjectsDetailsString = `jsondata=${projectDataAsStr}`;
  const connectSubProjectsString = `jsondata=${subProjectsDataAsStr}`;

  return {
    Name: "C200",
    Width: "0in",
    ReportParameters: [
      {
        AllowBlank: true,
        DataType: "String",
        DefaultValue: { Values: ["Kustannuslaskelma"] },
        Name: "title",
        Prompt: t("title"),
        ValidValues: { OrderBy: { Condition: "None" } },
      },
      {
        AllowBlank: true,
        DataType: "String",
        DefaultValue: {
          DataSetReference: {
            DataSetName: "ProjectDetails",
            ValueField: "=Fields!projectName.Value",
          },
        },
        Name: "projectName",
        Prompt: t("projectName"),
        ValidValues: { OrderBy: { Condition: "None" } },
      },
      {
        DataType: "String",
        MultiValue: true,
        Name: "subProjects",
        Prompt: t("subProjects"),
        ValidValues: {
          DataSetReference: {
            DataSetName: "SubProjects",
            ValueField: "subProjectCode",
            LabelField: "subProjectCode",
          },
          OrderBy: { Condition: "None" },
        },
      },
    ],
    Layers: [{ Name: "default" }],
    CustomProperties: [
      { Name: "DisplayType", Value: "Page" },
      { Name: "SizeType", Value: "Default" },
      { Name: "CollapseWhiteSpace", Value: "True" },
    ],
    Version: "7.6.0",
    Page: {
      PageWidth: "8.5in",
      PageHeight: "11in",
      RightMargin: "0in",
      LeftMargin: "0in",
      TopMargin: "0in",
      BottomMargin: "0in",
      Columns: 1,
      ColumnSpacing: "0.25in",
      PaperOrientation: "Portrait",
    },
    DataSources: [
      {
        Name: "ComponentsJSON",
        ConnectionProperties: {
          DataProvider: "JSONEMBED",
          ConnectString: connectComponentsString,
        },
      },
      {
        Name: "ProjectDetailsJSON",
        ConnectionProperties: {
          DataProvider: "JSONEMBED",
          ConnectString: connectProjectsDetailsString,
        },
      },

      {
        Name: "SubProjectsJSON",
        ConnectionProperties: {
          DataProvider: "JSONEMBED",
          ConnectString: connectSubProjectsString,
        },
      },
    ],
    ReportSections: [
      {
        Type: "Continuous",
        Name: "ContinuousSection1",
        Page: {
          PageWidth: "8.5in",
          PageHeight: "11in",
          RightMargin: "0.25in",
          LeftMargin: "0.25in",
          TopMargin: "0.5in",
          BottomMargin: "1in",
          Columns: 1,
          ColumnSpacing: "0in",
          PaperOrientation: "Portrait",
        },
        Width: "8.0105in",
        Body: {
          Height: "1.3334in",
          ReportItems: [
            {
              Type: "table",
              Name: "Table2",
              ZIndex: 1,
              DataSetName: "Components",
              TableColumns: [
                { Width: "0.75in" },
                { Width: "2in" },
                { Width: "0.25in" },
                { Width: "0.5in" },
                { Width: "0.75in" },
                { Width: "0.5in" },
                { Width: "0.5in" },
                { Width: "0.5in" },
                { Width: "0.25in" },
                { Width: "0.75in" },
                { Width: "1in" },
              ],
              Header: {
                TableRows: [
                  {
                    Height: "0.191in",
                    TableCells: [
                      {
                        Item: {
                          Type: "textbox",
                          Name: "TextBox1",
                          CanGrow: true,
                          KeepTogether: true,
                          Value: "Koodi",
                          Style: {
                            PaddingLeft: "2pt",
                            PaddingRight: "2pt",
                            PaddingTop: "2pt",
                            PaddingBottom: "2pt",
                          },
                          Width: "0.649in",
                          Height: "0.191in",
                        },
                      },
                      {
                        Item: {
                          Type: "textbox",
                          Name: "TextBox2",
                          CanGrow: true,
                          KeepTogether: true,
                          Value: "Selite",
                          Style: {
                            PaddingLeft: "2pt",
                            PaddingRight: "2pt",
                            PaddingTop: "2pt",
                            PaddingBottom: "2pt",
                          },
                          Width: "1.5142in",
                          Height: "0.191in",
                        },
                      },
                      {
                        Item: {
                          Type: "textbox",
                          Name: "TextBox3",
                          CanGrow: true,
                          KeepTogether: true,
                          Style: {
                            PaddingLeft: "2pt",
                            PaddingRight: "2pt",
                            PaddingTop: "2pt",
                            PaddingBottom: "2pt",
                          },
                          Width: "0.2163in",
                          Height: "0.191in",
                        },
                      },
                      {
                        Item: {
                          Type: "textbox",
                          Name: "TextBox4",
                          CanGrow: true,
                          KeepTogether: true,
                          Value: "Määrä",
                          Style: {
                            PaddingLeft: "2pt",
                            PaddingRight: "2pt",
                            PaddingTop: "2pt",
                            PaddingBottom: "2pt",
                          },
                          Width: "0.4326in",
                          Height: "0.191in",
                        },
                      },
                      {
                        Item: {
                          Type: "textbox",
                          Name: "TextBox5",
                          CanGrow: true,
                          KeepTogether: true,
                          Value: "Yksikkö",
                          Style: {
                            PaddingLeft: "2pt",
                            PaddingRight: "2pt",
                            PaddingTop: "2pt",
                            PaddingBottom: "2pt",
                          },
                          Width: "0.649in",
                          Height: "0.191in",
                        },
                      },
                      {
                        Item: {
                          Type: "textbox",
                          Name: "TextBox6",
                          CanGrow: true,
                          KeepTogether: true,
                          Value: "H/Yks",
                          Style: {
                            PaddingLeft: "2pt",
                            PaddingRight: "2pt",
                            PaddingTop: "2pt",
                            PaddingBottom: "2pt",
                          },
                          Width: "0.4326in",
                          Height: "0.191in",
                        },
                      },
                      {
                        Item: {
                          Type: "textbox",
                          Name: "TextBox7",
                          CanGrow: true,
                          KeepTogether: true,
                          Value: "H",
                          Style: {
                            PaddingLeft: "2pt",
                            PaddingRight: "2pt",
                            PaddingTop: "2pt",
                            PaddingBottom: "2pt",
                          },
                          Width: "0.4326in",
                          Height: "0.191in",
                        },
                      },
                      {
                        Item: {
                          Type: "textbox",
                          Name: "TextBox8",
                          CanGrow: true,
                          KeepTogether: true,
                          Value: "EUR/h",
                          Style: {
                            PaddingLeft: "2pt",
                            PaddingRight: "2pt",
                            PaddingTop: "2pt",
                            PaddingBottom: "2pt",
                          },
                          Width: "0.4326in",
                          Height: "0.191in",
                        },
                      },
                      {
                        Item: {
                          Type: "textbox",
                          Name: "TextBox9",
                          CanGrow: true,
                          KeepTogether: true,
                          Value: "PL",
                          Style: {
                            PaddingLeft: "2pt",
                            PaddingRight: "2pt",
                            PaddingTop: "2pt",
                            PaddingBottom: "2pt",
                          },
                          Width: "0.2163in",
                          Height: "0.191in",
                        },
                      },
                      {
                        Item: {
                          Type: "textbox",
                          Name: "TextBox10",
                          CanGrow: true,
                          KeepTogether: true,
                          Value: "EUR/Yks",
                          Style: {
                            PaddingLeft: "2pt",
                            PaddingRight: "2pt",
                            PaddingTop: "2pt",
                            PaddingBottom: "2pt",
                          },
                          Width: "0.649in",
                          Height: "0.191in",
                        },
                      },
                      {
                        Item: {
                          Type: "textbox",
                          Name: "TextBox11",
                          CanGrow: true,
                          KeepTogether: true,
                          Value: "Yhteensä",
                          Style: {
                            PaddingLeft: "2pt",
                            PaddingRight: "2pt",
                            PaddingTop: "2pt",
                            PaddingBottom: "2pt",
                          },
                          Width: "0.8654in",
                          Height: "0.191in",
                        },
                      },
                    ],
                  },
                ],
                RepeatOnNewPage: true,
              },
              TableGroups: [
                {
                  Group: {
                    Name: "Table2_TableGroup1",
                    GroupExpressions: ["=Fields!costGroupCode.Value"],
                  },
                  Footer: {
                    TableRows: [
                      {
                        Height: "0.1909in",
                        TableCells: [
                          {
                            Item: {
                              Type: "textbox",
                              Name: "TextBox23",
                              DataElementName: "costGroupCode",
                              CanGrow: true,
                              KeepTogether: true,
                              Value: "=Fields!costGroupCode.Value",
                              Style: {
                                PaddingLeft: "2pt",
                                PaddingRight: "2pt",
                                PaddingTop: "2pt",
                                PaddingBottom: "2pt",
                              },
                              Width: "0.649in",
                              Height: "0.1909in",
                            },
                          },
                          {
                            Item: {
                              Type: "textbox",
                              Name: "TextBox24",
                              CanGrow: true,
                              KeepTogether: true,
                              Value:
                                '=Fields!costGroupDescription.Value & " yhteensä"',
                              Style: {
                                PaddingLeft: "2pt",
                                PaddingRight: "2pt",
                                PaddingTop: "2pt",
                                PaddingBottom: "2pt",
                              },
                              Width: "1.5142in",
                              Height: "0.1909in",
                            },
                          },
                          {
                            Item: {
                              Type: "textbox",
                              Name: "TextBox25",
                              CanGrow: true,
                              KeepTogether: true,
                              Style: {
                                PaddingLeft: "2pt",
                                PaddingRight: "2pt",
                                PaddingTop: "2pt",
                                PaddingBottom: "2pt",
                              },
                              Width: "0.2163in",
                              Height: "0.1909in",
                            },
                          },
                          {
                            Item: {
                              Type: "textbox",
                              Name: "TextBox26",
                              DataElementName: "quantity",
                              CanGrow: true,
                              KeepTogether: true,
                              Style: {
                                PaddingLeft: "2pt",
                                PaddingRight: "2pt",
                                PaddingTop: "2pt",
                                PaddingBottom: "2pt",
                              },
                              Width: "0.4326in",
                              Height: "0.1909in",
                            },
                          },
                          {
                            Item: {
                              Type: "textbox",
                              Name: "TextBox27",
                              CanGrow: true,
                              KeepTogether: true,
                              Style: {
                                PaddingLeft: "2pt",
                                PaddingRight: "2pt",
                                PaddingTop: "2pt",
                                PaddingBottom: "2pt",
                              },
                              Width: "0.649in",
                              Height: "0.1909in",
                            },
                          },
                          {
                            Item: {
                              Type: "textbox",
                              Name: "TextBox28",
                              DataElementName: "hoursPerUnit",
                              CanGrow: true,
                              KeepTogether: true,
                              Style: {
                                PaddingLeft: "2pt",
                                PaddingRight: "2pt",
                                PaddingTop: "2pt",
                                PaddingBottom: "2pt",
                              },
                              Width: "0.4326in",
                              Height: "0.1909in",
                            },
                          },
                          {
                            Item: {
                              Type: "textbox",
                              Name: "TextBox29",
                              DataElementName: "hours",
                              CanGrow: true,
                              KeepTogether: true,
                              Style: {
                                PaddingLeft: "2pt",
                                PaddingRight: "2pt",
                                PaddingTop: "2pt",
                                PaddingBottom: "2pt",
                              },
                              Width: "0.4326in",
                              Height: "0.1909in",
                            },
                          },
                          {
                            Item: {
                              Type: "textbox",
                              Name: "TextBox30",
                              DataElementName: "pricePerHours",
                              CanGrow: true,
                              KeepTogether: true,
                              Style: {
                                PaddingLeft: "2pt",
                                PaddingRight: "2pt",
                                PaddingTop: "2pt",
                                PaddingBottom: "2pt",
                              },
                              Width: "0.4326in",
                              Height: "0.1909in",
                            },
                          },
                          {
                            Item: {
                              Type: "textbox",
                              Name: "TextBox31",
                              CanGrow: true,
                              KeepTogether: true,
                              Style: {
                                PaddingLeft: "2pt",
                                PaddingRight: "2pt",
                                PaddingTop: "2pt",
                                PaddingBottom: "2pt",
                              },
                              Width: "0.2163in",
                              Height: "0.1909in",
                            },
                          },
                          {
                            Item: {
                              Type: "textbox",
                              Name: "TextBox32",
                              DataElementName: "pricePerUnit",
                              CanGrow: true,
                              KeepTogether: true,
                              Style: {
                                PaddingLeft: "2pt",
                                PaddingRight: "2pt",
                                PaddingTop: "2pt",
                                PaddingBottom: "2pt",
                              },
                              Width: "0.649in",
                              Height: "0.1909in",
                            },
                          },
                          {
                            Item: {
                              Type: "textbox",
                              Name: "TextBox33",
                              DataElementName: "totalPrice",
                              CanGrow: true,
                              KeepTogether: true,
                              Value: "=Round(Sum(Fields!totalPrice.Value), 2)",
                              Style: {
                                PaddingLeft: "2pt",
                                PaddingRight: "2pt",
                                PaddingTop: "2pt",
                                PaddingBottom: "2pt",
                              },
                              Width: "0.8654in",
                              Height: "0.1909in",
                            },
                          },
                        ],
                      },
                    ],
                  },
                },
              ],
              Details: {
                TableRows: [
                  {
                    Height: "0.191in",
                    TableCells: [
                      {
                        Item: {
                          Type: "textbox",
                          Name: "TextBox12",
                          DataElementName: "code",
                          CanGrow: true,
                          KeepTogether: true,
                          Value: "=Fields!code.Value",
                          Style: {
                            Border: { Style: "Solid" },
                            TopBorder: { Style: "Solid" },
                            BottomBorder: { Style: "Solid" },
                            LeftBorder: { Style: "None" },
                            RightBorder: { Style: "None" },
                            PaddingLeft: "2pt",
                            PaddingRight: "2pt",
                            PaddingTop: "2pt",
                            PaddingBottom: "2pt",
                          },
                          Width: "0.649in",
                          Height: "0.191in",
                        },
                      },
                      {
                        Item: {
                          Type: "textbox",
                          Name: "TextBox13",
                          DataElementName: "description",
                          CanGrow: true,
                          KeepTogether: true,
                          Value: "=Fields!description.Value",
                          Style: {
                            Border: { Style: "Solid" },
                            TopBorder: { Style: "Solid" },
                            BottomBorder: { Style: "Solid" },
                            LeftBorder: { Style: "None" },
                            RightBorder: { Style: "None" },
                            PaddingLeft: "2pt",
                            PaddingRight: "2pt",
                            PaddingTop: "2pt",
                            PaddingBottom: "2pt",
                          },
                          Width: "1.5142in",
                          Height: "0.191in",
                        },
                      },
                      {
                        Item: {
                          Type: "textbox",
                          Name: "TextBox14",
                          DataElementName: "subProject",
                          CanGrow: true,
                          KeepTogether: true,
                          Value: "=Fields!subProject.Value",
                          Style: {
                            Border: { Style: "Solid" },
                            TopBorder: { Style: "Solid" },
                            BottomBorder: { Style: "Solid" },
                            LeftBorder: { Style: "None" },
                            RightBorder: { Style: "Solid" },
                            PaddingLeft: "2pt",
                            PaddingRight: "2pt",
                            PaddingTop: "2pt",
                            PaddingBottom: "2pt",
                          },
                          Width: "0.2163in",
                          Height: "0.191in",
                        },
                      },
                      {
                        Item: {
                          Type: "textbox",
                          Name: "TextBox15",
                          DataElementName: "quantity",
                          CanGrow: true,
                          KeepTogether: true,
                          Value: "=Round(Fields!quantity.Value, 2)",
                          Style: {
                            Border: { Style: "Solid" },
                            TopBorder: { Style: "Solid" },
                            BottomBorder: { Style: "Solid" },
                            LeftBorder: { Style: "Solid" },
                            RightBorder: { Style: "Solid" },
                            PaddingLeft: "2pt",
                            PaddingRight: "2pt",
                            PaddingTop: "2pt",
                            PaddingBottom: "2pt",
                          },
                          Width: "0.4326in",
                          Height: "0.191in",
                        },
                      },
                      {
                        Item: {
                          Type: "textbox",
                          Name: "TextBox16",
                          DataElementName: "unit",
                          CanGrow: true,
                          KeepTogether: true,
                          Value: "=Fields!unit.Value",
                          Style: {
                            Border: { Style: "Solid" },
                            TopBorder: { Style: "Solid" },
                            BottomBorder: { Style: "Solid" },
                            LeftBorder: { Style: "Solid" },
                            RightBorder: { Style: "Solid" },
                            PaddingLeft: "2pt",
                            PaddingRight: "2pt",
                            PaddingTop: "2pt",
                            PaddingBottom: "2pt",
                          },
                          Width: "0.649in",
                          Height: "0.191in",
                        },
                      },
                      {
                        Item: {
                          Type: "textbox",
                          Name: "TextBox17",
                          DataElementName: "hoursPerUnit",
                          CanGrow: true,
                          KeepTogether: true,
                          Value: "=Round(Fields!hoursPerUnit.Value, 2)",
                          Style: {
                            Border: { Style: "Solid" },
                            TopBorder: { Style: "Solid" },
                            BottomBorder: { Style: "Solid" },
                            LeftBorder: { Style: "Solid" },
                            RightBorder: { Style: "Solid" },
                            PaddingLeft: "2pt",
                            PaddingRight: "2pt",
                            PaddingTop: "2pt",
                            PaddingBottom: "2pt",
                          },
                          Width: "0.4326in",
                          Height: "0.191in",
                        },
                      },
                      {
                        Item: {
                          Type: "textbox",
                          Name: "TextBox18",
                          DataElementName: "hours",
                          CanGrow: true,
                          KeepTogether: true,
                          Value: "=Round(Fields!hours.Value, 2)",
                          Style: {
                            Border: { Style: "Solid" },
                            TopBorder: { Style: "Solid" },
                            BottomBorder: { Style: "Solid" },
                            LeftBorder: { Style: "Solid" },
                            RightBorder: { Style: "Solid" },
                            PaddingLeft: "2pt",
                            PaddingRight: "2pt",
                            PaddingTop: "2pt",
                            PaddingBottom: "2pt",
                          },
                          Width: "0.4326in",
                          Height: "0.191in",
                        },
                      },
                      {
                        Item: {
                          Type: "textbox",
                          Name: "TextBox19",
                          DataElementName: "pricePerUnit",
                          CanGrow: true,
                          KeepTogether: true,
                          Value: "=Round(Fields!pricePerUnit.Value, 2)",
                          Style: {
                            Border: { Style: "Solid" },
                            TopBorder: { Style: "Solid" },
                            BottomBorder: { Style: "Solid" },
                            LeftBorder: { Style: "Solid" },
                            RightBorder: { Style: "Solid" },
                            PaddingLeft: "2pt",
                            PaddingRight: "2pt",
                            PaddingTop: "2pt",
                            PaddingBottom: "2pt",
                          },
                          Width: "0.4326in",
                          Height: "0.191in",
                        },
                      },
                      {
                        Item: {
                          Type: "textbox",
                          Name: "TextBox20",
                          DataElementName: "cc",
                          CanGrow: true,
                          KeepTogether: true,
                          Value: "=Join(Fields!cc.Value, vbCrLf)",
                          Style: {
                            Border: { Style: "Solid" },
                            TopBorder: { Style: "Solid" },
                            BottomBorder: { Style: "Solid" },
                            LeftBorder: { Style: "Solid" },
                            RightBorder: { Style: "Solid" },
                            PaddingLeft: "2pt",
                            PaddingRight: "2pt",
                            PaddingTop: "2pt",
                            PaddingBottom: "2pt",
                          },
                          Width: "0.2163in",
                          Height: "0.191in",
                        },
                      },
                      {
                        Item: {
                          Type: "textbox",
                          Name: "TextBox21",
                          CanGrow: true,
                          KeepTogether: true,
                          Value: "=Round(Fields!pricePerUnit.Value, 2)",
                          Style: {
                            Border: { Style: "Solid" },
                            TopBorder: { Style: "Solid" },
                            BottomBorder: { Style: "Solid" },
                            LeftBorder: { Style: "Solid" },
                            RightBorder: { Style: "Solid" },
                            PaddingLeft: "2pt",
                            PaddingRight: "2pt",
                            PaddingTop: "2pt",
                            PaddingBottom: "2pt",
                          },
                          Width: "0.649in",
                          Height: "0.191in",
                        },
                      },
                      {
                        Item: {
                          Type: "textbox",
                          Name: "TextBox22",
                          CanGrow: true,
                          KeepTogether: true,
                          Value: "=Round(Fields!totalPrice.Value, 2)",
                          Style: {
                            Border: { Style: "Solid" },
                            TopBorder: { Style: "Solid" },
                            BottomBorder: { Style: "Solid" },
                            LeftBorder: { Style: "Solid" },
                            RightBorder: { Style: "None" },
                            PaddingLeft: "2pt",
                            PaddingRight: "2pt",
                            PaddingTop: "2pt",
                            PaddingBottom: "2pt",
                          },
                          Width: "0.8654in",
                          Height: "0.191in",
                        },
                      },
                    ],
                  },
                ],
                Group: {
                  Name: "Table2_DetailsGroup1",
                  GroupExpressions: [
                    "=Fields!costGroupCode.Value",
                    "=Fields!code.Value",
                  ],
                },
              },
              Left: "0.0105in",
              Top: "0.7605in",
              Width: "8in",
              Height: "0.5729in",
            },
            {
              Type: "textbox",
              Name: "TextBox34",
              ZIndex: 2,
              CanGrow: true,
              KeepTogether: true,
              Value:
                '=Globals!PageNumberInSection & " / " & Globals!TotalPagesInSection',
              Style: {
                PaddingLeft: "2pt",
                PaddingRight: "2pt",
                PaddingTop: "2pt",
                PaddingBottom: "2pt",
                TextAlign: "Right",
              },
              Left: "5.8in",
              Top: "0.2813in",
              Width: "2in",
              Height: "0.2396in",
            },
            {
              Type: "textbox",
              Name: "TextBox35",
              ZIndex: 3,
              CanGrow: true,
              KeepTogether: true,
              Value:
                '=Day(Globals!ExecutionTime) & "." & Month(Globals!ExecutionTime) & ". " & Year(Globals!ExecutionTime)',
              Style: {
                PaddingLeft: "2pt",
                PaddingRight: "2pt",
                PaddingTop: "2pt",
                PaddingBottom: "2pt",
                TextAlign: "Right",
              },
              Left: "5.8in",
              Top: "0.0208in",
              Width: "2in",
              Height: "0.25in",
            },
            {
              Type: "textbox",
              Name: "TextBox37",
              ZIndex: 4,
              CanGrow: true,
              KeepTogether: true,
              Value: "=Parameters!title.Value",
              Style: {
                FontSize: "18pt",
                FontWeight: "Bold",
                PaddingLeft: "2pt",
                PaddingRight: "2pt",
                PaddingTop: "2pt",
                PaddingBottom: "2pt",
              },
              Left: "0.0104in",
              Width: "4.5104in",
              Height: "0.3542in",
            },
            {
              Type: "textbox",
              Name: "TextBox38",
              ZIndex: 5,
              CanGrow: true,
              KeepTogether: true,
              Value: "=Parameters!projectName.Value",
              Style: {
                PaddingLeft: "2pt",
                PaddingRight: "2pt",
                PaddingTop: "2pt",
                PaddingBottom: "2pt",
              },
              Top: "0.3543in",
              Width: "4.5208in",
              Height: "0.25in",
            },
          ],
        },
      },
    ],
    DataSets: [
      {
        Name: "Components",
        Fields: [
          { Name: "code", DataField: "code" },
          { Name: "description", DataField: "description" },
          { Name: "subProject", DataField: "subProject" },
          { Name: "quantity", DataField: "quantity" },
          { Name: "unit", DataField: "unit" },
          { Name: "hours", DataField: "hours" },
          { Name: "hoursPerUnit", DataField: "hoursPerUnit" },
          { Name: "pricePerHours", DataField: "pricePerHours" },
          { Name: "cc", DataField: "cc" },
          { Name: "pricePerUnit", DataField: "pricePerUnit" },
          { Name: "totalPrice", DataField: "totalPrice" },
          { Name: "costGroupCode", DataField: "costGroupCode" },
          { Name: "costGroupDescription", DataField: "costGroupDescription" },
        ],
        Query: {
          DataSourceName: "ComponentsJSON",
          CommandText: "jpath=$.[*]",
        },
        Filters: [
          {
            FilterExpression: "=Fields!subProject.Value",
            Operator: "In",
            FilterValues: ["=Parameters!subProjects.Value"],
          },
        ],
        CaseSensitivity: "Auto",
        KanatypeSensitivity: "Auto",
        AccentSensitivity: "Auto",
        WidthSensitivity: "Auto",
      },
      {
        Name: "ProjectDetails",
        Fields: [{ Name: "projectName", DataField: "name" }],
        Query: {
          DataSourceName: "ProjectDetailsJSON",
          CommandText: "jpath=$",
        },
        CaseSensitivity: "Auto",
        KanatypeSensitivity: "Auto",
        AccentSensitivity: "Auto",
        WidthSensitivity: "Auto",
      },
      {
        Name: "SubProjects",
        Fields: [
          { Name: "subProjectCode", DataField: "code" },
          { Name: "subProjectName", DataField: "name" },
        ],
        Query: {
          DataSourceName: "SubProjectsJSON",
          CommandText: "jpath=$.[*]",
        },
        CaseSensitivity: "Auto",
        KanatypeSensitivity: "Auto",
        AccentSensitivity: "Auto",
        WidthSensitivity: "Auto",
      },
    ],
  };
};
